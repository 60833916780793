import styled from '@emotion/styled'
import { cssVariables } from 'theme'

export const Container = styled.div`
  position: relative;
  flex: 6;
  max-width: calc(100vw - 32px);
  min-width: 300px;
  background-color: ${cssVariables.colors.ternaryLightGrey};
  border-radius: 12px;
`

export const Body = styled.div`
  background-color: white;
  box-shadow: 0px 2px 9px -1px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`

export const TabNameWithIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const TabName = styled.p`
  margin-block-start: 0;
  margin-block-end: 0;
  margin-left: 0.5rem;
  padding-top: 0.3rem;
`
// Gray overlay with partial opacity
export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(106, 106, 106, 0.25); /* Gray with 50% opacity */
  z-index: 2;
  border-radius: 10px;
  pointer-events: none; /* Prevents interactions with overlay itself, allowing it to block the content */
`

// Wrapper for the main content
export const Content = styled.div`
  position: relative;
  z-index: 1; /* Keeps content behind overlay when overlay is present */
`
