import { request } from 'utils/request'

export const update = async data => {
  return await request({
    url: `/api/trip-polls-items-options/${data.uid}`,
    method: 'put',
    data: { uid: data.uid, ...data },
  })
}

export const remove = async data => {
  return await request({
    url: '/api/trip-polls-items-options/remove',
    method: 'delete',
    data: { uid: data.uid, ...data },
  })
}
