import React, { useState, useCallback, useEffect } from 'react'

import { makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useSelector } from 'react-redux'
import { MTModal } from 'components/MTModal'
import { isScreenSize } from 'utils/screenSzie'
import { style } from './collaboratorsModal.style'
import { Invitation } from './layouts/Invitation'
import { MembersList } from './layouts/MembersList'
import { ShareTripIcon } from 'components/MTIcons'
import { find } from 'api/tripMemberInvite'
import { CONSTANTS } from 'core/constants'

const useStyles = makeStyles(theme => style(theme))

const CollaboratorsModal = ({
  open,
  setOpen,
  currentTrip,
  setCurrentTripCallback
}) => {
  const classes = useStyles()
  const { user } = useSelector(state => state.user)
  const [fetchTripOnInvite, setFetchTripOnInvite] = useState(false)
  const [invitedMembers, setInvitedMembers] = useState([])
  const isScreenSizeEquals1024 = isScreenSize(1024)
  const isScreenSizeEquals500 = isScreenSize(500)
  const isScreenSizeEquals400 = isScreenSize(400)

  /**
   * Get all invited members which have not yet been admitted to the trip
   */
  const getInvitedMembers = useCallback(async () => {
    try {
      const res = await find({
        short_uid: currentTrip?.short_uid,
        user_uid: user?.uid,
      })
      setInvitedMembers(res)
    } catch (error) {
      console.error('Error fetching invited members:', error)
    } finally {
      setFetchTripOnInvite(false)
    }
  }, [currentTrip?.short_uid, user, setFetchTripOnInvite])

  useEffect(() => {
    if (currentTrip) getInvitedMembers()
  }, [getInvitedMembers, currentTrip])

  let width = '40%'
  if (isScreenSizeEquals1024) {
    width = '60%'
  }
  if (isScreenSizeEquals500) {
    width = '80%'
  }
  if (isScreenSizeEquals400) {
    width = '95%'
  }

  // modal close handler
  const handleClose = () => {
    setOpen(false)
  }

  const modalContent = (
    <div className={classes.container}>
      <div
        className="m-24"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '24px',
          marginBottom: '15px',
        }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <ShareTripIcon width={24} height={24} />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ fontSize: '20px', margin: 0, fontWeight: '700' }}>
              {CONSTANTS.SHARE_TRIP_WITH_MATES}

            </p>
          </div>
        </div>
        <CloseIcon onClick={handleClose} />
      </div>
      <hr style={{ color: '#8F8F8F', opacity: '1' }} />
      <Invitation
        currentTrip={currentTrip}
        setFetchTripOnInvite={setFetchTripOnInvite}
        invitedMembers={invitedMembers}
        setInvitedMembers={setInvitedMembers}
      />
      <MembersList
        currentTrip={currentTrip}
        fetchTripOnInvite={fetchTripOnInvite}
        setFetchTripOnInvite={setFetchTripOnInvite}
        setCurrentTripCallback={setCurrentTripCallback}
        invitedMembers={invitedMembers}
        setInvitedMembers={setInvitedMembers}
      />
    </div>
  )

  // props to pass to modal parent class
  const modalProps = {
    open,
    setOpen,
    modalContent,
    width,
  }
  return <MTModal {...modalProps} onClose={handleClose} />
}

export default CollaboratorsModal
