import React from 'react'

import { cssVariables } from 'theme'

type IconProp = {
  fill?: string
}

const NoteIcon = ({ fill }: IconProp) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6667 0H1.77778C1.30628 0 0.854097 0.186378 0.520699 0.518134C0.187301 0.84989 0 1.29985 0 1.76902V14.1522C0 14.6213 0.187301 15.0713 0.520699 15.4031C0.854097 15.7348 1.30628 15.9212 1.77778 15.9212H14.2222C14.6937 15.9212 15.1459 15.7348 15.4793 15.4031C15.8127 15.0713 16 14.6213 16 14.1522V5.30706L10.6667 0ZM14.2222 13.7522C14.2222 13.9731 14.0431 14.1522 13.8222 14.1522H2.17778C1.95686 14.1522 1.77778 13.9731 1.77778 13.7522V2.16902C1.77778 1.94811 1.95686 1.76902 2.17778 1.76902H9.37778C9.59869 1.76902 9.77778 1.94811 9.77778 2.16902V5.79157C9.77778 6.01249 9.95686 6.19157 10.1778 6.19157H13.8222C14.0431 6.19157 14.2222 6.37066 14.2222 6.59157V13.7522ZM12.4444 8.8451C12.4444 9.3336 12.0484 9.72961 11.5599 9.72961H4.44007C3.95156 9.72961 3.55556 9.3336 3.55556 8.8451V8.8451C3.55556 8.3566 3.95156 7.96059 4.44007 7.96059H11.5599C12.0484 7.96059 12.4444 8.3566 12.4444 8.8451V8.8451Z"
        fill={fill || cssVariables.colors.alternativeDarkGrey2}
      />
      <path
        d="M4.5 12.5H11.6199C12.1084 12.5 12.5044 12.104 12.5044 11.6155C12.5044 11.127 12.1084 10.731 11.6199 10.731H4.5C4.0115 10.731 3.61549 11.127 3.61549 11.6155C3.61549 12.104 4.0115 12.5 4.5 12.5Z"
        fill={fill || cssVariables.colors.alternativeDarkGrey2}
      />
    </svg>
  )
}

export default NoteIcon
