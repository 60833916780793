import styled from '@emotion/styled'

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 8px;
`
export const ListItem = styled.div`
  background: #ddf1ff;
  border-radius: 3px;
  max-width: fit-content;
  padding: 3px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
  font-weight: 450;
  font-size: 1rem;
  width: auto;
  &:nth-of-type(1) {
    flex-shrink: 2;
  }
`

export const LastItem = styled.div`
  background: #ddf1ff;
  border-radius: 3px;
  width: 30px !important;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
  font-weight: 450;
  font-size: 1rem;
`
