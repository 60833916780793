import styled from '@emotion/styled'
import { Language, AccessTime } from '@material-ui/icons'



export const InfoContainer = styled.div`
  border-radius-top-left: 5px;
  left: 0%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const HeaderTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  max-width: 20rem;
  margin-block-start: 0px;
  margin-block-end: 0px;
`

export const DetailsTitle = styled.div`
  font-weight: bold;
  margin-right: 5px;
  margin-block-start: 0px;
  margin-block-end: 0px;
`

export const Details = styled.div`
  font-size: 14px;
  margin-block-start: 0px;
  margin-block-end: 0px;
`

export const DetailsContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: 1.2rem;
  margin-bottom: 0.3rem;
`

export const PhotosCarouselContainer = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  & > div > ul > li {
    width: 160px !important;
  }
`

export const CarouselItem = styled.div`
  height: 100px;
  padding: 0.5rem;
`

export const CarouselItemPhoto = styled.img`
  height: 150px;
  width: 150px;
  float: left;
  object-fit: cover;
  border-radius: 5px;
`
const IconStyle = 'padding-right: 10px;'
export const DetailIconLanguage = styled(Language)`
  ${IconStyle}
`

export const DetailIconAccessTime = styled(AccessTime)`
  ${IconStyle}
`

export const responsive = {
  desktop: {
    breakpoint: { max: 2560, min: 1024 },
    items: 3,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    paritialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
}
