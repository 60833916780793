import { request } from 'utils/request'

export const create = async data => {
    return await request({
        url: '/api/trip-note-item/create',
        method: 'post',
        data,
    })
}

export const getSignedURL = async data => {
    return await request({
        url: '/api/trip-note-item/doc',
        method: 'get',
        searchParams: data,
    })
}

export const update = async(uid, data) => {
    return await request({
        url: `/api/trip-note-item/${uid}`,
        method: 'put',
        data,
    })
}

export const updateOrder = async data => {
    return await request({
        url: `/api/trip-note-item/update-order/${data.uid}`,
        method: 'put',
        data,
    })
}

export const remove = async data => {
    return await request({
        url: '/api/trip-note-item/remove',
        method: 'delete',
        data: { uid: data.uid, ...data },
    })
}