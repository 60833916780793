import styled from '@emotion/styled'
import { Field, Form } from 'formik'

export const Container = styled.div`
  padding: 1rem 2rem;
`

export const NewNoteForm = styled(Form)`
  display: flex;
  flex-direction: column;
`

export const BtnContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

export const NoteTitle = styled(Field)`
  font-size: 1.5rem;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  border: none;
  font-weight: bold;
  &:focus {
    outline-width: 0;
  }
  margin-bottom: 5px;
`

export const NoteDescription = styled(Field)`
  font-family: Roboto;
`

export const ErrorsMsg = styled.div`
  color: red;
`

export const FileInputContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`

export const FileUploadIconLabel = styled.label`
  cursor: pointer;
  margin-right: 10px;
  margin-top: 4px;
`
