/* eslint-disable @typescript-eslint/no-explicit-any */
import { CustomDropDown } from 'components/MTIcons'
import React, { useState, useEffect, useRef } from 'react'
import { style, CenteredContainer } from './TagDropDown.style'
import { useAppSelector } from 'store/hooks'

interface TagDropDownProps {
  handleCustomTagSelect: (arg_0: string, arg_2: string) => void
  handleCustomTagDeselect: (arg_0: string, arg2: string) => void
  selectedTags: string[]
  customTags: string[]
  removeAllTags: (e: React.SyntheticEvent) => void
}

const TagDropDown = ({
  handleCustomTagSelect,
  handleCustomTagDeselect,
  selectedTags,
  customTags,
  removeAllTags,
}: TagDropDownProps) => {
  const styles = style()
  const [open, setOpen] = useState<boolean>(false)
  const dropdownRef = useRef<HTMLDivElement>(null) // Reference to the dropdown

  const toggleDropdown = () => setOpen(!open)

  const handleCheckboxChange = (tag: string) => {
    const colorExists = selectedTags.find(c => c === tag)
    if (colorExists) {
      handleCustomTagDeselect(tag, 'tag')
    }
    if (!colorExists) {
      handleCustomTagSelect(tag, 'tag')
    }
    console.log(selectedTags)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div ref={dropdownRef} style={styles.dropdownContainer}>
      <button onClick={toggleDropdown} style={styles.dropdownButton}>
        <CenteredContainer>
          <input
            type="checkbox"
            title="Colors"
            checked={selectedTags.length > 0}
            style={styles.dropdownItemInput}
            onClick={removeAllTags}
          />
          <p style={styles.buttonText}>Tags</p>
        </CenteredContainer>
        <CenteredContainer style={open ? styles.rotate : {}}>
          <CustomDropDown height="10px" width="5px" />
        </CenteredContainer>
      </button>
      {open && (
        <div style={styles.dropdownMenu}
        >
          {customTags.length < 1 && (
            <p style={{ padding: 0, margin: 0 }}>No Data Found</p>
          )}
          {customTags.map((tag, idx) => (
            <div key={idx} style={styles.dropdownItem} onClick={event => handleCheckboxChange(tag)}>
              <input
                type="checkbox"
                checked={!!selectedTags.find(c => c === tag)}
                style={styles.dropdownItemInput}
              />
              <p style={styles.dropdownText}>{tag}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default TagDropDown
