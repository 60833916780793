import moment from 'moment'

type DateProps = {
  updatedValue: string
  startDate: moment.Moment | null
  endDate: moment.Moment | null
  setFieldValue: (key: string, d: Date) => void
  setStartDateVisual: (d: Date) => void
  setEndDateVisual: (d: Date) => void
  startTime: string
  endTime: string
  setStartTimeVisual: (d: Date) => void
  setEndTimeVisual: (d: Date) => void
}

type TimesProps = {
  updatedValue: string
  startTime: moment.Moment | null
  endTime: moment.Moment | null
  setFieldValue: (key: string, d: Date) => void
  setStartTimeVisual: (d: Date) => void
  setEndTimeVisual: (d: Date) => void
  setStartDateVisual: (d: Date) => void
  setEndDateVisual: (d: Date) => void
}

type UpdateTimeIfOnlyDateChangedProps = {
  formikKey: string
  time: string
  date: moment.Moment
  setLocalTimeInState: (d: Date) => void
  setFieldValue: (key: string, d: Date) => void
}

/**
 * Update start and end date. Pass updated value back to formik
 * and visually display date to the user.
 *
 * @param { String } updatedValue type
 * @param { Date } startDate start date
 * @param { Date } endDate end date
 * @param { Function } setFieldValue Formik callback
 * @param { Function } setStartDateVisual Local start date state setter
 * @param { Function } setEndDateVisual Local end date state setter
 * @param { Date } startTime start time
 * @param { Date } endTime end time
 * @param { Function } setStartTimeVisual Local start time state setter
 * @param { Function } setEndTimeVisual Local end time state setter
 */
export const updateDates = ({
  updatedValue,
  startDate,
  endDate,
  setFieldValue,
  setStartDateVisual,
  setEndDateVisual,
  startTime,
  endTime,
  setStartTimeVisual,
  setEndTimeVisual,
}: DateProps) => {
  if (updatedValue === 'startDate') {
    const momentStart = moment(startDate)
    const momentEnd = moment(endDate)

    setFieldValue('startDate', momentStart.toDate())
    setStartDateVisual(momentStart.toDate())

    updateTimeIfOnlyDateChanged({
      formikKey: 'startTime',
      time: startTime,
      date: momentStart,
      setLocalTimeInState: setStartTimeVisual,
      setFieldValue,
    })

    // if new starting date is later than end date,
    // move end date to be the same day as new start date
    if (
      momentStart.isValid() &&
      momentEnd.isValid() &&
      momentStart.isAfter(momentEnd)
    ) {
      const differenceInDays = momentStart.diff(momentEnd, 'days') + 1
      const incrimentEndDateByNDays = momentEnd.add(differenceInDays, 'day')

      setFieldValue('endDate', incrimentEndDateByNDays.toDate())
      setEndDateVisual(incrimentEndDateByNDays.toDate())

      // update endTime to match new endDate
      const momentEndTime = moment(endTime)
      const incrementedMomentEndTime = momentEndTime.add(
        differenceInDays,
        'day',
      )
      setFieldValue('endTime', incrementedMomentEndTime.toDate())
      setEndTimeVisual(incrementedMomentEndTime.toDate())
    }
  }

  if (updatedValue === 'endDate') {
    const momentStart = moment(startDate)
    const momentEnd = moment(endDate)

    setFieldValue('endDate', momentEnd.toDate())
    setEndDateVisual(momentEnd.toDate())

    updateTimeIfOnlyDateChanged({
      formikKey: 'endTime',
      time: endTime,
      date: momentEnd,
      setLocalTimeInState: setEndTimeVisual,
      setFieldValue,
    })

    // if new ending date is earlier than start date,
    // move start date to be the same date as new end date
    if (
      momentStart.isValid() &&
      momentEnd.isValid() &&
      momentEnd.isBefore(startDate)
    ) {
      const differenceInDays = momentStart.diff(momentEnd, 'days') + 1
      const decrimentStartDateByNDays = momentStart.subtract(
        differenceInDays,
        'day',
      )
      setFieldValue('startDate', decrimentStartDateByNDays.toDate())
      setStartDateVisual(decrimentStartDateByNDays.toDate())

      // update startTime to match new startDate
      const momentStartTime = moment(startTime)
      const decrementedMomentStartTime = momentStartTime.subtract(
        differenceInDays,
        'day',
      )
      setFieldValue('startTime', decrementedMomentStartTime.toDate())
      setStartTimeVisual(decrementedMomentStartTime.toDate())
    }
  }
}

/**
 * If start of end date is changed to a brand new date, update it's
 * respectful start or end time to 24 hours, forward or backwards.
 *
 * @param { String } formikKey formik value to update
 * @param { Date } time time object
 * @param { Date } date date object
 * @param { Function } setLocalTimeInState local state time setter to display val
 * @param { Function } setFieldValue formik value callback setter
 */
const updateTimeIfOnlyDateChanged = ({
  formikKey,
  time,
  date,
  setLocalTimeInState,
  setFieldValue,
}: UpdateTimeIfOnlyDateChangedProps) => {
  let newTime = null
  // calculate difference in days between end time and end date
  // note: end time must be set to start of the day 00:00 (hh:mm)
  // in order to get an accurate result
  const differenceInDaysBetweenEndDateAndEndTime = Math.abs(
    moment(time).startOf('day').diff(date, 'days'),
  )
  // case: date changed so now we need to update
  // time to match to the same date
  if (moment(time).isAfter(date)) {
    newTime = moment(time).subtract(
      differenceInDaysBetweenEndDateAndEndTime,
      'day',
    )
    setLocalTimeInState(newTime.toDate())
    setFieldValue(formikKey, newTime.toDate())
  } else if (moment(time).isBefore(date)) {
    newTime = moment(time).add(differenceInDaysBetweenEndDateAndEndTime, 'day')
    setLocalTimeInState(newTime.toDate())
    setFieldValue(formikKey, newTime.toDate())
  }
}

/**
 * Update start and end time. Pass updated value back to formik
 * and visually display time to the user.
 *
 * @param { String } updatedValue type
 * @param { Date } startTime start time
 * @param { Date } endTime end time
 * @param { Function } setFieldValue Formik callback
 * @param { Function } setStartTimeVisual Local start time state setter
 * @param { Function } setEndTimeVisual Local end time state setter
 * @param { Function } setStartDateVisual Local start date state setter
 * @param { Function } setEndDateVisual Local end date state setter
 */
export const updateTimes = ({
  updatedValue,
  startTime,
  endTime,
  setFieldValue,
  setStartTimeVisual,
  setEndTimeVisual,
  setStartDateVisual,
  setEndDateVisual,
}: TimesProps) => {
  if (updatedValue === 'startTime') {
    const momentStart = moment(startTime)
    const momentEnd = moment(endTime)

    setFieldValue('startTime', momentStart.toDate())
    setStartTimeVisual(momentStart.toDate())
    // if new starting time is later than end time,
    // move end time to be the same day as new start time
    if (
      momentStart.isValid() &&
      momentEnd.isValid() &&
      momentStart.isAfter(momentEnd)
    ) {
      const copyOfUpdatedStartTime = momentStart
      const incrementEndTimeByNHours = copyOfUpdatedStartTime.add(24, 'hour')

      // update start date to match decremented start time
      const momentStartDateStartOfDay = moment(
        moment.utc(incrementEndTimeByNHours).startOf('day').format('LL'),
      )
        .startOf('day')
        .toDate()

      setFieldValue('endDate', momentStartDateStartOfDay)
      setEndDateVisual(momentStartDateStartOfDay)

      setFieldValue('endTime', incrementEndTimeByNHours.toDate())
      setEndTimeVisual(incrementEndTimeByNHours.toDate())
    }
  }

  if (updatedValue === 'endTime') {
    const momentStart = moment(startTime)
    const momentEnd = moment(endTime)

    setFieldValue('endTime', momentEnd.toDate())
    setEndTimeVisual(momentEnd.toDate())

    // if new ending time is earlier than start time,
    // move start time to be the same time as new end time
    if (
      momentStart.isValid() &&
      momentEnd.isValid() &&
      momentEnd.isBefore(startTime)
    ) {
      const copyOfUpdatedEndTime = momentEnd
      const decrimentStartTimeByNHours = copyOfUpdatedEndTime.subtract(
        24,
        'hour',
      )

      // update start date to match decremented start time
      const momentStartDateStartOfDay = moment(
        moment.utc(decrimentStartTimeByNHours).startOf('day').format('LL'),
      )
        .startOf('day')
        .toDate()

      setFieldValue('startDate', momentStartDateStartOfDay)
      setStartDateVisual(momentStartDateStartOfDay)

      setFieldValue('startTime', decrimentStartTimeByNHours.toDate())
      setStartTimeVisual(decrimentStartTimeByNHours.toDate())
    }
  }
}
