import React from 'react'

type IconProp = {
  stroke?: string
  height?: string
  width?: string
}
const SearchIcon = ({ stroke, height, width }: IconProp) => {
  return (
    <svg
      width={width ?? '20'}
      height={height ?? '20'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
        stroke={stroke ?? '#3A3A3A'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0016 20.9994L16.6516 16.6494"
        stroke={stroke ?? '#3A3A3A'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SearchIcon
