import React from 'react'

import styled from '@emotion/styled'
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded'
import StarHalfRoundedIcon from '@material-ui/icons/StarHalfRounded'
import StarRoundedIcon from '@material-ui/icons/StarRounded'

const styles = { color: '#FFD469', height: '20px', width: '20px' }

const StarRoundedIconStyled = styled(StarRoundedIcon)`
  ${styles}
`
const StarHalfRoundedIconStyled = styled(StarHalfRoundedIcon)`
  ${styles}
`
const StarBorderRoundedIconStyled = styled(StarBorderRoundedIcon)`
  ${styles}
`

type StarsProps = {
  rating: number
}

const Stars = ({ rating }: StarsProps) => {
  const starsArray = []

  const generateRandomKey = () => {
    const randomNumber = Math.floor(Math.random() * (100000000 - 1 + 1)) + 1
    return `k3y${randomNumber}`
  }

  if (rating) {
    const ratingToString = rating.toString()
    const ratingToStringArray = ratingToString.split('.')
    const numberOfFilledStars = parseInt(ratingToStringArray[0], 10)
    const numberOfHalfFilledStars = parseInt(ratingToStringArray[1], 10)
    let numberOfEmptyStars = 0

    if (!!numberOfHalfFilledStars) {
      numberOfEmptyStars =
        5 - numberOfFilledStars - 1 === 0 ? 0 : 5 - numberOfFilledStars - 1
    } else {
      numberOfEmptyStars =
        5 - numberOfFilledStars === 0 ? 0 : 5 - numberOfFilledStars
    }
    for (let i = 0; i < numberOfFilledStars; i++) {
      starsArray.push(<StarRoundedIconStyled key={generateRandomKey()} />)
    }
    if (!!numberOfHalfFilledStars) {
      for (let i = 0; i < 1; i++) {
        starsArray.push(<StarHalfRoundedIconStyled key={generateRandomKey()} />)
      }
    }

    for (let i = 0; i < numberOfEmptyStars; i++) {
      starsArray.push(<StarBorderRoundedIconStyled key={generateRandomKey()} />)
    }
  }

  return <div style={{ marginTop: '2px', display: 'flex', justifyContent: 'flex-start' }}>{starsArray.length > 0 && starsArray}</div>
}

export default Stars
