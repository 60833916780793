import React, { useState, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { MTModal } from 'components/MTModal'
import { isScreenSize } from 'utils/screenSzie'
import { CurrentTrip } from 'pages/Workspace/layouts/PlanningBoard/types'
import {
  Container,
  Header,
  HeaderText,
  HeaderTitleSection,
  NormalText,
  Footer,
  Spacer,
  WarningText,
  ProceedButton,
  DuplicateButton,
  Body,
  InputTitle,
  InputField,
  OptionsContainer,
  TravelStyle,
  CheckboxTitle,
  PublishButtonText,
  CopyLinkButton,
} from './publishTripModal.style'
import { DuplicateIcon, PublishIcon, RightIcon, XIcon } from 'components/MTIcons'
import publishimage from './assets/publishimage.svg'
import publishsuccessimage from './assets/publishsuccessimage.svg'
import { DuplicateTripButton } from 'components/DuplicateTripButton'
import { ChevronRight } from '@material-ui/icons'
import { handleTripPublication } from 'api/trip'
import { sendNotification } from 'utils/toast'
import { useAppSelector } from 'store/hooks'

interface PublishTripProps {
  open: boolean
  setOpen: boolean
  currentTrip: CurrentTrip
  setCurrentTripCallback: boolean
}
const PublishTripModal = ({
  open,
  setOpen,
  currentTrip,
  setCurrentTripCallback,
} : PublishTripProps ) => {
  const { user } = useSelector(state => state.user)
  const [toggleForm, setToggleForm] = useState(false)
  const [tripTitle, setTripTitle] = useState(currentTrip?.title)
  const [tripPublishSuccess, setTripPublishSuccess] = useState(false)
  const [selectedStyles, setSelectedStyles] = useState<string[]>([])
  const [publishLoading, setPublishLoading] = useState(false)
  const coordinates = useAppSelector(state => state.geoLocation)
  const travelStyles = [
    'Budget Traveling',
    'Comfort Travel',
    'Couples Travel',
    'Group Travel',
    'Family Vacation',
    'Solo Traveler',
    'Luxury Traveler',
    'Digital Nomad Travel',
  ]

  useEffect(() => {
    setTripTitle(currentTrip?.title)
  }, [currentTrip])

  const handleCheckboxChange = (style: string) => {
    if(!selectedStyles.includes(style)){
      setSelectedStyles(prevSelectedStyles => [...prevSelectedStyles, style])
    }
    if(selectedStyles.includes(style)){
      setSelectedStyles(selectedStyles.filter(f => f != style))
    }
  }

  const handlePublishTrip = async () => {
    setPublishLoading(true)
    try {
      await handleTripPublication({
        operation: 'publish',
        short_uid: currentTrip.short_uid,
        title: tripTitle,
        travel_styles: JSON.stringify(selectedStyles),
        coordinates
      })
      setCurrentTripCallback!({ ...currentTrip, published: true, title: tripTitle })
      setTripPublishSuccess(true)
    } catch (e) {
      sendNotification(
        'Unable to publish the trip, Please try again later',
        'error',
      )
    } finally {
      setPublishLoading(false)
      setSelectedStyles([])
      setToggleForm(false)
      setTimeout(() => {
        setTripPublishSuccess(false)
        setOpen(false)
      }, 30000)
    }
  }

  // modal close handler
  const handleClose = () => {
    setToggleForm(false)
    setOpen(false)
    setTripPublishSuccess(false)
  }

  const handleCopyUrl = async () => {
      console.log('handle copy link')
      const url = window.location.href // Get the full URL from the browser
      await navigator.clipboard.writeText(url)
        .then(() => {
          sendNotification('URL copied to clipboard!', 'success')
        })
        .catch((error) => {
          sendNotification('Failed to copy URL:', error)
        })
  }


  const publishButtonDisabled = (tripTitle?.trim().length < 1) || (selectedStyles.length < 1)
  console.log(publishButtonDisabled)
  const modalContent = (
    <Container>
      <Header>
        <HeaderTitleSection>
          <PublishIcon height="24" width="24" />
          <HeaderText>{tripPublishSuccess ?  'Trip published successfully!' : 'Publish to Community'}</HeaderText>
        </HeaderTitleSection>
        <div onClick={handleClose}><XIcon /></div>

      </Header>
      <Spacer />
      { tripPublishSuccess
      ? <Body>
          <NormalText>Congratulations, you have published your trip successfully. It can now be viewed in the Community page by everyone. </NormalText>
          <img src={publishsuccessimage}/>
          <CopyLinkButton onClick={handleCopyUrl}>
            <DuplicateIcon height='24' width='24' stroke='#3A3A3A'/>
            <PublishButtonText>Copy link to published trip</PublishButtonText>
          </CopyLinkButton>
        </Body>
      : toggleForm ? (
        <Body>
          <div>
          <InputTitle>Name of the trip*</InputTitle>
          <InputField type='text' value={tripTitle} onChange={(e) => setTripTitle(e.target.value)} />
          </div>
          <InputTitle>Select travel styles*</InputTitle>
          <OptionsContainer>
            {travelStyles.map((style, key) => (
              <TravelStyle key={key} onClick={() => handleCheckboxChange(style)}>
                <input style={{ height: '16px', width: '16px' }} type='checkbox' checked={selectedStyles.includes(style)}/>
                <CheckboxTitle>{style}</CheckboxTitle>
              </TravelStyle>
          ))}
          </OptionsContainer>
          <Footer style={{ justifyContent: 'flex-end' }}>
              <ProceedButton disabled={publishButtonDisabled} onClick={handlePublishTrip}>
                <PublishIcon width='24' height='24' stroke={publishButtonDisabled ? '#858585': '#3F3F3F'}/>
                <PublishButtonText>Publish</PublishButtonText>
              </ProceedButton>
          </Footer>
        </Body>
      ) : (
        <Body>
          <NormalText>
            Publish this trip to the Community for the public to duplicate.{' '}
          </NormalText>
          <NormalText>
            Everyone will be able to view all content in the board, including
            note and attachments.{' '}
            <WarningText>
              Please remove all private information (name, credit card,
              reservations, etc) prior to publishing, or duplicate this trip for
              use instead.
            </WarningText>
          </NormalText>
          <img src={publishimage} />
          <Footer>
            <DuplicateTripButton
              currentTrip={currentTrip}
              DuplicateButton={
                <DuplicateButton>
                  <InputTitle>Duplicate this trip</InputTitle>
                </DuplicateButton>
              }
            />
            <ProceedButton onClick={() => setToggleForm(true)}>
              <InputTitle>Proceed to Publish</InputTitle>
              <ChevronRight fontSize="medium" />
            </ProceedButton>
          </Footer>
        </Body>
      )}
    </Container>
  )

  // props to pass to modal parent class
  const modalProps = {
    open,
    setOpen,
    modalContent,
  }
  return <MTModal {...modalProps} width='90%' customStyles={{maxWidth: '480px'}} onClose={handleClose} />
}

export default PublishTripModal
