import styled from '@emotion/styled'
import { Avatar } from '@material-ui/core'

export const MembersList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
`

export const CollabModalIcon = styled(Avatar)`
  margin-left: 0.3rem;
  transition: all 0.1s ease-out;
  backface-visibility: hidden;
  &:hover {
    backface-visibility: hidden;
    transform: translateY(-1px);
    box-shadow: 0px 3px 7px -1px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }
  &:active {
    transform: translateY(0px);
    box-shadow: 0px 1px 7px -3px rgba(0, 0, 0, 0.65);
    cursor: pointer;
  }
`

export const styles = () => {
  return {
    colorDefault: {
      backgroundColor: '#FFFFFF',
    },
    root: {
      height: '3rem',
      width: '3rem',
    },
  }
}
