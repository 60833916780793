export const style = ({ colors }) => {
  return {
    modalContainer: {
      display: 'flex',
      width: '100%',
      height: '100%',
      padding: '0rem',
      flexDirection: 'row',
    },
    modalInput: {
      display: 'flex',
      height: '100%',
      padding: '0rem',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '6rem',
      justifyContent: 'space-evenly',
    },
    signInForm: {
      padding: '2rem',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    },
    header: {
      marginLeft: '2rem',
    },
    form: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0em',
    },
    signInInput: {
      minWidth: '75%',
      width: '100%',
      padding: '3rem',
      alignItems: 'center',
      justifyContent: 'center',
    },
    signInHeader: {
      display: 'flex',
      flexDirection: 'column',
      padding: '3rem 0',
      alignContent: 'center',
    },
    logo: {
      width: '10rem',
    },
    signInButton: {
      backgroundColor: colors.primaryBlue,
      hover: colors.primaryGrey,
      height: '5rem',
      borderRadius: '2rem',
      margin: '2rem 0',
      fontSize: '1.7rem',
      textTransform: 'capitalize',
      width: '100%',
      '&:hover': {
        backgroundColor: colors.primaryDarkGrey,
      },
    },
    signUpFooter: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
    },

    closeButton: {
      position: 'absolute',
      top: 10,
      right: 10,
      color: colors.primaryWhite,
      backgroundColor: colors.primaryBlue,
      '&:hover': {
        backgroundColor: colors.primaryDarkGrey,
      },
    },
    signinButtonLink: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '16px',
      letterSpacing: '0.012em',
      background: 'none!important',
      border: 'none',
      cursor: 'pointer',
      textTransform: 'none',
    },
    links: {
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '16px',
      letterSpacing: '0.012em',
      width: '100%',
      justifyContent: 'center',
    },
    title: {
      color: colors.secondaryDarkGrey,
      fontSize: '3rem',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '27px',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    subtitle: {
      marginTop: '0.6rem',
      color: colors.alternativeDarkGrey,
      fontWeight: '700',
      fontSize: '1.75rem',
      fontStyle: 'normal',
      lineHeight: '22px',
      letterSpacing: '0em',
    },
    imageContainer: {
      position: 'relative',
      width: '100%',
    },
    imageSignIn: {
      width: '100%',
      height: '100%',
      padding: '0rem',
      objectFit: 'cover',
    },
    // Local Override for form input
    inputText: {
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '16px',
      '&:placeholder': {
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '16px',
      },
    },
    labelRoot: {
      fontSize: 16,
      '&$labelFocused': {
        color: colors.primaryBlue,
      },
    },
    input: {
      width: '100%',
      borderRadius: 12,
      height: '4.5rem',
      marginBottom: '1rem',
    },
    label: {
      fontWeight: 'bold',
      marginBottom: '0.3rem',
      fontSize: '1.4rem',
    },
    usernameInput: {
      marginBottom: '1rem',
    },
    errorField: {
      color: 'red',
      marginBottom: '0',
    },
  }
}
