import styled from '@emotion/styled'

export const styles = () => {
  return {
    root: {
      boxShadow: '0px 10px 5px 2px rgba(0,0,0,0.02)',
      borderRadius: '20px',
      transition: 'all 0.2s ease-out',
      transform: 'translateY(-1px)',
    },
    media: {
      height: 354,
      cursor: 'pointer',
    },
    cardContent: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    tripInfo: {
      display: 'flex',
      flexDirection: 'row',
    },
    tripInfoLeft: {
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    destinationName: {
      fontSize: '2.2rem',
      fontWeight: 'bold',
    },
    destinationDates: {
      fontSize: '1.8rem',
    },
    destinationStatus: {
      paddingLeft: '4rem',
      fontSize: '1.5rem',
    },
    destinationLastModified: {
      fontSize: '1.2rem',
      marginTop: '1rem',
    },
    members: {
      marginTop: '8px',
      display: 'flex',
      '& > div': {
        margin: '0 -0.5rem 0 0',
      },
    },
    menu: {
      cursor: 'pointer',
    },
  }
}

export const PublishedIndicator = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
  width: fit-content;
  padding: 9px 16px;
  color: white;
  position: absolute;
  background-color: #3A3A3A;
  top: 20px;
  right: 0;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
`

export const PublishedText = styled.p`
  font-size: 16px;
  line-height: 22px;
  margin: 0;
`


export const CardContainer = styled.div`
  box-shadow: 0px 10px 5px 2px rgba(0, 0, 0, 0.02);
  border-radius: 20px;
  background-color: #FFFFFF;
  transition: all 0.2s ease-out;
  transform: translateY(-1px);
  width: ${({ width }) => width ?? 'clamp(220px, 90%, 405px)'};

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0px 10px 14px 2px rgba(0, 0, 0, 0.07);
  }

  @media (max-width: 280px) {
    width: ${({ width }) => width ?? '95%'};
  }
`

export const DestinationName = styled.div`
  font-size: 20px;
  margin:0;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  color: #3A3A3A;
`

export const DestinationDates = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  margin: 0;
  margin-top: 4px;
`

export const LastModifiedContent = styled.div`
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #686868;
  margin-top: 8px;
`
