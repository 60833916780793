import React from 'react'

const Downgrade = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <svg
        width="136"
        height="119"
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <ellipse
          cx="59.7308"
          cy="59.5323"
          rx="59.7308"
          ry="59.5323"
          fill="#FFD600"
        />
        <circle cx="44.292" cy="83.6079" r="14.0284" fill="white" />
        <circle cx="48.4344" cy="83.6083" r="9.50915" fill="#263238" />
        <circle cx="50.9764" cy="75.7933" r="1.6947" fill="white" />
        <path
          d="M35.8384 71.9109C36.5916 70.9066 38.644 69.0111 40.8283 69.463"
          stroke="#263238"
          strokeWidth="1.1298"
          strokeLinecap="round"
        />
        <circle cx="74.9849" cy="83.6084" r="14.0284" fill="white" />
        <circle cx="70.8422" cy="83.6083" r="9.50915" fill="#263238" />
        <circle cx="74.3256" cy="75.7938" r="1.6947" fill="white" />
        <path
          d="M78.7309 68.953C79.9772 69.1032 82.6451 69.9329 83.3458 72.0505"
          stroke="#263238"
          strokeWidth="1.1298"
          strokeLinecap="round"
        />
        <path
          d="M55.1365 99.2123C55.1365 99.5615 56.1541 100.067 59.1032 100.067C62.0522 100.067 63.0698 99.5615 63.0698 99.2123C63.0698 98.8632 61.9505 97.29 59.0014 97.29C56.0524 97.29 55.1365 98.8632 55.1365 99.2123Z"
          fill="#FF8CCC"
        />
      </svg>

      <div
        className="m-3"
        style={{ maxWidth: 'fit-content', textAlign: 'center' }}>
        <h2>
          {' '}
          <strong>We are sad to see you go!</strong>{' '}
        </h2>{' '}
        <br />
        <h3>
          {' '}
          <strong>You are now back on the Tourist (Free) Plan.</strong>{' '}
        </h3>{' '}
        <br />
        <div>
          <h4>
            While you can still view and edit your existing trip boards,
            creating new trips will require an purchase.
          </h4>
        </div>{' '}
        <br />
        <div>
          <h4>
            If you wish to plan new trips, you can purchase at any time. We hope
            to welcome you back soon!
          </h4>
        </div>
      </div>
    </div>
  )
}

export default Downgrade
