/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'

import { createApi } from 'unsplash-js'

export const fetchUnsplashImageData = async () => {
  // @ts-ignore
  const unsplash = createApi({
    accessKey: process.env.REACT_APP_UNSPLASH_ACCESS_KEY,
  })

  // TODO: use google maps location to make this query more unique for PB only
  const res = await unsplash.photos.getRandom({
    query: 'tourism,travel,america,europe,asia,canada,mexico',
    orientation: 'portrait',
  })
  // @ts-ignore
  const imageUrl = res && res?.response?.urls?.regular
  // @ts-ignore
  const unsplashRefUser = res?.response?.user
  const referralLink = `https://unsplash.com/@${unsplashRefUser?.username}?utm_source=mitravelapp&utm_medium=referral`

  const linkUrl = (
    <>
      Photo by{' '}
      <a href={referralLink} style={{ color: 'white' }}>
        {unsplashRefUser.first_name} {unsplashRefUser.last_name}
      </a>{' '}
      on{' '}
      <a
        href="https://unsplash.com/?utm_source=mitravelapp&utm_medium=referral"
        style={{ color: 'white' }}>
        Unsplash
      </a>
    </>
  )
  return { imageUrl, linkUrl }
}
