import styled from '@emotion/styled'
import CloseIcon from '@material-ui/icons/Close'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { cssVariables } from 'theme'

export const FileUploadContainer = styled.div`
  font-size: 1.3rem;
  &:hover: {
    cursor: pointer;
  }
`

export const FileList = styled.ul`
  padding-inline-start: 0;
  margin-top: 0;
  margin-bottom: 0;
`

export const FileListItem = styled.li`
  list-style-type: none;
`

export const FileInput = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
`

export const FileWrapper = styled.div`
  width: fit-content;
  border-width: 1px;
  border-style: solid;
  border-color: #f9f9f9;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem;
  border-radius: 12px;
`

export const FileIcon = styled(FileCopyIcon)`
  color: ${cssVariables.colors.primaryBlue};
`

export const CancelIcon = styled(CloseIcon)`
  cursor: pointer;
`

const FileNameStyle = 'margin: 0 0.5rem;'

export const FileNameAnchor = styled.a`
  ${FileNameStyle}
  margin: 0 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70px;
`

export const FileNamePar = styled.p`
  ${FileNameStyle}
  margin: 0 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70px;
`
