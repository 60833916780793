import React from 'react'

type IconProp = {
  stroke?: string
  height?: string
  width?: string
}
const RightIcon = ({ stroke, height, width }: IconProp) => {
  return (
    <svg
      width={width ?? '27'}
      height={height ?? '26'}
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.6635 25.4111L10.7406 22.5133L18.4133 14.8407H0.434783V10.5823H18.4133L10.7406 2.92218L13.6635 0.0118579L26.3631 12.7115L13.6635 25.4111Z"
        fill="#3A3A3A"
      />
    </svg>
  )
}

export default RightIcon
