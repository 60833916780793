import React from 'react'

import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import { CONSTANTS } from 'core/constants'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { isScreenSize } from 'utils/screenSzie'

import { DestinationSearchBar } from './layouts/DestinationSearchBar'
import {
  Container,
  Body,
  TabNameWithIcon,
  TabName,
} from './searchExplore.style'

type Props = {
  setSearchedTitle: (title: string) => void
  tripName: string
  tripDestination: string
}

const SearchExplore = ({
  tripName,
  tripDestination,
  setSearchedTitle,
}: Props) => {
  const smallScreen = isScreenSize(350)
  return (
    <Container>
      <Tabs>
        <TabList className="react-tabs__tab-list--inner">
          <Tab
            className="react-tabs__tab--inner"
            selectedClassName="react-tabs__tab--inner--selected"
            style={{ maxWidth: 'fit-content' }}>
            <TabNameWithIcon>
              <LocationOnIcon></LocationOnIcon>
              <TabName>{CONSTANTS.EXPLORE_PAGE_TAB_DESTINATION}</TabName>
            </TabNameWithIcon>
          </Tab>
          {/* {!smallScreen && (
            <Tab
              disabled
              className="react-tabs__tab--inner"
              selectedClassName="react-tabs__tab--inner--selected">
              <TabNameWithIcon>
                <EmojiObjectsIcon></EmojiObjectsIcon>
                <TabName>{CONSTANTS.EXPLORE_PAGE_TAB_INSPIRATION}</TabName>
              </TabNameWithIcon>
            </Tab>
          )} */}
        </TabList>
        <TabPanel>
          <Body>
            <DestinationSearchBar
              tripName={tripName}
              tripDestination={tripDestination}
              setSearchedTitle={setSearchedTitle}
            />
          </Body>
        </TabPanel>
      </Tabs>
    </Container>
  )
}

export default SearchExplore
