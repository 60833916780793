import { request } from '../utils/request'

export const create = async data => {
    return await request({
        url: '/api/trip-groups',
        method: 'post',
        data,
    })
}

export const remove = async data => {
    return await request({
        url: '/api/trip-groups/remove',
        method: 'delete',
        data: { uid: data.uid, ...data },
    })
}

export const update = async(uid, data) => {
    return await request({
        url: `/api/trip-groups/${uid}`,
        method: 'put',
        data,
    })
}

export const getTripGroups = async uid => {
    return await request({
        url: '/api/trip-groups/:trip_uid',
        method: 'get',
        searchParams: { uid },
    })
}

export const updateOrder = async data => {
    return await request({
        url: '/api/trip-groups/update-order',
        method: 'patch',
        data,
    })
}