export const MAP_POS_TOP = 30
export const MAP_HEIGHT_SML_SCREEN_SML = 550
export const MAP_HEIGHT_LG_SCREEN_MED = 700
export const MAP_HEIGHT_LG_SCREEN_LG = 800

export const largeHeights = {
  large: MAP_HEIGHT_LG_SCREEN_LG,
  med: MAP_HEIGHT_LG_SCREEN_MED,
}

export const smallHeights = {
  sml: MAP_HEIGHT_SML_SCREEN_SML,
}

/**
 * Map height is a bit tricky. Because of the way the Map component is positioned in Planning Board,
 * (it's position is absolute which means a predefined height is required) the height changes based
 * on user's screen size. This method measures the user's screen size and returns a predefined height
 * for the map.
 *
 * Tested on a 21" Asus monitor and 15" Macbook Pro.
 *
 * Note: some further modifications may be necessary for smaller or larger screens.
 *
 * @param {Number} screenHeight height of browser on computer
 * @param {Number} currentMapHeight height of map in px on planning board
 * @returns Height of map based on browser size and if user toggled the button
 */

export const determineMapHeight = (
  screenHeight: number,
  currentMapHeight: number,
) => {
  let mapHeightBasedOnScreen = 0

  // map going from large to small so we need to return small
  if (currentMapHeight > 600) {
    mapHeightBasedOnScreen = smallHeights.sml
  }

  // map going from small to large
  // return large height based on screen size
  if (currentMapHeight < 600) {
    switch (true) {
      case screenHeight > 900:
        mapHeightBasedOnScreen = largeHeights.large
        break
      case screenHeight > 700 && screenHeight < 900:
        mapHeightBasedOnScreen = largeHeights.med
        break
      default:
        break
    }
  }
  mapHeightBasedOnScreen = smallHeights.sml //hack
  return mapHeightBasedOnScreen
}
type LatDifference = {
  [key: number]: string
}

export const latDifference: LatDifference = {
  12: '0.03',
  13: '0.02',
  14: '0.008',
  15: '0.005',
  16: '0.003',
  17: '0.001',
}
