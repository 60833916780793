import styled from '@emotion/styled'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

export const style = () => {
  return {
    paper: {
      boxShadow: '0px 5px 11px -1px rgba(0,0,0,0.1) !important',
    },
  }
}

export const Container = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  padding: 1.2rem;
  border-radius: 8px;
`

export const Icon = styled(FiberManualRecordIcon)`
  fontsize: 2rem;
  &:hover: {
    cursor: pointer;
  }
`
