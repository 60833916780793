import { createTheme } from '@material-ui/core/styles'

export const cssVariables = {
  colors: {
    primaryBlue: '#4184FF',
    primaryYellow: '#FFD469',
    primaryLightGrey: '#C4C4C4',
    primaryDarkGrey: '#727272',
    primaryAlmostBlack: '#727272',
    primaryPink: '#F36A80',
    primaryWhite: '#FFFFFF',
    primaryOffWhite: '#FAFAFA',
    primaryRed: '#FF5C5C',
    primaryBlack: '#3A3A3A',

    secondaryLightBlue: '#DDF1FF',
    secondaryLightGrey: '#AFAFAF',
    secondaryDarkGrey: '#3A3A3A',

    ternaryLightGrey: '#E5E5E5',
    ternaryDarkGrey: '#A6A6A6',

    alternativeLightGrey: '#DADADA',
    alternativeLightGrey2: '#D1D1D1',
    alternativeDarkGrey: '#676767',
    alternativeDarkGrey2: '#686868',

    checkboxGreen: '#5ab74e',
  },
}

export default createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '14px',
        backgroundColor: cssVariables.colors.secondaryDarkGrey,
        padding: '8px 12px',
        borderRadius: '6px',
      },
    },
    MuiCssBaseline: {
      '@global': {
        html: {
          padding: '0',
          fontSize: '62.5%',
          height: '100%',
        },
        body: {
          height: '100% !important',
          display: 'flex',
          flexDirection: 'column',
        },
        a: {
          textDecoration: 'none',
        },
      },
    },
    // Global Material-UI Form Inputs
    MuiOutlinedInput: {
      root: {
        fontSize: 16,
        fontFamily: 'Lato',
        fontWeight: 400,
        letterSpacing: 'normal',
        lineHeight: '22px',
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 16,
        fontFamily: 'Lato',
      },
    },
    MuiTypography: {
      root: {
        fontFamily: 'Lato',
      },
    },
    MuiFormHelperText: {
      root: {
        '&$error': {
          fontFamily: 'Lato',
          fontSize: 14,
        },
      },
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: [
      'Lato',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
})
