import { getCommunityTrips } from 'api/trip'
import React, { useEffect, useState } from 'react'
import useSWR, { useSWRConfig } from 'swr'
import Pagination from '@material-ui/lab/Pagination'
import { CommunityTripCard } from '../CommunityTripCard'
import { CenteredDiv, CommunityContainer } from './CommunityTrips.style'
import loadingGif from '../../assets/loading_bg.gif'
import { Link, useHistory } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'
import noresults from './assets/noresults.svg'
import { Spinner } from 'reactstrap'

interface user {
  id: string
  email: string
  firstName: string
  lastName: string
}
interface CommunityTripsProps {
  search: string
  page: number
  setPage: (arg_0: number) => void
  selectedTags: string[]
  preview?: boolean
  customLoading?: boolean
}
export interface TripMember {
  id: number
  uid: string
  role: string
  users: user[]
}

export interface TripMetadata {
  id: number
  uid: string
  bucket: string
}

export interface Trip {
  id: number
  uid: string
  title: string
  destination: string
  travel_styles: string | null
  start_date: string
  end_date: string
  created_at: string
  published: boolean
  published_at: string | null
  unpublished_at: string | null
  views: number | null
  duplicates: number | null
  updated_at: string
  created_by: string
  updated_by: string | null
  deleted_by: string | null
  short_uid: string
  google_place_id: string
  lat: number
  lng: number
  grid_orientation: number
  sw_bounds: [number, number]
  ne_bounds: [number, number]
  trip_metadata: TripMetadata
  trips_members: TripMember[]
}

export default function CommunityTrips({
  search,
  selectedTags,
  page,
  setPage,
  preview,
  customLoading

}: CommunityTripsProps) {
  const coordinates = useAppSelector(state => state.geoLocation)
  const { user } = useAppSelector(state => state.user)
  const history = useHistory()
  const limit = preview ? '6' : '12'
  const url = `/api/trip/community/trips?&latitude=${coordinates.latitude}&longitude=${coordinates.longitude}&page=${page}&limit=${limit}&search=${
    search ?? ''
  }&travel_styles=${JSON.stringify(selectedTags) ?? []}&uid=${user?.uid ?? 'public'}`
  const { data, error, isLoading } = useSWR(
    url,
    async () => await getCommunityTrips(url),
    {
      refreshInterval: 0,
    },
  ) // doesn't refresh

  const onPageChange = (page: number) => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('page', (page.toString())) // Add or update the 'search' parameter
    history.push({
      pathname: window.location.pathname,
      search: searchParams.toString(),
    })
    setPage(page)
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const pageNumber = searchParams.get('page') ?? 1 // Default to 1
    onPageChange(+pageNumber)
  }, [page])

  return (
    <CommunityContainer style={data && (data.trips.length < 3 && data.trips.length > 0) ? {justifyContent: 'flex-start'} : {}}>
      {(isLoading || customLoading) && (
        <CenteredDiv>
          <p style={{ fontWeight: 700, fontSize: '20px', color: '#686868', textAlign: 'center', marginBottom: '16px' }}>Loading your results...</p>
          <Spinner style={{color: '#686868'}}/>
          {/* <img
            src={loadingGif}
          alt="Loading..."
            style={{
              height: '155px',
              maxWidth: '100%',
            }}
          /> */}
        </CenteredDiv>
      )}
      {
        data && data.trips.length === 0 && !(isLoading || customLoading) &&  <CenteredDiv>
          {/* <p style={{ fontWeight: 700, fontSize: '20px' }}>
          No Results Found
          </p> */}
          <img src={noresults}/>
          </CenteredDiv>
      }
      {data && data.trips.length > 0 && ! (isLoading || customLoading) &&
        data.trips.map((trip: Trip, key: number) => {
          return (
            <Link to={`/workspace/${trip.short_uid}`} key={key}>
              <CommunityTripCard trip={trip} preview={preview} />
            </Link>
          )
        })}
      {!preview && ! (isLoading || customLoading) && data?.totalPageCount > 0 && data?.totalCount > data.limit && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            marginBottom: '20px',
          }}>
          <Pagination
            count={data?.totalPageCount ?? 1}
            page={page}
            onChange={(e, page) => onPageChange(page)}
            size="large"
          />
        </div>
      )}
    </CommunityContainer>
  )
}
