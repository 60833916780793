/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'

import type { AppDispatch } from '../index'

type UserOwnedPlace = {
  place_id: string
  color: string
  trip_group_uid?: string
}
type MapBounds = {
  sw_bounds: Array<number>
  ne_bounds: Array<number>
}

type MapCenter = {
  lat: number
  lng: number
}
type LocationSearchResultsState = {
  places: Array<any>
  endingIndex: number
  userOwnedPlacesArray: Array<PlaceObject>
  mapInfoPlaceId: string | null
  mapCenter: MapCenter
  mapZoom: number
  mapBounds: MapBounds
  scrollToSearchedLocation: boolean
}

type LocationItem = {
  place: google.maps.places.PlaceResult
  placeIsPartOfTrip: boolean
  color: string
}

const initialState: LocationSearchResultsState = {
  places: [],
  endingIndex: 8,
  userOwnedPlacesArray: [],
  mapInfoPlaceId: null,
  mapCenter: {
    lat: 0,
    lng: 0,
  },
  mapZoom: 12,
  mapBounds: { sw_bounds: [], ne_bounds: [] },
  scrollToSearchedLocation: false,
}

type PlaceObject = {
  place_id: string
  color?: string
  trip_group_uid?: string
}

type PlaceIdsObject = {
  [key: string]: string | PlaceObject
}

type LocationItemColor = {
  color: string
  place_id: string
}

const slice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    _addPlaces: (state, { payload }) => {
      const moddedPlaces: any[] = []
      const placeIdsObject: PlaceIdsObject = {
        place_id: '',
        color: '',
      }

      state.userOwnedPlacesArray.forEach(place => {
        placeIdsObject[place.place_id] = {
          place_id: place.place_id,
          color: place.color,
        }
      })

      payload.forEach((place: any) => {
        if (placeIdsObject[place.place_id]) {
          moddedPlaces.push({
            ...place,
            ...{
              color: (placeIdsObject[place.place_id] as any)?.color,
              placeIsPartOfTrip: true,
            },
          })
        } else {
          moddedPlaces.push(place)
        }
      })

      state.places = moddedPlaces
    },

    _setEndingIndex: state => {
      const showNextEight = state.endingIndex + 8 <= state.places.length
      const remainingAmountOfItemsToShow =
        state.places.length - state.endingIndex
      const newEndingIndex = showNextEight
        ? state.endingIndex + 8
        : state.endingIndex + remainingAmountOfItemsToShow
      state.endingIndex = newEndingIndex
    },

    _initializeUserOwnedPlacesArray: (state, { payload }) => {
      state.userOwnedPlacesArray = payload
    },

    _updateUserOwnedPlacesArray: (state, { payload }) => {
      const { place_id, trip_group_uid } = payload[0]
      const placeIdsObject: PlaceIdsObject = {}
      const moddedPlaces = []

      placeIdsObject[place_id] = {
        place_id,
        color: '#4184FF',
        trip_group_uid,
      }

      state.places.forEach(place => {
        if (placeIdsObject[place.place_id]) {
          moddedPlaces.push({
            ...place,
            ...{
              color: (placeIdsObject[place.place_id] as any)?.color,
              trip_group_uid: (placeIdsObject[place.place_id] as any)
                ?.trip_group_uid,
              placeIsPartOfTrip: true,
            },
          })
        } else {
          moddedPlaces.push(place)
        }
      })

      state.userOwnedPlacesArray = [...state.userOwnedPlacesArray, ...payload]
    },

    _updatePlacesArray: (state, { payload }) => {
      const { place_id } = payload

      const placeToUpdate = state.places.find(p => p.place_id === place_id)

      if (placeToUpdate) {
        placeToUpdate.color = '#4184FF'
        placeToUpdate.placeIsPartOfTrip = true
      }
    },

    _removeUserOwnedPlacesArray: (state, { payload }) => {
      const { place_id } = payload
      const allExceptSelf = state.userOwnedPlacesArray.filter(
        obj => obj.place_id !== place_id,
      )
      state.userOwnedPlacesArray = allExceptSelf
    },

    _updateUserOwnedPlaceGroup: (state, { payload }) => {
      const { place_id, trip_group_uid } = payload
      const objIndex = state.userOwnedPlacesArray.findIndex(
        place => place.place_id === place_id,
      )
      if (
        state.userOwnedPlacesArray[objIndex] &&
        state.userOwnedPlacesArray[objIndex].trip_group_uid
      )
        state.userOwnedPlacesArray[objIndex].trip_group_uid = trip_group_uid
    },

    _removeAllPlaces: state => {
      state.places = []
    },

    _resetEndingIndex: state => {
      state.endingIndex = 8
    },

    _resetAllLocationFields: state => {
      state.endingIndex = 8
      state.places = []
      state.userOwnedPlacesArray = []
      state.mapInfoPlaceId = null
      state.mapCenter = {
        lat: 0,
        lng: 0,
      }
      state.mapZoom = 12
      state.mapBounds = { sw_bounds: [], ne_bounds: [] }
    },

    _setMapInfoForSearchGridItem: (state, { payload }) => {
      const { placeId } = payload
      state.mapInfoPlaceId = placeId
    },

    _resetMapInfoForSearchGridItem: state => {
      state.mapInfoPlaceId = null
    },

    _showLocationItemMapInfo: (state, { payload }) => {
      const { placeId } = payload
      const objIndex = state.places.findIndex(loc => loc.place_id === placeId)
      state.places[objIndex].showMarkerMapInfo = true
    },

    _hideLocationItemMapInfo: (state, { payload }) => {
      const { placeId } = payload
      const objIndex = state.places.findIndex(loc => loc.place_id === placeId)
      state.places[objIndex].showMarkerMapInfo = false
    },

    _updateLocationItemColor: (state, { payload }) => {
      const { place_id, color } = payload
      state.places[place_id].color = color
    },

    _setMapCenter: (state, { payload }) => {
      const center = payload
      state.mapCenter = center
    },

    _setMapZoom: (state, { payload }) => {
      const zoom = payload
      state.mapZoom = zoom
    },

    _setMapBounds: (state, { payload }) => {
      const bounds = payload
      state.mapBounds = bounds
    },

    _setScrolltoSearchedLocationOnMap: (state, { payload }) => {
      state.scrollToSearchedLocation = payload
    },
  },
})

export default slice.reducer

const {
  _addPlaces,
  _removeAllPlaces,
  _setEndingIndex,
  _resetEndingIndex,
  _resetAllLocationFields,
  _updateUserOwnedPlacesArray,
  _updateUserOwnedPlaceGroup,
  _removeUserOwnedPlacesArray,
  _initializeUserOwnedPlacesArray,
  _updatePlacesArray,
  _setMapInfoForSearchGridItem,
  _resetMapInfoForSearchGridItem,
  _showLocationItemMapInfo,
  _hideLocationItemMapInfo,
  _updateLocationItemColor,
  _setMapCenter,
  _setMapZoom,
  _setMapBounds,
  _setScrolltoSearchedLocationOnMap,
} = slice.actions

export const updatePlacesArray =
  (data: Partial<UserOwnedPlace>) => (dispatch: AppDispatch) => {
    try {
      dispatch(_updatePlacesArray(data))
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }

export const setMapZoom = (data: number) => (dispatch: AppDispatch) => {
  try {
    dispatch(_setMapZoom(data))
  } catch (error) {
    throw new Error((error as Error).message)
  }
}

export const setMapBounds = (data: MapBounds) => (dispatch: AppDispatch) => {
  try {
    dispatch(_setMapBounds(data))
  } catch (error) {
    throw new Error((error as Error).message)
  }
}

export const setMapCenter = (data: MapCenter) => (dispatch: AppDispatch) => {
  try {
    dispatch(_setMapCenter(data))
  } catch (error) {
    throw new Error((error as Error).message)
  }
}

export const updateLocationItemColor =
  (data: LocationItemColor) => (dispatch: AppDispatch) => {
    try {
      dispatch(_updateLocationItemColor(data))
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }

export const hideLocationItemMapInfo =
  (data: { placeId: string }) => (dispatch: AppDispatch) => {
    try {
      dispatch(_hideLocationItemMapInfo(data))
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }

export const showLocationItemMapInfo =
  (data: { placeId: string }) => (dispatch: AppDispatch) => {
    try {
      dispatch(_showLocationItemMapInfo(data))
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }

export const setMapInfoForSearchGridItem =
  (data: { placeId: string }) => (dispatch: AppDispatch) => {
    try {
      dispatch(_setMapInfoForSearchGridItem(data))
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }

export const resetMapInfoForSearchGridItem = () => (dispatch: AppDispatch) => {
  try {
    dispatch(_resetMapInfoForSearchGridItem())
  } catch (error) {
    throw new Error((error as Error).message)
  }
}

export const setEndingIndex = () => (dispatch: AppDispatch) => {
  try {
    dispatch(_setEndingIndex())
  } catch (error) {
    throw new Error((error as Error).message)
  }
}

export const removeAllPlacesFromState = () => (dispatch: AppDispatch) => {
  try {
    dispatch(_removeAllPlaces())
  } catch (error) {
    throw new Error((error as Error).message)
  }
}

export const resetAllLocationFields = () => (dispatch: AppDispatch) => {
  try {
    dispatch(_resetAllLocationFields())
  } catch (error) {
    throw new Error((error as Error).message)
  }
}

export const addSelectedLocationItem =
  (data: Array<LocationItem>) => (dispatch: AppDispatch) => {
    try {
      dispatch(_resetEndingIndex())
      dispatch(_removeAllPlaces())
      dispatch(_addPlaces(data))
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }

export const initializeUserOwnedPlacesArray =
  (data: Array<UserOwnedPlace>) => (dispatch: AppDispatch) => {
    try {
      dispatch(_initializeUserOwnedPlacesArray(data))
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }

export const updateUserOwnedPlacesArray =
  (data: Array<UserOwnedPlace>) => (dispatch: AppDispatch) => {
    try {
      dispatch(_updateUserOwnedPlacesArray(data))
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }

export const updateUserOwnedPlaceGroup =
  (data: Partial<UserOwnedPlace>) => (dispatch: AppDispatch) => {
    try {
      dispatch(_updateUserOwnedPlaceGroup(data))
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }

export const removeUserOwnedPlacesArray =
  (data: Partial<UserOwnedPlace>) => (dispatch: AppDispatch) => {
    try {
      dispatch(_removeUserOwnedPlacesArray(data))
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }

export const setScrolltoSearchedLocationOnMap =
  (data: boolean) => (dispatch: AppDispatch) => {
    try {
      dispatch(_setScrolltoSearchedLocationOnMap(data))
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }
