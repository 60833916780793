import moment from 'moment'

export const formatDates = trip => {
  const dateFormatOptions = {
    month: 'long',
    day: 'numeric',
  }

  const startDateDay = new Date(trip.start_date).getDate()
  const startDateMonth = new Date(trip.start_date).getMonth()
  const endDateMonth = new Date(trip.end_date).getMonth()
  const endDateDay = new Date(trip.end_date).getDate()

  const startDate = new Date(trip.start_date).toLocaleDateString(
    'en-US',
    dateFormatOptions,
  )

  const endDate = new Date(trip.end_date).toLocaleDateString(
    'en-US',
    endDateDay <= startDateDay && endDateMonth > startDateMonth
      ? dateFormatOptions
      : { day: 'numeric' },
  )

  return {
    startDate,
    endDate,
  }
}

export const updatedAt = trip => {
  const when = !_.isNil(trip.updated_at) ? trip.updated_at : trip.created_at
  return moment(when).format('MMMM Do YYYY')
}

export const fallBackImage =
  'https://images.unsplash.com/photo-1534008897995-27a23e859048?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1568&q=80'
