/**
 * Validate chosen destination
 * @param {string} value destination
 */
export const validateDestination = (value: string) => {
  let error
  if (!value) {
    error = 'Destination Required!'
  }
  return error
}
