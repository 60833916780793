import React, { ReactNode } from 'react'

import { makeStyles } from '@material-ui/core'

import {
  style,
  SaveButtonStyled,
  SaveButtonWithDropdownStyled,
} from './saveButton.style'

const useStyles = makeStyles(() => style())

type Props = {
  children?: ReactNode
  disabled: boolean
  showTripGroupDropdown?: boolean
}
const SaveButton = ({
  disabled = false,
  children,
  showTripGroupDropdown,
}: Props) => {
  const classes = useStyles()
  return showTripGroupDropdown ? (
    <SaveButtonWithDropdownStyled
      classes={{ contained: classes.contained }}
      variant="contained"
      disabled={disabled}
      type="submit">
      {children ? children : 'Save'}
    </SaveButtonWithDropdownStyled>
  ) : (
    <SaveButtonStyled
      classes={{ contained: classes.contained }}
      variant="contained"
      disabled={disabled}
      type="submit">
      {children ? children : 'Save'}
    </SaveButtonStyled>
  )
}

export default SaveButton
