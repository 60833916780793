type RolesType = { roles: string[] }
type PermissionRoleType = {
  [permission: string]: RolesType
}

type Permission = {
  TRIP_TITLE_EDIT: string
  TRIP_DATE_START_EDIT: string
  TRIP_DATE_END_EDIT: string
  TRIP_CHANGE_ROLE: string
  TRIP_INVITE_MEMBER_VIEWER: string
  POLL_TITLE_EDIT: string
  POLL_DELETE: string
  POLL_OPTIONS_ADD: string
  NOTE_DELETE: string
  TRIP_ITEM_EDIT: string
  TRIP_ITEM_DELETE: string
  TRIP_ITEM_TAGS_EDIT: string
  TRIP_DELETE: string
  TRIP_ITEM_LOCATION_SEARCH: string
}

const ALL = { roles: ['ADMIN', 'EDITOR', 'VIEWER'] }
const ADMIN = { roles: ['ADMIN'] }
const ADMIN_AND_EDITOR = { roles: ['ADMIN', 'EDITOR'] }
const VIEWER_AND_EDITOR = { roles: ['VIEWER', 'EDITOR'] }

export const TRIP_TITLE_EDIT = 'TRIP_TITLE_EDIT'
export const TRIP_DATE_START_EDIT = 'TRIP_DATE_START_EDIT'
export const TRIP_DATE_END_EDIT = 'TRIP_DATE_END_EDIT'
export const TRIP_CHANGE_ROLE = 'TRIP_CHANGE_ROLE'
export const TRIP_INVITE_MEMBER_VIEWER = 'TRIP_INVITE_MEMBER_VIEWER'
export const POLL_TITLE_EDIT = 'POLL_TITLE_EDIT'
export const POLL_DELETE = 'POLL_DELETE'
export const POLL_OPTIONS_ADD = 'POLL_OPTIONS_ADD'
export const NOTE_DELETE = 'NOTE_DELETE'
export const TRIP_ITEM_EDIT = 'TRIP_ITEM_EDIT'
export const TRIP_ITEM_DELETE = 'TRIP_ITEM_DELETE'
export const TRIP_ITEM_TAGS_EDIT = 'TRIP_ITEM_TAGS_EDIT'
export const TRIP_DELETE = 'TRIP_DELETE'
export const TRIP_ITEM_LOCATION_SEARCH = 'TRIP_ITEM_LOCATION_SEARCH'

const ACL: PermissionRoleType = {
  TRIP_TITLE_EDIT: ADMIN,
  TRIP_DATE_START_EDIT: ADMIN,
  TRIP_DATE_END_EDIT: ADMIN,
  TRIP_DELETE: ADMIN,

  TRIP_CHANGE_ROLE: ADMIN,
  TRIP_INVITE_MEMBER_VIEWER: ADMIN_AND_EDITOR ,
  POLL_TITLE_EDIT: ADMIN_AND_EDITOR,
  POLL_DELETE: ADMIN_AND_EDITOR,
  POLL_OPTIONS_ADD: ADMIN_AND_EDITOR,
  NOTE_DELETE: ADMIN_AND_EDITOR,

  TRIP_ITEM_EDIT: ADMIN_AND_EDITOR,
  TRIP_ITEM_DELETE: ADMIN_AND_EDITOR,
  TRIP_ITEM_TAGS_EDIT: ADMIN_AND_EDITOR,
  TRIP_ITEM_LOCATION_SEARCH: ALL,
}

/**
 * Function retuns whether a user of some role type has the ability
 * to perfom a set of operations on a given functionality.
 *
 * For example, a user whose status is 'Viewer' in the trip cannot
 * edit the title of the trip as that is a 'Admin' functionality.
 *
 * @param {String} userRole Trip user role
 * @param {String} permission Permissions based on role
 * @returns Boolean
 */
export const can = (userRole: string, permission: keyof Permission) => {
  const { roles } = ACL[`${permission}`]
  return roles.some((role: string) => role === userRole)
}
