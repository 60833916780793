import React from 'react'

import styled from '@emotion/styled'
import { MTButton } from 'components'
import { TransparentButtonStyleProps } from 'components/MTDialog/helper'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
`

type DeleteItemProps = {
  setOpenDialog?: (value: boolean) => void
  setSubscriptionAlreadyCancelledDialog?: (value: boolean) => void
  onCancelClickHandler?: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transparentButtonStyle: TransparentButtonStyleProps
}

const SubscriptionAlreadyCancelled = ({
  setSubscriptionAlreadyCancelledDialog,
  transparentButtonStyle,
}: DeleteItemProps) => {
  return (
    <>
      <Container>
        <MTButton
          customStyles={transparentButtonStyle}
          title="Cancel"
          onHandleClick={() => {
            if (setSubscriptionAlreadyCancelledDialog)
              setSubscriptionAlreadyCancelledDialog(false)
          }}
        />
      </Container>
    </>
  )
}

export default SubscriptionAlreadyCancelled
