import styled from '@emotion/styled'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
    width: 100%;
    gap: 16px;
`

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`

export const HeaderTitleSection = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
`
export const HeaderText = styled.p`
    margin:0;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #3A3A3A;
`

export const Spacer = styled.div`
    height: 1px;
    background-color: black;
`
export const Body = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`
export const NormalText = styled.span`
    margin:0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #3A3A3A;
`
export const WarningText = styled.span`
    margin:0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #C10F45;
`
export const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 8px;
`

export const ProceedButton = styled.button`
    padding: 8px 12px;
    height: 40px;
    background-color: transparent;
    border: 1px solid #AFAFAF;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
    &:disabled {
        background-color: #e5e5e5;
        color: #8F8F8F;
        border-color: #AFAFAF;
        cursor: not-allowed;
      }
`

export const DuplicateButton = styled.button`
    padding: 8px 12px;
    height: 40px;
    background-color: transparent;
    border: 1px solid #8F8F8F;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const CopyLinkButton = styled.button`
    padding: 8px 12px;
    background-color: transparent;
    border: 1px solid #8F8F8F;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 12px;
`
export const InputTitle = styled.p`
    margin:0;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #3A3A3A;
`
export const InputField = styled.input`
    width: 100%;
    border: 1px solid #8F8F8F;
    padding: 12px;
    height: 44px;
    font-size: 14px;
    line-height: 20px;
    border-radius: 6px;
    background-color: transparent;
    outline: none;
`
export const OptionsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    row-gap: 12px;
    justify-content: space-between;
`
export const TravelStyle = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    min-width: 140px;
    max-height: fit-content;
    padding: 4px;
    column-gap: 8px;
    cursor: default;
    user-select: none;
`
export const CheckboxTitle = styled.p`
    margin: 0;
    font-size: 14px;
    font-weight: 400;
`
export const Checkbox = styled.input`
    height: 16px;
    width: 16px;
`
export const PublishButtonText = styled.p`
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height:24px;
`
