import styled from '@emotion/styled'
import { TextField } from '@material-ui/core'

export const Container = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
`

export const SearchBar = styled(TextField)`
  width: 100%;
  padding-top: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
`
