import styled from '@emotion/styled'

export const PlanningBoardGridColumnContainer = styled.div`
  //width: 100vw;
  height: 100%;
  overflow-x: visible;
  display: flex;
  flex-direction: column;
  //flex: auto;
`
export const PlanningBoardItemsList = styled.div`
  //padding: 10px;
  flex-grow: 1;
  width: 220px;
  min-height: 100px;
  //background-color: red !important;
  background-color: #f9f9f9 !important;
`
