import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch } from 'store'

type TripItemType = 'location' | 'poll' | 'note'

type DataObject = {
  uid: string
  type: TripItemType | null
  open: boolean
}

type InitialState = {
  data: DataObject | null
}

const initialState: InitialState = {
  data: {
    uid: '',
    type: null,
    open: false,
  },
}
const slice = createSlice({
  name: 'popupGridItem',
  initialState,
  reducers: {
    _add: (state, { payload }) => {
      state.data = payload
    },
    _clear: state => {
      state.data = null
    },
  },
})

export default slice.reducer

const { _add, _clear } = slice.actions

export const add = (data: DataObject) => (dispatch: AppDispatch) => {
  try {
    dispatch(_add(data))
  } catch (error) {
    throw new Error((error as Error).message)
  }
}

export const clear = () => (dispatch: AppDispatch) => {
  try {
    dispatch(_clear())
  } catch (error) {
    throw new Error((error as Error).message)
  }
}
