import React from 'react'

type IconProp = {
  stroke?: string
  height?: string
  width?: string
}
const PublishedIcon = ({ stroke, height, width }: IconProp) => {
  return (
    <svg
      width={width ?? '16'}
      height={ height ?? '17'}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.9641 9.16019C14.0961 7.96723 13.8674 6.76209 13.3073 5.7005C12.7473 4.63891 11.8818 3.76968 10.8226 3.20514C9.76343 2.6406 8.55927 2.4067 7.36576 2.53367C6.17226 2.66064 5.04426 3.14265 4.12755 3.91739C3.21084 4.69214 2.54756 5.72401 2.22342 6.87966C1.89929 8.0353 1.9292 9.2616 2.30929 10.4001C2.68938 11.5385 3.40218 12.5369 4.35557 13.266C5.30896 13.9951 6.45911 14.4216 7.65739 14.4902M2.40006 6.50019H13.6001M2.40006 10.5002H11.6667"
        stroke={stroke ?? 'white'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.66677 2.5C6.54367 4.29974 5.94824 6.37858 5.94824 8.5C5.94824 10.6214 6.54367 12.7003 7.66677 14.5M8.33344 2.5C9.82119 4.88363 10.3703 7.735 9.87411 10.5007M10.0001 13.1667L11.3334 14.5L14.0001 11.8333"
        stroke={stroke ?? 'white'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PublishedIcon
