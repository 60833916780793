import React from 'react'

import { Typography } from '@material-ui/core'
import { MTButton } from 'components'
import { CONSTANTS } from 'core/constants'
import { useHistory } from 'react-router-dom'
import { cssVariables } from 'theme'

import errorLogo from './assets/404.png'
import {
  ErrorContainer,
  ErrorLogo,
  ErrorHeader,
  ErrorFooter,
} from './error.style'

const Error = () => {
  const history = useHistory()

  return (
    <ErrorContainer>
      <ErrorLogo src={errorLogo} alt="error" />
      <ErrorHeader>
        <Typography variant="h2" style={{ fontWeight: 'bold' }}>
          {CONSTANTS.ERROR_GENERAL}
        </Typography>
      </ErrorHeader>
      <ErrorFooter>
        <MTButton
          customStyles={{
            width: '100%',
            color: 'white',
            backgroundColor: cssVariables.colors.primaryBlue,
          }}
          onHandleClick={() => history.push('/')}
          title="Go to Dashboard"
        />
      </ErrorFooter>
    </ErrorContainer>
  )
}

export default Error
