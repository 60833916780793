import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'
import { DatePicker, TimePicker } from 'formik-antd'

type Props = {
  isIOS: boolean
}

const getDateAndTimeItemStyles = (props: Props) => `
  margin: 0 0.7rem;
  border-radius: 5px;
  & input {
    height:  ${props.isIOS ? 'auto' : '1rem'};
    padding: 1rem;
  }
`

export const DateAndTimeContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
  align-items: center;
  `

export const DatePickerStyled = styled(DatePicker)<Props>`
  ${props => getDateAndTimeItemStyles(props)}
`

export const TimePickerStyled = styled(TimePicker)<Props>`
  ${props => getDateAndTimeItemStyles(props)}
`

export const DateAndTimeFromToText = styled(Typography)`
  font-size: 1.5rem;
  align-self: center;
  min-width: 35px;
`
