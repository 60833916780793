/* eslint-disable no-unused-vars */
import React, { useEffect, useCallback, useState } from 'react'

import {
  makeStyles,
  Typography,
  CircularProgress,
  IconButton,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { CONSTANTS } from 'core/constants'
import { motion } from 'framer-motion'
import { useQuery } from 'hooks/useQuery'
import { useWindowSize } from 'hooks/useWindowSize'
import mixpanel from 'mixpanel-browser'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { resetUserStore } from 'store'
import { useAppSelector } from 'store/hooks'
import { setGeoLocation } from 'store/slices/geoLocation'
import {
  setPlans,
  setUserPlans,
  setOtherPlans,
  setUserTripsCount,
} from 'store/slices/userPlanSlice'
import { pageVariants } from 'styles/sharedMotion'
import { isScreenSize } from 'utils/screenSzie'
import { sendNotification } from 'utils/toast'

import { find as findAllTripsByUserUID, findCount } from 'api/trip'
import { getUserPlans, getAllPlans } from 'api/userPlans'

import { styles } from './dashboard.styles'
import { BodyContainerWithoutTrips } from './layouts/BodyContainerWithoutTrips'
import { BodyContainerWithTrips } from './layouts/BodyContainerWithTrips'

const useStyles = makeStyles(theme => styles(theme))

const MyTrips = () => {
  const history = useHistory()
  let query = useQuery()
  const { width } = useWindowSize()

  const { user } = useSelector(state => state.user)
  const classes = useStyles()
  const coordinates = useAppSelector(state => state.geoLocation)

  const [loading, setLoading] = useState(false)
  const [userTrips, setUserTrips] = useState([])
  const [showBanner, setShowBanner] = useState(true)
  const [totalTrips, setTotalTrips] = useState(0)
  const [totalPublished, setTotalPublished] = useState(0)
  const displayLoginOnAccountActivation = query.get('display_login')
  const displaySignUpAccountActivation = query.get('display_signup')
  const dispatch = useDispatch()
  const userPlan = useAppSelector(state => state.userPlanSlice.userPlan)
  const plans = useAppSelector(state => state.userPlanSlice.plans)

  useEffect(() => {
    const redirectURL = sessionStorage.getItem('updatePaymentDetailsURL')
    if (redirectURL && redirectURL.includes('?_ptxn=')) {
      // Clear the stored redirect URL
      sessionStorage.removeItem('updatePaymentDetailsURL')

      // Redirect the user back to the stored URL
      history.push(redirectURL)
    }
  }, [])
  let isTabletView = isScreenSize(1024)
  const isScreenSizeEquals400 = isScreenSize(400)

  let welcomeFont = 'h3'
  if (isScreenSizeEquals400) {
    welcomeFont = 'h4'
  }

  /**
   * Get user's trips
   */
  const getUserTrips = useCallback(async () => {
    try {
      setLoading(true)
      const allTrips = await findAllTripsByUserUID({
        user_uid: user.uid,
      })
      setUserTrips(allTrips?.trips)
      setTotalPublished(allTrips?.published_trips)
      setTotalTrips(allTrips?.total_trips)
      const allTripsCreatedByCurrentUser = await findCount({
        user_uid: user.uid,
      })
      await dispatch(setUserTripsCount(allTripsCreatedByCurrentUser))
      setLoading(false)
    } catch (error) {
      const e = await error?.response?.json()
      resetUserStore(dispatch)
      history.push('/create-trip')
    }
  }, [user, history, dispatch])

  useEffect(() => {
    if (_.isNil(user?.firstName) || user?.is_tentative) {
      history.push('/create-trip')
    } else if (user && user.uid && !user?.is_tentative) {
      getUserTrips()
    } else if (
      displayLoginOnAccountActivation ||
      displaySignUpAccountActivation
    ) {
      history.push('/signin')
    }

    if (displaySignUpAccountActivation) {
      sendNotification('Please register for an account first.', 'success')
    }
  }, [
    getUserTrips,
    user,
    displayLoginOnAccountActivation,
    displaySignUpAccountActivation,
    history,
  ])

  const getUserPlan = useCallback(async () => {
    try {
      const data = {
        user_uid: user.uid,
      }
      const result = await getUserPlans(data)
      await dispatch(setUserPlans(result))
    } catch (error) {
      console.error('Error fetching user plans:', error)
    }
  }, [user.uid, dispatch])

  useEffect(() => {
    getUserPlan() // Call the async function inside useEffect

    // If you want to clean up something, return a cleanup function
    return () => {
      // Cleanup code, if needed
    }
  }, [getUserPlan])

  const fetchAllPlans = useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await getAllPlans()
        await dispatch(setPlans(result))
        await resolve(result)
      } catch (error) {
        console.error('Error fetching user plans:', error)
        await reject(error)
      }
    })
  }, [dispatch])

  useEffect(() => {
    fetchAllPlans()
  }, [fetchAllPlans])

  const setOtherPlansGlobally = useCallback(async () => {
    try {
      if (plans && userPlan) {
        const otherPlans = plans
          .map(plan => {
            if (plan.uid !== userPlan.plan.uid) {
              return plan
            }
            return null
          })
          .filter(Boolean)
        await dispatch(setOtherPlans(otherPlans))
      }
    } catch (error) {
      console.error('Error fetching user plans:', error)
    }
  }, [dispatch, plans, userPlan])

  useEffect(() => {
    if (plans && userPlan) {
      setOtherPlansGlobally()
    }
  }, [plans, userPlan, setOtherPlansGlobally])
  useEffect(() => {
    if (user.uid) {
      mixpanel.track_pageview({
        page: '/dashboard',
        $latitude: coordinates?.latitude,
        $longitude: coordinates?.longitude,
      })
    }
  }, [])

  React.useEffect(() => {
    // dispatch(clearGeoLocation())
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        const coordinates = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        }
        dispatch(setGeoLocation(coordinates))
      })
    } else {
      console.log('Geolocation is not available in your browser.')
    }
  }, [dispatch])

  return (
    <motion.div
      className={classes.dashboardContainer}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}>
      {loading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <CircularProgress />
        </div>
      ) : (
        <div>
          {showBanner && (
            <div
              style={isTabletView ? { width: 'fit-content', backgroundColor: '#ffffff' } : {backgroundColor: '#ffffff'}}
              className={classes.header}>
              <div className={classes.headerLeft}>
                <Typography
                  variant={welcomeFont}
                  className={classes.headerLeftWelcomeText}>
                  {user && `Welcome ${user.firstName}!`}
                </Typography>
                <Typography className={classes.headerLeftInfoText}>
                  {CONSTANTS.DASHBOARD_TEXT}
                </Typography>

                <Typography className={`${classes.headerLeftInfoText}`}>
                  {CONSTANTS.DASHBOARD_TUTORIAL_TEST_PREFIX}
                  <a
                    href="https://mitravelapp.com/tutorial/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.headerLeftInfoTextSupportEmail}>
                    {CONSTANTS.DASHBOARD_TUTORIAL_TEST_LINK}
                  </a>
                  {CONSTANTS.DASHBOARD_TUTORIAL_TEST_SUFFIX}
                </Typography>

                <Typography
                  className={`${classes.headerLeftInfoText} ${classes.headerLeftInfoTextSupport}`}>
                  {CONSTANTS.DASHBOARD_SUPPORT_TEXT_PREFIX}
                  <span className={classes.headerLeftInfoTextSupportEmail}>
                    {CONSTANTS.DASHBOARD_SUPPORT_EMAIL}
                  </span>
                  {CONSTANTS.DASHBOARD_SUPPORT_TEXT_SUFFIX}
                </Typography>
              </div>
              <IconButton
                className={classes.headerCloseButton}
                onClick={() => setShowBanner(false)}>
                <CloseIcon fontSize="large" />
              </IconButton>
            </div>
          )}
          <div className={classes.body}>
            {userTrips && userTrips.length > 0 ? (
              <BodyContainerWithTrips
                trips={userTrips}
                getUserTrips={getUserTrips}
                totalTrips={totalTrips}
                totalPublished={totalPublished}
              />
            ) : (
              <BodyContainerWithoutTrips />
            )}
          </div>
        </div>
      )}
    </motion.div>
  )
}
export default MyTrips
