import React, { useCallback, useEffect } from 'react'

import { MTButton, MTTooltip } from 'components'
import { useDropzone } from 'react-dropzone'

import {
  FileUploadContainer,
  FileList,
  FileListItem,
  FileInput,
  FileWrapper,
  FileIcon,
  CancelIcon,
  FileNameAnchor,
  FileNamePar,
} from './fileUpload.style'

export type File = {
  doc?: string
  path?: string
}

type FileUploadProps = {
  setFieldValue: (key: string, val: string) => void
  docURL: string
  docName: string
  myFiles: Array<File>
  setMyFiles: (files: Array<File>) => void
  formikKey: string
  canEdit: boolean
}
const FileUpload = ({
  setFieldValue,
  docURL,
  docName,
  myFiles,
  setMyFiles,
  formikKey,
  canEdit,
}: FileUploadProps) => {
  useEffect(() => {
    if (docURL && docName)
      setMyFiles(
        docURL
          ? [
              {
                doc: docURL,
              },
            ]
          : [],
      )
  }, [docName, docURL, setMyFiles])

  /**
   * Set local uploaded file into state and into formik callback.
   */
  const onDrop = useCallback(
    acceptedFiles => {
      setMyFiles([...myFiles, ...acceptedFiles])
      // calling this twice because note item value for files
      // is lost during renders. aka, terrible hack.
      setFieldValue(formikKey, acceptedFiles)
      setFieldValue(formikKey, acceptedFiles)
    },
    [myFiles, setMyFiles, setFieldValue, formikKey],
  )

  /**
   * Remove uploaded file from form.
   * @param { Object } file newly uploaded file
   */
  const onRemoveTempUploadedFile = (file: File) => {
    const newFiles = [...myFiles]
    newFiles.splice(newFiles.indexOf(file), 1)
    setMyFiles(newFiles)
    setTimeout(() => {
      setFieldValue(formikKey, '')
    }, 0)
  }

  /**
   * Dropzone options and callback.
   */
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept:
      '.pdf, .doc, .docx, .png, .jpeg, .jpg, .JPG, .PNG, .JPEG, .PDF, .DOC, .DOCX',
    onDrop,
  })

  /**
   * Display a list of files to upload.
   * Limit is 1 for now.
   */
  const files = myFiles.map(file => (
    <FileListItem key={file.path || file.doc}>
      <FileWrapper>
        <FileIcon />
        {file.path && <FileNamePar>{file.path}</FileNamePar>}
        {file.doc && docName && (
          <FileNameAnchor
            href={file.doc}
            target="_blank"
            rel="noopener noreferrer">
            {docName}
          </FileNameAnchor>
        )}
        <MTTooltip
          title={canEdit ? '' : 'Only admins or editors can upload new files'}>
          <MTButton
            title=""
            customStyles={{
              justifyContent: 'none',
              minWidth: '40px',
              backgroundColor: 'white',
              boxShadow: 'none',
              padding: '0px',
            }}
            customIcon={<CancelIcon />}
            onHandleClick={file => {
              if (canEdit) {
                onRemoveTempUploadedFile(file)
              }
            }}
          />
        </MTTooltip>
      </FileWrapper>
    </FileListItem>
  ))

  return (
    <FileUploadContainer>
      {myFiles && myFiles.length == 0 && canEdit && (
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} id="fileUploadInput" />
          <FileInput>Upload file...</FileInput>
        </div>
      )}
      <aside>
        <FileList>{myFiles && myFiles.length > 0 && files}</FileList>
      </aside>
    </FileUploadContainer>
  )
}

export default FileUpload
