import downIcon from '../MembersList/assets/down.png'
import downdisabled from '../MembersList/assets/downdisabled.png'

export const styles = () => {
  return {
    membersList: {
      marginLeft: '2rem',
      marginRight: '2rem',
    },
    memberContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    memberName: {
      fontSize: '1.4rem',
      marginLeft: '0.8rem',
      marginBottom: '0',
      color: '#3A3A3A',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: '99%'
    },
    memberDetailsContainer: {
      display: 'flex',
      alignItems: 'center',
      margin: '3px 0',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    },
    membersRolesDropdown: {
      appearance: 'none',
      backgroundImage: `url(${downIcon})`,
      backgroundSize: '16px',
      backgroundPosition: 'left 1px center',
      minWidth: '70px',
      backgroundRepeat: 'no-repeat',
      textIndent:'20px',
      fontSize: '1.4rem',
      border: 0,
      color: '#3A3A3A',
      backgroundColor: 'white',
      '&:focus' : {
        outline: 0,
        border: 0
      },
      '&:disabled' : {
        color: '#8F8F8F',
        backgroundImage: `url(${downdisabled})`,
      }
    },
    select: {
      '&:focus': {
        backgroundColor: 'rgba(0, 0, 0, 0)',
      },
    },
    option: {
      padding: '1rem',
    },
    descriptionText:{
      fontSize: '14px',
      color: '#686868',
      marginTop: '10px',
      fontWeight:'700'
    }
  }
}
