import React, { ReactNode } from 'react'

import styled from '@emotion/styled'
import Tooltip from '@material-ui/core/Tooltip'

const StyledToolTip = styled.div(`
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
`)

type MTToolTipProps = {
  title: string
  children: ReactNode
  scroll?: boolean
}

const MTTooltip = ({ title, children, scroll = false }: MTToolTipProps) => {
  return (
    <StyledToolTip>
      <Tooltip title={title}>
        <div>{children}</div>
      </Tooltip>
    </StyledToolTip>
  )
}

export default MTTooltip
