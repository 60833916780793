import React from 'react'

import { AnimatePresence } from 'framer-motion'
import { Switch } from 'react-router'

import RouteWithSubRoutes from './RouteWithSubRoutes'

const Router = ({ routes }) => {
  return (
    <AnimatePresence>
      <Switch>
        {routes.map(route => (
          <RouteWithSubRoutes key={route.path} {...route} />
        ))}
      </Switch>
    </AnimatePresence>
  )
}

export default Router
