import React from 'react'

import styled from '@emotion/styled'
import { MTButton } from 'components'
import {
  RedButtonStyleProps,
  TransparentButtonStyleProps,
} from 'components/MTDialog/helper'

import { TrashIcon } from 'components/MTIcons'
import { Spinner } from 'reactstrap'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
`

type DeleteItemProps = {
  onDeleteTripClickHandler?: () => void
  onCancelClickHandler?: () => void
  redButtonStyle: RedButtonStyleProps
  loading?: boolean
  transparentButtonStyle: TransparentButtonStyleProps
}

const DeleteItem = ({
  onDeleteTripClickHandler,
  onCancelClickHandler,
  redButtonStyle,
  transparentButtonStyle,
  loading,
}: DeleteItemProps) => {
  return (
    <>
      <Container>
          <MTButton
            customStyles={{ ...redButtonStyle, minWidth: '105px', minHeight: '43px' }}
            onHandleClick={() => {
              if (onDeleteTripClickHandler) onDeleteTripClickHandler()
            }}
            title = {loading ? <Spinner/> : 'Delete'}
            customIcon={loading ? '' : <TrashIcon stroke='white'/>}
            transformprovidedTitle = {false}
          />

        <MTButton
          customStyles={transparentButtonStyle}
          onHandleClick={() => {
            if (onCancelClickHandler) onCancelClickHandler()
          }}
          title="Cancel"
        />
      </Container>
    </>
  )
}

export default DeleteItem
