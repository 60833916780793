import CustomToolTip from 'components/CustomTooltip/CustomTooltip'
import React from 'react'
import { Tag } from '../Tag'

import { Container, ListItem, LastItem } from './previewTags.style'

type Tag = {
  title: string
}

type Props = {
  tags: Array<Tag>
  tagsToShow?: number
}
/*
 * Preview Tags component, that display tags on the tripItem cards in PB
 */
const PreviewTags = ({ tags, tagsToShow = 2 }: Props) => {
  const remainingLength = tags.length - tagsToShow
  const remainingItems = tags.slice(2).map((tag, idx) => {
    // if(idx > tagsToShow){
      return tag.title
    // }
  })

  return (
    <Container>
      {tags &&
        tags.length > 0 &&
        tags.map((tag, idx) => {
          if (idx < tagsToShow) {
            return (
              <Tag key={idx} tag={tag.title} showRemoveButton={false}/>
            )
          }
        })}
      {remainingLength >= 1 &&
      <CustomToolTip title={remainingItems}>
        <Tag tag={'+' + remainingLength} showRemoveButton={false}/>
      </CustomToolTip>

      }
    </Container>
  )
}

export default PreviewTags
