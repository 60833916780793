import React, { Fragment, useState } from 'react'

import { NativeSelect, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CONSTANTS } from 'core/constants'
import { Member } from 'pages/Workspace/layouts/MembersListRow/components/Member'
import { useSelector } from 'react-redux'
import { can, TRIP_CHANGE_ROLE } from 'utils/permissions'
import { sendNotification } from 'utils/toast'

import { remove } from 'api/tripMemberInvite'

import { style } from './invitedMembers.style'
import CustomToolTip from 'components/CustomTooltip/CustomTooltip'

const useStyles = makeStyles(theme => style(theme))

const InvitedMembers = ({ invitedMembers, currentTrip, setInvitedMembers }) => {
  const classes = useStyles()
  const { user } = useSelector(state => state.user)

  const canEditRole = can(currentTrip.trips_roles[0].role, TRIP_CHANGE_ROLE)
  const [removeState, setRemoveState] = useState('')

  const handleChange = async (e, member) => {
    if (e.target.value === 'Remove') onRemovePendingTripMember(member)
    setRemoveState('')
  }

  /**
   * Remove pending trip member
   *
   * @param {String} member invited member
   */
  const onRemovePendingTripMember = async member => {
    try {
      const res = await remove({
        uid: member.uid,
        email: member.email,
        user_uid: user.uid,
        trip_uid: member.trip_uid,
      })

      if (res.deleted === true) {
        const invitedMemberWithRemovedMember = invitedMembers.filter(
          mem => mem.email !== member.email,
        )

        setInvitedMembers(invitedMemberWithRemovedMember)
        sendNotification(
          CONSTANTS.COLLABORATORS_MODAL_MEMBER_REMOVE_MEMBER_SUCCESS,
          'success',
        )
      }
    } catch (error) {
      sendNotification(
        CONSTANTS.COLLABORATORS_MODAL_MEMBER_REMOVE_MEMBER_ERROR,
        'error',
      )
    }
  }

  return (
    <Fragment>
      <div>
        <p className={classes.descriptionText}>Pending</p>
        {invitedMembers.length > 0 &&
          invitedMembers
            .sort((a, b) => {
              return a?.email?.localeCompare(b?.email) ?? true
            })
            .map((member, idx) => {
              return (
                <>
                  <div key={idx} className={classes.memberContainer}>
                    <div className={classes.memberDetailsContainer}>
                      <Member
                        key={idx}
                        name={''}
                        displayName={''}
                        backgroundColor="#E5E5E5"
                      />
                      <CustomToolTip title={member.email} showonClick={true}>
                        <div
                          key={member.email}
                          style={{ color: '#8F8F8F' }}
                          className={classes.email}>
                          <p className={classes.memberEmail}>{member.email}</p>
                        </div>
                      </CustomToolTip>
                    </div>
                    <CustomToolTip title={'Edit role'} showonClick={false}>
                      <select
                        style={{ color: 'gray' }}
                        className={`${classes.membersRolesDropdown}`}
                        name="member"
                        onChange={e => handleChange(e, member)}
                        inputProps={{
                          disabled: !canEditRole,
                        }}
                        value={
                          member.role.charAt(0) +
                          member.role.slice(1).toLowerCase()
                        }>
                        <option className={classes.option}>
                          {member.role.charAt(0) +
                            member.role.slice(1).toLowerCase()}
                        </option>
                        )<option className={classes.option}>Remove</option>)
                      </select>
                    </CustomToolTip>
                  </div>
                </>
              )
            })}
      </div>
    </Fragment>
  )
}

export default InvitedMembers
