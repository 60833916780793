// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { AppDispatch } from '../index'

type TripUserRole = {
  user_uid: string
  role: string
  role_uid: string
}

type TripUserRoles = { data: Array<TripUserRole> }

const initialState: TripUserRoles = {
  data: [],
}
const slice = createSlice({
  name: 'tripUserRoleSlice',
  initialState,
  reducers: {
    _setTripRoles: (state, { payload }: PayloadAction<Array<TripUserRole>>) => {
      state.data = payload
    },
  },
})

export default slice.reducer

const { _setTripRoles } = slice.actions

export const setTripUserRole =
  (data: Array<TripUserRole>) => (dispatch: AppDispatch) => {
    try {
      dispatch(_setTripRoles(data))
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }
