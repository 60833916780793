import React from 'react'

type IconProp = {
  stroke?: string
  height?: string
  width?: string
}

const CommunityLogo = ({ stroke, height, width }: IconProp) => {
  return (
    <svg
      width={width ?? '100%'}
      height={height ?? '164'}
      viewBox="0 0 470 164"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M321.037 126.635C324.486 144.877 342.118 156.86 360.421 153.4C378.724 149.941 390.766 132.348 387.318 114.106C383.87 95.864 366.237 83.8806 347.934 87.3402C329.631 90.7999 317.589 108.393 321.037 126.635Z"
        fill="#FFD600"
      />
      <path
        d="M383.797 110.771C386.109 110.744 391.109 111.836 392.617 116.424"
        stroke="#263238"
        strokeWidth="0.759439"
        strokeLinecap="round"
      />
      <path
        d="M330.379 93.4245C334.784 93.0388 339.222 93.4556 343.479 94.6547C347.242 96.1203 348.526 97.5315 350.625 97.7124C352.724 97.8934 354.37 96.6631 358.459 95.7765C362.714 95.2715 367.023 95.4671 371.215 96.3555C372.626 96.4821 373.892 96.9887 373.838 98.8704C373.784 100.752 372.427 100.842 372.029 102.254C371.106 107.157 369.84 113.417 363.362 113.326C349.992 113.326 354.986 100.571 350.064 100.915C348.255 101.204 348.345 104.624 347.115 106.994C346.658 107.939 346.047 108.801 345.306 109.545C340.71 113.869 332.641 110.812 331.573 106.162C330.596 103.828 330.995 100.209 329.945 98.1647C329.004 97.2058 328.317 93.732 330.379 93.4245ZM355.148 105.112C355.368 106.501 355.941 107.809 356.813 108.912C357.494 109.683 358.328 110.304 359.263 110.734C360.198 111.164 361.212 111.394 362.241 111.409C363.624 111.617 365.036 111.361 366.258 110.68C367.479 109.999 368.44 108.931 368.989 107.645L369.297 106.904C369.984 104.859 370.998 100.517 369.948 99.1779C368.139 96.9344 358.496 96.5183 355.474 99.576C354.606 100.643 354.732 103.05 355.148 105.112ZM332.46 102.616C332.547 103.833 332.9 105.016 333.494 106.082C334.088 107.148 334.908 108.071 335.898 108.785C339.516 110.83 344.618 109.256 345.577 105.764C348.183 98.5266 345.378 96.5907 338.033 95.3966C331.935 95.0347 331.501 96.5907 332.424 102.616L332.46 102.616Z"
        fill="#02C45D"
      />
      <path
        d="M332.424 102.615C332.511 103.832 332.863 105.016 333.457 106.082C334.051 107.148 334.872 108.07 335.861 108.785C339.48 110.829 344.582 109.255 345.541 105.763C348.146 98.526 345.342 96.59 337.996 95.3959C331.935 95.0341 331.501 96.5901 332.424 102.615Z"
        fill="#37474F"
      />
      <path
        d="M355.148 105.111C355.368 106.499 355.941 107.808 356.813 108.911C357.493 109.682 358.328 110.303 359.263 110.733C360.197 111.163 361.212 111.392 362.24 111.407C363.623 111.616 365.036 111.36 366.258 110.678C367.479 109.997 368.44 108.93 368.989 107.644L369.297 106.902C369.984 104.858 370.997 100.515 369.948 99.1766C368.139 96.9331 358.495 96.517 355.474 99.5747C354.605 100.642 354.732 103.048 355.148 105.111Z"
        fill="#37474F"
      />
      <path
        d="M362.296 97.3493C365.552 97.2408 368.845 97.9102 369.858 99.1586C370.871 100.407 369.858 104.84 369.207 106.884C369.148 107 369.099 107.121 369.062 107.246C368.079 107.122 367.114 106.886 366.185 106.54C365.588 106.305 364.828 106.052 364.376 106.54C363.924 107.029 364.105 107.59 364.376 108.115C364.93 109.007 365.19 110.052 365.118 111.1C364.171 111.364 363.187 111.473 362.205 111.425C361.176 111.411 360.162 111.181 359.227 110.751C358.293 110.321 357.458 109.7 356.777 108.929C356.493 108.57 356.239 108.189 356.017 107.789C357.121 108.418 358.365 108.76 359.636 108.784C359.853 108.826 360.076 108.822 360.291 108.772C360.506 108.722 360.708 108.628 360.884 108.494C361.053 108.291 361.167 108.048 361.214 107.788C361.262 107.528 361.242 107.261 361.156 107.011C360.822 105.655 360.205 104.385 359.346 103.284C359.082 102.962 358.879 102.594 358.749 102.198C358.692 102 358.699 101.789 358.767 101.595C358.835 101.401 358.961 101.232 359.129 101.113C359.3 101.037 359.485 100.998 359.672 100.998C359.859 100.998 360.044 101.037 360.215 101.113C361.086 101.401 361.919 101.796 362.694 102.289C363.2 102.614 363.833 102.994 364.34 102.669C364.467 102.567 364.569 102.437 364.638 102.29C364.706 102.142 364.741 101.981 364.738 101.818C364.702 100.98 364.376 100.181 363.815 99.5566C363.399 98.8148 362.856 98.1092 362.296 97.3493Z"
        fill="#455A64"
      />
      <path
        d="M333.691 105.618C334.03 105.766 334.397 105.843 334.767 105.843C335.137 105.843 335.504 105.766 335.844 105.618C336.001 105.551 336.133 105.438 336.224 105.293C336.26 105.144 336.26 104.989 336.224 104.84C336.073 103.797 335.729 102.791 335.21 101.873C334.951 101.438 334.861 100.923 334.957 100.426C335.111 100.114 335.361 99.8592 335.671 99.6997C335.98 99.5401 336.332 99.4839 336.676 99.5392C337.381 99.5392 338.033 99.883 338.72 99.9554C339.091 100.014 339.47 99.9369 339.788 99.7383C339.948 99.6019 340.071 99.428 340.147 99.2322C340.222 99.0364 340.248 98.8248 340.222 98.6165C340.166 98.2 340.024 97.7997 339.806 97.4405C339.405 96.7149 338.926 96.0354 338.377 95.4141C344.655 96.4634 347.423 98.1642 346.084 103.646C345.877 103.779 345.659 103.894 345.433 103.99C344.418 104.333 343.318 104.333 342.303 103.99C341.432 103.613 340.512 103.363 339.571 103.248C339.223 103.242 338.884 103.358 338.612 103.574C338.447 103.751 338.328 103.965 338.265 104.198C338.202 104.432 338.197 104.677 338.25 104.913C338.384 105.608 338.622 106.279 338.956 106.903C339.279 107.535 339.441 108.238 339.426 108.947C339.4 109.173 339.339 109.392 339.245 109.599C338.036 109.591 336.848 109.28 335.789 108.694C334.484 107.872 333.5 106.627 333.003 105.166C333.223 105.33 333.453 105.482 333.691 105.618Z"
        fill="#455A64"
      />
      <path
        d="M346.61 112.443C346.645 111.675 347.549 110.601 350.023 110.713C352.497 110.824 353.3 111.974 353.266 112.743C353.231 113.511 352.136 116.93 349.662 116.819C347.188 116.707 346.576 113.211 346.61 112.443Z"
        fill="#FF8CCC"
      />
      <path
        d="M364.009 93.4113C363.445 92.9812 362.039 92.2661 360.922 92.8468"
        stroke="#263238"
        strokeWidth="0.637943"
        strokeLinecap="round"
      />
      <path
        d="M344.684 91.74C344.053 91.4177 342.541 90.9659 341.546 91.7374"
        stroke="#263238"
        strokeWidth="0.637943"
        strokeLinecap="round"
      />
      <path
        d="M323.164 109.873C320.957 109.185 316.539 106.601 316.523 101.771"
        stroke="#263238"
        strokeWidth="0.759439"
        strokeLinecap="round"
      />
      <ellipse
        cx="436.641"
        cy="119.65"
        rx="33.3335"
        ry="33.2227"
        transform="rotate(-0.0445165 436.641 119.65)"
        fill="#02C45D"
      />
      <path
        d="M459.163 127.477C461.28 126.418 465.318 123.08 464.539 118.197"
        stroke="#263238"
        strokeWidth="0.777458"
        strokeLinecap="round"
      />
      <path
        d="M407.934 118.846C407.91 121.212 409.036 126.329 413.736 127.866"
        stroke="#263238"
        strokeWidth="0.777458"
        strokeLinecap="round"
      />
      <path
        d="M448.58 95.5165C448.064 95.0806 446.758 94.334 445.667 94.835"
        stroke="#263238"
        strokeWidth="0.608258"
        strokeLinecap="round"
      />
      <path
        d="M424.55 95.3637C423.879 95.4441 422.442 95.8898 422.064 97.0297"
        stroke="#263238"
        strokeWidth="0.608258"
        strokeLinecap="round"
      />
      <path
        d="M435.158 107.322C435.751 109.103 437.37 111.597 439.095 107.322"
        stroke="#263238"
        strokeWidth="0.750033"
        strokeLinecap="round"
      />
      <path
        d="M421 101.415C423.157 103.759 428.651 107.04 433.376 101.415"
        stroke="#263238"
        strokeWidth="0.750033"
        strokeLinecap="round"
      />
      <path
        d="M422.688 103.008C422.626 103.352 422.407 104.058 422.032 104.133"
        stroke="#263238"
        strokeWidth="0.750033"
        strokeLinecap="round"
      />
      <path
        d="M423.813 103.945C423.751 104.289 423.532 104.995 423.157 105.07"
        stroke="#263238"
        strokeWidth="0.750033"
        strokeLinecap="round"
      />
      <path
        d="M421.376 102.071C421.313 102.415 421.094 103.121 420.719 103.196"
        stroke="#263238"
        strokeWidth="0.750033"
        strokeLinecap="round"
      />
      <path
        opacity="0.62"
        d="M438.548 95.227C439.552 94.8452 440.421 94.1741 441.044 93.2985C441.667 92.423 442.016 91.3824 442.047 90.3083C442.079 89.2343 441.791 88.1751 441.22 87.2647C440.649 86.3543 439.821 85.6337 438.841 85.194C437.86 84.7543 436.771 84.6152 435.712 84.7945C434.653 84.9737 433.67 85.4632 432.889 86.2009C432.108 86.9387 431.563 87.8915 431.323 88.939C431.084 89.9865 431.16 91.0815 431.543 92.0854C432.056 93.4304 433.082 94.5167 434.395 95.1057C435.709 95.6948 437.202 95.7384 438.548 95.227Z"
        fill="#FFE55F"
      />
      <path
        opacity="0.62"
        d="M437.951 93.6521C438.646 93.3891 439.246 92.9258 439.677 92.3211C440.108 91.7163 440.349 90.9973 440.371 90.2551C440.393 89.5129 440.193 88.781 439.798 88.1522C439.404 87.5233 438.831 87.0259 438.153 86.7229C437.475 86.4199 436.723 86.325 435.991 86.4503C435.259 86.5755 434.581 86.9152 434.042 87.4264C433.504 87.9375 433.129 88.597 432.966 89.3214C432.802 90.0457 432.858 90.8022 433.125 91.4949C433.481 92.4197 434.188 93.1658 435.093 93.5701C435.997 93.9744 437.025 94.0039 437.951 93.6521Z"
        fill="#FFEE96"
      />
      <path
        d="M443.911 83.9039C444.456 83.2021 444.811 82.3724 444.942 81.4942C445.074 80.6161 444.978 79.7188 444.664 78.8882L444.998 78.7627C445.334 79.6537 445.435 80.6161 445.292 81.5574C445.149 82.4987 444.766 83.3874 444.18 84.1383L443.911 83.9039Z"
        fill="#0B6224"
      />
      <path
        d="M444.375 84.5277L444.228 84.2016C445.114 83.8569 446.073 83.7451 447.015 83.8768C447.957 84.0085 448.849 84.3793 449.606 84.9539L449.393 85.2423C448.688 84.7033 447.856 84.3545 446.977 84.2293C446.099 84.1042 445.203 84.2069 444.375 84.5277Z"
        fill="#0B6224"
      />
      <path
        d="M444.31 85.8333C444.102 85.9878 443.854 86.0775 443.596 86.091C443.338 86.1045 443.082 86.0412 442.859 85.9092C442.637 85.7772 442.459 85.5823 442.348 85.3492C442.236 85.116 442.196 84.8551 442.233 84.5993C442.27 84.3434 442.381 84.1042 442.554 83.9118C442.726 83.7193 442.952 83.5823 443.202 83.518C443.452 83.4537 443.716 83.4649 443.96 83.5504C444.204 83.6358 444.417 83.7916 444.573 83.9981C444.677 84.1355 444.752 84.2922 444.796 84.4591C444.839 84.6261 444.848 84.7999 444.824 84.9706C444.799 85.1412 444.741 85.3054 444.653 85.4534C444.565 85.6015 444.448 85.7307 444.31 85.8333Z"
        fill="#0B6224"
      />
      <path
        d="M442.508 85.8726C442.444 85.8128 442.386 85.7478 442.333 85.6783C442.158 85.4426 442.056 85.1601 442.041 84.8667C442.026 84.5732 442.099 84.2819 442.249 84.0295C442.4 83.7772 442.622 83.5751 442.887 83.4489C443.153 83.3227 443.45 83.2781 443.74 83.3205C444.031 83.363 444.303 83.4907 444.521 83.6875C444.739 83.8844 444.894 84.1415 444.966 84.4263C445.038 84.7112 445.024 85.0111 444.926 85.288C444.828 85.5649 444.65 85.8065 444.414 85.9823C444.134 86.1902 443.789 86.2931 443.441 86.2731C443.093 86.253 442.762 86.1113 442.508 85.8726ZM444.304 83.971C444.099 83.7725 443.827 83.6589 443.541 83.653C443.256 83.6471 442.979 83.7494 442.766 83.9394C442.553 84.1293 442.42 84.3928 442.393 84.6769C442.367 84.961 442.448 85.2447 442.622 85.471C442.802 85.7105 443.07 85.8696 443.366 85.9137C443.513 85.9352 443.664 85.9273 443.808 85.8903C443.952 85.8533 444.088 85.788 444.207 85.6982C444.326 85.6105 444.427 85.4997 444.502 85.3724C444.578 85.2451 444.627 85.1039 444.647 84.9571C444.693 84.6656 444.622 84.3678 444.45 84.1282C444.405 84.0697 444.354 84.0154 444.299 83.9661L444.304 83.971Z"
        fill="#0B6224"
      />
      <path
        d="M443.488 87.3515C443.158 87.5976 442.764 87.7405 442.353 87.7622C441.943 87.7838 441.535 87.6832 441.182 87.4731C440.828 87.263 440.545 86.9529 440.368 86.5819C440.191 86.2109 440.128 85.7957 440.187 85.3889C440.246 84.9821 440.425 84.602 440.7 84.2966C440.975 83.9911 441.334 83.7742 441.733 83.6732C442.131 83.5722 442.551 83.5916 442.938 83.729C443.326 83.8665 443.664 84.1157 443.909 84.4452C444.238 84.8867 444.379 85.4407 444.3 85.9856C444.221 86.5305 443.929 87.0218 443.488 87.3515Z"
        fill="white"
      />
      <path
        d="M440.712 87.338C440.617 87.2485 440.53 87.1515 440.451 87.0479C440.126 86.5695 439.997 85.9842 440.091 85.4132C440.184 84.8422 440.494 84.3291 440.955 83.98C441.417 83.6308 441.995 83.4723 442.57 83.5373C443.145 83.6022 443.673 83.8856 444.045 84.3289C444.401 84.8087 444.552 85.4099 444.466 86.001C444.38 86.5922 444.063 87.1251 443.585 87.4833C443.164 87.7973 442.647 87.9545 442.122 87.928C441.598 87.9015 441.099 87.6928 440.712 87.338ZM443.563 84.3199C443.278 84.0532 442.916 83.8827 442.528 83.8325C442.094 83.7697 441.651 83.8594 441.275 84.0864C440.899 84.3135 440.613 84.6637 440.466 85.0776C440.319 85.4916 440.321 85.9436 440.469 86.3567C440.618 86.7699 440.906 87.1186 441.283 87.3437C441.66 87.5688 442.104 87.6562 442.538 87.5912C442.972 87.5261 443.371 87.3126 443.665 86.9869C443.96 86.6612 444.133 86.2435 444.154 85.8048C444.175 85.3662 444.044 84.9337 443.782 84.5809C443.714 84.481 443.635 84.3886 443.548 84.3052L443.563 84.3199Z"
        fill="#0B6224"
      />
      <path
        d="M438.948 90.7375C440.965 89.2317 442.044 87.2655 441.358 86.3459C440.671 85.4262 438.48 85.9014 436.462 87.4073C434.445 88.9131 433.366 90.8793 434.052 91.7989C434.738 92.7185 436.93 92.2433 438.948 90.7375Z"
        fill="white"
      />
      <path
        d="M434.038 92.0597C433.99 92.0133 433.946 91.9631 433.906 91.9097C433.533 91.4059 433.595 90.6353 434.086 89.7414C435.285 87.8051 437.174 86.3955 439.372 85.797C440.369 85.5821 441.125 85.7422 441.5 86.2433C441.874 86.7444 441.811 87.5177 441.322 88.409C440.745 89.3823 439.972 90.225 439.052 90.8836C438.159 91.5772 437.132 92.0777 436.036 92.3534C435.144 92.5501 434.445 92.4439 434.038 92.0597ZM441.137 86.367C440.821 86.0684 440.219 85.9897 439.46 86.155C437.365 86.738 435.566 88.0867 434.419 89.9337C434.007 90.6866 433.932 91.3366 434.217 91.7042C434.503 92.0717 435.144 92.1972 435.983 92.0145C437.03 91.7487 438.009 91.2684 438.86 90.6042C439.74 89.9769 440.481 89.1738 441.035 88.2457C441.447 87.4928 441.522 86.8427 441.236 86.4752C441.207 86.4363 441.174 86.4001 441.137 86.367Z"
        fill="#0B6224"
      />
      <path
        d="M434.032 91.8138C433.667 91.3224 433.803 90.5295 434.321 89.6702C434.496 89.3805 434.695 89.1059 434.915 88.8491C435.484 89.1423 435.978 89.5601 436.362 90.0713C436.746 90.5826 437.009 91.174 437.132 91.8013C435.745 92.408 434.528 92.4451 434.032 91.8138Z"
        fill="white"
      />
      <path
        d="M434.036 92.057C433.989 92.0114 433.945 91.962 433.906 91.9095C433.51 91.3787 433.612 90.5237 434.187 89.5669C434.367 89.27 434.57 88.9884 434.796 88.7252L434.892 88.6242L435.017 88.6837C435.606 88.9933 436.118 89.4322 436.514 89.9675C436.91 90.5029 437.18 91.1208 437.303 91.7752L437.338 91.9204L437.198 91.9798C435.798 92.5793 434.617 92.6053 434.036 92.057ZM434.977 89.063C434.8 89.2813 434.638 89.5117 434.493 89.7524C433.997 90.5783 433.886 91.2873 434.195 91.6971C434.588 92.2204 435.655 92.2279 436.915 91.703C436.794 91.1521 436.559 90.6327 436.225 90.1781C435.892 89.7234 435.466 89.3436 434.977 89.063Z"
        fill="#0B6224"
      />
      <path
        d="M437.132 91.8016C437.015 91.1735 436.757 90.58 436.378 90.0656C435.998 89.5512 435.508 89.1291 434.943 88.8309C435.397 88.2989 435.911 87.82 436.473 87.4033C436.712 87.2226 436.952 87.0619 437.198 86.9113C437.843 87.2119 438.403 87.6688 438.826 88.2405C439.25 88.8122 439.525 89.4807 439.625 90.1853C439.414 90.3729 439.189 90.5582 438.95 90.739C438.387 91.162 437.777 91.5186 437.132 91.8016Z"
        fill="#92E3A9"
      />
      <path
        d="M436.998 92.0619L436.949 91.854C436.84 91.2506 436.594 90.6802 436.231 90.186C435.868 89.6918 435.397 89.2867 434.854 89.0016L434.649 88.9015L434.806 88.7357C435.271 88.1901 435.796 87.6997 436.373 87.2744C436.608 87.0988 436.857 86.9278 437.11 86.7744L437.192 86.7242L437.276 86.7647C437.951 87.0713 438.537 87.5422 438.983 88.1343C439.428 88.7264 439.718 89.4207 439.826 90.1537L439.843 90.249L439.775 90.3216C439.546 90.5222 439.322 90.7076 439.087 90.8831C438.512 91.3173 437.888 91.6836 437.228 91.9747L436.998 92.0619ZM435.963 89.3483C436.589 89.9518 437.033 90.7194 437.244 91.5633C437.802 91.2971 438.333 90.9781 438.83 90.6106C439.03 90.4562 439.227 90.2993 439.422 90.1299C439.314 89.489 439.055 88.883 438.666 88.3622C438.277 87.8414 437.77 87.4208 437.186 87.1353C436.973 87.2674 436.763 87.412 436.56 87.564C436.069 87.9306 435.615 88.3437 435.204 88.7976C435.478 88.9505 435.733 89.1354 435.963 89.3483Z"
        fill="#0B6224"
      />
      <path
        d="M438.769 90.773C438.301 88.2374 436.483 87.6084 436.409 87.5626L436.518 87.2242C436.604 87.252 438.615 87.9361 439.125 90.6923L438.769 90.773Z"
        fill="#0B6224"
      />
      <path
        d="M437.836 91.3675C437.423 88.9085 435.654 88.326 435.573 88.3081L435.674 87.9674C435.758 87.9928 437.735 88.6249 438.179 91.3149L437.836 91.3675Z"
        fill="#0B6224"
      />
      <path
        d="M436.958 91.8862L436.945 91.7904L437.122 91.8107L437.293 91.7603L437.148 91.815L437.3 91.8309L437.148 91.815L436.958 91.8862Z"
        fill="#0B6224"
      />
      <path
        d="M434.805 88.9765L434.965 88.6355C434.993 88.6489 435.02 88.6642 435.045 88.6813L434.844 88.983L434.805 88.9765Z"
        fill="#0B6224"
      />
      <path
        d="M435.955 92.1597C435.687 91.1977 435.075 90.3679 434.235 89.8286L434.43 89.5231C435.345 90.106 436.012 91.0066 436.302 92.0515L435.955 92.1597Z"
        fill="#0B6224"
      />
      <path
        d="M442.813 84.5269C440.877 83.5645 438.75 83.0478 436.588 83.0146C433.271 83.0533 434.209 84.1057 434.209 84.1057C434.209 84.1057 431.822 83.7576 432.132 85.1984C432.443 86.6392 439.931 86.3736 441.261 85.9147C442.592 85.4559 443.617 84.6881 442.813 84.5269Z"
        fill="#0B6224"
      />
      <path
        d="M432.184 85.6114C432.072 85.5133 431.993 85.3824 431.959 85.2371C431.913 85.0819 431.907 84.9174 431.942 84.7593C431.977 84.6012 432.052 84.4547 432.16 84.3339C432.579 83.8909 433.445 83.8713 433.918 83.9008C433.894 83.7891 433.907 83.6726 433.956 83.5694C434.165 83.0946 435.023 82.8559 436.585 82.8367C438.767 82.8745 440.914 83.3956 442.871 84.3625C443.183 84.4317 443.26 84.5934 443.262 84.732C443.3 85.2579 441.998 85.8571 441.306 86.096C440.078 86.5192 434.125 86.7639 432.444 85.8183C432.349 85.761 432.261 85.6915 432.184 85.6114ZM442.917 84.7444C442.917 84.7444 442.883 84.7125 442.775 84.703L442.726 84.6867C440.816 83.7399 438.719 83.2294 436.588 83.1921C434.708 83.2153 434.349 83.5633 434.283 83.7114C434.268 83.7584 434.265 83.8087 434.275 83.857C434.285 83.9054 434.308 83.9503 434.341 83.9871L434.651 84.3489L434.181 84.2816C433.816 84.2314 432.799 84.1872 432.424 84.5836C432.357 84.6626 432.31 84.7578 432.29 84.86C432.27 84.9622 432.277 85.0679 432.31 85.1667C432.338 85.242 432.381 85.3108 432.437 85.3686C432.493 85.4265 432.56 85.4722 432.635 85.5028C434.21 86.3884 440.045 86.1546 441.214 85.7634C442.272 85.3804 442.893 84.8938 442.917 84.7444Z"
        fill="#0B6224"
      />
      <path
        d="M443.489 85.4372C443.862 87.5667 443.754 89.7527 443.171 91.8347C442.19 95.0014 441.447 93.8048 441.447 93.8048C441.447 93.8048 441.102 96.1946 439.811 95.485C438.52 94.7754 440.892 87.6816 441.734 86.5157C442.576 85.3497 443.549 84.6262 443.489 85.4372Z"
        fill="#0B6224"
      />
      <path
        d="M439.588 95.5386C439.445 95.3895 439.35 95.2006 439.315 94.9968C438.884 93.1182 440.816 87.4765 441.57 86.4189C441.99 85.8347 442.927 84.7593 443.434 84.934C443.554 84.9785 443.688 85.1007 443.665 85.4216C444.033 87.573 443.921 89.7794 443.336 91.8824C442.874 93.3729 442.399 94.1301 441.887 94.1951C441.774 94.2095 441.659 94.1899 441.558 94.1389C441.45 94.5958 441.188 95.4276 440.644 95.6977C440.498 95.7666 440.336 95.797 440.175 95.7861C440.013 95.7753 439.858 95.7235 439.722 95.6357C439.673 95.6099 439.628 95.5772 439.588 95.5386ZM443.297 85.2606C443.173 85.2364 442.533 85.6907 441.861 86.63C441.146 87.626 439.261 93.1554 439.661 94.9164C439.669 94.9961 439.694 95.0732 439.734 95.1429C439.774 95.2125 439.827 95.2731 439.892 95.3207C439.976 95.3802 440.074 95.4173 440.177 95.4285C440.28 95.4397 440.383 95.4246 440.479 95.3848C440.963 95.1416 441.21 94.1438 441.26 93.7793L441.332 93.2982L441.588 93.712C441.615 93.7541 441.652 93.7886 441.696 93.8121C441.74 93.8356 441.789 93.8473 441.839 93.846C441.997 93.8239 442.434 93.5796 442.991 91.7813C443.944 88.6961 443.312 85.5047 443.308 85.472L443.304 85.4192C443.318 85.2953 443.297 85.2606 443.297 85.2606Z"
        fill="#0B6224"
      />
      <path
        d="M452.681 104.628C452.329 106.553 449.122 107.913 445.66 107.281C442.198 106.648 439.676 104.575 440.028 102.65C440.38 100.725 443.638 97.8988 447.1 98.5314C450.562 99.164 453.033 102.703 452.681 104.628Z"
        fill="white"
      />
      <path
        d="M452.394 102.215C450.627 99.7009 445.796 95.8788 440.596 100.702"
        stroke="#263238"
        strokeWidth="0.750033"
        strokeLinecap="round"
      />
      <path
        d="M450.982 100.478C451.084 100.156 451.379 99.5046 451.746 99.4785"
        stroke="#263238"
        strokeWidth="0.750033"
        strokeLinecap="round"
      />
      <path
        d="M450.025 99.4411C450.127 99.1187 450.423 98.4674 450.79 98.4414"
        stroke="#263238"
        strokeWidth="0.750033"
        strokeLinecap="round"
      />
      <path
        d="M452.118 101.539C452.22 101.216 452.515 100.565 452.882 100.539"
        stroke="#263238"
        strokeWidth="0.750032"
        strokeLinecap="round"
      />
      <ellipse
        cx="445.96"
        cy="102.352"
        rx="4.0517"
        ry="3.84392"
        fill="#263238"
      />
      <ellipse
        cx="33.5718"
        cy="33.4603"
        rx="33.5718"
        ry="33.4603"
        transform="matrix(-0.974505 0.224367 0.224367 0.974505 159.911 80)"
        fill="#FF1053"
      />
      <circle
        cx="7.88468"
        cy="7.88468"
        r="7.88468"
        transform="matrix(-0.974505 0.224367 0.224367 0.974505 145.317 92.4236)"
        fill="white"
      />
      <circle
        cx="5.34465"
        cy="5.34465"
        r="5.34465"
        transform="matrix(-0.974505 0.224367 0.224367 0.974505 141.142 95.9912)"
        fill="#263238"
      />
      <circle
        cx="0.952511"
        cy="0.952511"
        r="0.952511"
        transform="matrix(-0.974505 0.224367 0.224367 0.974505 135.47 97.2966)"
        fill="white"
      />
      <path
        d="M142.985 91.621C142.445 91.1659 141.082 90.3865 139.943 90.9094"
        stroke="#263238"
        strokeWidth="0.635007"
        strokeLinecap="round"
      />
      <circle
        cx="7.88468"
        cy="7.88468"
        r="7.88468"
        transform="matrix(-0.974505 0.224367 0.224367 0.974505 128.506 96.293)"
        fill="white"
      />
      <circle
        cx="5.34465"
        cy="5.34465"
        r="5.34465"
        transform="matrix(-0.974505 0.224367 0.224367 0.974505 128.869 98.8154)"
        fill="#263238"
      />
      <circle
        cx="0.952511"
        cy="0.952511"
        r="0.952511"
        transform="matrix(-0.974505 0.224367 0.224367 0.974505 122.681 100.241)"
        fill="white"
      />
      <path
        d="M118.661 95.7685C117.961 95.8525 116.461 96.3178 116.066 97.5078"
        stroke="#263238"
        strokeWidth="0.635008"
        strokeLinecap="round"
      />
      <path
        d="M138.723 117.922C139.417 120.937 139.551 127.071 136.352 127.808C133.153 128.544 130.625 122.106 129.931 119.092C129.237 116.078 129.128 110.053 132.327 109.317C135.526 108.58 138.029 114.908 138.723 117.922Z"
        fill="#FFC7E7"
      />
      <path
        d="M102.722 125.142C100.471 125.925 95.2243 126.492 92.2488 122.499"
        stroke="#263238"
        strokeWidth="0.783017"
        strokeLinecap="round"
      />
      <path
        d="M175.602 107.99C174.252 109.954 170.361 113.519 165.598 112.064"
        stroke="#263238"
        strokeWidth="0.783017"
        strokeLinecap="round"
      />
      <path
        d="M107.765 99.1186C110.17 92.8211 116.567 89.9112 119.464 89.2435C118.254 86.2745 111.866 82.6601 106.93 86.8464C102.513 90.592 105.363 96.7204 107.765 99.1186Z"
        fill="#ED1990"
      />
      <path
        d="M120.566 82.9933C113.615 83.006 112.476 90.19 111.911 93.2349C115.115 91.0902 117.795 89.9304 119.464 89.2438C121.826 87.0133 124.611 82.9859 120.566 82.9933Z"
        fill="#FF88C7"
      />
      <path
        opacity="0.6"
        d="M12.0503 111.309L10.6818 103.466L16.9448 94.6155C17.0066 94.5339 17.0421 94.4356 17.0466 94.3334C17.0512 94.2312 17.0244 94.13 16.9701 94.0434C16.9157 93.9567 16.8362 93.8888 16.7422 93.8484C16.6482 93.808 16.5442 93.7972 16.4439 93.8174L0.503784 96.5988C0.404799 96.617 0.31329 96.6637 0.240532 96.7333C0.167775 96.8028 0.116941 96.8921 0.0942879 96.9902C0.0716346 97.0882 0.0781506 97.1908 0.113035 97.2852C0.14792 97.3796 0.209649 97.4617 0.290625 97.5215L9.14099 103.785L10.5074 111.615L7.97681 114.053C7.92446 114.108 7.89067 114.179 7.8801 114.254C7.86953 114.33 7.88271 114.407 7.91781 114.475C7.95292 114.542 8.00823 114.598 8.07609 114.633C8.14395 114.668 8.221 114.681 8.29662 114.67L15.1592 113.473C15.234 113.457 15.3021 113.419 15.354 113.363C15.406 113.307 15.4394 113.236 15.4494 113.16C15.4595 113.085 15.4458 113.008 15.4102 112.94C15.3747 112.873 15.319 112.818 15.2509 112.783L12.0503 111.309Z"
        fill="#98CDFE"
      />
      <path
        d="M4.57446 99.2686L9.81146 102.907L13.3805 97.8443C13.3585 97.805 13.327 97.7719 13.2889 97.748L4.57446 99.2686Z"
        fill="#407BFF"
      />
      <path
        d="M23.9552 114.979C20.5402 114.869 13.2284 112.929 11.3018 106.055"
        stroke="#263238"
        strokeWidth="1.12251"
        strokeLinecap="round"
      />
      <g clipPath="url(#clip0_132_42529)">
        <ellipse
          cx="33.6382"
          cy="33.5264"
          rx="33.6382"
          ry="33.5264"
          transform="matrix(-1 0 0 1 84.8008 86.4512)"
          fill="#54C1FE"
        />
        <circle
          cx="7.90025"
          cy="7.90025"
          r="7.90025"
          transform="matrix(-1 0 0 1 63.0483 97.3796)"
          fill="white"
        />
        <circle
          cx="5.3552"
          cy="5.3552"
          r="5.3552"
          transform="matrix(-1 0 0 1 58.1704 99.9255)"
          fill="#263238"
        />
        <circle
          cx="0.954393"
          cy="0.954393"
          r="0.954393"
          transform="matrix(-1 0 0 1 52.3379 99.9255)"
          fill="white"
        />
        <path
          d="M60.582 97.0624C60.1579 96.4968 59.002 95.4293 57.7719 95.6838"
          stroke="#263238"
          strokeWidth="0.636262"
          strokeLinecap="round"
        />
        <circle
          cx="7.27951"
          cy="7.27951"
          r="7.27951"
          transform="matrix(-1 0 0 1 45.7632 97.2964)"
          fill="white"
        />
        <circle
          cx="4.93443"
          cy="4.93443"
          r="4.93443"
          transform="matrix(-1 0 0 1 45.5674 99.6411)"
          fill="#263238"
        />
        <circle
          cx="0.879404"
          cy="0.879404"
          r="0.879404"
          transform="matrix(-1 0 0 1 39.7053 99.6411)"
          fill="white"
        />
        <path
          d="M36.3618 94.952C35.7151 95.0299 34.3307 95.4604 33.9671 96.5593"
          stroke="#263238"
          strokeWidth="0.636262"
          strokeLinecap="round"
        />
        <path
          d="M43.2834 115.201C43.2834 114.243 44.0116 114.166 46.3561 114.166C48.7007 114.166 49.5906 114.243 49.5906 115.201C49.5906 116.158 48.7007 120.473 46.3561 120.473C44.0116 120.473 43.2834 116.158 43.2834 115.201Z"
          fill="#FF8CCC"
        />
        <path
          d="M77.32 115.103C77.6339 118.505 76.6163 126.001 70.0342 128.766"
          stroke="#263238"
          strokeWidth="1.12251"
          strokeLinecap="round"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M181.587 73.3749C182.442 61.9817 186.656 51.0977 193.697 42.0994C200.737 33.101 210.289 26.3925 221.143 22.822C231.997 19.2515 243.666 18.9795 254.675 22.0403C265.684 25.1012 275.538 31.3574 282.99 40.0179C290.443 48.6784 295.16 59.3541 296.545 70.6951C297.202 76.0703 297.096 81.4805 296.254 86.771L296.274 86.7828C295.383 93.9995 296.324 106.642 297.397 113.954L299.619 125.572C300.307 129.17 297.405 132.447 293.749 132.197L281.698 131.374C274.291 131.151 261.75 131.658 254.731 133.352L254.72 133.339C248.303 135.128 241.591 135.804 234.878 135.301C219.611 134.123 205.433 126.946 195.445 115.34C185.458 103.734 180.475 88.6451 181.587 73.3749ZM237.154 104.491C251.939 105.6 264.824 94.513 265.933 79.7279C267.042 64.9429 255.955 52.0583 241.17 50.9495C226.385 49.8406 213.5 60.9273 212.392 75.7124C211.283 90.4974 222.369 103.382 237.154 104.491Z"
        fill="#196AFF"
      />
      <mask
        id="mask0_132_42529"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="190"
        y="28"
        width="101"
        height="99">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M190.516 74.0597C191.237 64.4482 194.792 55.2663 200.732 47.6752C206.672 40.0841 214.729 34.4246 223.886 31.4125C233.043 28.4004 242.887 28.1709 252.174 30.7531C261.461 33.3353 269.774 38.6131 276.062 45.9192C282.349 53.2253 286.328 62.2316 287.497 71.799C288.05 76.334 287.961 80.8985 287.251 85.362L287.267 85.3717C286.515 91.4582 287.309 102.12 288.214 108.289L290.39 119.667C290.819 121.908 289.011 123.949 286.735 123.793L274.981 122.99C268.733 122.802 258.145 123.229 252.221 124.659L252.211 124.647C246.798 126.156 241.136 126.726 235.473 126.301C222.594 125.308 210.633 119.253 202.207 109.462C193.781 99.6712 189.578 86.9418 190.516 74.0597ZM237.394 100.309C249.867 101.244 260.736 91.8912 261.672 79.4183C262.607 66.9454 253.254 56.0758 240.781 55.1403C228.308 54.2049 217.439 63.5578 216.503 76.0307C215.568 88.5036 224.921 99.3732 237.394 100.309Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_132_42529)">
        <rect
          width="42.7294"
          height="110.939"
          transform="matrix(0.75464 0.656139 -0.665035 0.746812 238.458 4.49756)"
          fill="#FF526D"
        />
        <rect
          width="42.7294"
          height="110.939"
          transform="matrix(0.75464 0.656139 -0.665035 0.746812 270.703 32.5337)"
          fill="#FFDD29"
        />
        <rect
          width="42.7294"
          height="110.939"
          transform="matrix(0.756494 0.654001 -0.662919 0.748691 303.273 59.6392)"
          fill="#40DBFF"
        />
      </g>
      <circle
        cx="239.106"
        cy="77.795"
        r="26.3588"
        transform="rotate(-0.510805 239.106 77.795)"
        stroke="#196AFF"
        strokeWidth="7.45125"
      />
      <ellipse
        cx="216.406"
        cy="34.6797"
        rx="1.98498"
        ry="2.15039"
        transform="rotate(-0.510805 216.406 34.6797)"
        fill="white"
      />
      <ellipse
        cx="239.729"
        cy="34.4727"
        rx="1.98498"
        ry="2.15039"
        transform="rotate(-0.510805 239.729 34.4727)"
        fill="white"
      />
      <path
        d="M230.396 39.7418C230.424 42.8837 231.941 53.895 233.346 53.8825C234.752 53.8699 235.853 42.8351 235.825 39.6933C235.797 36.5514 235.23 36.78 233.307 36.7972C231.271 36.8153 230.368 36.6 230.396 39.7418Z"
        fill="white"
      />
      <path
        d="M211.593 14.1145C212.518 13.2858 214.876 11.8435 216.907 12.7042"
        stroke="#263238"
        strokeWidth="1.11769"
        strokeLinecap="round"
      />
      <path
        d="M244.468 12.0846C245.529 11.4405 248.113 10.4575 249.951 11.6779"
        stroke="#263238"
        strokeWidth="1.11769"
        strokeLinecap="round"
      />
      <path
        d="M201.444 38.6326C194.633 34.9117 185.809 27.1798 193.271 14.4521"
        stroke="#263238"
        strokeWidth="1.11769"
        strokeLinecap="round"
      />
      <path
        d="M228.216 130.891C229.18 138.055 230.575 153.076 228.439 155.852C226.302 158.628 222.774 157.797 221.277 157.034"
        stroke="#263238"
        strokeWidth="1.11769"
        strokeLinecap="round"
      />
      <path
        d="M249.676 130.701C248.937 137.873 247.949 152.903 249.898 155.64C251.847 158.377 254.976 157.487 256.296 156.7"
        stroke="#263238"
        strokeWidth="1.11769"
        strokeLinecap="round"
      />
      <circle
        cx="218.357"
        cy="28.0547"
        r="13.878"
        transform="rotate(-0.510805 218.357 28.0547)"
        fill="white"
      />
      <circle
        cx="248.72"
        cy="27.7842"
        r="13.878"
        transform="rotate(-0.510805 248.72 27.7842)"
        fill="white"
      />
      <circle
        cx="222.455"
        cy="28.0185"
        r="9.40721"
        transform="rotate(-0.510805 222.455 28.0185)"
        fill="#263238"
      />
      <circle
        cx="244.622"
        cy="27.8205"
        r="9.40721"
        transform="rotate(-0.510805 244.622 27.8205)"
        fill="#263238"
      />
      <ellipse
        cx="226.223"
        cy="22.3714"
        rx="3.1198"
        ry="3.06506"
        transform="rotate(-0.510805 226.223 22.3714)"
        fill="white"
      />
      <ellipse
        cx="247.501"
        cy="21.5782"
        rx="3.01834"
        ry="3.18919"
        transform="rotate(-0.510805 247.501 21.5782)"
        fill="white"
      />
      <path
        d="M218.476 41.9851C228.171 41.6479 231.607 33.1731 231.598 32.1699L205.181 32.4054C205.614 34.0737 209.369 42.3019 218.476 41.9851Z"
        fill="#E3EDFF"
      />
      <path
        d="M248.739 41.7156C258.434 41.3784 261.87 32.9036 261.861 31.9004L235.512 32.0947C235.944 33.763 239.632 42.0324 248.739 41.7156Z"
        fill="#E3EDFF"
      />
      <defs>
        <clipPath id="clip0_132_42529">
          <rect
            width="67.2763"
            height="67.2763"
            fill="white"
            transform="matrix(-1 0 0 1 84.8008 86.4512)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CommunityLogo
