import React, { createContext, useState, useContext } from 'react'

interface IContext {
  pause: boolean
  setPause: (val: boolean) => void
  execPause: (time: number) => void
}

const defaultState = {
  pause: false,
  setPause: () => null,
  execPause: () => null,
}

type Props = {
  children: React.ReactNode
}

const PauseContext = createContext<IContext>(defaultState)

/**
 * Planning board grid orientation (4 or 6 columns)
 * @param { ReactNode } param children
 */
const PauseProvider = ({ children }: Props) => {
  const [pause, setPause] = useState(true)

  const execPause = (time: number) => setTimeout(() => setPause(false), time)

  return (
    <PauseContext.Provider value={{ pause, setPause, execPause }}>
      {children}
    </PauseContext.Provider>
  )
}

export { PauseContext, PauseProvider }

export const usePause = () => useContext(PauseContext)
