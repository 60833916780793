import styled from '@emotion/styled'
import { DatePicker } from 'formik-antd'

interface DatePickerProps {
    iconSize?: number // Make iconSize optional
  }

  export const StyledDatePicker = styled(DatePicker)<DatePickerProps>`
    border: 1px solid #AFAFAF;
    font-size: 20px;
    line-height: 26px;
    .ant-picker-suffix .anticon {
      ${({ iconSize }) => iconSize && `font-size: ${iconSize}px;`}
      color: #3A3A3A;
    }
    .ant-picker-input > input {
      font-size: 20px;
      line-height: 26px;
    }
  `
