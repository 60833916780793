/* eslint-disable */
/**
 * Validate email in forms
 */
export const emailRegex = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)

/**
 * Validate password in forms
 */
export const passwordRegex = RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*.])(?=.{7,})/,
)

/**
 * Validate username in forms
 */
export const usernameRegex = RegExp(/^[A-Za-z0-9\-\_]\w{6,31}$/)
