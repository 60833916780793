import styled from '@emotion/styled'
import { Container, Typography, Card } from '@material-ui/core'
import { Field, Form } from 'formik'
import { Link } from 'react-router-dom'

export const ContainerStyled = styled(Card)`
  width: 40%;
  padding: 2rem;
  border-radius: 12px;
`
export const Title = styled.p`
  display: flex;
  justify-content: flex-start;
  font-weight: 700;
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 34px;
  text-align: left;
  color: #3a3a3a;
  margin: 0;
`
export const FormStyled = styled(Form)`
  row-gap: 16px;
  display: flex;
  flex-direction: column;
`
export const Label = styled.p`
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  margin: 0;
`
export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`
export const DatesContainer = styled(Container)`
  display: flex;
  padding-left: 0;
  padding-right: 0;
  justify-content: space-between;
  row-gap: 5px;
  flex-wrap: wrap;
  & > div {
    width: 48%; /* Default width for larger screens */
    border-radius: 12px;
    min-width: 125px;

    /* Adjust width to 100% at 1000px or smaller screens */
    @media (max-width: 1150px) {
      width: 100%;
    }
  }
`
export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const SubmitButton = styled.button`
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #4184ff;
  border: none;
  color: white;
  height: 64px;
  font-size: 22px;
  line-height: 28px;
  padding: 18px 22px;
  border-radius: 10px;
  margin-top: 16px;
`

export const SubmitButtonText = styled.p`
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin: 0;
`
export const NavFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  font-size: 16px;
  line-height: 22px;
  margin-top: -16px;
  gap: 10px;
`
export const LinkStyled = styled(Link)`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #0545ba;
  min-width: fit-content;
`
interface FormContainerProps {
  fullWidth?: boolean
}

export const FormContainer = styled.div<FormContainerProps>`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 40px;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '50%')};
  min-width: 260px;
  max-width: 618px;
  max-height: fit-content;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px #0000000d;
  row-gap: 32px;
`
