import React from 'react'

type IconProp = {
  stroke?: string
  height?: string
  width?: string
}
const nextIcon = ({ stroke, height, width }: IconProp) => {
  return (
    <svg
      width={width ?? '20'}
      height={height ?? '20'}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.2524 25.4111L10.3295 22.5133L18.0022 14.8407H0.0236497V10.5823H18.0022L10.3295 2.92218L13.2524 0.0118579L25.952 12.7115L13.2524 25.4111Z"
        fill="white"
      />
    </svg>
  )
}

export default nextIcon
