import styled from '@emotion/styled'
import AttachmentIcon from '@material-ui/icons/Attachment'
import { cssVariables } from 'theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`
export const AttachmentContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  & > div {
    cursor: pointer;
  }
  margin-bottom: 1px;
`
export const AttachmentIconStyled = styled(AttachmentIcon)`
  color: ${cssVariables.colors.alternativeDarkGrey2};
  height: 17.5px;
  width: 17.5px;
  transform: rotate(-45deg);
  margin-right: 10px;
`

export const CommentSectionContainer = styled.div`
  margin-top: 1.6rem;
  padding-right: 10px
`
