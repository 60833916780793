import styled from '@emotion/styled'

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  background-color: white;
  border-radius: 10px;
  padding: 1rem;
  align-items: center;
  height: 100%;
`

export const ErrorLogo = styled.img`
  width: 50%;
  height: 50%;
  flex-grow: 2;
  padding: 30px;
`

export const ErrorHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`

export const ErrorFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`
