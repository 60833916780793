import styled from '@emotion/styled'

export const ListItem = styled.div`
  display: flex;
  background: #ECF3FF;
  min-width: fit-content;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  padding: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: #0545BA;
`

export const CloseIcon = styled.i`
  font-size: 10px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0;
  color: #0c41a1;
`
