import React, { useState } from 'react'

import { MTDialog } from 'components/MTDialog'
import { useHistory } from 'react-router'

const TentativeUserSaveDialog = ({
  open,
  setOpenTentativeUserDialog,
  type,
}) => {
  /**
   * Close warning dialog
   */

  const onCloseModal = () => {
    setOpenTentativeUserDialog(false)
  }



  return (
    <>
      <MTDialog
        openDialog={open}
        onCloseModal={onCloseModal}
        onSkipAndContinueClickHandler={onCloseModal}
        type={type}
      />
    </>
  )
}
export default TentativeUserSaveDialog
