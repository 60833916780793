/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useRef, useState } from 'react'

import { MenuItem } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { MTDialog } from 'components'
import { modalTypes } from 'components/MTDialog/types'
import { CONSTANTS } from 'core/constants'
import { Formik, Form } from 'formik'
import { DatePicker } from 'formik-antd'
import { HTTPError } from 'ky'
import { isNil } from 'lodash'
import moment from 'moment'
import type { Moment } from 'moment'
import { MembersListRow } from 'pages/Workspace/layouts/MembersListRow'
import { useHistory } from 'react-router-dom'
import { resetUserStore } from 'store'
import {
  useAppDispatch,
  useAppSelector,
  useGeolocation,
  CoordsObject,
} from 'store/hooks'
import { setUserTripsCount } from 'store/slices/userPlanSlice'
import { setDocumentTitle } from 'utils/documentTitle'
import {
  can,
  TRIP_TITLE_EDIT,
  TRIP_DATE_START_EDIT,
  TRIP_DATE_END_EDIT,
  TRIP_DELETE,
} from 'utils/permissions'
import { isScreenSize } from 'utils/screenSzie'
import { sendNotification } from 'utils/toast'

import { update, remove, handleTripPublication } from 'api/trip'
import {
  CalendarIcon,
  DuplicateIcon,
  PublishIcon,
  TrashIcon,
} from 'components/MTIcons'

import { CurrentTrip } from '../PlanningBoard/types'
import { updateDates } from './helper'
import {
  CustomTextField,
  ParentContainer,
  Title,
  HeroMenuButton,
  Icon,
  MenuStyled,
  Header,
  DateContainer,
  Body,
  MembersContainer,
  OwnerNameText,
  DuplicateButton,
  MenuItemText,
} from './hero.style'
import { DuplicateTripButton } from 'components/DuplicateTripButton'

export type HeroUpdateProps = {
  startDate: Moment
  endDate: Moment
}

type Props = {
  currentTrip: CurrentTrip
  setCurrentTripCallback: (trip: any) => void
}

const Hero = ({ currentTrip, setCurrentTripCallback }: Props) => {
  const dateFormat = 'MMM DD, YYYY'
  const history = useHistory()
  const { user } = useAppSelector(state => state.user)
  const dispatch = useAppDispatch()
  const titleRef = useRef<HTMLInputElement>(null)
  const userTripsCount = useAppSelector(
    state => state.userPlanSlice.userTripsCount,
  )

  const [openUnPublishModal, setopenUnPublishModal] = useState(false)
  const [openPublishModal, setOpenPublishModal] = useState(false)

  const canEditTripTitle =
    !currentTrip.community_trip &&
    can(currentTrip?.trips_roles[0]?.role, TRIP_TITLE_EDIT)
  const canEditTripStartDate =
    !currentTrip.community_trip &&
    can(currentTrip?.trips_roles[0]?.role, TRIP_DATE_START_EDIT)
  const canEditTripEndDate =
    !currentTrip.community_trip &&
    can(currentTrip?.trips_roles[0]?.role, TRIP_DATE_END_EDIT)
  const canDeleteTrip =
    !currentTrip.community_trip &&
    can(currentTrip?.trips_roles[0]?.role, TRIP_DELETE)

  const [openDialog, setOpenDialog] = useState(false)
  const [startDateVisual, setStartDateVisual] = useState<Date | string | null>(
    currentTrip?.start_date,
  )
  const [endDateVisual, setEndDateVisual] = useState<Date | string | null>(
    currentTrip?.end_date,
  )
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const coordinates = useAppSelector(state => state.geoLocation)

  const smallScreen = isScreenSize(550)
  /**
   * Open menu
   * @param { Object } event
   */
  const onHandleOpenMenu = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    setMenuAnchorEl(event.currentTarget)
  }

  /**
   * Close menu
   */
  const onHandleCloseMenu = () => {
    setMenuAnchorEl(null)
  }

  /**
   * Close delete dialogue
   */
  const onCloseModal = () => {
    setOpenDialog(false)
  }

  /**
   * Cancel poll deletion
   */
  const onHandleCancel = () => {
    onCloseModal()
  }

  /**
   * Show delete modal
   */
  const onHandleShowDeleteTripModal = () => {
    onHandleCloseMenu()

    if (!canDeleteTrip)
      return sendNotification(`${CONSTANTS.DELETE_ITEM_DENY}`, 'error')
    setOpenDialog(true)
  }

  /**
   * Delete trip handler
   */
  const onHandleDeleteTrip = async () => {
    try {
      const res = await remove({
        user_uid: user.uid,
        short_uid: currentTrip.short_uid,
        coordinates,
      })
      if (res) {
        sendNotification(`${CONSTANTS.TRIP_DELETE_SUCCESS}`, 'success')
        onCloseModal()
        const dropCookies = user?.is_tentative || !user?.firstName
        resetUserStore(dispatch, !!dropCookies)
        await dispatch(setUserTripsCount(userTripsCount - 1))
        history.push('/create-trip')
      }
    } catch (error) {
      const e = await (error as HTTPError)?.response?.json()
      const message = e?.message || error
      sendNotification(message, 'error')
      console.log('error occured: ', error)
    }
  }

  /**
   * Update trip title.
   * @param {String} title trip title
   * @param {String} destination trip destination
   */
  const onHandleUpdateTitle = async (title: string, destination: string) => {
    // if title is blank or undefined, create default title
    if (typeof title === 'undefined' || title === '') {
      title = `Trip to ${destination}`
    }

    try {
      const res = await update({
        uid: currentTrip.uid,
        title,
        user_uid: user.uid,
        short_uid: currentTrip.short_uid,
      })
      if (res) {
        setDocumentTitle(title)
        setCurrentTripCallback({...currentTrip, title: title})
        if (titleRef) titleRef?.current?.blur()
        sendNotification(CONSTANTS.HERO_TITLE_SUCCESSFULLY_UPDATED, 'success')
      }
    } catch (error) {
      const e = await (error as HTTPError)?.response?.json()
      const message = e?.message || error
      sendNotification(message, 'error')
      console.log('error occured: ', error)
    }
  }

  /**
   * Update trip start and end dates.
   * @param {Dates} data trip start and end date
   */
  const onHandleUpdateDates = async (data: HeroUpdateProps) => {
    const { startDate, endDate } = data

    try {
      const res = await update({
        uid: currentTrip.uid,
        start_date: startDate,
        end_date: endDate,
        user_uid: user.uid,
        short_uid: currentTrip.short_uid,
      })

      if (res) {
        sendNotification(CONSTANTS.HERO_DATES_SUCCESSFULLY_UPDATED, 'success')
      }
    } catch (error) {
      const e = await (error as HTTPError)?.response?.json()
      const message = e?.message || error
      sendNotification(message, 'error')
      console.log('error occured: ', error)
    }
  }

  const onUnPublishModalOpen = () => {
    setopenUnPublishModal(true)
    onHandleCloseMenu()
  }

  const onPublishModalOpen = () => {
    setOpenPublishModal(true)
    onHandleCloseMenu()
  }

  const datpickerwidth = '92px'
  return (
    <ParentContainer>
      <Formik
        enableReinitialize
        initialValues={{
          title: currentTrip.title || '',
        }}
        onSubmit={async ({ title }) => {
          await onHandleUpdateTitle(title, currentTrip.destination)
        }}>
        {({ handleSubmit, values, handleChange }) => (
          <Form
            noValidate
            onKeyDown={e => {
              if ((e.charCode || e.keyCode) === 13) {
                e.preventDefault()
                handleSubmit()
              }
            }}>
            <Header isCommunityTrip={currentTrip.community_trip}>
              <Title
                component={CustomTextField}
                disabled={!canEditTripTitle}
                spellCheck={false}
                name="title"
                placeholder="Trip Title"
                innerRef={titleRef}
                inputProps={{
                  maxLength: 100,
                }}
                onChange={handleChange('title')}
                value={values.title || ''}
                helperText={
                  values.title.length === 100
                    ? 'Title cannot be longer than 100 characters!'
                    : ''
                }
                style={{ margin: 0 }}
              />
              <div style={{ flex: 1, minWidth: 'fit-content' }}>
                {!user?.is_tentative && (
                  <>
                    {currentTrip.community_trip ? (
                      (currentTrip?.users[0]?.firstName ||
                        currentTrip?.users[0]?.lastName) && (
                        <OwnerNameText>
                          {`By ${currentTrip?.users[0]?.firstName ?? ''} ${
                            currentTrip?.users[0]?.lastName
                          }` ?? ''}
                        </OwnerNameText>
                      )
                    ) : (
                      <>
                        <HeroMenuButton
                          onClick={onHandleOpenMenu}
                          disabled={
                            currentTrip.community_trip ||
                            currentTrip.trips_roles[0]?.role !== 'ADMIN'
                          }>
                          <Icon />
                        </HeroMenuButton>
                        <MenuStyled
                          MenuListProps={{
                            style: {
                              padding: '10px',
                            },
                          }}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          anchorEl={menuAnchorEl}
                          disableScrollLock
                          disableRestoreFocus
                          keepMounted
                          open={Boolean(menuAnchorEl)}
                          onClose={onHandleCloseMenu}
                          elevation={3}>
                          <DuplicateTripButton
                            currentTrip={currentTrip}
                            DuplicateButton={
                              <div
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                  columnGap: '10px',
                                }}>
                                <MenuItem
                                  style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    columnGap: '10px',
                                  }}>
                                  <DuplicateIcon />
                                  <MenuItemText>Duplicate Trip</MenuItemText>
                                </MenuItem>
                              </div>
                            }
                          />

                          {/* <MenuItem
                            onClick={
                              currentTrip.published
                                ? onUnPublishModalOpen
                                : onPublishModalOpen
                            }
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              columnGap: '10px',
                            }}>
                            <PublishIcon />
                            <MenuItemText>
                              {currentTrip.published
                                ? 'Unpublish trip from Community'
                                : 'Publish to Community'}
                            </MenuItemText>
                          </MenuItem> */}
                          <MenuItem
                            style={{
                              color: '#C10F45',
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              columnGap: '10px',
                            }}
                            onClick={onHandleShowDeleteTripModal}>
                            <TrashIcon stroke="#C10F45" />
                            <MenuItemText>Delete Trip</MenuItemText>
                          </MenuItem>
                        </MenuStyled>
                      </>
                    )}
                  </>
                )}
              </div>
            </Header>
            <div
              style={{
                height: '1px',
                backgroundColor: '#AFAFAF',
                width: '100%',
                marginBottom: '12px',
              }}
            />
          </Form>
        )}
      </Formik>

      <div>
        <Formik
          enableReinitialize
          initialValues={{
            startDate: moment(currentTrip.start_date),
            endDate: moment(currentTrip.end_date),
          }}
          onSubmit={async data => await onHandleUpdateDates(data)}>
          {({ values, setFieldValue, handleSubmit }) => (
            <Body>
              <DateContainer>
                {!smallScreen && <CalendarIcon />}
                <DatePicker
                  clearIcon={false}
                  suffixIcon={false}
                  name="startDate"
                  style={{
                    border: 'none',
                    width: datpickerwidth,
                    padding: '0 5px'
                  }}
                  value={moment(startDateVisual)}
                  format={dateFormat}
                  disabled={!canEditTripStartDate}
                  onChange={startDate => {
                    if (isNil(startDate))
                      startDate = moment(currentTrip.start_date)
                    return updateDates({
                      updatedValue: 'startDate',
                      startDate: startDate,
                      endDate: values.endDate,
                      setFieldValue,
                      setStartDateVisual,
                      setEndDateVisual,
                      handleSubmit,
                    })
                  }}
                />
              <p style={{ margin: '0', fontSize: '20px' }}> - </p>
                <DatePicker
                  name="endDate"
                  clearIcon={false}
                  suffixIcon={false}
                  value={moment(endDateVisual)}
                  style={{
                    border: 'none',
                    width: datpickerwidth,
                    padding: '0 5px'
                  }}
                  format={dateFormat}
                  disabled={!canEditTripEndDate}
                  onChange={endDate => {
                    if (isNil(endDate)) endDate = moment(currentTrip.end_date)
                    return updateDates({
                      updatedValue: 'endDate',
                      startDate: values.startDate,
                      endDate: endDate,
                      setFieldValue,
                      setStartDateVisual,
                      setEndDateVisual,
                      handleSubmit,
                    })
                  }}
                />
              </DateContainer>
              <MembersContainer>
                {currentTrip.community_trip ? (
                  <DuplicateTripButton
                    showFullStatus={true}
                    DuplicateButton={
                      <DuplicateButton>Duplicate Trip</DuplicateButton>
                    }
                    currentTrip={currentTrip}
                  />
                ) : (
                  <MembersListRow
                    currentTrip={currentTrip}
                    setCurrentTripCallback={setCurrentTripCallback}
                    openPublishModal={openPublishModal}
                    openUnPublishModal={openUnPublishModal}
                    setOpenPublishModal={setOpenPublishModal}
                    setopenUnPublishModal={setopenUnPublishModal}
                  />
                )}
              </MembersContainer>
            </Body>
          )}
        </Formik>
      </div>

      {openDialog && (
        <MTDialog
          openDialog={openDialog}
          onCloseModal={onCloseModal}
          onDeleteTripClickHandler={onHandleDeleteTrip}
          onCancelClickHandler={onHandleCancel}
          itemToBeDeleted={CONSTANTS.TRIP_TO_BE_DELETED}
          type={modalTypes.DELETE_ITEM}
        />
      )}
    </ParentContainer>
  )
}

export default Hero
