import styled from '@emotion/styled'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 1rem;
  position: relative;
  & > form {
    width: 100%;
  }
`

export const ContainerWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: auto auto auto auto;
  column-gap: 2rem;
  padding: 8px 0;
`

export const Header = styled.div`
  margin-bottom: 1rem;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row-end: 2;
`

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-column-start: 1;
  grid-row-start: 4;
  grid-row-end: 5;
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: space-evenly;
  height: 100%;
  grid-column-start: 1;
  grid-row-start: 2;
  grid-row-end: 3;
`

export const BodyRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1rem 0.7rem;
  align-items: center;
`

export const BodyRowIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & > svg {
    transform: scale(1.25);
  }
  &:nth-of-type(3) {
    margin-top: 1rem;
  }
  &:nth-of-type(4) {
    margin-left: 4px;
  }
`

export const BodyRowContainer = styled.div`
  margin-left: 1rem;
  width: 100%;
`

export const LocationMapContainer = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 5;
  width: 100%;

`
