import React from 'react'

import { cssVariables } from 'theme'

interface iconProps {
  stroke? : string
  height? : string
  width? : string
}
const PlusIcon = (props: iconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ?? '20'}
      height={props.height ?? '20'}
      viewBox="0 0 24 24"
      fill="none"
      stroke={props.stroke ?? cssVariables.colors.alternativeDarkGrey2}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-plus">
      <line x1="12" y1="5" x2="12" y2="19"></line>
      <line x1="5" y1="12" x2="19" y2="12"></line>
    </svg>
  )
}

export default PlusIcon
