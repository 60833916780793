import styled from '@emotion/styled'

export const styles = () => {
  return {
    root: {
      paddingLeft: 0,
      paddingRight: 0,
      display: 'flex',
      flexFlow: 'column',
      height: 'fit-content',
      backgroundColor: 'white',
      '& .MuiContainer-root': {
        paddingLeft: 0,
        paddingRight: 0,
        border: 0,
      },
    },
    contentRoot: {
      flex: '1 1 auto',
      display: 'flex',
      backgroundColor: '#F3F2EF',
    },
  }
}

export const PageWrapper = styled.div`
  position: relative;
  width: 100%;
  & > div:first-of-type {
    background-color: #f9f9f9;
    border-radius: 12px;
    height: 100%;
  }
`

export const MobileScreenWarningContainer = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const MobileScreenWarningContent = styled.div`
  width: 50%;
  text-align: center;
`
