import React from 'react'

import { Fade } from '@material-ui/core'

import {
  ModalStyled,
  ModalWrapperStyled,
  ModalContentStyled,
} from './modal.style'

type ModalProps = {
  open: boolean
  modalContent: React.ReactNode
  width: number | string
  height?: number | string
  borderRadius?: number | string
  onClose: () => void
  customStyles?: React.CSSProperties
}

const MTModal = ({
  open,
  modalContent,
  width,
  height,
  borderRadius,
  onClose,
  customStyles
}: ModalProps) => {
  return (
    <ModalStyled
      open={open}
      style={{ ...customStyles, width}}
      closeAfterTransition
      onBackdropClick={onClose}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={open}>
        <ModalWrapperStyled
          style={{
            height,
            borderRadius: borderRadius ? borderRadius : '12px',
          }}>
          <ModalContentStyled>{modalContent}</ModalContentStyled>
        </ModalWrapperStyled>
      </Fade>
    </ModalStyled>
  )
}
export default MTModal
