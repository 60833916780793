export const style = ({ colors }) => {
  return {
    container: {
      width: '100%',
      padding: '2rem',
      position: 'relative',
    },
    closeIcon: {
      color: colors.primaryLightGrey,
      fontSize: '2rem',
      cursor: 'pointer',
      position: 'absolute',
      top: 15,
      right: 15,
    },
  }
}
