import React, { useState } from 'react'

import styled from '@emotion/styled'
import AttachmentOutlinedIcon from '@material-ui/icons/AttachmentOutlined'
import ControlPointIcon from '@material-ui/icons/ControlPoint'
import { modalTypes } from 'components/MTDialog/types'
import { MTTooltip } from 'components/MTTooltip'
import { useDialog } from 'contexts/DialogContext'
import { useGridOrientation } from 'contexts/GridOrientationContext'
import { CONSTANTS } from 'core/constants'
import { Formik, Field } from 'formik'
import { FileUpload } from 'pages/Workspace/layouts/PlanningBoard/components/FileUpload'
import { Quill } from 'pages/Workspace/layouts/PlanningBoard/components/Quill'
import { SaveButton } from 'pages/Workspace/layouts/PlanningBoard/components/SaveButton'
import {
  AddGridItemProps,
  CurrentTrip,
  TripGroup,
} from 'pages/Workspace/layouts/PlanningBoard/types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from 'reactstrap'
import {
  useAppDispatch,
  useAppSelector,
  useGeolocation,
  CoordsObject,
} from 'store/hooks'
import { addItemInTripGroup } from 'store/slices/tripGroupSlice'
import { addGridItemColor } from 'store/slices/tripItemColor'
import { tripItemTypes } from 'utils/itemTypes'
import { tabletMobileScreenSize } from 'utils/screenSzie'
import { sendNotification } from 'utils/toast'
import * as Yup from 'yup'

import { create } from 'api/tripNoteItem'

import CreateGroupModal from '../../../../../CreateGroupModal/modal'
import {
  Container,
  NewNoteForm,
  BtnContainer,
  NoteTitle,
  NoteDescription,
  ErrorsMsg,
  FileInputContainer,
  FileUploadIconLabel,
} from './noteForm.style'
import tripUserRoleSlice from 'store/slices/tripUserRoleSlice'

const TitleSchema = Yup.object().shape({
  title: Yup.string()
    .min(1, CONSTANTS.CREATE_NEW_TRIP_NOTES_ITEM_TITLE_MIN_LENGTH_MESSAGE)
    .max(100, CONSTANTS.CREATE_NEW_TRIP_NOTES_ITEM_TITLE_MAX_LENGTH_MESSAGE)
    .required(CONSTANTS.CREATE_NEW_TRIP_NOTES_ITEM_TITLE_REQUIRED_MESSAGE),
})

const GroupDropdown = styled.div`
  margin-left: auto;
  border-radius: 7px 0px 0px 7px;
  font-size: 1.4rem;
  width: 140px;
  height: 35px;
  margin-top: 9px;
  //margin-right: 5px;
  border: none;
  &:focus {
    outline-width: 0;
  }
`

type SubmitNoteArgs = {
  description: string
  title: string
  files: Array<File>
  trip_group: string
  setSubmitting: (val: boolean) => void
  resetForm: () => void
}

type Props = {
  currentTrip: CurrentTrip
  canEdit: boolean
}

const NoteForm = ({ currentTrip, canEdit }: Props) => {
  const dispatch = useAppDispatch()
  const { gridOrientation } = useGridOrientation()
  const { setOpenDialog } = useDialog()
  const [active, setActive] = useState(false)

  const { user } = useAppSelector(state => state.user)
  const { tripGroups } = useAppSelector(state => state.tripGroupSlice)
  const [myFiles, setMyFiles] = useState([])
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState<TripGroup | null>()
  const [selectedGroupTitle, setSelectedGroupTitle] = useState('')
  const [loading, setLoading] = useState(false)
  const tabletMobileViewGeneral = tabletMobileScreenSize()
  const coordinates = useAppSelector(state => state.geoLocation)

  const toggle = () => setDropdownOpen(prevState => !prevState)
  const handleSubmitNote = async ({
    description,
    title,
    files,
    setSubmitting,
    resetForm,
  }: SubmitNoteArgs) => {
    setLoading(true)
    if (user.is_tentative && !user?.is_trip_creator) {
      return setOpenDialog({
        show: true,
        type: modalTypes.SIGN_UP_TO_AS_NON_ADMIN_TO_EDIT_TRIP,
      })
    }
    const allGroupItems = selectedGroup?.tripItems
    const lastItemsOrder =
      allGroupItems && allGroupItems.length > 0 ? allGroupItems?.length + 1 : 1

    const formData = new FormData()
    formData.append('description', description)
    formData.append('title', title)
    formData.append('short_uid', currentTrip.short_uid)
    selectedGroup && formData.append('trip_group', selectedGroup.uid)
    formData.append('user_uid', user.uid as string)
    formData.append('grid_orientation', gridOrientation.toString())
    formData.append('is_tentative', (!!user.is_tentative).toString())
    formData.append('order', lastItemsOrder.toString())

    if (files && files.length > 0) {
      formData.append('file_name', files[0].name)
      formData.append('file', files[0], files[0].name)
    }
    if (coordinates && coordinates?.latitude && coordinates?.longitude) {
      formData.append('latitude', coordinates?.latitude?.toString())
      formData.append('longitude', coordinates?.longitude?.toString())
    }

    try {
      setSubmitting(true)
      setSelectedGroup(null)
      setSelectedGroupTitle('')
      const newNote = await create(formData)
      const payload = {
        groupUid: selectedGroup?.uid,
        tripItem: newNote,
      }
      await dispatch(addItemInTripGroup(payload))
      dispatch(
        addGridItemColor({
          uid: newNote.uid,
          color: newNote.trip_note_item_metadata.color,
        }),
      )

      setMyFiles([])
      setSubmitting(false)
      sendNotification(`${CONSTANTS.CREATE_NEW_TRIP_NOTES_ITEM}`, 'success')
    } catch (error) {
      setSubmitting(false)
      console.log(error)
      sendNotification(CONSTANTS.CREATE_NEW_TRIP_NOTES_ITEM_ERROR, 'error')
    } finally {
      setLoading(false)
      resetForm()
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const CustomSelectField = (props: any) => {
    return (
      <GroupDropdown>
        <Dropdown
          style={{ width: 'initial' }}
          size={'lg'}
          isOpen={dropdownOpen}
          toggle={toggle}
          direction={'down'}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '35px',
            }}>
            <DropdownToggle
              caret
              style={{
                fontFamily: 'Lato',
                fontSize: '16px',
                fontWeight: 'bold',
                width: '100%',
                border: 'none',
                color: '#686868',
                borderRadius: '7px 0px 0px 7px',
                background: '#F9F9F9',

                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              size="lg">
              {selectedGroup ? selectedGroupTitle : 'Select Group'}
            </DropdownToggle>
          </div>

          <DropdownMenu
            style={{
              marginTop: '6px',
              fontSize: '1.5rem',
              width: '140px',
              overflowX: 'hidden',
              borderRadius: '10px',
              padding: '7px',
            }}>
            {tripGroups.map(tripGroup => {
              return (
                <DropdownItem
                  style={{
                    marginTop: '6px',
                    fontFamily: 'Lato',
                    fontWeight: 700,
                    whiteSpace: 'unset',
                    overflowWrap: 'anywhere',
                  }}
                  key={tripGroup.uid}
                  onClick={() => {
                    setSelectedGroup(tripGroup)
                    setSelectedGroupTitle(tripGroup.title)
                    props.setFieldValue(props.formikKey, tripGroup.uid)
                  }}>
                  {tripGroup.title}
                </DropdownItem>
              )
            })}
            <DropdownItem
              style={{
                fontSize: '1.4rem',
                color: '#4287f5',
                fontWeight: 'bold',
                marginTop: '4px',
              }}
              onClick={() => {
                setActive(true)
                setSelectedGroup(null)
                setSelectedGroupTitle('')
                props.setFieldValue(props.formikKey, '')
              }}>
              <ControlPointIcon /> Create Group
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </GroupDropdown>
    )
  }

  return (
    <Container>
      <MTTooltip
        title={!canEdit ? 'You do not have permission to create notes.' : ''}>
        <Formik
          enableReinitialize
          validateOnBlur={false}
          validationSchema={TitleSchema}
          initialValues={{
            title: '',
            description: '',
            files: [],
            trip_group: '',
          }}
          onSubmit={async (
            { description, title, files, trip_group },
            { setSubmitting, resetForm },
          ) => {
            if (!selectedGroup) {
            }
            trip_group = 'new_group'
            await handleSubmitNote({
              description,
              title,
              files,
              trip_group,
              setSubmitting,
              resetForm,
            })
          }}>
          {({ values, errors, handleChange, setFieldValue }) => (
            <NewNoteForm noValidate>
              <NoteTitle
                disabled={!canEdit}
                name="title"
                data-testid="noteTitle"
                placeholder="Add a note title..."
                autoFocus={!tabletMobileViewGeneral}
              />
              <ErrorsMsg>{errors.title}</ErrorsMsg>
              <NoteDescription
                data-testid="noteDescription"
                value={values.description}
                component={Quill}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                formikKey="description"
                placeholder="Write your note: add your reminders, lists, booking information, and so on..."
                canEdit={canEdit}
              />

              <ErrorsMsg>{errors.description}</ErrorsMsg>
              <FileInputContainer>
                <FileUploadIconLabel htmlFor="fileUploadInput">
                  <AttachmentOutlinedIcon />
                </FileUploadIconLabel>
                <Field
                  maxFiles={1}
                  formikKey="files"
                  component={FileUpload}
                  myFiles={myFiles}
                  setMyFiles={setMyFiles}
                  setFieldValue={setFieldValue}
                  canEdit={canEdit}
                />
              </FileInputContainer>

              <BtnContainer>
                <Field
                  name="trip_group"
                  formikKey="trip_group"
                  as={CustomSelectField}
                  setFieldValue={setFieldValue}
                />
                <SaveButton
                  data-testid="submitButton"
                  disabled={
                    values.description.length < 1 ||
                    values.title.length < 1 ||
                    values.title.length > 100 ||
                    !selectedGroup
                  }>
                  {loading ? (
                    <Spinner />
                  ) : (
                    CONSTANTS.CREATE_NEW_TRIP_NOTES_ITEM_SUBMIT_BUTTON
                  )}
                </SaveButton>
              </BtnContainer>
            </NewNoteForm>
          )}
        </Formik>
      </MTTooltip>
      <CreateGroupModal
        active={active}
        setActive={setActive}
        currentTrip={currentTrip.short_uid}
        user={user}
      />
    </Container>
  )
}

export default NoteForm
