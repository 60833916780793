import React from 'react'

import { ProfileSection } from 'layouts/Auth/layouts/Profile'
import { SignIn } from 'layouts/Auth/layouts/SignIn'
import { SignUp } from 'layouts/Auth/layouts/SignUp'
import { FourOhFour } from 'pages/404'
import { CreateTrip } from 'pages/CreateTrip'
import { Workspace } from 'pages/Workspace'

import { Dashboard } from 'pages/Dashboard'
import { PasswordReset } from 'pages/PasswordReset'
import { ForgotPassword } from 'layouts/Auth/layouts/ForgotPassword'
import { PlanChanged } from 'pages/Plan'
import { Redirect } from 'pages/Redirect'



import Router from './Router'
import { Community } from 'pages/Community'
import CommunityPage from 'pages/Community/CommunityPage'


export const routes = [
  {
    path: '/',
    key: 'APP',
    component: Router,
    fallback: <div>Loading...</div>,
    routes: [
      {
        path: '/',
        key: 'APP_ROOT',
        exact: true,
        component: Dashboard,
        fallback: <div>Loading...</div>,
      },
      {
        path: '/password_reset',
        key: 'PASSWORD_RESET',
        exact: true,
        component: PasswordReset,
        fallback: <div>Loading...</div>,
      },
      {
        path: '/forgot-password',
        key: 'FORGOT_PASSWORD',
        exact: true,
        component: ForgotPassword,
        fallback: <div>Loading...</div>,
      },
      {
        path: '/redirect',
        key: 'REDIRECT',
        exact: true,
        component: Redirect,
        fallback: <div>Loading...</div>,
      },
      {
        path: '/create-trip',
        key: 'CREATE_TRIP',
        exact: true,
        component: CreateTrip,
        fallback: <div>Loading...</div>,
      },
      {
        path: '/workspace/:trip_uid',
        key: 'WORKSPACE',
        exact: true,
        component: Workspace,
        fallback: <div>Loading...</div>,
      },
      {
        path: '/404/',
        key: '404',
        exact: true,
        component: FourOhFour,
        fallback: <div>Loading...</div>,
      },
      {
        path: '/signup/',
        key: 'signup',
        exact: true,
        component: SignUp,
        fallback: <div>Loading...</div>,
      },
      {
        path: '/signin/',
        key: 'signin',
        exact: true,
        component: SignIn,
        fallback: <div>Loading...</div>,
      },
      {
        path: '/profile/',
        key: 'profile',
        exact: true,
        component: ProfileSection,
        fallback: <div> Loading... </div>,
      },
      {
        path: '/change-plan/:type',
        key: 'change-plan',
        exact: true,
        component: PlanChanged,
        fallback: <div> Loading... </div>,
      },
      {
        path: '/community',
        key: 'Community',
        exact: true,
        component: CommunityPage,
        fallback: <div> Loading... </div>,
      },
      {
        path: '*',
        key: '404',
        component: FourOhFour,
        fallback: <div>Loading...</div>,
      },
    ],
  },
]
