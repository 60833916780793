import styled from '@emotion/styled'
import { Button } from '@material-ui/core'
import { cssVariables } from 'theme'

export const Container = styled.div`
  background-color: white;
  display: flex;
  width: 200px;
  // height: fit;
  height:200px;
  overflow: auto;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 0px 5px 11px -1px rgba(0, 0, 0, 0.1);
  transition: all 0.15s ease;
  &:hover {
    box-shadow: 0px 8px 11px -1px rgba(0, 0, 0, 0.1);
  }

  // width: 150px;
  // flex-grow: 1;
`

export const ImageContainer = styled.div`
  & > div > .carousel-slider {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
`

export const Media = styled.div`
  width: 100%;
  height: 150px;
  max-height: 10rem;
  object-fit: cover;
  position: relative;
`

export const GroupButtonStyled = styled(Button)`
  border-radius: 0px 7px 7px 0px;

  background-color: #f3faff;
  color: ${cssVariables.colors.primaryBlue};
  //  border-radius: 10px;
  position: absolute;
  width: '50px';
  font-size: 1.03rem;
  height: '35px';
  padding: 5px 10px;
  //right: 1px;
  top: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f3faff;
    color: ${cssVariables.colors.primaryBlue};
  }
  font-family: Lato;
  font-weight: 700;
`

export const ButtonStyled = styled(Button)`
  background-color: #f3faff;
  color: ${cssVariables.colors.primaryBlue};
  border-radius: 10px;
  position: absolute;
  padding: 5px 10px;
  right: 10px;
  top: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f3faff;
    color: ${cssVariables.colors.primaryBlue};
  }
`

export const Body = styled.div`
  flex: 2;
  padding: 1rem 2rem;
`

export const Title = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
`
