import styled from '@emotion/styled'
import AutosizeInput from 'react-input-autosize'

export const TagList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`
export const TagInput = styled(AutosizeInput)`
  display: flex !important;
  justify-content: center;
  align-items: center;
  outline: none;
  text-align: center;
  border: 0.5px solid #979a94;
  border-radius: 4px;
  height:20px;

  & > input {
    border: none;
    border-radius: 4px;
  }

  & > input:focus-visible {
    outline: none;
  }
`
