import React, { useState } from 'react'

import { MTButton } from 'components/MTButton'
import { MTLogo } from 'components/MTLogo'
import { StartTripButton } from 'components/StartTripButton'
import { CONSTANTS } from 'core/constants'
import { useQuery } from 'hooks/useQuery'
import { isNull } from 'lodash'
import { useAppSelector } from 'store/hooks'
import { cssVariables } from 'theme'
import { isScreenSize } from 'utils/screenSzie'

import { UserIcon } from 'components/MTIcons'

import { NavAuthMenu } from './NavAuthMenu'
import {
  AppBarStyled,
  ToolbarStyled,
  ToolbarItemLogo,
  ToolbarButtonGroup,
  AuthButtonGroup,
} from './navBar.style'
import { useHistory } from 'react-router'
import { borderRadius } from '@mui/system'

const NavBar = () => {
  const query = useQuery()
  const history = useHistory()
  const { user } = useAppSelector(state => state.user)

  let planNewTripText = CONSTANTS.START_TRIP
  const smallScreen = isScreenSize(610)
  const isScreenSizeEquals350 = isScreenSize(350)
  const isScreenSizeEquals310 = isScreenSize(310)
  if (isScreenSizeEquals350) {
    planNewTripText = 'New Trip'
  }
  if (isScreenSizeEquals310) {
    planNewTripText = 'Plan'
  }

  return (
    <AppBarStyled position="absolute" elevation={0}>
      <ToolbarStyled style={smallScreen ? { paddingRight: '0px' } : {}}>
        <ToolbarItemLogo style={smallScreen ? { marginLeft: '0px' } : {}}>
          <MTLogo />
        </ToolbarItemLogo>
        <ToolbarButtonGroup>
          {!isNull(user?.username) && <NavAuthMenu />}
          {(isNull(user?.firstName) || user?.is_tentative) && (
            <AuthButtonGroup>
            { !window.location.href.includes('/signin') &&
              <MTButton
                title="Login"
                onHandleClick={() => history.push('/signin')}
                customStyles={{
                  backgroundColor: 'transparent',
                  color: cssVariables.colors.primaryBlack,
                  boxShadow: 'none',
                  border: '1px solid #8F8F8F',
                  width: 'auto',
                  minWidth: '90px',
                  height: '40px',
                  borderRadius: '6px'
                }}
                customTextStyles={{
                  fontSize: '16px',
                  fontWeight: 700,
                  lineHeight: '24px',
                  color: '#3A3A3A'
                 }}
              />
          }
              {
                !window.location.href.includes('/signup') && !((window.location.href.includes('community') || window.location.href.includes('workspace'))  && smallScreen) &&
                <MTButton
                title="Sign Up"
                onHandleClick={() => history.push('/signup')}
                customStyles={{
                  backgroundColor: 'transparent',
                  color: cssVariables.colors.primaryBlack,
                  boxShadow: 'none',
                  border: '1px solid #8F8F8F',
                  width: 'auto',
                  minWidth: '90px',
                  height: '40px',
                  borderRadius: '6px',
                }}
                customTextStyles={{
                  fontSize: '16px',
                  fontWeight: 700,
                  lineHeight: '24px',
                  color: '#3A3A3A'
                 }}
                />
              }

            </AuthButtonGroup>
          )}

          {(!window.location.href.includes('/create-trip')
            && !window.location.href.includes('/forgot-password')
            && !window.location.href.includes('/reset_password'))
            && !user?.is_tentative && (
              <StartTripButton
                title={planNewTripText}
                textCol="white"
                width="auto"
              />
            )}
        </ToolbarButtonGroup>
      </ToolbarStyled>
    </AppBarStyled>
  )
}

export default NavBar
