/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useRef, useCallback, useState } from 'react'

import InputAdornment from '@material-ui/core/InputAdornment'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { isEmpty, isNil, isUndefined } from 'lodash'
import mixpanel from 'mixpanel-browser'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { addSelectedLocationItem } from 'store/slices/locationSearchResults'
import { PlaceResult } from 'utils/googlePlace'
import { can, TRIP_ITEM_LOCATION_SEARCH } from 'utils/permissions'
import { isScreenSize } from 'utils/screenSzie'

import { Container, SearchBar } from './destinationSearchBar.style'

import './destinationSearchBar.scss'

type Props = {
  setSearchedTitle: (title: string) => void
  tripName: string
  tripDestination: string
}

const DestinationSearchBar = ({
  tripName,
  tripDestination,
  setSearchedTitle,
}: Props) => {
  const searchBoxRef = useRef<HTMLInputElement>()
  const dispatch = useAppDispatch()
  const state = useAppSelector(state => state.locationSearchResults)
  const { user } = useAppSelector(state => state.user)
  const { userOwnedPlacesArray } = state
  const tripsRoles = useAppSelector(state => state.tripUserRoleSlice)
  const { coords, lat, lng } = useAppSelector(state => state.tripCoords)
  const [platform, setPlatform] = useState('Web')
  const coordinates = useAppSelector(state => state.geoLocation)

  let placeholder = 'Search for destinations, accommodations, restaurants...'
  const isScreenSizeEquals600 = isScreenSize(600)

  if (isScreenSizeEquals600) {
    placeholder = 'Search for places'
  }

  const canEdit = can(tripsRoles?.data[0]?.role, TRIP_ITEM_LOCATION_SEARCH)

  useEffect(() => {
    const { userAgent } = navigator

    if (userAgent.includes('Android')) {
      setPlatform('Android')
    } else if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {
      setPlatform('iOS')
    } else {
      setPlatform('Web')
    }
  }, [])

  /**
   * Retreive seachered query from google place search
   */
  const getSearchedPlacesFromGoogle = useCallback(
    stateCoords => {
      /**
       * Set map bounds for search terms to apply on destination
       */
      const defaultBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(
          parseFloat(stateCoords.lat),
          parseFloat(stateCoords.lng),
        ),
      )

      const input = document.getElementById('destination-search')
      if (input) {
        input.style.fontSize = '16px'
        const searchBox = new google.maps.places.SearchBox(
          input as HTMLInputElement,
          {
            bounds: defaultBounds,
          },
        )

        /**
         * Return searched Google Place information
         */
        searchBox.addListener('places_changed', () => {
          if ((input as HTMLInputElement).value !== '') {
            let places = searchBox.getPlaces()

            type PhotosObject = {
              [key: string]: Array<PlaceResult>
            }
            const photosObject: PhotosObject = {}

            if (places) {
              places.forEach(p => {
                if (p?.photos && p?.photos?.length > 0) {
                  // @ts-ignore
                  photosObject[`${p.place_id}`] = p.photos.map(photo =>
                    photo.getUrl(),
                  )
                }
              })

              // @ts-ignore
              places = places.map(place => {
                if (photosObject[`${place.place_id}`]) {
                  return {
                    ...place,
                    ...{ photos: photosObject[`${place.place_id}`] },
                  }
                }
              })
              // @ts-ignore
              places = places.map(place => {
                if (!isUndefined(place) && !isEmpty(place.geometry)) {
                  const geometry = {
                    lat: place?.geometry?.location?.lat(),
                    lng: place?.geometry?.location?.lng(),
                  }
                  delete place.geometry

                  return { ...place, ...geometry }
                }
              })

              // @ts-ignore
              places = places.filter(place => !isNil(place))
              // pass an array of places ids and coordinates to the map component
              const filteredPlacesArray = places.map(place => {
                let dataObject = {}
                const tempPlace = {
                  ...place,
                } as Partial<google.maps.places.PlaceResult>

                if (place.opening_hours) {
                  if (typeof place.opening_hours.open_now !== 'undefined')
                    delete place.opening_hours.open_now // need to forcefully remove deprecated field
                  // @ts-ignore
                  delete tempPlace?.opening_hours?.isOpen
                }
                delete tempPlace.utc_offset // need to forcefully remove deprecated field

                const index = userOwnedPlacesArray.findIndex(
                  item => item.place_id === place.place_id,
                )
                const placeIsPartOfTrip = index > 0 ? true : false

                dataObject = {
                  ...dataObject,
                  ...place,
                  placeIsPartOfTrip,
                  color: placeIsPartOfTrip
                    ? userOwnedPlacesArray[index].color
                    : '#7C7C7C',
                }

                return dataObject
              })
              // @ts-ignore
              dispatch(addSelectedLocationItem(filteredPlacesArray))
            }

            mixpanel.track('Location Searched', {
              // @ts-ignore
              'Search Title': input.value,
              Platform: platform,
              'Trip Name': tripName,
              'Trip Destination': tripDestination,
              $latitude: coordinates?.latitude,
              $longitude: coordinates?.longitude,
            })
            // @ts-ignore
            setSearchedTitle(input.value)
            // @ts-ignore
            input.value = ''
          }
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [userOwnedPlacesArray, dispatch],
  )

  useEffect(() => {
    getSearchedPlacesFromGoogle(coords)
  }, [coords, lat, lng, getSearchedPlacesFromGoogle])

  return (
    <Container>
      <SearchBar
        disabled={!canEdit}
        inputRef={searchBoxRef}
        id="destination-search"
        placeholder={placeholder}
        name="googleSearchQuery"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <HighlightOffIcon style={{ cursor: 'pointer', fill: 'grey' }} />
            </InputAdornment>
          ),
        }}
      />
    </Container>
  )
}

export default DestinationSearchBar
