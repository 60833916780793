/* eslint-disable prefer-destructuring */
import React, { useState } from 'react'

import { isNil } from 'lodash'
import moment from 'moment'

import {
  DateAndTimeContainer,
  DatePickerStyled,
  TimePickerStyled,
  DateAndTimeFromToText,
} from './dateAndTime.style'
import { updateDates, updateTimes } from './helper'

type DateAndTimeProps = {
  setFieldValue: (key: string, d: Date) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any
  canEdit: boolean
  smallScreen: boolean
}

const DateAndTime = ({
  setFieldValue,
  values,
  canEdit,
  smallScreen,
}: DateAndTimeProps) => {
  const dateFormat = 'MMM DD, YYYY'
  const timeFormat = 'hh:mm A'

  const [startTimeVisual, setStartTimeVisual] = useState(values.startTime)
  const [startDateVisual, setStartDateVisual] = useState(values.startDate)
  const [endTimeVisual, setEndTimeVisual] = useState(values.endTime)
  const [endDateVisual, setEndDateVisual] = useState(values.endDate)
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)

  return (
    <>
      <div style={{ display: 'flex', gap: '8px', alignItems: 'center'}}>
      <DateAndTimeFromToText>From</DateAndTimeFromToText>
      <DateAndTimeContainer>
        <DatePickerStyled
          suffixIcon={false}
          clearIcon={false}
          isIOS={isIOS}
          style={{padding: 0 , maxWidth: '105px', margin:0, maxHeight: '32px'}}
          name="startDate"
          value={moment(startDateVisual)}
          format={dateFormat}
          disabled={!canEdit}
          onChange={startDate => {
            if (isNil(startDate)) startDate = values.startTime
            return updateDates({
              updatedValue: 'startDate',
              startDate,
              endDate: values.endDate,
              setFieldValue,
              setStartDateVisual,
              setEndDateVisual,
              startTime: startTimeVisual,
              endTime: endTimeVisual,
              setStartTimeVisual,
              setEndTimeVisual,
            })
          }}
        />
        <div style={{ display: 'flex', gap: '8px' }}>
        <DateAndTimeFromToText style={{ minWidth: '0' }}>at</DateAndTimeFromToText>
        <TimePickerStyled
          isIOS={isIOS}
          suffixIcon={false}
          clearIcon={false}
          style={{ padding: '0px', maxWidth: '88px', margin:0, marginLeft: 'auto', maxHeight: '32px'}}
          name="startTime"
          value={moment(startTimeVisual)}
          format={timeFormat}
          use12Hours
          minuteStep={15}
          disabled={!canEdit}
          onChange={startTime => {
            {
              if (isNil(startTime)) startTime = values.startTime
              return updateTimes({
                updatedValue: 'startTime',
                startTime,
                endTime: values.endTime,
                setFieldValue,
                setStartTimeVisual,
                setEndTimeVisual,
                setEndDateVisual,
                setStartDateVisual,
              })
            }
          }}
        />
        </div>
      </DateAndTimeContainer>
      </div>

      <div style={{ display: 'flex', gap: '8px', alignItems: 'center', marginTop: '12px'}}>
      <DateAndTimeFromToText>To</DateAndTimeFromToText>
      <DateAndTimeContainer>
        <DatePickerStyled
          isIOS={isIOS}
          suffixIcon={false}
          clearIcon={false}
          style={{padding: '0px', maxWidth: '105px', margin: 0, maxHeight: '32px'}}
          name="endDate"
          value={moment(endDateVisual)}
          format={dateFormat}
          disabled={!canEdit}
          onChange={endDate => {
            if (isNil(endDate)) endDate = values.endDate
            return updateDates({
              updatedValue: 'endDate',
              startDate: values.startDate,
              endDate,
              setFieldValue,
              setStartDateVisual,
              setEndDateVisual,
              startTime: startTimeVisual,
              endTime: endTimeVisual,
              setStartTimeVisual,
              setEndTimeVisual,
            })
          }}
        />
        <div style={{ display: 'flex', gap: '8px'}}>
        <DateAndTimeFromToText style={{ minWidth: '0' }}>at</DateAndTimeFromToText>
        <TimePickerStyled
          isIOS={isIOS}
          clearIcon={false}
          name="endTime"
          suffixIcon={false}
          style={{ margin:0, padding: '0px', maxWidth: '88px', marginLeft: 'auto', maxHeight: '32px'}}
          value={moment(endTimeVisual)}
          format={timeFormat}
          use12Hours
          minuteStep={15}
          disabled={!canEdit}
          onChange={endTime => {
            if (isNil(endTime)) endTime = values.endTime
            return updateTimes({
              updatedValue: 'endTime',
              startTime: values.startTime,
              endTime,
              setFieldValue,
              setStartTimeVisual,
              setEndTimeVisual,
              setStartDateVisual,
              setEndDateVisual,
            })
          }}
        />
        </div>
      </DateAndTimeContainer>
      </div>
    </>
  )
}

export default DateAndTime
