import styled from '@emotion/styled'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'

export const IconContainer = styled.article`
  list-style: none;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  font-family: inherit;
`

export const IconsList = styled.ul`
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 5px 0;
`

export const ListItemContainer = styled.li`
  display: flex;
  flex-direction: row;
  height: 57px;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  // margin: 5px 0;
`

export const GarbageIcon = styled(DeleteOutlineIcon)`
  font-size: 20px;
  cursor: pointer;
`
