import styled from '@emotion/styled'

export const CommunityContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: 30vh;
    flex-wrap: wrap;
    column-gap: 18px;
    row-gap: 24px;
    width: 100%;
    margin: 48px 0;
`

export const CenteredDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  justify-content: center;
  align-items: center;
`


