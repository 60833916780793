import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { cssVariables } from 'theme'

export const Media = styled.img`
  margin: 2rem 0;
  border-radius: 0.5rem;
  width: 100%;
  height: 125px;
  object-fit: scale-down;
  background: black;
`
export const BodyRow = styled.div`
  display: flex;
  flex-direction: row;
`

export const Attachment = styled.div`
  font-size: 1.2rem;
  border-width: 0.01rem;
  border-styler: solid;
  padding: 0.5rem;
  border-color: ${cssVariables.colors.primaryLightGrey};
  // width: fit-content;
  border-width: 1px;
  border-style: solid;
  border-color: #f9f9f9;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem;
  border-radius: 3px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const FileName = styled.a`
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const DateRange = styled(Typography)`
  font-size: 1.2rem;
  color: #7c7c7c;
  font-weight: 500;
`
export const FileCopyIconStyled = styled(FileCopyIcon)`
  color: ${cssVariables.colors.primaryBlue};
  margin-right: 5;
`
export const NoteContainer = styled.div`
  ul {
    padding-inline-start: 20px;
  }

  ol {
    padding-inline-start: 16px;
  }
  word-wrap: break-word;
  overflow: hidden;
  line-height: 17pt;
  word-spacing: 1px;
  font-size: 14px;
  margin-top: 14px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    ol {
      padding-inline-start: 18px; /* Adjust this value as needed */
    }
  }
`
