import ky from 'ky'

import { request } from '../utils/request'

const baseURL = process.env.REACT_APP_API_BASE_URL
export const getUserPlans = async({ user_uid }) => {
    return await request({
        url: `/api/user-plans/get-user-plan/${user_uid}`,
        method: 'get',
        user_uid: user_uid,
    })
}

export const manageSubscription = async data => {
    return await request({
        url: '/api/user-plans/subscribe-to-a-plan',
        method: 'post',
        data,
    })
}

export const verifyCustomer = async data => {
    return await request({
        url: '/api/paddle/verify-customer',
        method: 'post',
        data,
    })
}

export const getAllPlans = async() => {
    return await request({
        url: '/api/plan',
        method: 'get',
    })
}

export const cancelUserSubscriptionFromPaddle = async(uid, position) => {
    return await request({
        url: '/api/paddle/cancel-user-subscription',
        method: 'get',
        searchParams: { uid, position },
    })
}

// export const getWebhookResponseTest = async() => {
//     return await request({
//         url: '/api/webhooks/fetch-stored-data',
//         method: 'get',
//     })
// }

export const getUserPayment = async data => {
    return await request({
        url: '/api/payments/get-user-payment',
        method: 'post',
        data,
    })
}

export const useUserPayment = async data => {
    return await request({
        url: '/api/payments/use-user-payment',
        method: 'post',
        data,
    })
}

export const getRenewalDate = async data => {
    return await request({
        url: '/api/paddle/get-renewal-date',
        method: 'post',
        data,
    })
}

export const getSubscriptionStatus = async data => {
    return await request({
        url: '/api/paddle/get-susbcription-status',
        method: 'post',
        data,
    })
}

export const updateCustomer = async data => {
    return await request({
        url: '/api/paddle/update-customer',
        method: 'post',
        data,
    })
}
