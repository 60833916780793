import { request } from '../utils/request'

export const createTentative = async data => {
    return await request({
        url: '/api/user/tentative',
        method: 'post',
        data,
    })
}

export const update = async data => {
    return await request({
        url: `/api/user/${data.uid}`,
        method: 'put',
        data: { uid: data.uid, ...data },
    })
}

export const findOne = async uid => {
    return request({
        url: '/api/user/:uid',
        method: 'get',
        searchParams: { uid },
    })
}