import React from 'react'

import styled from '@emotion/styled'
import { Link } from '@material-ui/core'

const Container = styled.div`
  padding: 3rem;
  align-items: center;
  justify-content: center;
`

const Terms = styled.p`
  text-align: center;
  font-size: 1.2rem;
`

const TermsOfService = () => {
  const termsOfServiceUrl = 'https://mitravelapp.com/terms-of-service/'
  const privacyPolicyUrl = 'https://mitravelapp.com/privacy-policy/'

  return (
    <Container>
      <Terms>
        By continuing, you agree to MiTravel’s{' '}
        <Link
          href={termsOfServiceUrl}
          style={{ textDecoration: 'none' }}
          onClick={event => {
            event.preventDefault()
            window.open(termsOfServiceUrl, '_blank', 'noopener,noreferrer')
          }}>
          Terms of Service
        </Link>{' '}
        and confirm that you have read MiTravel’s{' '}
        <Link
          href={privacyPolicyUrl}
          style={{ textDecoration: 'none' }}
          onClick={event => {
            event.preventDefault()
            window.open(privacyPolicyUrl, '_blank', 'noopener,noreferrer')
          }}>
          Privacy Policy
        </Link>
        .
      </Terms>
    </Container>
  )
}

export default TermsOfService
