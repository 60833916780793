import React, { useEffect, useState, useCallback, useRef } from 'react'

import { Grid, CardContent, Typography } from '@material-ui/core'
import { Card } from '@mui/material'
import { MTDialog } from 'components'
import { modalTypes } from 'components/MTDialog/types'
import { CONSTANTS } from 'core/constants'
import { format } from 'date-fns'
import { HTTPError } from 'ky'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAppSelector, useGeolocation, CoordsObject } from 'store/hooks'
import {
  setUserPlans,
  setOtherPlans,
  PlanObject,
} from 'store/slices/userPlanSlice'
import { cssVariables } from 'theme'
import { paddleCheckout } from 'utils/paddle'
import { sendNotification } from 'utils/toast'

import {
  verifyCustomer,
  getUserPlans,
  getAllPlans,
  getUserPayment,
  manageSubscription,
  cancelUserSubscriptionFromPaddle,
  useUserPayment,
  getRenewalDate,
  getSubscriptionStatus,
} from 'api/userPlans'
import { FilledCheckbox } from 'components/MTIcons'

type PlanCardProps = {
  plan: PlanObject
  buttonText?: string
  handleClose?: () => void | undefined
  isPopup: boolean
  isSubscribed: boolean
}

type PriceCalculatorProps = {
  amount: number | null
  name: string | null
  detail: boolean | null
}

const PlanCard = ({
  plan,
  buttonText = '',
  handleClose,
  isSubscribed,
}: PlanCardProps) => {
  const { user } = useAppSelector(state => state.user)
  const [openDialog, setOpenDialog] = useState(false)
  const [paymentOption, setPaymentOption] = useState(false)
  const [openPaddleCheckoutFlag, setOpenPaddleCheckoutFlag] = useState(false)
  const [openPaddleCheckoutPlan, setOpenPaddleCheckoutPlan] = useState<
    string | null | undefined
  >(null)

  const [hasUnsedTrip, setHasUnsedTrip] = useState(false)
  const [subscriptionDate, setSubscriptionDate] = useState('')
  const [openDowngradeWarningDialog, setOpenDowngradeWarningDialog] =
    useState(false)
  const [openPurchaseWarningDialog, setOpenPurchaseWarningDialog] =
    useState(false)
  const [contactUsDialog, setContactUsDialog] = useState(false)
  const [
    subscriptionAlreadyCancelledDialog,
    setSubscriptionAlreadyCancelledDialog,
  ] = useState(false)
  const dispatch = useDispatch()
  const userPlan = useAppSelector(state => state.userPlanSlice.userPlan)
  const plans = useAppSelector(state => state.userPlanSlice.plans)
  let buttonStyle = {}
  const otherReasonText = useRef('')
  const [selectedReason, setSelectedReason] = React.useState<number | null>(
    null,
  )
  const history = useHistory()
  const coordinates = useAppSelector(state => state.geoLocation)

  const is_recurring =
    userPlan?.plan?.name === CONSTANTS.ANNUAL_SUBSCRIPTION ||
    userPlan?.plan?.name === CONSTANTS.MONTHLY_SUBSCRIPTION
      ? true
      : false
  const handleCheckboxChange = (index: number) => {
    setSelectedReason(index === selectedReason ? null : index)
    if (index !== 5) {
      otherReasonText.current = ''
    }
  }

  const formatDate = (isoString: string) => {
    const cleanIsoString = isoString.split('.')[0] + 'Z'
    const date = new Date(cleanIsoString)
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })
  }

  if (buttonText === 'Cancel Plan') {
    buttonStyle = {
      color: 'red',
      background: 'none',
      border: 'none',
      padding: 0,
    }
  }
  if (buttonText === 'Purchase') {
    buttonStyle = {
      color: 'white',
      background: cssVariables.colors.primaryBlue,
      fontSize: '13px',
      fontWeight: 800,
      border: 'none',
      borderRadius: '6px',
      padding: '10px',
    }
  }

  if (is_recurring && buttonText === 'Purchase') {
    buttonStyle = {
      ...buttonStyle,
      background: '#AFAFAF',
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let cardStyle: any = {
    maxWidth: '280px',
    minWidth: '220px',
    fontSize: '13px',
    padding: '0px',
    margin: '0 0 0 5px',
    flexGrow: 1,
    boxShadow:
      'rgba(0, 0, 0, 0.1) 0px 0px 1px 0px, rgb(0 0 0 / 4%) 0px 2px 10px 0px',
  }
  if (buttonText === '') {
    cardStyle = {
      ...cardStyle,
      // background: '#E5E5E5',
    }
  }
  if (isSubscribed) {
    cardStyle = {
      ...cardStyle,
      border: '1px solid #97BBFB',
      background: 'white',
    }
  }

  const Price = ({ amount, name, detail = true }: PriceCalculatorProps) => {
    let formattedString = ''
    let discountText = ''

    if (name === CONSTANTS.FREE_PLAN) {
      formattedString = 'Free'
    }
    if (name === CONSTANTS.PAY_PER_TRIP) {
      formattedString = `$${amount} USD/trip`
    }
    if (name === CONSTANTS.MONTHLY_SUBSCRIPTION) {
      formattedString = `$${amount} USD/month`
    }
    if (name === CONSTANTS.ANNUAL_SUBSCRIPTION) {
      formattedString = `$${amount} USD/year`
      discountText = ' — get 2 months off!'
    }

    const styles = {
      color: 'grey',
    }

    const discountStyles = {
      color: '#65A0FA',
      fontWeight: 600,
    }

    return (
      <div style={styles}>
        {formattedString}
        {discountText && <span style={discountStyles}>{discountText}</span>}
      </div>
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const PlanName = ({ name }: { name: any }) => {
    // Ensure the input is a string
    if (typeof name !== 'string') {
      return <span>{name}</span>
    }

    // Regular expression to find the text within parentheses
    const regex = /\(([^)]+)\)/
    const match = name.match(regex)

    if (match) {
      // Extract the part inside the parentheses
      const beforeText = name.split(regex)[0]
      const highlightedText = match[0]
      const afterText = name.split(regex)[2] || ''

      return (
        <span>
          {beforeText}
          <span style={{ color: '#8F8F8F', fontStyle: 'italic' }}>
            {highlightedText}
          </span>
          {afterText}
        </span>
      )
    }

    // If no parentheses are found, return the original name
    return <span>{name}</span>
  }

  const onCloseModal = () => {
    setOpenDialog(false)
    setSelectedReason(null)
    otherReasonText.current = ''
  }

  const onHandleCancel = () => {
    onCloseModal()
    setSelectedReason(null)
    otherReasonText.current = ''
  }

  const handleCancelSubscription = async () => {
    if (selectedReason === null || selectedReason === undefined) {
      sendNotification('Please select a reason', 'error')
      return
    }

    if (selectedReason === 5 && otherReasonText.current.trim().length === 0) {
      sendNotification('Please specify a reason', 'error')
      return
    }
    const freePlan = plans.filter(plan => plan?.name === CONSTANTS.FREE_PLAN)[0]
    const otherPlans = plans
      .map(plan => (plan?.name !== CONSTANTS.FREE_PLAN ? plan : null))
      .filter(Boolean)

    try {
      if (
        userPlan.plan?.name === CONSTANTS.MONTHLY_SUBSCRIPTION ||
        userPlan.plan?.name === CONSTANTS.ANNUAL_SUBSCRIPTION
      ) {
        await cancelUserSubscriptionFromPaddle(user.uid, coordinates)
      }
      if (userPlan.plan?.name === CONSTANTS.PAY_PER_TRIP) {
        const data = {
          uid: user.uid,
          is_recurring: false,
        }
        const payment = await getUserPayment(data)
        if (payment && Object.keys(payment).length > 0) {
          const data = {
            uid: user.uid,
            is_recurring: false,
          }
          const res = await useUserPayment(data)
          if (res) {
            await setHasUnsedTrip(false)
          }
        }
      }
      const reasonToCancel = {
        [selectedReason]:
          selectedReason === 5
            ? otherReasonText.current
            : CONSTANTS.DOWNGRADE_REASONS[selectedReason],
      }
      const res = await manageSubscription({
        planUid: freePlan?.uid,
        userUid: user.uid,
        reasonToCancel,
        coordinates,
      })
      dispatch(setUserPlans(res))
      dispatch(setOtherPlans(otherPlans))
      onCloseModal()

      if (res) {
        onCloseModal()
        history.push('/change-plan/downgrade')
      }
    } catch (error) {
      const e = await (error as HTTPError)?.response?.json()
      const message = e?.message || error
      sendNotification(message, 'error')
      console.log('error occured: ', error)
    }

    return
  }

  const handlePurchaseSubscription = useCallback(
    async (planUid: string | null | undefined) => {
      if (!planUid) {
        return
      }

      const selectedPlan = plans.filter(plan => plan?.uid === planUid)[0]
      if (
        selectedPlan?.name === CONSTANTS.MONTHLY_SUBSCRIPTION ||
        selectedPlan?.name === CONSTANTS.ANNUAL_SUBSCRIPTION
      ) {
        setPaymentOption(true)
      }
      const otherPlans = plans
        .map(plan => (plan?.uid !== planUid ? plan : null))
        .filter(Boolean)

      try {
        const name = selectedPlan?.name ? selectedPlan?.name : null
        const uid = selectedPlan?.uid ? selectedPlan?.uid : null
        const paddlePriceId = selectedPlan?.paddlePriceId
          ? selectedPlan?.paddlePriceId
          : null
        paddleCheckout(
          name,
          uid,
          user.uid,
          otherPlans,
          paddlePriceId,
          history,
          dispatch,
          user.email,
          user.firstName+' '+user.lastName,
          null,
          false,
          hasUnsedTrip,
          coordinates,
        )

        handleClose && handleClose()
        setOpenPurchaseWarningDialog(false)
      } catch (error) {
        const e = await (error as HTTPError)?.response?.json()
        const message = e?.message || error
        sendNotification(message, 'error')
        console.log('error occured: ', error)
        setOpenPurchaseWarningDialog(false)
      }
      return
    },
    [
      user.uid,
      user.email,
      plans,
      hasUnsedTrip,
      history,
      dispatch,
      handleClose,
      setOpenPurchaseWarningDialog,
    ],
  )

  const setOtherPlansGlobally = useCallback(async () => {
    try {
      if (plans && userPlan) {
        const otherPlans = plans
          .map(plan => {
            if (plan?.uid !== userPlan?.plan?.uid) {
              return plan
            }
            return null
          })
          .filter(Boolean)
        await dispatch(setOtherPlans(otherPlans))
      }
    } catch (error) {
      console.error('Error fetching user plans:', error)
    }
  }, [dispatch, plans, userPlan])

  useEffect(() => {
    setOtherPlansGlobally()
  }, [setOtherPlansGlobally])

  useEffect(() => {
    const fetchData = async () => {
      const data = {
        uid: user.uid,
        is_recurring: false,
      }
      const payment = await getUserPayment(data)
      if (payment && Object.keys(payment).length > 0) {
        setHasUnsedTrip(true)
      }
    }

    fetchData()
  }, [user.uid])

  useEffect(() => {
    if (openPaddleCheckoutFlag && openPaddleCheckoutPlan) {
      handlePurchaseSubscription(openPaddleCheckoutPlan)
    }
  }, [
    openPaddleCheckoutFlag,
    openPaddleCheckoutPlan,
    handlePurchaseSubscription,
  ])

  const handleCancelSubscriptionClicked = async () => {
    if (userPlan.plan?.name === CONSTANTS.PAY_PER_TRIP && hasUnsedTrip) {
      setOpenDowngradeWarningDialog(true)
      return
    }
    if (
      userPlan.plan?.name === CONSTANTS.MONTHLY_SUBSCRIPTION ||
      userPlan.plan?.name === CONSTANTS.ANNUAL_SUBSCRIPTION
    ) {
      try {
        const data = {
          paddleTransactionId: userPlan.paddleTransactionId,
        }
        const response = await getSubscriptionStatus(data)
        console.log('response', response)
        if (response.isError) {
          const data = {
            user_uid: user.uid,
          }
          const result = await getUserPlans(data)
          await dispatch(setUserPlans(result))
          return
        }
        if (response.data.cancelledAlready) {
          setSubscriptionDate(formatDate(response.data.cancelledOn))
          setSubscriptionAlreadyCancelledDialog(true)
          return
        }
        setSubscriptionDate(formatDate(response.data.renewedOn))
        console.log(
          'data -- formatted date',
          formatDate(response.data.renewedOn),
        )
        setContactUsDialog(true)
        return
      } catch (error) {
        console.error('Error fetching user plans:', error)
        sendNotification('Oops! Something went wrong.', 'error')
        return
      }
      // const transaction_id = userPlan.plan

      // New dialog
    }
    setOpenDialog(true)
  }

  const handlePurchaseSubscriptionClicked = (
    planUid: string | null | undefined,
  ) => {
    if (hasUnsedTrip) {
      setOpenPurchaseWarningDialog(true)
      setOpenPaddleCheckoutPlan(planUid)
    } else {
      handlePurchaseSubscription(planUid)
    }
  }

  const onHandleClosePurchaseWarningDialog = () => {
    setOpenPurchaseWarningDialog(false)
  }
  const onHandleCloseContactUsDialog = () => {
    setContactUsDialog(false)
  }
  const onHandleCloseSubscriptionAlreadyCancelledDialog = () => {
    setSubscriptionAlreadyCancelledDialog(false)
  }
  const onHandleCloseDowngradeWarningDialog = () => {
    setOpenDowngradeWarningDialog(false)
  }

  // useEffect(() => {
  //   // getRenewalDate

  //   const fetchData = async () => {
  //     const data = {
  //       uid: user.uid,
  //     }
  //     const subscriptionRenewalDate = await getRenewalDate(data)
  //     console.log('check', subscriptionRenewalDate)
  //     alert(subscriptionRenewalDate.data)
  //     setRenewalDate(subscriptionRenewalDate.data)
  //   }
  //   if (isSubscribed && is_recurring) fetchData()
  // }, [user.uid])

  return (
    <>
      {openDialog && (
        <MTDialog
          openDialog={openDialog}
          selectedReason={selectedReason}
          otherReasonText={otherReasonText}
          handleCheckboxChange={handleCheckboxChange}
          onCloseModal={onCloseModal}
          handleCancelSubscription={handleCancelSubscription}
          onCancelClickHandler={onHandleCancel}
          type={modalTypes.DOWNGRADE_PLAN}
        />
      )}

      {openDowngradeWarningDialog && (
        <MTDialog
          openDialog={openDowngradeWarningDialog}
          onCloseModal={onHandleCloseDowngradeWarningDialog}
          setOpenDialog={setOpenDialog}
          setOpenDowngradeWarningDialog={setOpenDowngradeWarningDialog}
          handleCancelSubscription={handleCancelSubscription}
          type={modalTypes.DOWNGRADE_PLAN_WARNING}
        />
      )}

      {openPurchaseWarningDialog && (
        <MTDialog
          openDialog={openPurchaseWarningDialog}
          onCloseModal={onHandleClosePurchaseWarningDialog}
          setOpenDialog={setOpenDialog}
          setOpenPurchaseWarningDialog={setOpenPurchaseWarningDialog}
          setOpenPaddleCheckoutFlag={setOpenPaddleCheckoutFlag}
          setOpenPaddleCheckoutPlan={setOpenPaddleCheckoutPlan}
          handleCancelSubscription={handleCancelSubscription}
          type={modalTypes.PURCHASE_PLAN_WARNING}
        />
      )}

      {contactUsDialog && (
        <MTDialog
          openDialog={contactUsDialog}
          onCloseModal={onHandleCloseContactUsDialog}
          setOpenDialog={setOpenDialog}
          setContactUsDialog={setContactUsDialog}
          subscriptionDate={subscriptionDate}
          type={modalTypes.CONTACT_US}
        />
      )}

      {subscriptionAlreadyCancelledDialog && (
        <MTDialog
          openDialog={subscriptionAlreadyCancelledDialog}
          onCloseModal={onHandleCloseSubscriptionAlreadyCancelledDialog}
          setOpenDialog={setOpenDialog}
          subscriptionDate={subscriptionDate}
          setSubscriptionAlreadyCancelledDialog={
            setSubscriptionAlreadyCancelledDialog
          }
          type={modalTypes.SUBSCRIPTION_ALREADY_CANCELLED}
        />
      )}

      <Card style={cardStyle}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">
                {' '}
                <strong>
                  <PlanName name={plan?.name} />
                </strong>
              </Typography>
            </Grid>
            <Grid style={{ paddingTop: '0px', paddingBottom: 0 }} item xs={12}>
              <Price
                amount={plan?.amount ? plan?.amount : null}
                name={plan?.name ? plan?.name : null}
                detail={true}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                {plan?.name !== CONSTANTS.FREE_PLAN && (
                  <p>Whats in the FREE plan, plus:</p>
                )}
              </Grid>
              {/* {isPopup &&
                  planType.Tourist.features.map((item, index) => (
                    <Grid item xs={12} key={index}>
                      <FilledCheckbox /> {item}
                    </Grid>
                  ))} */}
              {plan?.features?.map((item, index) => (
                <Grid style={{ padding: '2px' }} item xs={12} key={index}>
                  <FilledCheckbox /> {item}
                </Grid>
              ))}
            </Grid>
            {buttonText && (
              <Grid item xs={12}>
                <button
                  onClick={() => {
                    buttonText === 'Cancel Plan'
                      ? handleCancelSubscriptionClicked()
                      : handlePurchaseSubscriptionClicked(plan?.uid)
                  }}
                  disabled={
                    buttonText === 'Cancel Plan'
                      ? false
                      : is_recurring
                      ? true
                      : false
                  }
                  style={buttonStyle}>
                  {buttonText}
                </button>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}

export default PlanCard
