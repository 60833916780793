import { request } from '../utils/request'

export const find = async data => {
  return await request({
    url: '/api/trip-member/find',
    method: 'get',
    searchParams: data,
  })
}

export const update = async data => {
  return await request({
    url: `/api/trip-member/${data.uid}`,
    method: 'put',
    data: { uid: data.uid, ...data },
  })
}

export const remove = async data => {
  return await request({
    url: '/api/trip-member/remove',
    method: 'delete',
    data: { uid: data.uid, ...data },
  })
}

export const invite = async data => {
  return await request({
    url: '/api/trip-member/member-invite',
    method: 'post',
    data,
  })
}

export const confirmInvite = async data => {
  return await request({
    url: '/api/trip-member/confirm-invite',
    method: 'post',
    data,
  })
}

export const denyInvite = async data => {
  return await request({
    url: '/api/trip-member/deny-invite',
    method: 'post',
    data,
  })
}

export const confirmNonAdminMemberInvite = async data => {
  return await request({
    url: '/api/trip-member/confirm-non-admin-member-invite',
    method: 'post',
    data,
  })
}

export const findAllTripMembers = async data => {
  const {trip_short_uid} = data
  return await request({
    url: `/api/trip-member/${trip_short_uid}`,
    method: 'get',
  })
}

