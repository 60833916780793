import styled from '@emotion/styled'
import { IconButton } from '@material-ui/core'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

export const Container = styled.div`
  margin-right: 20px;
`
export const Options = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & > button: {
    margin-right: 1.5rem;
  }
`

export const ExitToAppIconStyled = styled(ExitToAppIcon)`
  top: 7rem !important;
  border-radius: 10px;
`

export const AccountBoxIconStyled = styled(AccountBoxIcon)`
  top: 7rem !important;
  border-radius: 10px;
`

export const IconButtonStyled = styled(IconButton)`
  &:hover {
    background-color: transparent;
  }
`

export const styles = () => {
  return {
    paper: {
      top: '7rem !important',
      borderRadius: 10,
    },
    menuItemText: {
      '& > span': {
        fontSize: '1.5rem !important',
        fontWeight: '400',
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
      },
    },
  }
}
