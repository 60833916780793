import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import ClearIcon from '@material-ui/icons/Clear'

import { Member } from '../../../../components/Member'
import { style } from './memberPopover.style'

const useStyles = makeStyles(theme => style(theme))

const MemberPopover = ({
  member,
  addingView = false,
  onRemoveMemberToTripLocationItem = null,
  onAddMemberToTripLocationItem = null,
}) => {
  const classes = useStyles()
  const isAdmin = member?.user_is_admin
  const memberName = member?.user_first_name ? member?.user_first_name : ''
  const displayMember = member?.user_first_name && member?.user_first_name

  /**
   * Callback which either adds or removes a member from being a trip
   * item location member.
   */
  const onHandleMemberOperation = () => {
    if (!isAdmin) {
      if (onAddMemberToTripLocationItem) onAddMemberToTripLocationItem(member)
      if (onRemoveMemberToTripLocationItem)
        onRemoveMemberToTripLocationItem(member)
    }
  }

  return (
    <Tooltip title={(isAdmin && 'Cannot remove trip admin') || ''}>
      <div className={classes.container} onClick={onHandleMemberOperation}>
        {displayMember && (
          <>
            <Member
              name={memberName}
              displayName={memberName.substring(0, 1)}
            />
            <p
              className={
                classes.memberName
              }>{`${memberName} ${member?.user_last_name}`}</p>
            {addingView && !isAdmin && (
              <ClearIcon className={classes.clearIcon} />
            )}
          </>
        )}
      </div>
    </Tooltip>
  )
}

export default MemberPopover
