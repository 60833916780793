import { useState, useEffect } from 'react'

// eslint-disable-next-line import/named
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import type { RootState, AppDispatch } from './index'

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export type CoordsObject = {
  latitude: number | null
  longitude: number | null
}

export const useGeolocation = (): CoordsObject => {
  const [coordinates, setCoordinates] = useState<CoordsObject>({
    latitude: null,
    longitude: null,
  })

  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setCoordinates({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        })
      })
    } else {
      console.log('Geolocation is not available in your browser.')
    }
  }, [])

  return coordinates
}
