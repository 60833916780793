import React, { createContext, useState, useContext } from 'react'

interface IContext {
  gridOrientation: number
  setGridOrientation: (val: number) => void
}

const defaultState = {
  gridOrientation: 4,
  setGridOrientation: () => null,
}

type Props = {
  children: React.ReactNode
}

const GridOrientationContext = createContext<IContext>(defaultState)

/**
 * Planning board grid orientation (4 or 6 columns)
 * @param { ReactNode } param children
 */
const GridOrientationProvider = ({ children }: Props) => {
  const [gridOrientation, setGridOrientation] = useState(4)

  return (
    <GridOrientationContext.Provider
      value={{ gridOrientation, setGridOrientation }}>
      {children}
    </GridOrientationContext.Provider>
  )
}

export { GridOrientationContext, GridOrientationProvider }

export const useGridOrientation = () => useContext(GridOrientationContext)
