import React from 'react'

type IconProp = {
  stroke?: string
  height?: string
  width?: string
}
const CommentsIcon = ({ stroke, height, width }: IconProp) => {
  return (
    <svg
      width={width ?? '20'}
      height={height ?? '20'}
      viewBox="0 0 16 16"
      fill={stroke ?? '#686868'}
      xmlns="http://www.w3.org/2000/svg"
      stroke= {stroke ?? '#686868'}
      strokeWidth='0'>
      <path
        d="M8.95312 6.57812C8.59219 6.57812 8.3125 6.85781 8.3125 7.20312C8.3125 7.54844 8.59219 7.82812 8.95312 7.82812C9.28281 7.82812 9.5625 7.54844 9.5625 7.20312C9.5625 6.85781 9.28281 6.57812 8.95312 6.57812ZM4.57812 6.57812C4.21719 6.57812 3.9375 6.85781 3.9375 7.20312C3.9375 7.54844 4.21719 7.82812 4.57812 7.82812C4.90781 7.82812 5.1875 7.54844 5.1875 7.20312C5.1875 6.85781 4.90781 6.57812 4.57812 6.57812Z"
      />
      <path
        d="M13.9685 5.39063C13.217 4.35938 12.167 3.67032 11.0154 3.35938V3.36094C10.7482 3.06407 10.4467 2.79063 10.1092 2.54688C7.55135 0.687505 3.96073 1.25469 2.09354 3.8125C0.588854 5.89063 0.652917 8.68594 2.18729 10.6719L2.19979 12.7438C2.19979 12.7938 2.2076 12.8438 2.22323 12.8906C2.30604 13.1547 2.58729 13.3 2.84979 13.2172L4.82792 12.5938C5.35135 12.7797 5.89198 12.8859 6.42948 12.9156L6.42167 12.9219C7.81385 13.9359 9.63885 14.2406 11.3123 13.6875L13.2982 14.3344C13.3482 14.35 13.3998 14.3594 13.4529 14.3594C13.7295 14.3594 13.9529 14.1359 13.9529 13.8594V11.7656C15.3295 9.89688 15.3654 7.31407 13.9685 5.39063ZM5.04667 11.4844L4.85917 11.4063L3.31229 11.8906L3.29667 10.2656L3.17167 10.125C1.84979 8.5125 1.76229 6.18907 2.99979 4.48438C4.50604 2.41875 7.39354 1.9625 9.45292 3.45313C11.5185 4.95469 11.9764 7.8375 10.4842 9.89063C9.2326 11.6078 6.99198 12.2422 5.04667 11.4844ZM12.9373 11.2188L12.8123 11.375L12.8279 13L11.2967 12.4844L11.1092 12.5625C10.2342 12.8875 9.30135 12.9141 8.43729 12.6719L8.43417 12.6703C9.58885 12.3156 10.6357 11.5969 11.3904 10.5625C12.5842 8.91719 12.7779 6.85001 12.0842 5.0875L12.0935 5.09375C12.4529 5.35157 12.7826 5.67344 13.0623 6.0625C14.1967 7.61875 14.1326 9.7375 12.9373 11.2188Z"
      />
      <path
        d="M6.76562 6.57812C6.40469 6.57812 6.125 6.85781 6.125 7.20312C6.125 7.54844 6.40469 7.82812 6.76562 7.82812C7.09531 7.82812 7.375 7.54844 7.375 7.20312C7.375 6.85781 7.09531 6.57812 6.76562 6.57812Z"
      />
    </svg>
  )
}

export default CommentsIcon
