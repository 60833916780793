import React from 'react'

import styled from '@emotion/styled'
import { Link, useLocation } from 'react-router-dom'
import { isScreenSize } from 'utils/screenSzie'

import navBarLogo from './assets/mtlogo.png'

type MTLogoProps = {
  onCloseModal?: (val: boolean) => void
}

const MTLogoStyled = styled.img`
  width: 16rem;
  margin-top: 0.75rem;
`

const MTLogo = ({ onCloseModal }: MTLogoProps) => {
  const location = useLocation()

  const onClose = () => {
    if (onCloseModal) onCloseModal(true)
  }

  let style = {}
  const smallScreen = isScreenSize(600)
  if (smallScreen) {
    style = {
      width: '8rem',
    }
  }

  return (
    <Link to={{ pathname: '/', state: { from: location.pathname } }}>
      <MTLogoStyled
        style={style}
        src={navBarLogo}
        alt="logo"
        onClick={onClose}
      />
    </Link>
  )
}

export default MTLogo
