import styled from '@emotion/styled'
import AttachmentIcon from '@material-ui/icons/Attachment'
import { cssVariables } from 'theme'

export const TripBodyRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px 0;
`

export const TripBodyRowCustom = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 0 2rem 0;
  align-items: center;
`

export const TripBodyRowIcon = styled.div`
  display: flex;
  margin-right: 0.5rem;

  & > svg: {
    margin-top: 7px;
    transform: scale(1);
  }

  &:nth-of-type(4) {
    margin-left: 15px;
  }
`

export const TripBodyRowContainer = styled.div`
  margin-left: 2rem;
  width: 100%;

  &:last-child {
    cursor: pointer;
  }
`

export const AttachmentIconStyled = styled(AttachmentIcon)`
  color: ${cssVariables.colors.alternativeDarkGrey2};
  margin-right: 10px;
  height: 17.5px;
  width: 17.5px;
  transform: rotate(-45deg);
`
