import React from 'react'

import { cssVariables } from 'theme'

interface iconProps {
  stroke?: string
  height?: string
  width?: string
}
const CustomDropDown = (props: iconProps) => {
  return (
    <svg
    width={props.width ?? '20'}
    height={props.height ?? '20'}
      viewBox="0 0 5 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.220133 2.13983L3.14458 5.00385L0.220133 7.86786C0.150353 7.9362 0.0949994 8.01733 0.0572344 8.10662C0.0194689 8.19591 3.10828e-05 8.29161 3.1084e-05 8.38826C3.10852e-05 8.4849 0.0194689 8.5806 0.0572344 8.66989C0.0949994 8.75918 0.150353 8.84031 0.220133 8.90865C0.289915 8.97699 0.372757 9.0312 0.46393 9.06819C0.555104 9.10517 0.652823 9.12421 0.751508 9.12421C0.850193 9.12421 0.947912 9.10517 1.03909 9.06819C1.13026 9.0312 1.2131 8.97699 1.28288 8.90865L4.74247 5.52055C4.81234 5.45226 4.86778 5.37115 4.9056 5.28185C4.94342 5.19256 4.96289 5.09683 4.96289 5.00016C4.96289 4.90348 4.94342 4.80776 4.9056 4.71846C4.86778 4.62916 4.81234 4.54805 4.74247 4.47976L1.28288 1.09166C1.21315 1.02323 1.13033 0.968942 1.03915 0.931901C0.947966 0.89486 0.850222 0.875794 0.751508 0.875794C0.652794 0.875794 0.555049 0.89486 0.463869 0.931901C0.372689 0.968942 0.289863 1.02323 0.220133 1.09166C-0.0662808 1.37954 -0.0738182 1.85195 0.220133 2.13983Z"
        fill="#686868"
      />
    </svg>
  )
}

export default CustomDropDown
