// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { AppDispatch } from '../index'

export type UserObject = {
  uid: string | null
  username: string | null
  firstName: string | null
  lastName: string | null
  email: string | null
  gender: string | null
  date_of_birth: string | null
  is_tentative: string | null
  is_trip_creator: string | null
  activated: boolean | null
}

type UserState = {
  user: UserObject
}

const initialState: UserState = {
  user: {
    uid: null,
    username: null,
    firstName: null,
    lastName: null,
    is_tentative: null,
    is_trip_creator: null,
    activated: null,
    email: null,
    gender: null,
    date_of_birth: null,
  },
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    _setUser: (state, { payload }: PayloadAction<UserObject>) => {
      state.user = payload
    },
    _updateUser: (state, { payload }: PayloadAction<UserObject>) => {
      state.user.firstName = payload.firstName
      state.user.lastName = payload.lastName
      state.user.gender = payload.gender
      state.user.date_of_birth = payload.date_of_birth
    },
    _clearUser: state => {
      state.user = {
        uid: null,
        username: null,
        firstName: null,
        lastName: null,
        is_tentative: null,
        is_trip_creator: null,
        activated: null,
        email: null,
        gender: null,
        date_of_birth: null,
      }
    },
  },
})

const { _setUser, _clearUser, _updateUser } = userSlice.actions

export const setUser = (data: UserObject) => (dispatch: AppDispatch) => {
  try {
    dispatch(_setUser(data))
  } catch (e) {
    throw new Error((e as Error).message)
  }
}

export const updateUser = (data: UserObject) => (dispatch: AppDispatch) => {
  try {
    dispatch(_updateUser(data))
  } catch (e) {
    throw new Error((e as Error).message)
  }
}

export const clearUser = () => (dispatch: AppDispatch) => {
  try {
    dispatch(_clearUser())
  } catch (e) {
    throw new Error((e as Error).message)
  }
}

export default userSlice.reducer
