// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { AppDispatch } from '../index'

export type PlanObject = {
  uid: string | null
  name: string | null
  features: string[] | null
  amount: number | null
  billing_cycle_interval: string | null
  paddlePriceId: string | null
  is_recurring: boolean | null
} | null

export type UserPlanObject = {
  plan: PlanObject
  user_uid: string | null
  paddleTransactionId: string | null
}

type UserPlanState = {
  userPlan: UserPlanObject
  plans: Array<PlanObject>
  otherPlans: Array<PlanObject>
  userTripsCount: number
}

type PlanType = {
  [key in string]: {
    features: string[]
  }
}

export const planType: PlanType = {
  Tourist: {
    features: [
      '1 Trip Planning Board',
      'Unlimited Planning Cards',
      'Unlimited Trip Viewers',
      'Up to 2 Trip Editors',
    ],
  },
  'Adventurer (Pay-Per-Trip)': {
    features: ['Up to 5 Trip Editors'],
  },
  'Globe-Trotter (Monthly Subscription)': {
    features: [
      'Data Preservation of All trip planning boards',
      'Unlimited Trip Planning Board',
      'Unlimited Planning Cards',
      'Unlimited Trip Viewers',
      'Unlimited Trip Editors',
    ],
  },
  'Globe-Trotter (Annual Subscription)': {
    features: [
      'Data Preservation of All trip planning boards',
      'Unlimited Trip Planning Board',
      'Unlimited Planning Cards',
      'Unlimited Trip Viewers',
      'Unlimited Trip Editors',
    ],
  },
}

const initialState: UserPlanState = {
  userPlan: {
    plan: {
      uid: null,
      name: null,
      features: null,
      amount: null,
      billing_cycle_interval: null,
      paddlePriceId: null,
      is_recurring: false,
    },
    user_uid: null,
    paddleTransactionId: null,
  },
  plans: [],
  otherPlans: [],
  userTripsCount: 0,
}

const getPlanObject = (payload: UserPlanObject) => {
  const planArray = Object.keys(planType)
    .map(planKey => {
      const planTypeInfo = planType[planKey]

      if (payload?.plan?.name === planKey) {
        return {
          ...payload?.plan,
          uid: payload?.plan?.uid,
          name: planKey,
          features: planTypeInfo.features,
          amount: payload?.plan?.amount,
          billing_cycle_interval: payload?.plan?.billing_cycle_interval,
        }
      }
      return null
    })
    .filter(Boolean)
  const data = {
    plan: planArray[0],
    user_uid: payload.user_uid,
    paddleTransactionId: payload.paddleTransactionId,
  }
  return data
}

const sortPlans = (
  activePlanName: string,
  plans: PlanObject[],
): PlanObject[] => {
  const planOrder: { [key: string]: string[] } = {
    Tourist: [
      'Adventurer (Pay-Per-Trip)',
      'Globe-Trotter (Monthly Subscription)',
      'Globe-Trotter (Annual Subscription)',
    ],
    'Adventurer (Pay-Per-Trip)': [
      'Tourist',
      'Globe-Trotter (Monthly Subscription)',
      'Globe-Trotter (Annual Subscription)',
    ],
    'Globe-Trotter (Monthly Subscription)': [
      'Tourist',
      'Globe-Trotter (Annual Subscription)',
      'Adventurer (Pay-Per-Trip)',
    ],
    'Globe-Trotter (Annual Subscription)': [
      'Tourist',
      'Globe-Trotter (Monthly Subscription)',
      'Adventurer (Pay-Per-Trip)',
    ],
  }

  const order = planOrder[activePlanName] || []

  return plans.sort((a, b) => {
    const indexA = order.indexOf(a?.name || '')
    const indexB = order.indexOf(b?.name || '')

    if (indexA === -1 || indexB === -1) return 0
    return indexA - indexB
  })
}
const userPlanSlice = createSlice({
  name: 'userPlan',
  initialState,
  reducers: {
    _setUserPlan: (state, { payload }: PayloadAction<UserPlanObject>) => {
      state.userPlan = getPlanObject(payload)
    },
    _setPlans: (state, { payload }: PayloadAction<Array<PlanObject>>) => {
      const allPlans: Array<PlanObject> = Object.keys(planType)
        .map(planKey => {
          const planTypeInfo = planType[planKey]
          const matchingPlan: PlanObject | undefined = payload.find(
            item => item?.name === planKey,
          )

          if (matchingPlan && planTypeInfo) {
            return {
              ...matchingPlan,
              uid: matchingPlan.uid,
              name: planKey,
              features: planTypeInfo.features,
              amount: matchingPlan.amount,
              billing_cycle_interval: matchingPlan.billing_cycle_interval,
            }
          }
          return null
        })
        .filter(Boolean)

      state.plans = allPlans
    },
    _setOtherPlans: (state, { payload }: PayloadAction<Array<PlanObject>>) => {
      const sortedPlans = sortPlans(state.userPlan.plan?.name || '', payload)
      state.otherPlans = sortedPlans
    },
    _setUserTripsCount: (state, { payload }) => {
      state.userTripsCount = payload
    },
    _clearUserPlan: state => {
      state.userPlan = {
        plan: {
          uid: null,
          name: null,
          features: null,
          amount: null,
          billing_cycle_interval: null,
          paddlePriceId: null,
          is_recurring: false,
        },
        user_uid: null,
        paddleTransactionId: null,
      }
    },
  },
})

const {
  _setPlans,
  _setUserPlan,
  _clearUserPlan,
  _setOtherPlans,
  _setUserTripsCount,
} = userPlanSlice.actions

export const setPlans =
  (data: Array<PlanObject>) => (dispatch: AppDispatch) => {
    try {
      dispatch(_setPlans(data))
    } catch (e) {
      throw new Error((e as Error).message)
    }
  }

export const setUserPlans =
  (data: UserPlanObject) => (dispatch: AppDispatch) => {
    try {
      dispatch(_setUserPlan(data))
    } catch (e) {
      throw new Error((e as Error).message)
    }
  }

export const setOtherPlans =
  (data: Array<PlanObject>) => (dispatch: AppDispatch) => {
    try {
      dispatch(_setOtherPlans(data))
    } catch (e) {
      throw new Error((e as Error).message)
    }
  }

export const setUserTripsCount = (data: number) => (dispatch: AppDispatch) => {
  try {
    dispatch(_setUserTripsCount(data))
  } catch (e) {
    throw new Error((e as Error).message)
  }
}

export const clearUser = () => (dispatch: AppDispatch) => {
  try {
    dispatch(_clearUserPlan())
  } catch (e) {
    throw new Error((e as Error).message)
  }
}

export default userPlanSlice.reducer
