/* eslint-disable @typescript-eslint/no-explicit-any */
import { CustomDropDown } from 'components/MTIcons'
import React, {
  useState,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import { style, CenteredContainer } from './TravelStyleDropdown.style'

interface TravelStyleDropDownProps {
  selectedTags: string[]
  setSelectedTags: Dispatch<SetStateAction<string[]>>
  handleCheckboxChange: (arg_0: string) => void
}

const TravelStyleDropDown = ({
  selectedTags,
  setSelectedTags,
  handleCheckboxChange
}: TravelStyleDropDownProps) => {
  const styles = style()
  const [open, setOpen] = useState<boolean>(false)
  const dropdownRef = useRef<HTMLDivElement>(null) // Reference to the dropdown

  const toggleDropdown = () => setOpen(!open)

  const removeAllTags = () => {
    setSelectedTags([])
  }
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const customTags = [
    'Budget Traveling',
    'Couples Travel',
    'Digital Nomad Travel',
    'Solo Traveler',
    'Comfort Travel',
    'Group Travel',
    'Family Vacation',
    'Luxury Traveler',
  ]

  return (
    <div ref={dropdownRef} style={styles.dropdownContainer}>
      <button onClick={toggleDropdown} style={styles.dropdownButton}>
        <CenteredContainer>
          <input
            type="checkbox"
            title="Colors"
            checked={selectedTags.length > 0}
            style={styles.dropdownItemInput}
            onClick={removeAllTags}
          />
          <p style={styles.buttonText}>Travel Styles</p>
        </CenteredContainer>
        <CenteredContainer style={open ? styles.rotate : {}}>
          <CustomDropDown height="10px" width="10px" />
        </CenteredContainer>
      </button>
      {open && (
        <div style={styles.dropdownMenu}>
          {customTags.length < 1 && (
            <p style={{ padding: 0, margin: 0 }}>No Data Found</p>
          )}
          {customTags.map((tag, idx) => (
            <div
              key={idx}
              style={styles.dropdownItem}
              onClick={event => handleCheckboxChange(tag)}>
              <input
                type="checkbox"
                checked={!!selectedTags.find(c => c === tag)}
                style={styles.dropdownItemInput}
              />
              <p style={styles.dropdownText}>{tag}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default TravelStyleDropDown
