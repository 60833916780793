import { request } from '../utils/request'

export const create = async data => {
    return await request({
        url: '/api/comments',
        method: 'post',
        data,
    })
}

export const remove = async (uid, latitude, longitude) => {
    return await request({
        url: `/api/comments/${uid}?latitude=${latitude}&longitude=${longitude}`,
        method: 'delete',
    })
}

export const getAll = async (trip_item_uid) => {
    return await request({
        url: `/api/comments/${trip_item_uid}`,
        method: 'get',
    })
}
