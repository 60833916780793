import React from 'react'

type IconProp = {
  stroke?: string
  height?: string
  width?: string
}
const DurationIcon = ({ stroke, height, width }: IconProp) => {
  return (
    <svg
      width= {width ?? '16'}
      height= {height ?? '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d="M14.1666 7.99967C14.1666 11.4055 11.4058 14.1663 7.99992 14.1663C4.59406 14.1663 1.83325 11.4055 1.83325 7.99967C1.83325 4.59382 4.59406 1.83301 7.99992 1.83301C11.4058 1.83301 14.1666 4.59382 14.1666 7.99967Z"
        stroke={stroke ?? '#3A3A3A'}
        strokeLinejoin="round"
      />
      <path
        d="M8.00269 4V8.00333L10.829 10.83"
        stroke={stroke ?? '#3A3A3A'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DurationIcon
