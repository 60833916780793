import styled from '@emotion/styled'
import { Button } from '@material-ui/core'
import { cssVariables } from 'theme'

export const Container = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
`

export const LoadMoreButton = styled(Button)`
  background: ${cssVariables.colors.primaryBlue};
  color: ${cssVariables.colors.primaryWhite};
  height: 40px;
  border-radius: 20px;
  font-size: 1.7rem;
  text-transform: capitalize;
  margin-top: 1rem;
`
