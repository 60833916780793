import React, { ReactNode, useState } from 'react'

import { Dialog, makeStyles } from '@material-ui/core'
import { MTButton } from 'components/MTButton'
import { CONSTANTS } from 'core/constants'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useAppSelector, useGeolocation, CoordsObject } from 'store/hooks'
import { cssVariables } from 'theme'
import { paddleCheckout } from 'utils/paddle'
import { isScreenSize } from 'utils/screenSzie'

import { getUserPayment } from 'api/userPlans'
import {
  PlusCircleIcon,
  AlertCircleIcon,
  XIcon,
  DuplicateIcon,
} from 'components/MTIcons'

import Plans from '../../layouts/Auth/layouts/Profile/subscription/Plans'
import { closeButtonStyle } from '../MTDialog/helper'
import {
  style,
  Header,
  HeaderTitle,
  HR,
  Body,
} from '../MTDialog/mTDialog.style'
import { useWindowSize } from 'hooks/useWindowSize'
// import { DuplicateButton } from './duplicateTripButton.style'
import { CurrentTrip } from 'pages/Workspace/layouts/PlanningBoard/types'
import { duplicateCurrentTrip } from 'api/trip'
import { Spinner } from 'reactstrap'
import { sendNotification } from 'utils/toast'
import { NavigateBefore } from '@material-ui/icons'
import { Spin } from 'antd'
import { NormalText } from 'pages/Workspace/layouts/MembersListRow/layouts/CollaboratorsModal/publishTripModal.style'

type DuplicateTripProps = {
  currentTrip: CurrentTrip
  DuplicateButton: ReactNode
  showFullStatus?: boolean
  additionalAction?: () => void
  spinnerSize?: string
  loadingTextSize?: string
}

const DuplicateTripButton = ({
  currentTrip,
  DuplicateButton,
  showFullStatus = false,
  additionalAction,
  spinnerSize = '16px',
  loadingTextSize = '16px'
}: DuplicateTripProps) => {
  const history = useHistory()
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const location = useLocation()
  const userPlan = useAppSelector(state => state.userPlanSlice.userPlan)
  const { user } = useAppSelector(state => state.user)
  const otherPlans = useAppSelector(state => state.userPlanSlice.otherPlans)
  const extraSmallScreen = isScreenSize(350)
  const smallScreen = isScreenSize(600)
  const styledProps = {
    padding: extraSmallScreen ? 15 : 25,
    margin: extraSmallScreen ? '15px' : '32px',
  }
  const dispatch = useDispatch()
  const coordinates = useAppSelector(state => state.geoLocation)
  const { width: screenWidth } = useWindowSize()
  const useStyles = makeStyles(() => style(styledProps))
  const classes = useStyles()
  const userTripsCount = useAppSelector(
    state => state.userPlanSlice.userTripsCount,
  )
  const [duplicateLoading, setDuplicateLoading] = useState(false)

  const duplicateTrip = async () => {
    if (!user.uid) {
      history.push('/signup')
      return
    }
    setDuplicateLoading(true)
    try {
      const response = await duplicateCurrentTrip({
        short_uid: currentTrip.short_uid,
        coordinates,
      })
      console.log(response.short_uid)
      if (response.short_uid) {
        history.push('/')
        sendNotification(
          <p style={{ margin: 0 }}>
            Trip duplicated successfully.{' '}
            <a href={`/workspace/${response.short_uid}`}>
              View duplicated trip
            </a>
          </p>,
          'success',
        )
        if(additionalAction){
          additionalAction()
        }
      }
    } catch (e) {
      console.log('error while duplicating trip')
      sendNotification('trip failed to Duplicate', 'error')
    } finally {
      setDuplicateLoading(false)
    }
  }

  const onHandleClick = async () => {
    if (userTripsCount < 1) {
      duplicateTrip()
      return
    }
    if (userPlan?.plan?.name === CONSTANTS.FREE_PLAN && userTripsCount >= 1) {
      handleOpen()
      return
    }
    if (userPlan?.plan?.name === CONSTANTS.PAY_PER_TRIP) {
      const data = {
        uid: user.uid,
        is_recurring: false,
      }
      const payment = await getUserPayment(data)
      if (payment && Object.keys(payment).length > 0) {
        duplicateTrip()
        return
      } else {
        paddleCheckout(
          userPlan?.plan?.name,
          userPlan?.plan?.uid,
          user.uid,
          otherPlans,
          userPlan?.plan?.paddlePriceId,
          history,
          dispatch,
          user.email,
          user.firstName + ' ' + user.lastName,
          null,
          true,
          false,
          coordinates,
        )
        return
      }
    }
    if (
      userPlan?.plan?.name === CONSTANTS.MONTHLY_SUBSCRIPTION ||
      userPlan?.plan?.name === CONSTANTS.ANNUAL_SUBSCRIPTION
    ) {
      duplicateTrip()
    }
  }

  return (
    <>
      {duplicateLoading ? (
        <div
          style={{
            paddingLeft: '17px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '10px',
          }}>
          <Spinner style={{ height: spinnerSize, width: spinnerSize }} />
          {
            !showFullStatus
            ? <NormalText style={{ fontSize: loadingTextSize }}>Duplicating...</NormalText>
            : <NormalText style={{ fontSize: loadingTextSize }}>Duplicating trip, please wait!</NormalText>
          }

        </div>
      ) : (
        <div onClick={onHandleClick}>{DuplicateButton}</div>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        style={{
          maxWidth: '100% !important',
        }}
        classes={{
          paperScrollPaper: classes.paperScrollPaper,
          paper: classes.container,
          paperWidthSm:
            screenWidth > 700
              ? classes.paperCustomWidthLg
              : classes.paperCustomWidthLgMobile,
        }}>
        <Header style={{ display: 'flex', flexWrap: 'wrap' }}>
          <HeaderTitle style={{ fontSize: smallScreen ? '1.8rem' : '2rem' }}>
            Upgrade your plan
          </HeaderTitle>
          <div style={closeButtonStyle}>
            <MTButton
              customStyles={{ backgroundColor: 'white', boxShadow: 'none' }}
              onHandleClick={handleClose}
              customIcon={<XIcon />}
            />
          </div>
        </Header>
        <HR></HR>
        <Body>
          <div style={{ minHeight: 'fit-content' }}>
            <strong>
              To plan a new trip aside from your existing free trip, please
              purchase your plan
            </strong>
            <Plans
              handleClose={handleClose}
              otherPlans={otherPlans}
              isPopup={true}
            />
          </div>
        </Body>
      </Dialog>
      <div className="checkout-container"></div>
    </>
  )
}

export default DuplicateTripButton
