import React, { createContext, useState, useContext } from 'react'

interface IModelContext {
  layout: string
  handleLayout: (layout: string) => void
}

const defaultState = {
  layout: '',
  handleLayout: () => null,
}

type Props = {
  children: React.ReactNode
}

const ModalContext = createContext<IModelContext>(defaultState)

const ModalProvider = ({ children }: Props) => {
  const [layout, setLayout] = useState('')

  const handleLayout = (content: string) => {
    setLayout(content)
  }

  return (
    <ModalContext.Provider
      value={{
        layout,
        handleLayout,
      }}>
      {children}
    </ModalContext.Provider>
  )
}

export { ModalContext, ModalProvider }

export const useModal = () => useContext(ModalContext)
