import React from 'react'

import { Field } from 'formik'
import { Quill } from 'pages/Workspace/layouts/PlanningBoard/components/Quill'

type Props = {
  handleChange: (val: string) => void
  formikKey: string
  setFieldValue: (key: string, d: Date) => void
  value: string
  canEdit: boolean
}

const Note = ({
  handleChange,
  formikKey,
  setFieldValue,
  value,
  canEdit,
}: Props) => {
  return (
    <div style={{ width: '100%', wordBreak: 'break-all' }}>
      <Field
        data-testid="noteDescription"
        component={Quill}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        formikKey={formikKey}
        value={value}
        canEdit={canEdit}
      />
    </div>
  )
}

export default Note
