import React from 'react'

import styled from '@emotion/styled'
import { MTButton } from 'components'
import {
  RedButtonStyleProps,
  TransparentButtonStyleProps,
} from 'components/MTDialog/helper'
import { isScreenSize } from 'utils/screenSzie'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
`

type DeleteItemProps = {
  setOpenDialog?: (value: boolean) => void
  setOpenPurchaseWarningDialog?: (value: boolean) => void
  setOpenPaddleCheckoutFlag?: (value: boolean) => void
  setOpenPaddleCheckoutPlan?: (value: string | null | undefined) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  blueButtonStyle: any
  transparentButtonStyle: TransparentButtonStyleProps
}

const PurchasePlanWarning = ({
  setOpenPaddleCheckoutPlan,
  setOpenPaddleCheckoutFlag,
  setOpenPurchaseWarningDialog,
  blueButtonStyle,
  transparentButtonStyle,
}: DeleteItemProps) => {
  return (
    <>
      <Container>
        <MTButton
          customStyles={blueButtonStyle}
          onHandleClick={() => {
            if (setOpenPaddleCheckoutFlag) setOpenPaddleCheckoutFlag(true)
            if (setOpenPurchaseWarningDialog)
              setOpenPurchaseWarningDialog(false)
          }}
          title={'Purchase your plan anyway'}
        />
        <MTButton
          customStyles={transparentButtonStyle}
          title="Cancel"
          onHandleClick={() => {
            if (setOpenPurchaseWarningDialog) {
              setOpenPurchaseWarningDialog(false)
              setOpenPaddleCheckoutPlan && setOpenPaddleCheckoutPlan(null)
              setOpenPaddleCheckoutFlag && setOpenPaddleCheckoutFlag(false)
            }
          }}
        />
      </Container>
    </>
  )
}

export default PurchasePlanWarning
