/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'

import { CONSTANTS } from 'core/constants'
import type { ArrayHelpers } from 'formik'
import { includes, startCase } from 'lodash'
import { useAppSelector } from 'store/hooks'
import { can, TRIP_ITEM_TAGS_EDIT } from 'utils/permissions'
import { sendNotification } from 'utils/toast'

import { ItemPopupFormikValues } from '../../ItemPopup'
import { Tag } from './components/Tag'
import { TagList, TagInput } from './popupTags.style'

type Props = {
  setFieldValue: (key: string, val: Array<any>) => void
  values: ItemPopupFormikValues
  arrayHelpers: ArrayHelpers
}

const PopupTags = ({ values, setFieldValue }: Props) => {
  const [inputValue, setInputValue] = useState('')
  const [tags, setTags] = useState(values.tags)
  const defaultTag = '' + values.type
  const { data: tripRoles } = useAppSelector(state => state.tripUserRoleSlice)
  const canEditTripItemTags = can(tripRoles[0].role, TRIP_ITEM_TAGS_EDIT)

  useEffect(() => {
    // ensure default tag is 'location', 'note' or 'poll'
    if (!includes(tags, defaultTag)) {
      values?.tags?.push(defaultTag)
    }
  }, [defaultTag, tags, values.tags])

  /**
   * Add Tag to Tags array.
   * @param {any} event input event
   */
  const addTags = (event: any) => {
    setInputValue(event.target.value)
    if (
      (event.key === 'Enter' && event.target.value !== '') ||
      event.type === 'blur'
    ) {
      let isDuplicate = false
      tags.forEach((e: any) => {
        if (e === event.target.value || e.toLowerCase() === event.target.value.toLowerCase()) {
          isDuplicate = true
          sendNotification(CONSTANTS.TAGS_DUPLICATE_ERROR, 'error')
        }
        if (event.target.value === '') {
          //don't save epmty tags that create when focus out after enter
          isDuplicate = true
        }
      })
      if (!isDuplicate) {
        setTags([...tags, event.target.value])
        setFieldValue('tags', [...values.tags, event.target.value])
      }
      setInputValue('')
    }
  }

  /**
   * Remove tag from tags array.
   * @param { string } tag which to remove
   */
  const removeTags = async (tag: string) => {
    if (!canEditTripItemTags) {
      return sendNotification(
        `${CONSTANTS.UPDATE_TRIP_ITEM_TAGS_PERMISSION_ERROR}`,
        'error',
      )
    }

    const updatedFormikTagsArray = values.tags.filter((t: string) => t !== tag)
    setFieldValue('tags', updatedFormikTagsArray)
    setTags(updatedFormikTagsArray)
  }

  const isTagDefault = (tag: string) => {
    return tag != 'location' && tag != 'note' && tag != 'poll'
  }

  return (
    <TagList>
      <Tag tag={startCase(defaultTag)} immutable />
      {tags
        ?.slice(0, 5)
        .map((tag: string, index: number) =>
          isTagDefault(tag) ? (
            <Tag key={index} tag={tag} removeTags={removeTags} />
          ) : (
            ''
          ),
        )}
      {tags && tags.length < 5 && (
        <TagInput
          name="form-field-name"
          value={inputValue}
          type="text"
          onBlur={e => addTags(e)}
          onChange={e => setInputValue(e.target.value)}
          onKeyUp={e => addTags(e)}
          placeholder={CONSTANTS.ADD_CUSTOM_TAGS_PLACEHOLDER}
          maxLength={14}
          disabled={!canEditTripItemTags}
        />
      )}
    </TagList>
  )
}

export default PopupTags
