import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  convertFromRaw,
  convertToRaw,
  EditorState,
  RawDraftContentState,
} from 'draft-js'
import Editor from '@draft-js-plugins/editor'
import createMentionPlugin, {
  defaultSuggestionsFilter,
} from '@draft-js-plugins/mention'
import editorStyles from './SimpleMentionEditor.module.css'
import './comments.css'
import '@draft-js-plugins/mention/lib/plugin.css'
import { CommentsIcon } from 'components/MTIcons'
import { MTAvatar } from 'components/MTAvatar'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { MTButton } from 'components/MTButton'
import mentionsStyles from './MentionsStyles.module.css'
import { create, getAll, remove } from 'api/comment'
import { sendNotification } from 'utils/toast'
import { CONSTANTS } from 'core/constants'
import { EntryComponentProps } from '@draft-js-plugins/mention/lib/MentionSuggestions/Entry/Entry'
import useSWR, { useSWRConfig } from 'swr'
import Comment from 'components/Comment/Comment'
import { updateCommentCountOfTripItem } from 'store/slices/tripGroupSlice'
import { findAllTripMembers } from 'api/tripMember'
import {
  CommentBodyContainer,
  CommentButton,
  CommentIconContainer,
  CommentInputContainer,
  CommentLoadingText,
  CommentsContainer,
  CommentText,
  EntryParentContainer,
  FullWidthDiv,
  PlaceHolderInput,
  SpinnerContainer,
  StyledSpinner,
} from './commentSection.style'

interface tripMember {
  users: {
    firstName: string
    lastName: string
    id: string
  }[]
}

export interface comment {
  id: number
  text: string
  created_at: string
  uid: string
  user: {
    firstName: string
    lastName: string
    email: string
    uid: string
    id: string
  }
}
interface CommentsProps {
  trip_item_uid: string
  trip_short_uid: string
  type: 'poll' | 'note' | 'location'
}

export default function CommentSection({
  trip_item_uid,
  trip_short_uid,
  type,
}: CommentsProps): ReactElement {
  console.log({
    trip_item_uid,
    trip_short_uid,
    type,
  })
  const {
    data: AllComments,
    error: CommentsError,
    isLoading: CommentsLoading,
  } = useSWR(
    `/api/comments/${trip_item_uid}`,
    async () => await getAll(trip_item_uid),
    {
      refreshInterval: 60000,
    },
  ) //refresh comments every 2 mins, if the component is still mounted

  const {
    data: TripMembers,
    error: TripMembersError,
    isLoading: TripMembersLoading,
  } = useSWR(
    `/api/trip-member/${trip_short_uid}`,
    async () => await findAllTripMembers({ trip_short_uid }),
    {
      refreshInterval: 0,
    },
  ) // doesn't refresh

  const ref = useRef<Editor>(null)
  const CommentsContainerRef = useRef(null)
  const { user } = useAppSelector(state => state.user)
  const { mutate } = useSWRConfig()
  const [commentDeleteLoading, setCommentDeleteLoading] = useState(false)
  const [displayCommentBox, setDisplayCommentBox] = useState(false)
  const { tripGroups, newlyCreatedItem } = useAppSelector(
    state => state.tripGroupSlice,
  )
  const coordinates = useAppSelector(state => state.geoLocation)
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  )
  const [open, setOpen] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const [commentSaving, setCommentSaving] = useState(false)

  const dispatch = useAppDispatch()

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      theme: mentionsStyles,
      mentionPrefix: '@',
      supportWhitespace: true,
    })
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin]
    return { plugins, MentionSuggestions }
  }, [])

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open)
  }, [])

  const onSearchChange = useCallback(
    ({ value }: { value: string }, TripMembers) => {
      const mentions = TripMembers.map((member: tripMember) => {
        return {
          name: member.users[0].firstName + ' ' + member.users[0].lastName,
          id: member.users[0].id,
        }
      })
      setSuggestions(mentions)
      setSuggestions(defaultSuggestionsFilter(value, mentions))
    },
    [],
  )

  //function to programatically add a mention (Reply to comment case)
  const addMention = (user: { id: string; username: string }) => {
    const StateJson = `{"blocks":[{"key":"amgq4","text":"@${
      user.username + ' '
    } ","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[{"offset":0,"length":${
      user.username.length + 1
    },"key":0}],"data":{}}],"entityMap":{"0":{"type":"mention","mutability":"IMMUTABLE","data":{"mention":{"name":"${
      user.username
    }","id":"${user.id}"}}}}}`
    const state = JSON.parse(StateJson)
    setDisplayCommentBox(true)
    setEditorState(
      EditorState.createWithContent(
        convertFromRaw(state as unknown as RawDraftContentState),
      ),
    )
    setTimeout(() => {
      (CommentsContainerRef.current! as HTMLBodyElement).scrollIntoView({
        behavior: 'smooth',
      })
    }, 0)
  }

  const updateItemComments = async (opt: 'add' | 'delete') => {
    let tripGroupOfThatItem = {}

    for (const tripGroup of tripGroups) {
      for (const tripGroupItem of tripGroup.tripItems) {
        if (tripGroupItem.uid === trip_item_uid) {
          tripGroupOfThatItem = tripGroup
          break
        }
      }
    }

    let newGroupItem = {}
    for (const groupItem of tripGroupOfThatItem.tripItems) {
      if (groupItem.uid === trip_item_uid) {
        newGroupItem = groupItem
        break
      }
    }

    const payload = {
      groupUid: tripGroupOfThatItem.uid,
      tripItemUid: newGroupItem.uid,
      opt: opt,
    }
    await dispatch(updateCommentCountOfTripItem(payload))
  }

  const handleDeleteComment = async (comment_uid: string) => {
    setCommentDeleteLoading(true)
    return await remove(
      comment_uid,
      coordinates.latitude,
      coordinates.longitude,
    )
      .then(() => {
        updateItemComments('delete')
        mutate(`/api/comments/${trip_item_uid}`)
        sendNotification(CONSTANTS.COMMENT_DELETED, 'success')
      })
      .catch(() => {
        sendNotification(CONSTANTS.COMMENT_DELETE_FAILED, 'error')
      })
      .finally(() => {
        setCommentDeleteLoading(false)
      })
  }

  const handleSaveComment = async (e: React.SyntheticEvent) => {
    e.stopPropagation()
    e.preventDefault()
    const rawContent = convertToRaw(editorState.getCurrentContent())
    // Serialize the content to JSON for saving to a database
    const jsonContent = JSON.stringify(rawContent)
    setCommentSaving(true)
    setTimeout(async () => {
      await create({
        text: jsonContent,
        plain_text: editorState.getCurrentContent().getPlainText(),
        coordinates,
        ...(type === 'location'
          ? { trip_location_item_uid: trip_item_uid }
          : type === 'note'
          ? { trip_note_item_uid: trip_item_uid }
          : { trip_poll_item_uid: trip_item_uid }),
      })
        .then(() => {
          sendNotification(CONSTANTS.NEW_COMMENT_ADDED, 'success')
          updateItemComments('add')
          setEditorState(EditorState.createEmpty())
          mutate(`/api/comments/${trip_item_uid}`)
          setDisplayCommentBox(false)
        })
        .catch(() => {
          sendNotification(CONSTANTS.NEW_COMMENT_FAILED, 'error')
        })
        .finally(() => {
          setCommentSaving(false)
        })
    }, 0)
  }

  //customizing the popup suggestions component
  function Entry(props: EntryComponentProps): ReactElement {
    const {
      mention,
      theme,
      searchValue, // eslint-disable-line @typescript-eslint/no-unused-vars
      isFocused, // eslint-disable-line @typescript-eslint/no-unused-vars
      ...parentProps
    } = props

    return (
      <div {...parentProps}>
        <div className={theme?.mentionSuggestionsEntryContainer}>
          <EntryParentContainer>
            <MTAvatar
              width="24px"
              height="24px"
              displayName={mention.name.slice(0, 1).toUpperCase()}
            />
            <div className={theme?.mentionSuggestionsEntryText}>
              {mention.name}
            </div>
          </EntryParentContainer>
        </div>
      </div>
    )
  }

  const handlePlaceHolderClick = () => {
    setDisplayCommentBox(true)
    setTimeout(() => {
      ref?.current?.focus()
    }, 0)
  }

  return (
    <CommentsContainer ref={CommentsContainerRef}>
      <CommentIconContainer>
        <CommentsIcon height="16px" width="16px" />
      </CommentIconContainer>
      {AllComments && TripMembers ? (
        <FullWidthDiv>
          <CommentInputContainer>
            <MTAvatar
              displayName={user.firstName?.slice(0, 1).toUpperCase() ?? ''}
              height="32px"
              width="32px"
              customStyles={{ marginRight: '1.2rem' }}
            />
            {!displayCommentBox ? (
              <PlaceHolderInput
                onClick={handlePlaceHolderClick}
                type="text"
                placeholder="Write a comment..."
              />
            ) : (
              <FullWidthDiv>
                <div
                  className={editorStyles.editor}
                  onClick={() => {
                    ref.current!.focus()
                  }}>
                  <Editor
                    editorKey={'editor'}
                    editorState={editorState}
                    onChange={setEditorState}
                    plugins={plugins}
                    ref={ref}
                  />
                  <MentionSuggestions
                    entryComponent={Entry}
                    open={open}
                    onOpenChange={onOpenChange}
                    suggestions={suggestions}
                    onSearchChange={e => onSearchChange(e, TripMembers)}
                    onAddMention={() => {
                      // get the mention object selected
                    }}
                  />
                </div>
                <CommentButton
                  disabled={
                    commentSaving ||
                    editorState.getCurrentContent().getPlainText().trim()
                      .length < 1
                  }
                  onClick={handleSaveComment}
                  style={{
                    height: '32px',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    lineHeight: '24px',
                    marginTop: '12px',
                    backgroundColor: 'white',
                    border: '1px solid #8F8F8F',
                    borderRadius: '6px',
                    width: '86px',
                    minWidth: 'fit-content',
                    color: '#3A3A3A',
                  }}>
                  {commentSaving ? (
                    <StyledSpinner />
                  ) : (
                    <CommentText>Comment</CommentText>
                  )}
                </CommentButton>
              </FullWidthDiv>
            )}
          </CommentInputContainer>
          <hr />
          <CommentBodyContainer>
            {CommentsLoading && !AllComments && (
              <CommentLoadingText>Fetching Comments...</CommentLoadingText>
            )}
            {CommentsError && !AllComments && (
              <p>Error While Loading Comments....</p>
            )}
            {AllComments &&
              AllComments.map((comment: comment, key: number) => {
                return (
                  <Comment
                    addMention={addMention}
                    comment={comment}
                    key={key}
                    handleDeleteComment={handleDeleteComment}
                    commentDeleteLoading={commentDeleteLoading}
                    lastCommentUserId={AllComments[key - 1]?.user?.uid}
                  />
                )
              })}
          </CommentBodyContainer>
        </FullWidthDiv>
      ) : (
        <SpinnerContainer>
          <StyledSpinner />
        </SpinnerContainer>
      )}
    </CommentsContainer>
  )
}
