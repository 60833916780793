import React from 'react'

import { makeStyles } from '@material-ui/core'
import Popover from '@material-ui/core/Popover'

import { style, Container, Icon } from './colorPalette.style'
import CustomToolTip from 'components/CustomTooltip/CustomTooltip'

const useStyles = makeStyles(() => style())

type ColorPaletteProps = {
  onHandleCloseColorPalette: () => void
  colorPaletteAnchorEl: Element | null
  onUpdateItemColor: (color: string) => void
  colorUpdating: boolean
}

export const colorslist: { color: string; label: string }[] = [
  {
    color: '#4184FF',
    label: 'Blue',
  },
  {
    color: '#FC8585',
    label: 'Red',
  },
  {
    color: '#B985FC',
    label: 'Purple',
  },
  {
    color: '#85FCB5',
    label: 'Green',
  },
  {
    color: '#FCB085',
    label: 'Orange',
  },
  {
    color: '#FCF785',
    label: 'Yellow',
  },
]

const ColorPalette = ({
  onHandleCloseColorPalette,
  colorPaletteAnchorEl,
  onUpdateItemColor,
  colorUpdating
}: ColorPaletteProps) => {
  const classes = useStyles()
  const open = Boolean(colorPaletteAnchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={colorPaletteAnchorEl}
      onClose={onHandleCloseColorPalette}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      disableScrollLock
      disableRestoreFocus
      elevation={0}
      classes={{ paper: classes.paper }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}>
      <Container>
        {colorslist.map((item: { color: string; label: string }, k: number) => {
          return (
            <CustomToolTip showonClick={false} title={item.label} key={k}>
              <div
                onClick={() => !colorUpdating && onUpdateItemColor(item.color)}
                style={{
                  borderRadius: '3px',
                  height: '24px',
                  minWidth: '24px',
                  backgroundColor: `${item.color}`,
                }}
              />{' '}
            </CustomToolTip>
          )
        })}
      </Container>
    </Popover>
  )
}

export default ColorPalette
