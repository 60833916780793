import React, { useState } from 'react'

import styled from '@emotion/styled'
import { Button } from '@material-ui/core'
import ExpandMore from '@material-ui/icons/ExpandMore'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { MTAvatar } from 'components/MTAvatar'
import { isNil, isNull } from 'lodash'
import { useAppSelector } from 'store/hooks'

import { PenIcon } from 'components/MTIcons'

import { IconButtonStyled } from '../../../../NavBar/NavAuthMenu/navAuthMenu.styles'
import { ModalContainer } from '../../AuthStyles'
import EditProfileForm from './EditProfileForm'

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

export function BasicModal() {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <div>
      <Button variant="contained" onClick={handleOpen} endIcon={<PenIcon />}>
        Update
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <EditProfileForm />
        </Box>
      </Modal>
    </div>
  )
}

const Profile = () => {
  const { user } = useAppSelector(state => state.user)
  const [expand, setExpand] = useState(true)

  const capitalizeFirstLetter = (str: string | null) => {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  }

  return (
    <>
      <ModalContainer>
        <div
          style={{
            display: 'flex',
            flexFlow: 'column',
            padding: '2% 0 0 0%',
            margin: '0 10%',
            paddingBottom: !expand ? 0 : '1%',
            borderBottom: !expand ? 0 : '1px solid #e1dcdc',
          }}>
          {!isNull(user) && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                // padding: '2% 0% 0% 10%',
              }}>
              <IconButtonStyled
                style={{ paddingLeft: '0' }}
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                // onClick={handleProfileMenuOpen}
                color="inherit">
                <MTAvatar
                  fontSize={'4rem'}
                  height={'90px'}
                  width={'90px'}
                  displayName={
                    !isNil(user) && !isNull(user.firstName)
                      ? user?.firstName.substring(0, 1)
                      : ''
                  }
                  verticalAnimation={false}
                />
              </IconButtonStyled>
              <span
                style={{
                  marginLeft: '8px',
                  fontSize: '18px',
                }}>{`${capitalizeFirstLetter(
                user?.firstName,
              )} ${capitalizeFirstLetter(user?.lastName)} `}</span>
            </div>
          )}
          <Accordion
            style={{
              width: 'inherit',
              background: 'inherit',
              boxShadow: 'none',
            }}
            onChange={() => {
              setExpand(!expand)
            }}
            sx={{
              '&:before': {
                display: 'none',
              },
            }}
            defaultExpanded>
            <AccordionSummary
              style={{
                // margin: '0 10%',
                paddingLeft: 0,
                borderBottom: expand ? 0 : '1px solid #e1dcdc',
                minHeight: expand ? '10px' : 'inherit',
                height: expand ? '25px' : 'inherit',

                borderRadius: '0',
              }}
              expandIcon={<ExpandMore />}>
              <span style={{ fontFamily: 'Lato', fontSize: '18px' }}>
                <strong>Profile</strong>
              </span>
            </AccordionSummary>
            <AccordionDetails style={{ marginTop: '6px', fontSize: '12px' }}>
              <EditProfileForm />
            </AccordionDetails>
          </Accordion>
        </div>
      </ModalContainer>
    </>
  )
}

export default Profile
