import React, { useState } from 'react'

import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { CONSTANTS } from 'core/constants'

import { LocationData } from '../../layouts/MapInfo/MapInfo'
import { style } from './hoursOfOperation.style'

type Props = {
  locationData: LocationData
}

const useStyles = makeStyles(() => style())

const HoursOfOperations = ({ locationData }: Props) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.list}>
      <ListItem
        button
        onClick={handleClick}
        classes={{ gutters: classes.gutters, root: classes.root }}>
        <ListItemText primary={CONSTANTS.HOURS} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {locationData?.opening_hours?.weekday_text.map(
            (day: string, index: number) => (
              <ListItem
                key={index}
                classes={{ gutters: classes.gutters, root: classes.root }}>
                <ListItemText>{day}</ListItemText>
              </ListItem>
            ),
          )}
        </List>
      </Collapse>
    </List>
  )
}

export default HoursOfOperations
