import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch } from 'store'

type ItemColor = {
  color: string
}

type Item<TValue> = {
  [uid: string]: TValue
}

type InitialState = {
  data: Item<ItemColor>
}

const initialState: InitialState = {
  data: {},
}

type PayloadItem = {
  uid: string
  color: string
}

const slice = createSlice({
  name: 'tripItemColor',
  initialState,
  reducers: {
    _initializeTripItemColors: (
      state,
      { payload }: PayloadAction<Array<PayloadItem>>,
    ) => {
      const colorObj: Item<ItemColor> = {}
      payload.forEach((item: PayloadItem) => {
        colorObj[item.uid] = {
          color: item.color,
        }
      })
      state.data = colorObj
    },
    _addGridItemColor: (state, { payload }) => {
      const { uid, color } = payload
      const newObj = { [uid]: { color } }
      state.data = { ...state.data, ...newObj }
    },
    _updateTripItemColor: (state, { payload }) => {
      const { uid, color } = payload
      if (state.data[uid] && state.data[uid].color)
        state.data[uid].color = color
    },
    _clear: state => {
      state.data = {}
    },
  },
})

export default slice.reducer

const {
  _clear,
  _initializeTripItemColors,
  _updateTripItemColor,
  _addGridItemColor,
} = slice.actions

export const initializeTripItemColors =
  (data: Array<PayloadItem>) => (dispatch: AppDispatch) => {
    try {
      dispatch(_initializeTripItemColors(data))
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }

export const addGridItemColor =
  (data: PayloadItem) => (dispatch: AppDispatch) => {
    try {
      dispatch(_addGridItemColor(data))
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }

export const updateTripItemColor =
  (data: PayloadItem) => (dispatch: AppDispatch) => {
    try {
      dispatch(_updateTripItemColor(data))
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }

export const clearAllTripItemColors = () => (dispatch: AppDispatch) => {
  try {
    dispatch(_clear())
  } catch (error) {
    throw new Error((error as Error).message)
  }
}
