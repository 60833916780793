import groupIcon from '../MembersList/assets/down.png'

export const style = ({ colors }) => {
  return {
    container: {
      width: '100%',
      '& > form > div': {
        margin: '1rem 0',
      },
    },
    formElement: {
      fontSize: '1.6rem',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
      fontWeight: '400'
    },
    dropdownLabel: {
      marginRight: '0.8rem',
    },
    textInput: {
      width: '100%',
      height: '3em',
    },
    submitBtn: {
      backgroundColor: colors.secondaryLightBlue,
      color: colors.primaryBlue,
      fontSize: '1.6rem',
      borderRadius: '6px',
      fontWeight: 'bolder',
      textTransform: 'capitalize',
      boxShadow: 'none',
      width: '52px',
      height: '32px',
      border: 0
    },
    textInputForm: {
      width: '100%',
    },
    membersRolesDropdown: {
      fontSize: '1.5rem',
    },
    select: {
      display: 'block-content',
      appearance: 'none',
      backgroundImage: `url(${groupIcon})`,
      backgroundSize: '16px',
      backgroundPosition: 'left 5px center',
      maxHeight: '32px',
      minWidth: '90px',
      backgroundRepeat: 'no-repeat',
      textIndent:'20px',
      border: '1px solid #8F8F8F',
      lineHeight: '24px',
      borderRadius: '6px',
      fontSize: '16px',
      fontWeight: '700',
      padding: '3px 8px',
      backgroundColor: 'white',
      marginRight: '0',
      '&:focus': {
        borderRadius: '6px',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        outline: 0,
        border: '1px solid #8F8F8F',
      }
    },
    option: {
      padding: '1rem',
    },
    invitationTypeMemberDropdown: {
      borderRadius: 5,
    },
    invitationTypeDropDown: {
      '& > svg': {
        right: '-2px',
        top: 'calc(0%)',
      },
    },
    mailDropdownContainer: {
      borderRadius: '5px',
      backgroundColor: '#FFD469',
    },
    mailIcon: {
      marginLeft: 3,
      marginRight: 3,
    },
    dropdownStyle: {
      backgroundColor: 'red !important',
      width: '10rem',
    },
    emailInputForm: {
      WebkitBoxShadow: '0 0 0 1000px white inset',
      flex: 9,
      height: '32px',
      border: 0,
      fontSize: '1.6rem',
      '&:focus': {
        outline: 0,
        border: 0
      },
    },
    emailInputContainer: {
      width: '100%',
      border: '1px solid #8F8F8F',
      borderRadius: '6px',
      maxHeight: '44px'
    },
    emailInputContainerForm: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5px 12px'
    }
  }
}
