import React, { useState, useEffect, useRef } from 'react'

import ExpandMore from '@material-ui/icons/ExpandMore'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import { useAppSelector } from 'store/hooks'

import { ModalContainer } from '../../AuthStyles'
import Plans from './Plans'

const Subscription = () => {
  const [expand, setExpand] = useState(true)
  const otherPlans = useAppSelector(state => state.userPlanSlice.otherPlans)
  const myRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const redirectURL = sessionStorage.getItem('scrollToPayment')
    if (redirectURL && redirectURL === 'true') {
      myRef.current &&
        myRef.current.scrollIntoView({
          behavior: 'smooth',
        })
      // Clear the stored redirect URL
      sessionStorage.removeItem('scrollToPayment')
    }
  }, [])

  return (
    <>
      <ModalContainer>
        <div
          style={{
            display: 'flex',
            flexFlow: 'column',
            margin: '0 10%',
          }}>
          <Accordion
            style={{
              width: 'inherit',
              background: 'inherit',
              boxShadow: 'none',
              marginBottom: '0px',
            }}
            ref={myRef}
            onChange={() => {
              setExpand(!expand)
            }}
            defaultExpanded>
            <AccordionSummary
              style={{
                paddingLeft: 0,
                borderBottom: expand ? 0 : '1px solid #e1dcdc',
                marginTop: expand ? '6px' : 0,
                borderRadius: '0',
                minHeight: expand ? '16px' : 'inherit',
                height: expand ? '40px' : 'inherit',
              }}
              expandIcon={<ExpandMore />}>
              <span style={{ fontFamily: 'Lato', fontSize: '18px' }}>
                <strong>Subscription Plans</strong>
              </span>
            </AccordionSummary>
            <AccordionDetails style={{ padding: 0 }}>
                <Plans isPopup={false} otherPlans={otherPlans} />
            </AccordionDetails>
          </Accordion>
        </div>
      </ModalContainer>
    </>
  )
}

export default Subscription
