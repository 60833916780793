export type ModalTypes = {
  SIGN_UP_TO_SAVE_TRIP: 'SIGN_UP_TO_SAVE_TRIP'
  SIGN_UP_TO_INVITE_USER: 'SIGN_UP_TO_INVITE_USER'
  DELETE_ITEM: 'DELETE_ITEM'
  SIGN_UP_TO_AS_NON_ADMIN_TO_EDIT_TRIP: 'SIGN_UP_TO_AS_NON_ADMIN_TO_EDIT_TRIP'
  ACTIVATE_ACCOUNT: 'ACTIVATE_ACCOUNT'
  CREATE_GROUP: 'CREATE_GROUP'
  DOWNGRADE_PLAN: 'DOWNGRADE_PLAN'
  PURCHASE_PLAN_WARNING: 'PURCHASE_PLAN_WARNING'
  DOWNGRADE_PLAN_WARNING: 'DOWNGRADE_PLAN_WARNING'
  CONTACT_US: 'CONTACT_US'
  SUBSCRIPTION_ALREADY_CANCELLED: 'SUBSCRIPTION_ALREADY_CANCELLED'
  UNPUBLISH_A_TRIP: 'UNPUBLISH_A_TRIP'
}

export type ModalTypeKey = keyof ModalTypes

export const modalTypes: ModalTypes = {
  SIGN_UP_TO_SAVE_TRIP: 'SIGN_UP_TO_SAVE_TRIP',
  SIGN_UP_TO_INVITE_USER: 'SIGN_UP_TO_INVITE_USER',
  DELETE_ITEM: 'DELETE_ITEM',
  SIGN_UP_TO_AS_NON_ADMIN_TO_EDIT_TRIP: 'SIGN_UP_TO_AS_NON_ADMIN_TO_EDIT_TRIP',
  ACTIVATE_ACCOUNT: 'ACTIVATE_ACCOUNT',
  CREATE_GROUP: 'CREATE_GROUP',
  DOWNGRADE_PLAN: 'DOWNGRADE_PLAN',
  PURCHASE_PLAN_WARNING: 'PURCHASE_PLAN_WARNING',
  DOWNGRADE_PLAN_WARNING: 'DOWNGRADE_PLAN_WARNING',
  CONTACT_US: 'CONTACT_US',
  SUBSCRIPTION_ALREADY_CANCELLED: 'SUBSCRIPTION_ALREADY_CANCELLED',
  UNPUBLISH_A_TRIP: 'UNPUBLISH_A_TRIP'
}
