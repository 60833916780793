import React, { useState } from 'react'

import styled from '@emotion/styled'
import StopRoundedIcon from '@material-ui/icons/StopRounded'
import { MTTooltip } from 'components'
import { modalTypes } from 'components/MTDialog/types'
import { useDialog } from 'contexts/DialogContext'
import { CONSTANTS } from 'core/constants'
import { useAppSelector } from 'store/hooks'
import { sendNotification } from 'utils/toast'

import { ColorPalette } from '../ColorPalette'

type Props = {
  setFieldValue: (key: string, val: string) => void
  defaultColor: string | boolean
  canEdit: boolean
}

const StopRoundedIconStyled = styled(StopRoundedIcon)`
  cursor: pointer;
  font-size: 3.5rem;
  margin: 3px 0px 0px 2px;
  // margin-left: 2rem;
`

const ColorPicker = ({ setFieldValue, defaultColor, canEdit }: Props) => {
  const { setOpenDialog } = useDialog()
  const defaultColorParam =
    typeof defaultColor === 'string' ? defaultColor : 'white'
  const [color, setColor] = useState(defaultColorParam)
  const [colorPaletteAnchorEl, setColorPaletteAnchorEl] = useState(null)
  const { user } = useAppSelector(state => state.user)

  /**
   * Open color palette popover
   * @param {Event} event event
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onHandleOpenColorPalette = (event: any) => {
    if (!canEdit) {
      return sendNotification(CONSTANTS.CHANGE_COLOR_PERMISSION_ERROR, 'error')
    }
    setColorPaletteAnchorEl(event.currentTarget)
  }

  /**
   * Close color palette popover
   */
  const onHandleCloseColorPalette = () => {
    setColorPaletteAnchorEl(null)
  }

  /**
   * Update trip item color
   * @param {String} color trip item color
   */
  const onUpdateItemColor = async (color: string) => {
    if (user?.is_tentative && !user?.is_trip_creator) {
      return setOpenDialog({
        show: true,
        type: modalTypes.SIGN_UP_TO_AS_NON_ADMIN_TO_EDIT_TRIP,
      })
    }

    onHandleCloseColorPalette()
    setFieldValue('color', color)
    setColor(color)
  }

  return (
    <>
      <MTTooltip title="Change color">
        <StopRoundedIconStyled
          fontSize="large"
          onClick={onHandleOpenColorPalette}
          style={{ color }}
        />
      </MTTooltip>

      <ColorPalette
        onHandleCloseColorPalette={onHandleCloseColorPalette}
        colorPaletteAnchorEl={colorPaletteAnchorEl}
        onUpdateItemColor={onUpdateItemColor}
      />
    </>
  )
}

export default ColorPicker
