import React from 'react'

import { cssVariables } from 'theme'
type Props = {
  stroke?: string
  height?: string
  width?: string
}
const TagIcon = ({ stroke, height, width }: Props) => {
  return (
    <svg
      width= {width ?? '20'}
      height= {height ?? '20'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1578:5206)">
        <g clipPath="url(#clip1_1578:5206)">
          <path
            d="M0.708986 11.4404V4.56917C0.708886 4.39106 0.743894 4.21468 0.812006 4.05011C0.880118 3.88555 0.98 3.73602 1.10594 3.61008C1.23188 3.48414 1.38141 3.38426 1.54597 3.31615C1.71054 3.24803 1.88692 3.21303 2.06503 3.21313L10.2923 3.20833L15.084 8L10.2923 12.7917H2.06024C1.70219 12.7906 1.35911 12.6479 1.10593 12.3947C0.852756 12.1415 0.710051 11.7985 0.708986 11.4404V11.4404Z"
            stroke={stroke || cssVariables.colors.alternativeDarkGrey2}
            strokeWidth="1.35529"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.293 8L10.2885 8.00442"
            stroke={stroke || cssVariables.colors.alternativeDarkGrey2}
            strokeWidth="1.35529"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1578:5206">
          <rect width="16" height="16" fill="white" />
        </clipPath>
        <clipPath id="clip1_1578:5206">
          <rect
            width="16.2635"
            height="16.2635"
            fill="white"
            transform="translate(17 8) rotate(135)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default TagIcon
