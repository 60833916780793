import React, { useEffect } from 'react'

import { isNull } from 'lodash'
import mixpanel from 'mixpanel-browser'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAppSelector, useGeolocation, CoordsObject } from 'store/hooks'
import { setUserPlans } from 'store/slices/userPlanSlice'
import { paddleCheckout } from 'utils/paddle'
import { sendNotification } from 'utils/toast'

import { verifyCustomer, getUserPlans } from 'api/userPlans'

import { ModalContainer } from '../AuthStyles'
import Profile from './profile/profile'
import Footer from './SharedFooter'
import Subscription from './subscription/Subscription'

const ProfileSection = () => {
  const history = useHistory()
  const { user } = useAppSelector(state => state.user)
  const otherPlans = useAppSelector(state => state.userPlanSlice.otherPlans)
  const dispatch = useDispatch()
  const coordinates = useAppSelector(state => state.geoLocation)

  sessionStorage.setItem(
    'updatePaymentDetailsURL',
    window.location.pathname + window.location.search,
  )

  if (isNull(user?.uid)) {
    history.push('/create-trip')
    return null
  }

  useEffect(() => {
    const fetchData = async () => {
      const urlParams = new URLSearchParams(window.location.search)
      const transactionId = urlParams.get('_ptxn')

      if (transactionId) {
        try {
          // verifyCustomer
          const foundCustomer = await verifyCustomer({
            loggedInUserId: user.uid,
            transactionId,
          })

          if (foundCustomer) {
            paddleCheckout(
              null, // planName
              null, // planUid
              null, // userUid
              otherPlans,
              null, // paddlePriceId
              history,
              dispatch, // dispatch
              user.email, // email
              user.firstName+' '+user.lastName,
              transactionId,
              false,
              false,
              coordinates,
            )
          } else {
            sessionStorage.removeItem('updatePaymentDetailsURL')
            sendNotification('Invalid user!', 'error')
          }
        } catch (error) {
          console.error('Error:', error)
          // Handle error here
        }
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (user.uid) {
      mixpanel.track_pageview({
        page: '/profile',
        $latitude: coordinates?.latitude,
        $longitude: coordinates?.longitude,
      })
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = {
          user_uid: user.uid,
        }
        const result = await getUserPlans(data)
        await dispatch(setUserPlans(result))
      } catch (error) {
        console.error('Error fetching user plans:', error)
        sendNotification('Oops! Something went wrong.', 'error')
      }
    }
    fetchData()
  }, [])

  return (
    <>
      <ModalContainer style={{ marginBottom: '10px', height: 'fit-content' }}>
        <Profile />
        <Subscription />
      </ModalContainer>
      <Footer />
    </>
  )
}

export default ProfileSection
