import React from 'react'

import { cssVariables } from 'theme'

const ColorIcon = ({ fill }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke={fill || cssVariables.colors.alternativeDarkGrey2}
      xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="14" height="14" rx="4" fill={fill} />
    </svg>
  )
}

export default ColorIcon
