import moment from 'moment'

type UpdateDateProps = {
  formikKey: string
  startDate: Date
  endDate: Date
  date: Date
  setFieldValue: (key: string, val: Date) => void
  setStartDateVisual: (date: Date) => void
  setEndDateVisual: (date: Date) => void
}

/**
 * Update start and end date. Pass updated value back to formik
 * and visually display date to the user.
 *
 * @param { Date } dateBeingUpdated new date selected
 * @param { String } formikKey formik date key
 * @param { Function } setFieldValue Formik callback
 * @param { Date } startDate start date
 * @param { Date } endDate end date
 * @param { Function } setStartDateVisual Local start date state setter
 * @param { Function } setEndDateVisual Local end date state setter
 */
export const updateDates = ({
  date,
  formikKey,
  startDate,
  endDate,
  setFieldValue,
  setStartDateVisual,
  setEndDateVisual,
}: UpdateDateProps) => {
  if (formikKey === 'startDate') {
    const momentStart = moment(date)
    const momentEnd = moment(endDate)

    setFieldValue(formikKey, momentStart.toDate())
    setStartDateVisual(momentStart.toDate())

    // if new starting date is later than end date,
    // move end date to be the same day as new start date
    if (
      momentStart.isValid() &&
      momentEnd.isValid() &&
      momentStart.isAfter(momentEnd)
    ) {
      const differenceInDays = momentStart.diff(momentEnd, 'days') + 1
      const incrimentEndDateByNDays = momentEnd.add(differenceInDays, 'day')

      setFieldValue('endDate', incrimentEndDateByNDays.toDate())
      setEndDateVisual(incrimentEndDateByNDays.toDate())
    }
  }

  if (formikKey === 'endDate') {
    const momentStart = moment(startDate)
    const momentEnd = moment(date)

    setFieldValue(formikKey, momentEnd.toDate())
    setEndDateVisual(momentEnd.toDate())

    // if new ending date is earlier than start date,
    // move start date to be the same date as new end date
    if (
      momentStart.isValid() &&
      momentEnd.isValid() &&
      momentEnd.isBefore(startDate)
    ) {
      const differenceInDays = momentStart.diff(momentEnd, 'days') + 1
      const decrimentStartDateByNDays = momentStart.subtract(
        differenceInDays,
        'day',
      )
      setFieldValue('startDate', decrimentStartDateByNDays.toDate())
      setStartDateVisual(decrimentStartDateByNDays.toDate())
    }
  }
}
