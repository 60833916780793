import React from 'react'
import Community from './Community'

export default function CommunityPage() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center', paddingTop: '56px', backgroundColor: '#ffffff'}}>
      <div style={{ width: '90%', maxWidth: '1258px' }}>
        <Community />
      </div>
    </div>
  )
}
