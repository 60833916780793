import React, { useState } from 'react'

import {
  LocationMarkerInfoContainer,
  LocationMarkerStyled,
} from './locationMarker.styles'

export type MarkerPlaceData = {
  placeId: string
  lat: number
  lng: number
}

type LocationMarkerType = {
  handleClick?: (val: MarkerPlaceData) => void
  placeIsPartOfTrip?: boolean | undefined
  planningBoardLocationItemColor: string | undefined
  showMarkerMapInfo?: boolean | null
  placeTitle?: string | null
  uid?: string | undefined
  planningBoardOnly?: boolean | undefined
  locationItemColor?: string | undefined
  placeId?: string
  lat: number
  lng: number
  iconIsClickable?: boolean
  selected?: boolean
}

const LocationMarker = ({
  handleClick,
  placeIsPartOfTrip = undefined,
  planningBoardLocationItemColor,
  placeTitle = null,
  locationItemColor,
  placeId,
  lat,
  lng,
  iconIsClickable = true,
  selected = false
}: LocationMarkerType) => {
  const [showMarkerMapInfoState, setShowMarkerMapInfoState] = useState(false)
  return (
    <div>
      <LocationMarkerStyled
        selected={selected}
        onMouseEnter={() => setShowMarkerMapInfoState(true)}
        onMouseLeave={() => setShowMarkerMapInfoState(false)}
        style={{
          fill: placeIsPartOfTrip
            ? locationItemColor
            : planningBoardLocationItemColor
            ? planningBoardLocationItemColor
            : '#7C7C7C',
          cursor: 'pointer',
          stroke: showMarkerMapInfoState ? 'white' : 'none',
          strokeWidth: showMarkerMapInfoState ? '1.5px' : '0',
          strokeLinejoin: showMarkerMapInfoState ? 'round' : 'unset',
        }}
        onClick={() => {
          if (iconIsClickable && handleClick && placeId)
            handleClick({ placeId, lat, lng })
        }}
      />
      {showMarkerMapInfoState && placeTitle && (
        <LocationMarkerInfoContainer>{placeTitle}</LocationMarkerInfoContainer>
      )}
    </div>
  )
}

export default LocationMarker
