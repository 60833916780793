import React from 'react'

import { cssVariables } from 'theme'

type IconProp = {
  stroke?: string
  height?: string
  width?:string
}
const XIcon = ({ stroke, height, width }: IconProp) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? '20'}
      height={height ?? '20'}
      viewBox="0 0 24 24"
      fill="none"
      stroke={stroke || cssVariables.colors.alternativeDarkGrey2}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-x">
      <line x1="18" y1="6" x2="6" y2="18"></line>
      <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
  )
}

export default XIcon
