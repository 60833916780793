import { request } from '../utils/request'

export const create = async data => {
    return await request({
        url: '/api/trip-location-item/create',
        method: 'post',
        data,
    })
}

export const update = async(uid, data) => {
    return await request({
        url: `/api/trip-location-item/${uid}`,
        method: 'put',
        data,
    })
}

export const getSignedURL = async data => {
    return await request({
        url: '/api/trip-location-item/doc',
        method: 'get',
        searchParams: data,
    })
}

export const findOne = async data => {
    return request({
        url: '/api/trip-location-item/:uid',
        method: 'get',
        searchParams: data,
    })
}

export const remove = async data => {
    return await request({
        url: '/api/trip-location-item/remove',
        method: 'delete',
        data: { uid: data.uid, ...data },
    })
}

export const updateOrder = async data => {
    return await request({
        url: `/api/trip-location-item/update-order/${data.uid}`,
        method: 'put',
        data,
    })
}