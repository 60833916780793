import React, { CSSProperties } from 'react'

import { makeStyles, Grid } from '@material-ui/core'
import { MTButton } from 'components/MTButton'
import { CONSTANTS } from 'core/constants'
import { isScreenSize } from 'utils/screenSzie'

import {
  SignUpToSaveYourTrip,
  SignUpToInviteUser,
  DeleteItem,
  DowngradePlan,
  DowngradePlanWarning,
  PurchasePlanWarning,
  ContactUs,
  SubscriptionAlreadyCancelled,
  UnpublishTrip,
} from './DialogFooter'
import { dialogContent } from './helper'
import {
  style,
  Header,
  HeaderTitle,
  HR,
  Body,
  DialogStyled,
} from './mTDialog.style'
import { ModalTypeKey, modalTypes } from './types'
import { useHistory } from 'react-router'
import { useWindowSize } from 'hooks/useWindowSize'

type MTDialogProps = {
  openDialog: boolean
  type: ModalTypeKey
  itemToBeDeleted?: string
  isTripCreator?: boolean
  customStyle?: CSSProperties
  onCloseModal: () => void
  onSkipAndContinueClickHandler?: () => void
  onDeleteTripClickHandler?: () => void
  onSignUpClickHandler?: () => void
  handleCancelSubscription?: () => void
  onSignInClickHandler?: () => void
  onCancelClickHandler?: () => void
  selectedReason?: number | null
  otherReasonText?: React.MutableRefObject<string>
  handleCheckboxChange?: (value: number) => void
  setOpenDialog?: (value: boolean) => void
  setOpenDowngradeWarningDialog?: (value: boolean) => void
  setOpenPurchaseWarningDialog?: (value: boolean) => void
  setContactUsDialog?: (value: boolean) => void
  setSubscriptionAlreadyCancelledDialog?: (value: boolean) => void
  subscriptionDate?: string
  setOpenPaddleCheckoutFlag?: (value: boolean) => void
  setOpenPaddleCheckoutPlan?: (value: string | null | undefined) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headerIcon?: React.ReactNode
  isDowngrading?: boolean
  loading?: boolean
}

const MTDialog = (props: MTDialogProps) => {
  const extraSmallScreen = isScreenSize(350)
  const smallScreen = isScreenSize(500)
  const styledProps = {
    padding: extraSmallScreen ? 15 : 25,
    margin: extraSmallScreen ? '15px' : '32px',
  }
  const useStyles = makeStyles(() => style(styledProps))
  const history = useHistory()
  const { width } = useWindowSize()
  //manually setting the props as they dont require a context anymore
  /**
   * Open sign up modal
   */
  const onSignInClickHandler = () => {
    history.push('/signin')
  }

  /**
   * Open sign up modal
   */
  const onSignUpClickHandler = () => {
    history.push('/signup')
  }
  props = { ...props, onSignInClickHandler, onSignUpClickHandler }

  const DonwngradePlanBody = () => {
    return (
      <div className="mt-3">
        <strong>{CONSTANTS.DOWNGRADE_HEADER}</strong>
        <div className="mt-3">
          {CONSTANTS.DOWNGRADE_REASONS.map((reason, index) => (
            <div key={index}>
              <Grid container alignItems="center" spacing={2}>
                <Grid style={{ flexBasis: '0', paddingRight: '0' }} item xs={1}>
                  <input
                    style={{ height: '17px', width: '17px', marginTop: '3px' }}
                    type="checkbox"
                    id={`reason_${index}`}
                    checked={index === props.selectedReason}
                    onChange={() =>
                      props.handleCheckboxChange &&
                      props.handleCheckboxChange(index)
                    }
                  />
                </Grid>
                <Grid item xs={11}>
                  <label
                    style={{ marginBottom: '4px' }}
                    htmlFor={`reason_${index}`}>
                    {reason}
                  </label>
                </Grid>
              </Grid>
            </div>
          ))}
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <input
              type="text"
              disabled={props.selectedReason === 5 ? false : true}
              onChange={e => {
                if (props.otherReasonText) {
                  props.otherReasonText.current = e.target.value
                }
              }}
              style={{
                width: '100%',
                padding: '10px',
                borderRadius: '7px',
                boxSizing: 'border-box',
              }}
              placeholder={
                smallScreen
                  ? 'Other reasons'
                  : CONSTANTS.DOWNGRADE_REASON_OTHER_PLACEHOLDER
              }
            />
          </Grid>
        </Grid>
        <br />
        <p>{CONSTANTS.DOWNGRADE_CONFIRMATION_SUFFIX}</p>
        <p>{CONSTANTS.DOWNGRADE_CONFIRMATION_SUFFIX2}</p>
      </div>
    )
  }

  const ContactUsDialogBody = () => {
    return (
      <div className="pt-3">
        <span>
          If you wish to cancel your <strong>Globe-Trotter Plan</strong>, please
          reach out to us at{' '}
          <a href="support@mitravelapp.com">support@mitravelapp.com</a>. Your
          subscription is set to renew on {props.subscriptionDate}.
        </span>
      </div>
    )
  }

  const SubscriptionAlreadyCancelledDialogBody = () => {
    return (
      <div className="pt-3">
        <span>
          Your subscription is already cancelled and will take effect on{' '}
          {props.subscriptionDate} . If you need support, please contact us at{' '}
          <a href="support@mitravelapp.com">support@mitravelapp.com</a>
        </span>
      </div>
    )
  }

  const {
    openDialog,
    type,
    itemToBeDeleted = null,
    onCloseModal,
    isTripCreator = false,
    customStyle,
  } = props

  const classes = useStyles()

  const {
    title,
    body,
    headerIcon,
    closeButtonIcon,
    closeButtonStyle,
    redButtonStyle,
    blueButtonStyle,
    transparentButtonStyle,
  } = dialogContent(type) || {}

  const onRenderFooterByType = () => {
    let footer = null

    switch (type) {
      case modalTypes.SIGN_UP_TO_SAVE_TRIP:
        footer = (
          <SignUpToSaveYourTrip
            {...props}
            onSkipAndContinueClickHandler={onCloseModal}
            blueButtonStyle={blueButtonStyle}
            transparentButtonStyle={transparentButtonStyle}
            isTripCreator={isTripCreator}
          />
        )
        break
      case modalTypes.SIGN_UP_TO_AS_NON_ADMIN_TO_EDIT_TRIP:
        footer = (
          <SignUpToInviteUser
            {...props}
            blueButtonStyle={blueButtonStyle}
            transparentButtonStyle={transparentButtonStyle}
          />
        )
        break
      case modalTypes.SIGN_UP_TO_INVITE_USER:
        footer = (
          <SignUpToInviteUser
            {...props}
            blueButtonStyle={blueButtonStyle}
            transparentButtonStyle={transparentButtonStyle}
          />
        )
        break
      case modalTypes.DELETE_ITEM:
        footer = (
          <DeleteItem
            {...props}
            redButtonStyle={redButtonStyle}
            loading={props.loading}
            transparentButtonStyle={transparentButtonStyle}
          />
        )
        break
      case modalTypes.UNPUBLISH_A_TRIP:
        footer = (
          <UnpublishTrip
            {...props}
            redButtonStyle={redButtonStyle}
            loading={props.loading}
            transparentButtonStyle={transparentButtonStyle}
          />
        )
        break
      case modalTypes.CREATE_GROUP:
        footer = (
          <DeleteItem
            {...props}
            loading={props.loading}
            redButtonStyle={redButtonStyle}
            transparentButtonStyle={transparentButtonStyle}
          />
        )
        break
      case modalTypes.DOWNGRADE_PLAN:
        footer = (
          <DowngradePlan
            {...props}
            redButtonStyle={redButtonStyle}
            transparentButtonStyle={transparentButtonStyle}
          />
        )
        break
      case modalTypes.PURCHASE_PLAN_WARNING:
        footer = (
          <PurchasePlanWarning
            {...props}
            blueButtonStyle={blueButtonStyle}
            transparentButtonStyle={transparentButtonStyle}
          />
        )
        break
      case modalTypes.DOWNGRADE_PLAN_WARNING:
        footer = (
          <DowngradePlanWarning
            {...props}
            redButtonStyle={redButtonStyle}
            transparentButtonStyle={transparentButtonStyle}
          />
        )
        break
      case modalTypes.CONTACT_US:
        footer = (
          <ContactUs
            {...props}
            transparentButtonStyle={transparentButtonStyle}
          />
        )
        break
      case modalTypes.SUBSCRIPTION_ALREADY_CANCELLED:
        footer = (
          <SubscriptionAlreadyCancelled
            {...props}
            transparentButtonStyle={transparentButtonStyle}
          />
        )
        break
      default:
        break
    }
    return footer
  }

  return (
    <>
      {type && (
        <DialogStyled
          open={openDialog}
          onClose={onCloseModal}
          style={customStyle}
          classes={{
            paperScrollPaper: classes.paperScrollPaper,
            paper: classes.container,
            paperWidthSm:
              width < 700
                ? classes.paperCustomWidthLgMobile
                : classes.paperWidthSm,
          }}>
          <Header style={{ display: 'inherit' }}>
            {headerIcon}
            <HeaderTitle
              style={{
                fontSize: '2rem',
              }}>
              {title}
            </HeaderTitle>
            <MTButton
              customStyles={closeButtonStyle}
              onHandleClick={onCloseModal}
              customIcon={closeButtonIcon ? closeButtonIcon : ''}
            />
          </Header>
          <HR></HR>
          <Body>
            {(() => {
              switch (type) {
                case modalTypes.DOWNGRADE_PLAN:
                  return <DonwngradePlanBody />
                case modalTypes.CONTACT_US:
                  return <ContactUsDialogBody />
                case modalTypes.SUBSCRIPTION_ALREADY_CANCELLED:
                  return <SubscriptionAlreadyCancelledDialogBody />
                default:
                  return dialogContent(type).body(itemToBeDeleted)
              }
            })()}
          </Body>
          {onRenderFooterByType()}
        </DialogStyled>
      )}
    </>
  )
}

export default MTDialog
