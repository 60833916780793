export const style = ({ colors }) => {
  return {
    container: {
      borderRadius: 10,
      padding: '0.35rem',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: 'fit-content',
      marginLeft: '5px',
      transition: 'background-color .15s linear',
      '&:hover': {
        backgroundColor: '#f9f9f9',
        cursor: 'pointer',
      },
    },
    memberName: {
      marginLeft: 10,
      fontSize: '1.4rem',
      marginBlockStart: '0',
      marginBlockEnd: '0',
      color: colors.secondaryDarkGrey,
    },
    clearIcon: {
      color: colors.secondaryDarkGrey,
      marginLeft: 3,
      fontSize: '1.2rem',
    },
  }
}
