import styled from '@emotion/styled'
import { Dialog } from '@material-ui/core'

export const Header = styled.div`
  display: grid;
  grid-template-columns: 85% 15%;
  align-items: center;
  justify-content: flex-start;
`

export const HeaderTitle = styled.div`
  font-size: 2rem;
  margin-bottom: 0;
  font-weight: 700;
  font-family: Lato;
  margin-left: 1rem;
`

export const HR = styled.div`
  border-width: 1px;
  border-style: solid;
  border-color: #afafaf;
  width: 100%;
  margin: 10px 0 10px 0;
`

export const Body = styled.div`
  font-size: 1.7em;
`
export const DialogStyled = styled(Dialog)``

interface Props {
  padding: number
  margin: string
}

export const style = ({ padding, margin }: Props) => {
  return {
    container: {
      padding,
      borderRadius: 12,
    },
    paperWidthSm: {
      margin,
      maxWidth: 500,
    },
    paperScrollPaper: {
      justifyContent: 'space-around',
    },
    paperWidthLg: {
      maxWidth: '60%',
    },
    paperCustomWidthLgMobile: {
      maxWidth: '100%',
      minWidth: '100%',
      position: 'absolute',
      bottom: 0,
      margin:0,
      borderRadius: '12px 12px 0 0'
    },
    paperCustomWidthLg: {
      maxWidth: '70%'
    },
  }
}
