import { request } from '../utils/request'

export const register = async data => {
  return await request({
    url: '/api/auth/register',
    method: 'post',
    data,
  })
}

export const login = async data => {
  return await request({
    url: '/api/auth/login',
    method: 'post',
    data,
  })
}

export const googleUser = async email => {
  return await request({
    url: '/api/google/:email',
    method: 'get',
    searchParams: { email },
  })
}

export const activate = async data => {
  return await request({
    url: '/api/auth/activate',
    method: 'post',
    data,
  })
}

export const resetPassword = async data => {
  return await request({
    url: '/api/auth/send_reset_email',
    method: 'post',
    data,
  })
}

export const verifyResetPassword = async data => {
  return await request({
    url: '/api/auth/verify_password_reset',
    method: 'post',
    data,
  })
}

export const confirmPasswordReset = async data => {
  return await request({
    url: '/api/auth/confirm_password_reset',
    method: 'post',
    data,
  })
}
