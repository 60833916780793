import { createSlice } from '@reduxjs/toolkit'
import {
  LocationTripGroupItem,
  NoteTripGroupItem,
  PollTripGroupItem,
  TripGroup,
} from 'pages/Workspace/layouts/PlanningBoard/types'

import type { AppDispatch } from '../index'

type TripGroupsMapObject = {
  tripGroups: Array<TripGroup>
  filteredTripGroups: Array<TripGroup>
  newlyCreatedItem: NoteTripGroupItem | PollTripGroupItem | null
  newlyCreatedGroup: TripGroup | null
  //workspaceTabIndex: number
}

type TripGroupsMapState = TripGroupsMapObject

const initialState: TripGroupsMapState = {
  tripGroups: [],
  filteredTripGroups: [],
  newlyCreatedItem: null,
  newlyCreatedGroup: null,
  //workspaceTabIndex: 0,
}

const slice = createSlice({
  name: 'tripGroups',
  initialState,
  reducers: {
    _loadTripGroups: (state, { payload }) => {
      state.tripGroups = payload
    },
    _setFilteredTripGroups: (state, { payload }) => {
      state.filteredTripGroups = payload
    },
    _updateTripGroup: (state, { payload }) => {
      const { uid, title, color, order, id, created_by, tripItems } = payload
      const objIndex = state.tripGroups.findIndex(loc => loc.uid === uid)
      state.tripGroups[objIndex].title = title
      state.tripGroups[objIndex].order = order
      state.tripGroups[objIndex].color = color
      state.tripGroups[objIndex].id = id
      state.tripGroups[objIndex].created_by = created_by
      state.tripGroups[objIndex].tripItems = tripItems

      const filteredObjIndex = state.filteredTripGroups.findIndex(
        loc => loc.uid === uid,
      )
      if (state.filteredTripGroups[filteredObjIndex]) {
        state.filteredTripGroups[filteredObjIndex].title = title
        state.filteredTripGroups[filteredObjIndex].order = order
        state.filteredTripGroups[filteredObjIndex].color = color
        state.filteredTripGroups[filteredObjIndex].id = id
        state.filteredTripGroups[filteredObjIndex].created_by = created_by
        state.filteredTripGroups[filteredObjIndex].tripItems = tripItems
      }
    },

    _addItemInTripGroup: (state, { payload }) => {
      const { groupUid, tripItem } = payload
      const objIndex = state.tripGroups.findIndex(
        group => group.uid === groupUid,
      )
      const newItem = { ...tripItem }
      newItem.id = 'tripItem' + (Math.floor(Math.random() * 999) + 1)
      if (state.tripGroups[objIndex]) {
        state.tripGroups[objIndex].tripItems = [
          ...state.tripGroups[objIndex].tripItems,
          newItem,
        ]
      }

      const filteredObjIndex = state.filteredTripGroups.findIndex(
        group => group.uid === groupUid,
      )
      if (state.filteredTripGroups[filteredObjIndex]) {
        state.filteredTripGroups[filteredObjIndex].tripItems = [
          ...state.filteredTripGroups[filteredObjIndex].tripItems,
          newItem,
        ]
      }
      //set newly created Item
      state.newlyCreatedItem = tripItem
    },

    _removeItemFromTripGroup: (state, { payload }) => {
      const { groupUid, tripItem } = payload
      const objIndex = state.tripGroups.findIndex(
        group => group.uid === groupUid,
      )
      const filteredObjIndex = state.filteredTripGroups.findIndex(
        group => group.uid === groupUid,
      )

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const allItems: any = []
      state.tripGroups[objIndex].tripItems.forEach(singleItem => {
        if (singleItem.uid !== tripItem.uid) {
          allItems.push(allItems)
        }
      })
      state.tripGroups[objIndex].tripItems = allItems
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const allFilteredItems: any = []
      if (state.filteredTripGroups[filteredObjIndex]) {
        state.filteredTripGroups[filteredObjIndex].tripItems.forEach(
          singleItem => {
            if (singleItem.uid !== tripItem.uid) {
              allFilteredItems.push(allFilteredItems)
            }
          },
        )
        state.filteredTripGroups[filteredObjIndex].tripItems = allFilteredItems
      }
    },

    _updateColorOfTripGroupItem: (state, { payload }) => {
      const { groupUid, tripItemUid, color } = payload
      const objIndex = state.tripGroups.findIndex(
        group => group.uid === groupUid,
      )
      const filteredObjIndex = state.filteredTripGroups.findIndex(
        group => group.uid === groupUid,
      )
      const ObjItemIndex = state.tripGroups[objIndex].tripItems.findIndex(
        loc => loc.uid === tripItemUid,
      )
      const FilteredObjItemIndex = state.filteredTripGroups[
        filteredObjIndex
      ]?.tripItems?.findIndex(loc => loc?.uid === tripItemUid)

      let tempOpt = {}
      if (state.tripGroups[objIndex].tripItems[ObjItemIndex].type === 'note') {
        tempOpt = (
          state.tripGroups[objIndex].tripItems[
            ObjItemIndex
          ] as NoteTripGroupItem
        ).trip_note_item_metadata.color = color
      }
      if (
        state.filteredTripGroups[filteredObjIndex]?.tripItems[
          FilteredObjItemIndex
        ]?.type === 'note'
      ) {
        tempOpt = (
          state.filteredTripGroups[filteredObjIndex]?.tripItems[
            FilteredObjItemIndex
          ] as NoteTripGroupItem
        ).trip_note_item_metadata.color = color
      }
      if (state.tripGroups[objIndex].tripItems[ObjItemIndex].type === 'poll') {
        tempOpt = (
          state.tripGroups[objIndex].tripItems[
            ObjItemIndex
          ] as PollTripGroupItem
        ).trip_poll_item_metadata.color = color
      }
      if (
        state.filteredTripGroups[filteredObjIndex]?.tripItems[
          FilteredObjItemIndex
        ]?.type === 'poll'
      ) {
        tempOpt = (
          state.filteredTripGroups[filteredObjIndex]?.tripItems[
            FilteredObjItemIndex
          ] as PollTripGroupItem
        ).trip_poll_item_metadata.color = color
      }
      if (
        state.tripGroups[objIndex].tripItems[ObjItemIndex].type === 'location'
      ) {
        tempOpt = (
          state.tripGroups[objIndex].tripItems[
            ObjItemIndex
          ] as LocationTripGroupItem
        ).trip_location_item_metadata.color = color
      }
      if (
        state.filteredTripGroups[filteredObjIndex]?.tripItems[
          FilteredObjItemIndex
        ]?.type === 'location'
      ) {
        tempOpt = (
          state.filteredTripGroups[filteredObjIndex]?.tripItems[
            FilteredObjItemIndex
          ] as LocationTripGroupItem
        ).trip_location_item_metadata.color = color
      }
    },

    _updateCommentCountOfTripItem: (state, { payload }) => {
      const { groupUid, tripItemUid, opt } = payload
      const objIndex = state.tripGroups.findIndex(
        group => group.uid === groupUid,
      )
      const filteredObjIndex = state.filteredTripGroups.findIndex(
        group => group.uid === groupUid,
      )
      const ObjItemIndex = state.tripGroups[objIndex].tripItems.findIndex(
        loc => loc.uid === tripItemUid,
      )
      const FilteredObjItemIndex = state.filteredTripGroups[
        filteredObjIndex
      ]?.tripItems?.findIndex(loc => loc?.uid === tripItemUid)

      let tempOpt = {}
      if(opt == 'add'){
        tempOpt = (
          state.filteredTripGroups[filteredObjIndex]?.tripItems[
            FilteredObjItemIndex
          ]
        ).commentCount = state.filteredTripGroups[filteredObjIndex]?.tripItems[FilteredObjItemIndex].commentCount + 1
      }

      else if(opt == 'delete'){
        tempOpt = (
          state.filteredTripGroups[filteredObjIndex]?.tripItems[
            FilteredObjItemIndex
          ]
        ).commentCount = state.filteredTripGroups[filteredObjIndex]?.tripItems[FilteredObjItemIndex].commentCount - 1
      }
    },

    _updateIdOfTripGroupItem: (state, { payload }) => {
      const { groupUid, tripItem } = payload
      // Find index of the group in both `tripGroups` and `filteredTripGroups`
      const tripGroupIndex = state.tripGroups.findIndex(loc => loc.uid === groupUid)
      const filteredTripGroupIndex = state.filteredTripGroups.findIndex(loc => loc.uid === groupUid)

      // If group is found in `tripGroups`
      if (tripGroupIndex !== -1) {
        const { tripItems } = state.tripGroups[tripGroupIndex]

        // Find index of the tripItem
        const tripItemIndex = tripItems.findIndex(item => item.uid === tripItem.uid)

        if (tripItemIndex !== -1) {
          // Preserve the original ID and update the trip item
          const originalId = tripItems[tripItemIndex].id
          tripItems[tripItemIndex] = { ...tripItem, id: originalId }

          // If group is also found in `filteredTripGroups`
          if (filteredTripGroupIndex !== -1) {
            const filteredTripItems = state.filteredTripGroups[filteredTripGroupIndex].tripItems

            // Update the corresponding trip item in `filteredTripGroups`
            const filteredTripItemIndex = filteredTripItems.findIndex(item => item.uid === tripItem.uid)
            if (filteredTripItemIndex !== -1) {
              filteredTripItems[filteredTripItemIndex] = { ...tripItem, id: originalId }
            }
          }
        }
      }
    },

    _castVoteOfTripGroupPollItem: (state, { payload }) => {
      const { groupUid, tripItemUid, updatedOption } = payload
      const objIndex = state.tripGroups.findIndex(loc => loc.uid === groupUid)
      const filteredObjIndex = state.filteredTripGroups.findIndex(
        loc => loc.uid === groupUid,
      )
      const ObjItemIndex = state.tripGroups[objIndex].tripItems.findIndex(
        loc => loc.uid === tripItemUid,
      )
      const filteredObjItemIndex = state.filteredTripGroups[
        filteredObjIndex
      ].tripItems.findIndex(loc => loc.uid === tripItemUid)

      const ObjItemOptionIndex = (
        state.tripGroups[objIndex].tripItems[ObjItemIndex] as PollTripGroupItem
      ).trip_poll_item_options.findIndex(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (opt: any) => opt.uid === updatedOption.uid,
      )

      const filteredObjItemOptionIndex = (
        state.filteredTripGroups[filteredObjIndex].tripItems[
          filteredObjItemIndex
        ] as PollTripGroupItem
      ).trip_poll_item_options.findIndex(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (opt: any) => opt.uid === updatedOption.uid,
      )

      //This tempOpt is just to stop auto-formatting.
      const tempOpt = ((
        state.tripGroups[objIndex].tripItems[ObjItemIndex] as PollTripGroupItem
      ).trip_poll_item_options[ObjItemOptionIndex] = updatedOption)

      const filteredTempOpt = ((
        state.filteredTripGroups[filteredObjIndex].tripItems[
          filteredObjItemIndex
        ] as PollTripGroupItem
      ).trip_poll_item_options[filteredObjItemOptionIndex] = updatedOption)
    },

    _addOPtionsToTripGroupPollItem: (state, { payload }) => {
      const { groupUid, tripItemUid, updatedOption } = payload
      const objIndex = state.tripGroups.findIndex(loc => loc.uid === groupUid)
      const filteredObjIndex = state.filteredTripGroups.findIndex(
        loc => loc.uid === groupUid,
      )
      const ObjItemIndex = state.tripGroups[objIndex].tripItems.findIndex(
        loc => loc.uid === tripItemUid,
      )
      const filteredObjItemIndex = state.filteredTripGroups[
        filteredObjIndex
      ].tripItems.findIndex(loc => loc.uid === tripItemUid)

      //This temp is just to stop auto-formatting.
      const temp = ((
        state.tripGroups[objIndex].tripItems[ObjItemIndex] as PollTripGroupItem
      ).trip_poll_item_options = updatedOption)
      //This temp2 is just to stop auto-formatting.
      const temp2 = ((
        state.filteredTripGroups[filteredObjIndex].tripItems[
          filteredObjItemIndex
        ] as PollTripGroupItem
      ).trip_poll_item_options = updatedOption)
    },

    _removeOPtionFromTripGroupPollItem: (state, { payload }) => {
      const { groupUid, tripItemUid, optionToBeRemoved } = payload

      const objIndex = state.tripGroups.findIndex(loc => loc.uid === groupUid)
      const filteredObjIndex = state.filteredTripGroups.findIndex(
        loc => loc.uid === groupUid,
      )
      const ObjItemIndex = state.tripGroups[objIndex].tripItems.findIndex(
        loc => loc.uid === tripItemUid,
      )
      const filteredObjItemIndex = state.filteredTripGroups[
        filteredObjIndex
      ].tripItems.findIndex(loc => loc.uid === tripItemUid)

      //This temp is just to stop auto-formatting.
      const updatedOptions = (
        state.tripGroups[objIndex].tripItems[ObjItemIndex] as PollTripGroupItem
      ).trip_poll_item_options.filter(option => {
        return option.uid !== optionToBeRemoved.uid
      })
      const updatedFilteredGroupOptions = (
        state.filteredTripGroups[filteredObjIndex].tripItems[
          filteredObjItemIndex
        ] as PollTripGroupItem
      ).trip_poll_item_options.filter(option => {
        return option.uid !== optionToBeRemoved.uid
      })
      const temp = ((
        state.tripGroups[objIndex].tripItems[ObjItemIndex] as PollTripGroupItem
      ).trip_poll_item_options = updatedOptions)
      //This temp2 is just to stop auto-formatting.
      const temp2 = ((
        state.filteredTripGroups[filteredObjIndex].tripItems[
          filteredObjItemIndex
        ] as PollTripGroupItem
      ).trip_poll_item_options = updatedFilteredGroupOptions)
    },

    _deleteTripGroup: (state, { payload }) => {
      const { uid } = payload
      state.tripGroups = state.tripGroups.filter(obj => obj.uid !== uid)
      state.filteredTripGroups = state.filteredTripGroups.filter(
        obj => obj.uid !== uid,
      )
    },
    _addTripGroup: (state, { payload }) => {
      const { uid, title, color, order, id, short_uid, created_by } = payload
      const newTripGroup: TripGroup = {
        uid: uid,
        short_uid: short_uid,
        title: title,
        color: color,
        order: order,
        created_by: created_by,
        id: id,
        tripItems: [],
      }
      state.tripGroups = [...state.tripGroups, newTripGroup]
      state.filteredTripGroups = [...state.filteredTripGroups, newTripGroup]
      //set newly created group
      state.newlyCreatedGroup = newTripGroup
    },

    _unsetNewlyCreatedItem: state => {
      state.newlyCreatedItem = null
    },
    _unsetNewlyCreatedGroup: state => {
      state.newlyCreatedGroup = null
    },
  },
})

export default slice.reducer

const {
  _loadTripGroups,
  _setFilteredTripGroups,
  _updateTripGroup,
  _deleteTripGroup,
  _addTripGroup,
  _updateColorOfTripGroupItem,
  _updateCommentCountOfTripItem,
  _castVoteOfTripGroupPollItem,
  _addOPtionsToTripGroupPollItem,
  _removeOPtionFromTripGroupPollItem,
  _addItemInTripGroup,
  _removeItemFromTripGroup,
  _updateIdOfTripGroupItem,
  _unsetNewlyCreatedItem,
  _unsetNewlyCreatedGroup,
} = slice.actions

export const addTripGroup = (data: TripGroup) => (dispatch: AppDispatch) => {
  try {
    dispatch(_addTripGroup(data))
  } catch (e) {
    throw new Error((e as Error).message)
  }
}

export const loadTripGroups =
  (data: Partial<Array<TripGroup>>) => (dispatch: AppDispatch) => {
    try {
      dispatch(_loadTripGroups(data))
    } catch (e) {
      throw new Error((e as Error).message)
    }
  }
export const setFilteredTripGroups =
  (data: Partial<Array<TripGroup>>) => (dispatch: AppDispatch) => {
    try {
      dispatch(_setFilteredTripGroups(data))
    } catch (e) {
      throw new Error((e as Error).message)
    }
  }

export const deleteGroup =
  (data: Partial<TripGroup>) => (dispatch: AppDispatch) => {
    dispatch(_deleteTripGroup(data))
  }

export const updateColorOfTripGroupItem =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (data: any) => (dispatch: AppDispatch) => {
    dispatch(_updateColorOfTripGroupItem(data))
  }


export const updateCommentCountOfTripItem =
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(data: any) => (dispatch: AppDispatch) => {
  dispatch(_updateCommentCountOfTripItem(data))
}

export const updateIdOfTripGroupItem =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (data: any) => (dispatch: AppDispatch) => {
    dispatch(_updateIdOfTripGroupItem(data))
  }

export const castVoteOfTripGroupPollItem =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (data: any) => (dispatch: AppDispatch) => {
    dispatch(_castVoteOfTripGroupPollItem(data))
  }

export const addOPtionsToTripGroupPollItem =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (data: any) => (dispatch: AppDispatch) => {
    dispatch(_addOPtionsToTripGroupPollItem(data))
  }

export const removeOPtionFromTripGroupPollItem =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (data: any) => (dispatch: AppDispatch) => {
    dispatch(_removeOPtionFromTripGroupPollItem(data))
  }

export const addItemInTripGroup =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (data: any) => (dispatch: AppDispatch) => {
    try {
      dispatch(_addItemInTripGroup(data))
    } catch (e) {
      throw new Error((e as Error).message)
    }
  }

export const removeItemFromTripGroup =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (data: any) => (dispatch: AppDispatch) => {
    try {
      dispatch(_removeItemFromTripGroup(data))
    } catch (e) {
      throw new Error((e as Error).message)
    }
  }

export const updateTripGroup =
  (data: Partial<TripGroup>) => (dispatch: AppDispatch) => {
    try {
      dispatch(_updateTripGroup(data))
    } catch (e) {
      throw new Error((e as Error).message)
    }
  }

export const unsetNewlyCreatedItem = () => (dispatch: AppDispatch) => {
  try {
    dispatch(_unsetNewlyCreatedItem())
  } catch (e) {
    throw new Error((e as Error).message)
  }
}

export const unsetNewlyCreatedGroup = () => (dispatch: AppDispatch) => {
  try {
    dispatch(_unsetNewlyCreatedGroup())
  } catch (e) {
    throw new Error((e as Error).message)
  }
}
