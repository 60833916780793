// eslint-disable-next-line import/no-named-as-default
import toast from 'react-hot-toast'
import { ReactNode } from 'react'


type Type = 'success' | 'error' | 'info'

export const sendNotification = (message: string | ReactNode, type: Type) => {
  const style = {
    boxShadow: '2px 5px 41px -7px rgba(0,0,0,0.47)',
    fontSize: '1.6rem',
    fontWeight: 700,
    minWidth: 'fit-content'
  }

  type === 'success'
    ? toast.success(message as string | JSX.Element, { style, duration: 7000 })
    : type === 'error'
    ? toast.error(message as string | JSX.Element, { style, duration: 7000 })
    : toast(message as string | JSX.Element)
}
