import styled from '@emotion/styled'
import { Room } from '@material-ui/icons'

type props = {
  selected?: boolean
}

export const LocationMarkerStyled = styled(Room)<props>`
  font-size: 40px;
  transform: translate(-50%, -50%);
  cursor: pointer;
  stroke: ${props => props.selected ?  'white !important' : ''};
  stroke-width: ${props => props.selected ?  '1.5px !important' : ''};
  stroke-linejoin: ${props => props.selected ?  'round !important' : ''};
  stroke-linejoin: round !important;
  &:hover {
    stroke: white !important;
    stroke-width: 1.5px !important;
    stroke-linejoin: round !important;
  }
`

export const LocationMarkerInfoContainer = styled.div`
  background-color: white;
  padding: 5px;
  width: 80px;
  border-radius: 12px;
  text-align: center;
  top: 20px;
  left: -40px;
  position: absolute;
  z-index: 100;
`
