import React from 'react'

import { CONSTANTS } from 'core/constants'
import { cssVariables } from 'theme'

import {
  WarningIcon,
  AlertCircleIcon,
  XIcon,
  CheckCircleIcon,
} from 'components/MTIcons'

import { ModalTypeKey, modalTypes } from './types'

type CloseButtonStyleProps = {
  backgroundColor: string
  color: string
  width: string
  boxShadow: string
  position: 'absolute' | 'relative'
  right: number
}

type CommonButtonStyleProps = {
  backgroundColor: string
  color: string
  width: string
}

export type BlueButtonStyleProps = CommonButtonStyleProps

export type RedButtonStyleProps = {
  boxShadow: string
} & CommonButtonStyleProps

export type TransparentButtonStyleProps = {
  boxShadow: string
  borderWidth: string
  borderStyle: string
  borderColor: string
} & CommonButtonStyleProps

export const closeButtonStyle: CloseButtonStyleProps = {
  backgroundColor: 'white',
  color: 'white',
  width: 'auto',
  boxShadow: 'none',
  position: 'absolute',
  right: 0,
} as const

const redButtonStyle: RedButtonStyleProps = {
  backgroundColor: '#C10F2A',
  width: 'auto',
  boxShadow: 'none',
  color: 'white',
} as const

const transparentButtonStyle: TransparentButtonStyleProps = {
  backgroundColor: 'white',
  color: cssVariables.colors.secondaryDarkGrey,
  width: 'auto',
  boxShadow: 'none',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '#AFAFAF',
} as const

const blueButtonStyle: BlueButtonStyleProps = {
  backgroundColor: cssVariables.colors.primaryBlue,
  color: 'white',
  width: 'auto',
}

type DefaulProps = {
  closeButtonIcon: React.ReactNode
  closeButtonStyle: CloseButtonStyleProps
  transparentButtonStyle: TransparentButtonStyleProps
  redButtonStyle: RedButtonStyleProps
  blueButtonStyle: BlueButtonStyleProps
  headerIcon?: React.ReactNode
}

const defaultProps: DefaulProps = {
  closeButtonIcon: <XIcon />,
  closeButtonStyle,
  transparentButtonStyle,
  redButtonStyle,
  blueButtonStyle,
}

const dialogTypes = {
  [modalTypes.DELETE_ITEM]: {
    headerIcon: <WarningIcon stroke="#C10F2A" />,
    title: 'Delete Confirmation',
    body: (
      itemToBeDeleted?: string | null,
    ) => `${CONSTANTS.DELETE_CONFIRMATION} ${itemToBeDeleted}
    ${CONSTANTS.DELETE_CONFIRMATION_SUFFIX}`,
    ...defaultProps,
  },

  [modalTypes.UNPUBLISH_A_TRIP]: {
    headerIcon: <WarningIcon stroke="#C10F2A" />,
    title: 'Unpublish confirmation',
    body: () =>
      CONSTANTS.UNPUBLISH_CONFIRMATION,
    ...defaultProps,
  },

  [modalTypes.DOWNGRADE_PLAN]: {
    headerIcon: <WarningIcon stroke="#C10F2A" />,
    title: 'Are you sure?',
    body: () => {
      return <></>
    },
    ...defaultProps,
  },

  [modalTypes.CREATE_GROUP]: {
    // headerIcon: <AlertCircleIcon stroke="#E6CB4B" />,
    title: 'Create Group',
    body: () => {
      return <input type="text" placeholder="Group Name" />
    },
    ...defaultProps,
  },
  [modalTypes.SIGN_UP_TO_SAVE_TRIP]: {
    headerIcon: <AlertCircleIcon stroke="#E6CB4B" />,
    title: 'Sign up to save your trip',
    body: () => {
      const str = (
        <span style={{ fontWeight: 'bold' }}>Sign up for an account</span>
      )
      return (
        <>
          If you exit the Planning Board now, all the changes you made will be
          lost. {str} to save the trip and invite other people to collaborate
          with you!
        </>
      )
    },
    ...defaultProps,
  },
  [modalTypes.SIGN_UP_TO_INVITE_USER]: {
    headerIcon: <AlertCircleIcon stroke="#E6CB4B" />,
    title: 'Sign up to save your trip',
    body: () => {
      const str = (
        <span style={{ fontWeight: 'bold' }}>Sign up for an account</span>
      )
      return (
        <>
          If you exit the Planning Board now, all the changes you made will be
          lost. {str} to save the trip and invite other people to collaborate
          with you!
        </>
      )
    },
    ...defaultProps,
  },
  [modalTypes.SIGN_UP_TO_AS_NON_ADMIN_TO_EDIT_TRIP]: {
    headerIcon: <AlertCircleIcon stroke="#E6CB4B" />,
    title: 'Sign up to make changes',
    body: () =>
      'To collaborate with your fellow travellers, please create an account first!',
    ...defaultProps,
  },
  [modalTypes.ACTIVATE_ACCOUNT]: {
    headerIcon: <CheckCircleIcon stroke="#0F883A" />,
    title: 'Verify your email',
    body: () => (
      <>
        Woot! You have successfully registered!{' '}
        <span style={{ fontWeight: 'bold' }}>
          To activate your account, click the verification link in the email you
          just received.{' '}
        </span>
        <br />
        <br />
        If you don&apos;t see the email in your inbox, please check your spam
        folder.
      </>
    ),
    ...defaultProps,
  },
  [modalTypes.DOWNGRADE_PLAN_WARNING]: {
    headerIcon: <WarningIcon stroke="#C10F2A" />,
    title: 'Are you sure?',
    body: () => (
      <div className="pt-3">
        <span style={{ fontWeight: 'bold' }}>
          You still have 1 unused paid trip in your Adventurer plan.{' '}
        </span>
        <br />
        <br />
        <span>
          If you cancel your plan now, you will no longer be able to create a
          new trip.{' '}
        </span>
      </div>
    ),
    ...defaultProps,
  },

  [modalTypes.PURCHASE_PLAN_WARNING]: {
    headerIcon: <AlertCircleIcon stroke="#E6CB4B" />,

    title: 'Are you sure?',
    body: () => (
      <div className="pt-3">
        <span style={{ fontWeight: 'bold' }}>
          You still have 1 unused paid trip in your Adventurer plan.{' '}
        </span>
        <br />
        <br />
        <span>Are you sure you want to purchase your plan now?</span>
      </div>
    ),
    ...defaultProps,
  },

  [modalTypes.CONTACT_US]: {
    headerIcon: <WarningIcon stroke="#C10F2A" />,

    title: 'Please contact us',
    body: () => (
      <div className="pt-3">
        <span>
          If you wish to cancel your <strong>Globe-Trotter Plan</strong>, please
          reach out to us at{' '}
          <a href="support@mitravelapp.com">support@mitravelapp.com</a>
        </span>
      </div>
    ),
    ...defaultProps,
  },

  [modalTypes.SUBSCRIPTION_ALREADY_CANCELLED]: {
    headerIcon: <WarningIcon stroke="#C10F2A" />,

    title: 'Your subscription is already cancelled',
    body: () => (
      <div className="pt-3">
        <span>
          Your subscription is already cancelled and will take effect on next
          billing. If you need support, please contact us at{' '}
          <a href="support@mitravelapp.com">support@mitravelapp.com</a>
        </span>
      </div>
    ),
    ...defaultProps,
  },
}

export const dialogContent = (type: ModalTypeKey) => dialogTypes[type]
