import React from 'react'

type IconProp = {
  stroke?: string
  height?: string
  width?: string
}
const MapZoomOutIcon = ({ stroke, height, width }: IconProp) => {
  return (
    <svg
      width={width ?? '20'}
      height={height ?? '20'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        stroke={stroke ?? '#4184aFF'}
        d="M18 12.998H6C5.73478 12.998 5.48043 12.8926 5.29289 12.7051C5.10536 12.5176 5 12.2632 5 11.998C5 11.7328 5.10536 11.4784 5.29289 11.2909C5.48043 11.1033 5.73478 10.998 6 10.998H18C18.2652 10.998 18.5196 11.1033 18.7071 11.2909C18.8946 11.4784 19 11.7328 19 11.998C19 12.2632 18.8946 12.5176 18.7071 12.7051C18.5196 12.8926 18.2652 12.998 18 12.998Z"
        fill="#686868"
      />
    </svg>
  )
}

export default MapZoomOutIcon
