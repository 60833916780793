import React, { useCallback, useEffect, useState } from 'react'

import { Grid, LinearProgress } from '@material-ui/core'
import { CONSTANTS } from 'core/constants'
import { Formik } from 'formik'
import { HTTPError } from 'ky'
import { TermsOfService } from 'layouts/Auth/components/TermsOfService'
import { isUndefined } from 'lodash'
import mixpanel from 'mixpanel-browser'
import { useHistory } from 'react-router-dom'
import { resetAction, resetUserStore } from 'store'
import {
  useAppDispatch,
  useAppSelector,
  CoordsObject,
} from 'store/hooks'
import { setUser } from 'store/slices/userSlice'
import { pageVariants } from 'styles/sharedMotion'
import { tabletMobileScreenSize, isScreenSize } from 'utils/screenSzie'
import { sendNotification } from 'utils/toast'

import { login } from 'api/auth'

import {
  ModalInput,
  SignInForm,
  SignInInput,
  SignInHeader,
  Title,
  SubTitle,
  FormStyled,
  Label,
  ErrorField,
  UsernameInput,
  PasswordInput,
  SubmitButtonStyled,
  SignUpFooter,
  SignUpFooterLinks,
  SignUpButtonLink,
} from '../AuthStyles'
import { validationSchemaLogin } from './helper'



type FormProps = {
  usernameOrEmail: string
  password: string
}

type LoginDataObject = {
  password: string
  tentative_user_uid: string | null
  tentative_role: string
  email: string
  username: string
  coordinates?: CoordsObject
}

const SignIn = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(state => state.user)
  const currentUserRole = useAppSelector(state => state.tripUserRoleSlice)
  const [platform, setPlatform] = useState('Web')
  const smallScreen = isScreenSize(650)
  const tabletMobileViewGeneral = tabletMobileScreenSize()

  const coordinates = useAppSelector(state => state.geoLocation)
  /**
   * Handle user login
   * @param {object} userInfo login info
   */
  const handleLogin = async (userInfo: FormProps) => {
    try {
      const data: LoginDataObject = {
        password: userInfo.password,
        tentative_user_uid: '',
        tentative_role: '',
        email: '',
        username: '',
      }

      if (user?.is_tentative) {
        data['tentative_user_uid'] = user?.uid
      }

      if (currentUserRole?.data[0]?.role) {
        data['tentative_role'] = currentUserRole?.data[0]?.role
      }

      if (userInfo.usernameOrEmail.includes('@')) {
        data['email'] = userInfo.usernameOrEmail
      } else {
        data['username'] = userInfo.usernameOrEmail
      }

      data['coordinates'] = coordinates
      // erase the cookies before receiving new ones on succesful sign in
      dispatch(resetAction())

      const res = await login(data)

      if (res.success) {
        resetUserStore(dispatch, false)

        // call this function after half a second to let user store to clear and rerender
        setTimeout(() => {
          dispatch(setUser(res.data))
          history.push('/')
          sendNotification(
            `${CONSTANTS.SIGN_IN_WELCOME_MESSAGE} ${res.data.firstName}!`,
            'success',
          )
          if (
            res.data?.newlyCreatedTripResponse?.success === false &&
            res.data?.newlyCreatedTripResponse?.message
          ) {
            sendNotification(res.data.newlyCreatedTripResponse.message, 'error')
          }
        }, 500)
      } else if (!res.success) {
        console.log('Error:', res)
        sendNotification(res.message, 'error')
      }
    } catch (error) {
      console.log(error)
      const res = await (error as HTTPError)?.response?.json()
      const message = !isUndefined(res.message)
        ? res.message
        : CONSTANTS.SIGN_IN_ERROR_MESSAGE
      sendNotification(message, 'error')
    }
  }

  useEffect(() => {
    // Handle redirection if user is already logged in
    if (user.username) {
     history.push('/')
    }
  }, [])

  useEffect(() => {
    // Check if the user state has been updated
    if (user.uid) {
      mixpanel.identify(user.uid)
      mixpanel.track('Login Completed', {
        Platform: platform,
        $latitude: coordinates?.latitude,
        $longitude: coordinates?.longitude,
      })
    }
  }, [user, platform])


  useEffect(() => {
    const { userAgent } = navigator

    if (userAgent.includes('Android')) {
      setPlatform('Android')
    } else if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {
      setPlatform('iOS')
    } else {
      setPlatform('Web')
    }
  }, [])

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 'calc(100vh - 58px)' }}>
      <SignInForm
        style={{ maxWidth: '442px' }}
        className="sign-in">
        <Grid item xs={12}>
          <ModalInput
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}>
            <SignInInput style={{ paddingTop: smallScreen ? '0px' : '3rem' }}>
              <SignInHeader>
                <Title>{CONSTANTS.LOG_IN}</Title>
                <SubTitle style={{ whiteSpace: 'nowrap' }}>
                  {CONSTANTS.SIGN_IN_HEADER_SUBTITLE}
                </SubTitle>
              </SignInHeader>
              <Formik
                initialValues={{
                  usernameOrEmail: '',
                  password: '',
                }}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={validationSchemaLogin}
                onSubmit={async ({ usernameOrEmail, password }) => {
                  await handleLogin({ usernameOrEmail, password })
                }}>
                {({ isSubmitting, dirty, errors }) => (
                  <FormStyled className="sign-in">
                    <Label>Username/Email</Label>
                    <UsernameInput
                      name="usernameOrEmail"
                      type="text"
                      autoFocus={!tabletMobileViewGeneral}
                    />
                    <ErrorField>{errors.usernameOrEmail}</ErrorField>
                    <Label>Password</Label>
                    <PasswordInput name="password" type="password" />
                    <ErrorField>{errors.password}</ErrorField>
                    {isSubmitting && <LinearProgress />}
                    <SubmitButtonStyled disabled={!dirty}>
                      {CONSTANTS.LOG_IN}
                    </SubmitButtonStyled>
                  </FormStyled>
                )}
              </Formik>
              <SignUpFooter>
                <SignUpFooterLinks>
                  <Grid item xs={12}>
                    <SignUpButtonLink onClick={() => history.push('/forgot-password')}>
                      {CONSTANTS.LABEL_BUTTON_FORGOT_ME}
                    </SignUpButtonLink>
                  </Grid>
                  <Grid item xs={12}>
                    <SignUpButtonLink
                      style={{ whiteSpace: 'nowrap' }}
                      onClick={() => history.push('/signup')}>
                      {CONSTANTS.DONT_HAVE_ACCOUNT_SIGN_UP}
                    </SignUpButtonLink>
                  </Grid>
                </SignUpFooterLinks>
              </SignUpFooter>
            </SignInInput>
            {/* <TermsOfService /> */}
          </ModalInput>
        </Grid>
      </SignInForm>
      </div>
  )
}

export default SignIn
