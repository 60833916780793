import React from 'react'

type IconProp = {
  stroke?: string
  height?: string
  width?: string
}
const ShareTripIcon = ({ stroke, height, width }: IconProp) => {
  return (
    <svg
      width={width ?? '20'}
      height={height ?? '20'}
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M22 2.63672L11 13.6367'
        stroke={stroke ?? '#4184FF'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22 2.63672L15 22.6367L11 13.6367L2 9.63672L22 2.63672Z'
        stroke={stroke ?? '#4184FF'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default ShareTripIcon
