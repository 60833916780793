import styled from '@emotion/styled'
import { AppBar, Toolbar } from '@material-ui/core'
import { cssVariables } from 'theme'

export const AppBarStyled = styled(AppBar)`
  position: sticky;
  top:0;
  background-color: #fdfdfd;
  border-bottom: solid;
  border-bottomwidth: 1px;
  border-bottomcolor: ${cssVariables.colors.ternaryLightGrey};
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  box-shadow: 0px 1px 1px 0px #00000014;
  backdrop-filter: blur(10px)
`
export const ToolbarStyled = styled(Toolbar)`
  display: grid;
  min-height: 48px;
  grid-template-columns: 1fr 5fr;
`
export const ToolbarItemLogo = styled.div`
  margin-left: 3rem;
`
export const ToolbarButtonGroup = styled.div`
  justify-self: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 100%;
  row-gap: 5px;
  column-gap: 12px;
`
export const AuthButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 12px;
  row-gap: 12px
`
