export const styles = () => {
  return {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    dashboardPeopleLogo: {
      marginTop: '3rem',
      height: '35rem',
    },
    title: {
      fontWeight: 'bold',
    },
    subTitle: {
      marginTop: '1rem',
      marginBottom: '3rem',
    },
    button: {
      margin: '2rem 0 ',
    },
  }
}
