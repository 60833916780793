import { CoordsObject } from 'store/hooks'
import {
  setUserPlans,
  setOtherPlans,
  PlanObject,
} from 'store/slices/userPlanSlice'
import { sendNotification } from 'utils/toast'

import {
  useUserPayment,
  manageSubscription,
  updateCustomer,
} from 'api/userPlans'

import { CONSTANTS } from '../core/constants'

interface PaddleEventData {
  name: string
  data: {
    status: string
    transaction_id?: string | null
    customer?: {
      email: string
      name: string | null
      id?: string | null
    }
    totals?: {
      tax: number
    }
  }
}

interface Paddle {
  Environment: {
    set: (env: string) => void
  }
  Initialized: boolean
  Update: (config: { eventCallback: (data: PaddleEventData) => void }) => void
  Initialize: (config: {
    token: string
    eventCallback: (data: PaddleEventData) => void
  }) => void
  Checkout: {
    open: (options: {
      settings: { displayMode: string; theme: string; locale: string }
      transactionId?: string
      items?: Array<{ priceId: string | null }>
      customer?: {
        email: string | null
        name: string | null
      }
      customData?: {
        userUid: string | null
        latitude?: number | null | undefined
        longitude?: number | null | undefined
        planUid: string | null
        operatingSystem: string
      }
    }) => Promise<void>
  }
}

declare global {
  interface Window {
    Paddle: Paddle
  }
}

const paddleEventCallBack = async (
  planName: string | null,
  planUid: string | null,
  userUid: string | null,
  otherPlans: Array<PlanObject>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  history: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: any,
  data: PaddleEventData,
  email: string | null,
  name: string | null,
  planAlreadySubscribed = false,
  hasUnusedTrip = false,
  coordinates?: CoordsObject,
) => {
  const paddleTransactionId = data.data?.transaction_id
  if (data.name === 'checkout.completed' && data.data.status === 'completed') {
    await updateCustomer({
      customerId: data.data?.customer?.id,
      dataToUpdate: {
        name,
      },
    })
    if (!planAlreadySubscribed) {
      try {
        if (hasUnusedTrip) {
          const paymentData = {
            uid: userUid,
            is_recurring: false,
          }
          await useUserPayment(paymentData)
        }
        const result = await manageSubscription({
          planUid,
          userUid,
          coordinates,
          taxCalculated: data.data.totals?.tax,
          paddleTransactionId,
        })
        await dispatch(setUserPlans(result))
        await dispatch(setOtherPlans(otherPlans))
        history.push('/change-plan/purchase')
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    } else {
      history.push('/create-trip', { from: location.pathname })
    }
  }
  if (data.name === 'checkout.customer.updated') {
    if (data.data.customer?.email !== email?.toLowerCase()) {
      sendNotification('Please do not change your email', 'error')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { Checkout } = (window as any).Paddle
      Checkout.close()
    }
  }
  const redirectURL = sessionStorage.getItem('updatePaymentDetailsURL')
  if (redirectURL && redirectURL.includes('?_ptxn=')) {
    sessionStorage.removeItem('updatePaymentDetailsURL')
    history.push('/profile')
  }
}

export const paddleCheckout = async (
  planName: string | null,
  planUid: string | null,
  userUid: string | null,
  otherPlans: Array<PlanObject>,
  paddlePriceId: string | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  history: any | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: any | null,
  email: string | null,
  name: string | null,
  transactionId: string | null,
  planAlreadySubscribed = false,
  hasUnusedTrip = false,
  coordinates?: CoordsObject,
) => {
  const token = process.env.REACT_APP_PADDLE_TOKEN

  if(process.env.REACT_APP_ENV != 'production'){
    window.Paddle.Environment.set('sandbox')
  }

  let operatingSystem = 'Not known'
  if (window.navigator.appVersion.indexOf('Win') !== -1)
    operatingSystem = 'Windows'
  if (window.navigator.appVersion.indexOf('Mac') !== -1)
    operatingSystem = 'MacOS'
  if (window.navigator.appVersion.indexOf('X11') !== -1)
    // operatingSystem = 'UNIX'
    if (window.navigator.appVersion.indexOf('Linux') !== -1)
      operatingSystem = 'Linux'

  if (window.Paddle.Initialized) {
    window.Paddle.Update({
      eventCallback: async (data: PaddleEventData) => {
        await paddleEventCallBack(
          planName,
          planUid,
          userUid,
          otherPlans,
          history,
          dispatch,
          data,
          email,
          name,
          planAlreadySubscribed,
          hasUnusedTrip,
          coordinates,
        )
      },
    })
  } else {
    window.Paddle.Initialize({
      token: token,
      eventCallback: async (data: PaddleEventData) => {
        await paddleEventCallBack(
          planName,
          planUid,
          userUid,
          otherPlans,
          history,
          dispatch,
          data,
          email,
          name,
          planAlreadySubscribed,
          hasUnusedTrip,
          coordinates,
        )
      },
    })
  }

  if (transactionId) {
    await window.Paddle.Checkout.open({
      settings: {
        displayMode: 'overlay',
        theme: 'light',
        locale: 'en',
      },
      transactionId,
    })
  } else {
    await window.Paddle.Checkout.open({
      settings: {
        displayMode: 'overlay',
        theme: 'light',
        locale: 'en',
      },
      items: [
        {
          priceId: paddlePriceId,
        },
      ],
      customer: {
        email,
        name,
      },
      customData: {
        userUid,
        latitude: coordinates?.latitude,
        longitude: coordinates?.longitude,
        planUid: planUid,
        operatingSystem,
      },
    })
  }
}
