import React, { useEffect } from 'react'

import { Grid, CardContent, Typography } from '@material-ui/core'
import { Card } from '@mui/material'
import { CONSTANTS } from 'core/constants'
import { PlanObject } from 'store/slices/userPlanSlice'
import { isScreenSize } from 'utils/screenSzie'

import PlanCard from './PlanCard'

type Props = {
  subscribedPlan: PlanObject
  otherPlans: Array<PlanObject>
}
const ActiveSubscription = ({ subscribedPlan, otherPlans }: Props) => {
  const isSmallTablet = isScreenSize(600)
  const isFreePlan = subscribedPlan?.name === CONSTANTS.FREE_PLAN ? true : false

  return (
    <div style={{ marginTop: '10px' }}>
      <strong style={{ fontSize: '16px'}}>
        You are currently on the <strong>{subscribedPlan?.name}</strong> plan.
      </strong>
      <div style={{ margin: '10px 0' }}>
      <PlanCard
        plan={subscribedPlan}
        buttonText={isFreePlan ? '' : 'Cancel Plan'}
        isPopup={false}
        isSubscribed={true}
      />
      </div>
    </div>
  )
}

export default ActiveSubscription
