import styled from '@emotion/styled'
import { CommunityTripCardProps } from './CommunityTripCard'

interface containerProps {
    preview: boolean
}

export const CommunityCardContainer = styled.div<containerProps>`
    color: #3A3A3A;
    width: ${props => (props.preview ? '405px' : '300px')};
    maxWidth: ${props => (props.preview ? '405px': '300px')};
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px #0000000D;
    user-select: none;
    @media (max-width: 410px) {
        width: 100%;
        max-width: 300px;
    }
    @media (max-width: 300px) {
        width: 100%;
        max-width: 250px;
    }
    @media (max-width: 250px) {
        width: 100%;
        max-width: 220px;
    }
`

export const DateSection = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    width: 100%;
    height: 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
`

export const DurationSection = styled.div`
    display: flex;
    flex-direction: row;
    row-gap: 2px;
    column-gap: 8px;
    align-items: center;
`

export const CommunityCardDescription = styled.div`
    width: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
`

export const CommunityCardFooter = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`

export const TripOwnerInformation = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
`

export const ViewsAndDuplicates = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
`
export const Views = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    gap: 4px;
`
export const Duplicates = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    gap: 4px;
`

export const CommunityCardImage = styled.img`
    width: 100%;
    aspect-ratio: 3 / 2.2;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
`

export const TripTitle = styled.p`
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin:0;
`

export const Text = styled.p`
    margin: 0;
`

export const DurationText = styled.p`
    margin: 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #3A3A3A;
`

