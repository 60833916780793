import React, { useEffect, useCallback, useRef } from 'react'

import { useQuery } from 'hooks/useQuery'
import itly from 'itly'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useGeolocation } from 'store/hooks'
import { setUser } from 'store/slices/userSlice'
import { sendNotification } from 'utils/toast'

import { activate, verifyResetPassword } from 'api/auth'
import {
  invite,
  confirmInvite,
  denyInvite,
  confirmNonAdminMemberInvite,
} from 'api/tripMember'
import './style.scss'

const Redirect = () => {
  const history = useHistory()
  let uriQuery = useQuery()
  const dispatch = useDispatch()
  const accountActivationQuery = uriQuery.get('account_activation')
  const queryRedirectType = uriQuery.get('type')
  const { user } = useSelector(state => state.user)
  const coordinates = useGeolocation()

  /**
   * Return parsed message from API.
   * @param { String } message API response message
   * @returns message
   */
  const getMessage = message => {
    let msg = null

    if (message && typeof message === 'string') {
      msg = message
    } else if (message && typeof message === 'object') {
      msg = message.message
    }

    return msg
  }

  /**
   * User's account will become activated.
   */
  const activateAccount = useCallback(async () => {
    const res = await activate({ token: accountActivationQuery })

    console.log(
      'sendNotificationsendNotificationsendNotificationsendNotification',
      res,
    )
    if (res.success) {
      itly.userActivated({
        user_uid: res?.user_uid,
        is_tentative: res?.is_tentative,
      })

      history.push('/signin')
      sendNotification('Your account has been activated!', 'success')
    } else {
      history.push('/create-trip')
      sendNotification(
        'Oops! Something went wrong while activating the account',
        'error',
      )
    }
  }, [accountActivationQuery, history])

  /**
   * User redirected to signup for an account first.
   */
  const registerAccount = useCallback(async () => {
    history.push('/signup')
  }, [history])

  /**
   * If invited by admin, selected member will be added to trip.
   */
  const inviteMember = useCallback(async () => {
    const to = uriQuery.get('to')
    const short_uid = uriQuery.get('short_uid')
    const invited_member_role = uriQuery.get('invited_member_role')
    const access_token = uriQuery.get('access_token')

    const args = {
      to,
      short_uid,
      invited_member_role,
      access_token,
      coordinates,
    }
    const res = await invite(args)
    let msg = getMessage(res.message)

    // case: user has account
    if (
      res?.success &&
      res?.short_uid === short_uid &&
      !res?.data?.is_tentative
    ) {
      // case: user is logged in in another tab
      if (user?.uid) {
        history.push(`/workspace/${short_uid}`)
      } else {
        // case: user is NOT logged in in another tab
        history.push('signin')
      }
      sendNotification(msg, 'success')
      // case: user does NOT have an account yet
    } else if (
      res?.success &&
      res?.short_uid === short_uid &&
      res?.data?.is_tentative
    ) {
      // hack to allow user to be set in state before navigating to pb
      // setTimeout(() => {
      //   history.push(`/workspace/${short_uid}`)
      // }, 1000)
      await dispatch(setUser(res?.data))
      history.push(`/workspace/${short_uid}`)
      sendNotification(msg, 'success')
    }
  }, [history, uriQuery, dispatch, user])

  /**
   * Admin confirms that the invited member to be added as trip member.
   */
  const onMemberAdminConfirmation = useCallback(async () => {
    const invited_trip_member_uid = uriQuery.get('invited_trip_member_uid')
    const short_uid = uriQuery.get('short_uid')
    const access_token = uriQuery.get('access_token')

    const args = {
      invited_trip_member_uid,
      short_uid,
      access_token,
      coordinates,
    }
    const { success, message, status, member_registered } = await confirmInvite(
      args,
    )

    let msg = getMessage(message)

    if (success && status === 'member_removed') {
      history.push('/')
      sendNotification(msg, 'success')
    } else if (success && status === 'member_confirmed' && !member_registered) {
      history.push('/signin')
      sendNotification(msg, 'success')
    } else if (success && status === 'member_confirmed' && member_registered) {
      history.push(`/workspace/${short_uid}`)
      sendNotification(msg, 'success')
    }
  }, [history, uriQuery])

  /**
   * Admin denies entry to member invited by editor or viewer of admin's trip.
   */
  const onMemberAdminDenial = useCallback(async () => {
    const invited_trip_member_uid = uriQuery.get('invited_trip_member_uid')
    const short_uid = uriQuery.get('short_uid')
    const access_token = uriQuery.get('access_token')

    const args = {
      invited_trip_member_uid,
      short_uid,
      access_token,
    }
    const { success, message, status } = await denyInvite(args)

    let msg = getMessage(message)

    if (success && status === 'member_access_denied') {
      history.push(`/workspace/${short_uid}`)
      sendNotification(msg, 'success')
    }
  }, [history, uriQuery])

  /**
   * Password reset.
   */
  const onPasswordReset = useCallback(async () => {
    const access_token = uriQuery.get('access_token')
    const res = await verifyResetPassword({ token: access_token })

    if (res.success) {
      history.push(`/password_reset?user_uid=${res.user_uid}`)
    }
  }, [history, uriQuery])

  const onMemberInvitedByNonAdminTripLogin = useCallback(async () => {
    const to = uriQuery.get('to')
    const short_uid = uriQuery.get('short_uid')
    const access_token = uriQuery.get('access_token')

    const args = {
      short_uid,
      access_token,
      email: to,
      coordinates,
    }

    const res = await confirmNonAdminMemberInvite(args)

    let msg = getMessage(res.message)

    if (res.success && res.data.is_tentative) {
      // hack to allow user to be set in state before navigating to pb
      setTimeout(() => {
        history.push(`/workspace/${short_uid}`)
      }, 1000)
      dispatch(setUser(res.data))
      sendNotification(msg, 'success')
    } else if (res.success && !res.data.is_tentative) {
      // case: user is logged in in another tab
      if (user?.uid) {
        history.push(`/workspace/${short_uid}`)
      } else {
        // case: user is NOT logged in in another tab
        history.push('/signin')
      }
      sendNotification(msg, 'success')
    }
  }, [history, uriQuery, dispatch, user])
  /**
   * Redirect user to login when admin manual activates their account.
   */
  const onManuallyActivateAccount = useCallback(() => {
    history.push('/signin')
  }, [history])

  const notificationSentRef = useRef(false)

  useEffect(() => {
    const handleRedirect = async () => {
      switch (queryRedirectType) {
        case 'account_activation':
          activateAccount()
          break
        case 'account_registration':
          registerAccount()
          break
        case 'member_invitation':
          inviteMember()
          break
        case 'confirm-invitation':
          onMemberAdminConfirmation()
          break
        case 'deny-invitation':
          onMemberAdminDenial()
          break
        case 'password_reset':
          onPasswordReset()
          break
        case 'manual_account_activation':
          onManuallyActivateAccount()
          break
        case 'member_invitation_by_non_admin':
          onMemberInvitedByNonAdminTripLogin()
          break
        default:
          break
      }
    }
    if (!notificationSentRef.current) {
      handleRedirect()
      notificationSentRef.current = true
    }
  }, [
    onPasswordReset,
    activateAccount,
    onMemberAdminConfirmation,
    inviteMember,
    onMemberAdminDenial,
    queryRedirectType,
    registerAccount,
    onManuallyActivateAccount,
    onMemberInvitedByNonAdminTripLogin,
  ])
  return <div>Redirecting</div>
}

export default Redirect
