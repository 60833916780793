/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React from 'react'

import MomentUtils from '@date-io/moment'
import { CssBaseline } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import amplitude from 'amplitude-js'
import itly from 'itly'
import * as _ from 'lodash'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import App from './App'
import { DialogProvider } from './contexts/DialogContext'
import { ModalProvider } from './contexts/ModalContext'
import { store, persistor } from './store'
import theme, { cssVariables } from './theme'
import './index.scss'
import 'antd/dist/antd.css'
import 'bootstrap/dist/css/bootstrap.min.css'

amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_KEY)
itly.load({ environment: process.env.REACT_APP_NODE_ENV })

if (
  process.env.NODE_ENV === 'production' ||
  process.env.NODE_ENV === 'staging'
) {
  Sentry.init({
    dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MuiThemeProvider theme={{ ...theme, ...cssVariables }}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <CssBaseline />
          <BrowserRouter>
            <ModalProvider>
              <DialogProvider>
                <App />
              </DialogProvider>
            </ModalProvider>
          </BrowserRouter>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)
