import { createSlice } from '@reduxjs/toolkit'

import type { AppDispatch } from '../index'

type UserOwnedLocationObject = {
  color: string
  uid: string
  showMarkerMapInfo?: boolean
  lat: number
  lng: number
  place_id: string
  title: string
}

type TripItemLocationsMapObject = {
  userOwnedLocations: Array<UserOwnedLocationObject>
  workspaceTabIndex: number
}

type TripItemLocationsMapState = TripItemLocationsMapObject

const initialState: TripItemLocationsMapState = {
  userOwnedLocations: [],
  workspaceTabIndex: 0,
}
/**
 * When user hovers over a location item on the grid in the planning board
 * the marker on the map will display the title of that location and vice versa.
 */
const slice = createSlice({
  name: 'tripItemLocationsMap',
  initialState,
  reducers: {
    _initializeLocatons: (state, { payload }) => {
      state.userOwnedLocations = payload
    },
    _updateLocationColor: (state, { payload }) => {
      const { uid, color } = payload
      const objIndex = state.userOwnedLocations.findIndex(
        loc => loc.uid === uid,
      )
      if (state.userOwnedLocations[objIndex]) {
        state.userOwnedLocations[objIndex].color = color
      }
    },
    _setWorkspaceTabIndex: (state, { payload }) => {
      state.workspaceTabIndex = payload
    },
    _clearLocations: state => {
      state.userOwnedLocations = []
    },
    _clearLocationsAndIndex: state => {
      state.userOwnedLocations = []
      state.workspaceTabIndex = 0
    },
    _showLocationItemMapInfo: (state, { payload }) => {
      const { uid } = payload
      const objIndex = state.userOwnedLocations.findIndex(
        loc => loc.uid === uid,
      )
      if (state.userOwnedLocations[objIndex]) {
        // state.userOwnedLocations[objIndex].showMarkerMapInfo = true
      }
    },
    _hideLocationItemMapInfo: (state, { payload }) => {
      const { uid } = payload
      const objIndex = state.userOwnedLocations.findIndex(
        loc => loc.uid === uid,
      )
      if (state.userOwnedLocations[objIndex]) {
        // state.userOwnedLocations[objIndex].showMarkerMapInfo = false
      }
    },
    _deleteLocation: (state, { payload }) => {
      const { uid } = payload
      const allExceptSelf = state.userOwnedLocations.filter(
        obj => obj.uid !== uid,
      )
      state.userOwnedLocations = allExceptSelf
    },
  },
})

export default slice.reducer

const {
  _initializeLocatons,
  _clearLocations,
  _setWorkspaceTabIndex,
  _clearLocationsAndIndex,
  _updateLocationColor,
  _showLocationItemMapInfo,
  _hideLocationItemMapInfo,
  _deleteLocation,
} = slice.actions

export const initializeLocatons =
  (data: Partial<Array<UserOwnedLocationObject>>) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(_initializeLocatons(data))
    } catch (e) {
      throw new Error((e as Error).message)
    }
  }

export const deleteLocation =
  (data: Partial<UserOwnedLocationObject>) => (dispatch: AppDispatch) => {
    dispatch(_deleteLocation(data))
  }

export const updateLocationColor =
  (data: Partial<UserOwnedLocationObject>) => (dispatch: AppDispatch) => {
    try {
      dispatch(_updateLocationColor(data))
    } catch (e) {
      throw new Error((e as Error).message)
    }
  }

export const showLocationItemMapInfo =
  (data: Partial<UserOwnedLocationObject>) => (dispatch: AppDispatch) => {
    try {
      dispatch(_showLocationItemMapInfo(data))
    } catch (e) {
      throw new Error((e as Error).message)
    }
  }

export const hideLocationItemMapInfo =
  (data: Partial<UserOwnedLocationObject>) => (dispatch: AppDispatch) => {
    try {
      dispatch(_hideLocationItemMapInfo(data))
    } catch (e) {
      throw new Error((e as Error).message)
    }
  }

export const clearLocations = () => (dispatch: AppDispatch) => {
  try {
    dispatch(_clearLocations())
  } catch (e) {
    throw new Error((e as Error).message)
  }
}

export const clearLocationsAndIndex = () => (dispatch: AppDispatch) => {
  try {
    dispatch(_clearLocationsAndIndex())
  } catch (e) {
    throw new Error((e as Error).message)
  }
}

export const setWorkspaceTabIndex =
  (index: number) => (dispatch: AppDispatch) => {
    try {
      dispatch(_setWorkspaceTabIndex(index))
    } catch (e) {
      throw new Error((e as Error).message)
    }
  }
