/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useRef } from 'react'

import { MTDialog } from 'components/MTDialog'
import { modalTypes } from 'components/MTDialog/types'
import { useDialog } from 'contexts/DialogContext'
import { CONSTANTS } from 'core/constants'
import { Formik, Form, FieldArray } from 'formik'
import type { FormikState } from 'formik'
import { HTTPError } from 'ky'
import { PollOptions } from 'pages/Workspace/layouts/PlanningBoard/layouts/PollOptions'
import { UpdateGridItemProps } from 'pages/Workspace/layouts/PlanningBoard/types'
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks'
import {
  addOPtionsToTripGroupPollItem,
  castVoteOfTripGroupPollItem,
  removeOPtionFromTripGroupPollItem,
} from 'store/slices/tripGroupSlice'
import { tripItemTypes } from 'utils/itemTypes'
import { sendNotification } from 'utils/toast'
import * as Yup from 'yup'

import { update as tripPollItemUpdate } from 'api/tripPollItem'
import { update, remove } from 'api/tripPollItemOption'

import {
  PopupContainer,
  SmallerVoteContainer,
  ErrorsMsg,
  AnswerField,
  AddAnswerButton,
  OptionCancelButton,
  AddAnswerButtonContainer,
  NewOptionsContainer,
  Icon,
  CommentSectionContainer,
} from './pollItemPopup.style'
import { MTButton } from 'components'
import { PlusIcon } from 'components/MTIcons'
import { CommentSection } from 'components/CommentSection'

type Option = {
  uid: string
  title: string
}

type Props = {
  tripItem: any
  tripShortUid: string
  canEdit: boolean
  tripMembers: Array<any>
}

type ResetForm = (nextState?: Partial<FormikState<any>>) => void

type AddOptionProps = {
  resetForm: ResetForm
  answers: Array<string>
}
const PollItemPopup = ({
  tripItem,
  tripShortUid,
  canEdit,
  tripMembers,
}: Props) => {
  const { user } = useAppSelector(state => state.user)
  const { data: tripRoles } = useAppSelector(state => state.tripUserRoleSlice)
  const { tripGroups } = useAppSelector(state => state.tripGroupSlice)
  const dispatch = useAppDispatch()
  const [optionToBeDeleted, setOptionToBeDeleted] = useState<Option>({
    uid: '',
    title: '',
  })
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [hover, setHoverIndex] = useState<null | number>(null)
  const [tempNewOpt, setTempNewOpt] = useState<Array<string>>([])
  const { setOpenDialog } = useDialog()
  const myRef = useRef(false)
  const coordinates = useAppSelector(state => state.geoLocation)

  const OptionSchema = Yup.object().shape({
    answers: Yup.array()
      .of(Yup.string().required('Required'))
      .required('Required'),
  })

  /**
   * Handle voting and unvoting on an option
   * @param { Option } option poll option object
   */
  const onHandleOptionVote = async (option: Option) => {
    try {
      const res = await update({
        user_uid: user.uid,
        uid: option.uid,
      })
      if (res.uid === option.uid) {
        const voteAction = res?.users.some((u: any) => u.uid === user.uid)
          ? 'Voted'
          : 'Unvoted'
        sendNotification(`${voteAction} "${option.title}"`, 'success')

        let item: any = {}
        let tripGroupOfThatItem: any = {}

        for (const tripGroup of tripGroups) {
          for (const tripGroupItem of tripGroup.tripItems) {
            if (tripGroupItem.uid === tripItem.uid) {
              tripGroupOfThatItem = tripGroup
              break
            }
          }
        }

        if (tripGroupOfThatItem && tripGroupOfThatItem.tripItems) {
          for (const groupItem of tripGroupOfThatItem.tripItems) {
            if (groupItem.uid === tripItem.uid) {
              item = groupItem
              break
            }
          }

          const payload = {
            groupUid: tripGroupOfThatItem.uid,
            tripItemUid: item.uid,
            updatedOption: res,
          }

          await dispatch(castVoteOfTripGroupPollItem(payload))
        }
      }
    } catch (error) {
      const { message } =
        (await (error as HTTPError)?.response?.json()) || error
      sendNotification(message, 'error')
      console.log('error occured: ', error)
    }
  }

  /**
   * Handle deleting an option
   */
  const onHandleDelete = async () => {
    if (user?.is_tentative && !user?.is_trip_creator) {
      return setOpenDialog({
        show: true,
        type: modalTypes.SIGN_UP_TO_AS_NON_ADMIN_TO_EDIT_TRIP,
      })
    }

    const option = optionToBeDeleted
    try {
      const res = await remove({
        user_uid: user.uid,
        uid: option?.uid,
      })
      if (res) {
        let item: any = {}
        let tripGroupOfThatItem: any = {}

        for (const tripGroup of tripGroups) {
          for (const tripGroupItem of tripGroup.tripItems) {
            if (tripGroupItem.uid === tripItem.uid) {
              tripGroupOfThatItem = tripGroup
              break
            }
          }
        }
        if (tripGroupOfThatItem && tripGroupOfThatItem.tripItems) {
          for (const groupItem of tripGroupOfThatItem.tripItems) {
            if (groupItem.uid === tripItem.uid) {
              item = groupItem
              break
            }
          }
          const payload = {
            groupUid: tripGroupOfThatItem.uid,
            tripItemUid: item.uid,
            optionToBeRemoved: optionToBeDeleted,
          }
          await dispatch(removeOPtionFromTripGroupPollItem(payload))
        }
        sendNotification(
          `${CONSTANTS.DELETE_POLL_OPTION_SUCESS} ${option?.title}!`,
          'success',
        )
      }
      onHandleCloseItemDeletePopupModal()
    } catch (error) {
      const { message } =
        (await (error as HTTPError)?.response?.json()) || error

      sendNotification(message, 'error')
      console.log('error occured: ', error)
    }
  }

  /**
   * Open delete modal for grid item
   */
  const onOpenItemDeletePopupModal = async (option: Option) => {
    if (user?.is_tentative && !user?.is_trip_creator) {
      return setOpenDialog({
        show: true,
        type: modalTypes.SIGN_UP_TO_AS_NON_ADMIN_TO_EDIT_TRIP,
      })
    }

    if (!canEdit)
      return sendNotification(
        `${CONSTANTS.DELETE_POLL_OPTION_PERMISSION_ERROR}`,
        'error',
      )
    setOptionToBeDeleted(option)
    setOpenDeleteDialog(true)
  }

  /**
   * Close delete dialogue
   */
  const onHandleCloseItemDeletePopupModal = () => {
    setOpenDeleteDialog(false)
  }

  /**
   * Cancel poll deletion
   */
  const onHandleCancel = () => {
    onHandleCloseItemDeletePopupModal()
  }

  /**
   * Validate options prior to adding
   * @param { AddOptionProps } args - resetForm, answers
   */
  const handleAddOption = async ({ resetForm, answers }: AddOptionProps) => {
    if (myRef.current) {
      myRef.current = false
      return
    }
    if (user?.is_tentative && !user?.is_trip_creator) {
      return setOpenDialog({
        show: true,
        type: modalTypes.SIGN_UP_TO_AS_NON_ADMIN_TO_EDIT_TRIP,
      })
    }

    if (!canEdit)
      return sendNotification(
        `${CONSTANTS.ADD_POLL_OPTIONS_PERMISSION_ERROR}`,
        'error',
      )
    const options = answers
    tripItem.trip_poll_item_options.map((poll_option: Option) => {
      answers = answers.filter(answer => answer !== poll_option.title)
    })

    // Filter new options with the same names as old options
    if (answers.length < options.length)
      return sendNotification(
        `${CONSTANTS.POLL_ITEM_DUPLICATE_OPTION_FOUND_ERROR}`,
        'error',
      )

    // Filter new duplicate options
    answers = answers.filter((v, i) => answers.indexOf(v) == i)
    if (answers.length < options.length)
      return sendNotification(
        `${CONSTANTS.POLL_ITEM_ADD_DUPLICATE_OPTION_ERROR}`,
        'error',
      )

    setTempNewOpt([...tempNewOpt, ...answers])
    resetForm()
    addOptions(options)
    myRef.current = false
  }

  /**
   * Add new options
   * @param { Array<string> } options array of new options
   */
  const addOptions = async (options: Array<string>) => {
    try {
      const res = await tripPollItemUpdate({
        user_uid: user.uid,
        uid: tripItem.uid,
        short_uid: tripShortUid,
        poll_options: options,
        coordinates,
      })

      if (res) {
        sendNotification(
          `${CONSTANTS.POLL_ITEM_ADD_OPTIONS_SUCCESS}`,
          'success',
        )
        let tripGroupOfThatItem: any = {}
        for (const tripGroup of tripGroups) {
          for (const tripGroupItem of tripGroup.tripItems) {
            if (tripGroupItem.uid === tripItem.uid) {
              tripGroupOfThatItem = tripGroup
              break
            }
          }
        }

        const payload = {
          groupUid: tripGroupOfThatItem.uid,
          tripItemUid: tripItem.uid,
          updatedOption: res.trip_poll_item_options,
        }

        await dispatch(addOPtionsToTripGroupPollItem(payload))
      }
    } catch (error) {
      const { message } =
        (await (error as HTTPError)?.response?.json()) || error
      console.log('Error: ', error)
      sendNotification(message, 'error')
      console.log('error occured: ', error)
    }
  }

  return (
    <div style={{ overflow: 'auto', maxHeight:'60vh'}}>
      <PopupContainer
        style={{
          margin: '5px',
          display: 'flex',
          alignItems: 'center',
        }}>
        <PollOptions
          options={tripItem.trip_poll_item_options}
          onHandleOptionVote={onHandleOptionVote}
          onOpenItemDeletePopupModal = {onOpenItemDeletePopupModal}
          tripMembersCount={tripMembers.length}
          fullView={true}
        />
        <MTDialog
          openDialog={openDeleteDialog}
          onCloseModal={onHandleCloseItemDeletePopupModal}
          onDeleteTripClickHandler={onHandleDelete}
          onCancelClickHandler={onHandleCancel}
          itemToBeDeleted={CONSTANTS.OPTION_TO_BE_DELETED}
          type={modalTypes.DELETE_ITEM}
        />
      </PopupContainer>
      <div>
        <Formik
          initialValues={{ answers: [] }}
          validationSchema={OptionSchema}
          onSubmit={({ answers }, { resetForm }) =>
            handleAddOption({ answers, resetForm })
          }>
          {({ values, handleSubmit, errors, touched }) => (
            <Form
              onKeyDown={e => {
                if ((e.charCode || e.keyCode) === 13) {
                  e.preventDefault()
                  handleSubmit()
                }
              }}
              onBlur={e => {
                e.preventDefault()
                setTimeout(() => {
                  handleSubmit()
                }, 0)
              }}>
              <FieldArray
                name="answers"
                render={arrayHelpers => (
                  <NewOptionsContainer>
                    {values.answers && values.answers.length > 0
                      ? values.answers.map((answer, index) => {
                          return (
                            <div
                              key={index}
                              style={{ position: 'relative' }}
                              onMouseEnter={() => setHoverIndex(index)}
                              onMouseLeave={() => setHoverIndex(null)}>
                              <AnswerField
                                name={`answers.${index}`}
                                data-testid="answer"
                                maxLength="200"
                                placeholder={`Answer ${index + 1}...`}
                              />
                              {errors.answers && touched.answers ? (
                                <ErrorsMsg>{errors.answers[index]}</ErrorsMsg>
                              ) : null}
                              {hover === index && (
                                <OptionCancelButton
                                  onMouseDown={() => {
                                    myRef.current = true
                                    arrayHelpers.remove(index)
                                  }}>
                                  <Icon />
                                </OptionCancelButton>
                              )}
                            </div>
                          )
                        })
                      : null}

                    <AddAnswerButtonContainer>
                      {values.answers.length < 5 ? (
                        <MTButton
                          buttonFontSize={14}
                          customStyles={{ backgroundColor: 'transparent', boxShadow: 'none', color: '#4184FF'}}
                          disabled={!canEdit}
                          onHandleClick={() => arrayHelpers.push('')}
                          title = {CONSTANTS.POLL_ITEM_ADD_OPTIONS_BUTTON}
                          customIcon = {<PlusIcon stroke='#4184FF' height='16px' width='16px'/>}
                        />
                      ) : null}
                    </AddAnswerButtonContainer>
                  </NewOptionsContainer>
                )}
              />
            </Form>
          )}
        </Formik>
        <CommentSectionContainer>
          <CommentSection trip_short_uid={tripShortUid} trip_item_uid={tripItem.uid} type='poll'/>
        </CommentSectionContainer>
      </div>
    </div>
  )
}

export default PollItemPopup
