/* eslint-disable react/jsx-no-duplicate-props */
import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useRef,
} from 'react'

import GoogleMapReact from 'google-map-react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { resetMapInfoForSearchGridItem } from 'store/slices/locationSearchResults'
import { getGooglePlaceInformation } from 'utils/googlePlace'
import { tabletMobileScreenSize } from 'utils/screenSzie'

import { updateTripCoords } from 'store/slices/tripCoords'

import { LocationMarker } from './components/LocationMarker'
import { latDifference } from './helper'
import { MapInfo } from './layouts/MapInfo'
import {
  MapContainerDiv,
  ExpandButton,
  MapButtonGroup,
  ZoomButtonGroup,
  MapCenterButton,
  MapZoomOutButton,
  MapZoomInButton,
  ExpandButtonContainer,
} from './map.styles'
import {
  CollapseIcon,
  ExpandIcon,
  MapCenterIcon,
  MapZoomInIcon,
  MapZoomOutIcon,
} from 'components/MTIcons'
import { LocationData } from './layouts/MapInfo/MapInfo'
import CustomToolTip from 'components/CustomTooltip/CustomTooltip'

export const mapOptions = {
  fullscreenControl: false,
  clickableIcons: false,
  disableDefaultUI: true,
}

export const mapConfig = {
  key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
  language: 'en',
}

export type LocationMarkerType = {
  placeId: string
  lat?: number
  lng?: number
}

export type LocationPinType = {
  key?: number
  placeIsPartOfTrip?: boolean
  handleClick?: (val: string) => void
  planningBoardLocationItemColor?: string
  showMarkerMapInfo?: boolean
  placeTitle?: string
  uid?: string
  planningBoardOnly?: boolean
  locationItemColor?: string
  color?: string
  placeId: string
  lat: number
  lng: number
}

type DragEvent = {
  center: {
    lat: () => number
    lng: () => number
  }
}

type MapProps = {
  mapExpanded: boolean
  setMapExpanded?: Dispatch<SetStateAction<boolean>>
  tripShortUid?: string
  destinationLat?: number
  destinationLng?: number
  tripStartDate?: string
  tripEndDate?: string
  showMapInfo: boolean
  showExpandButton?: boolean
  locationData?: LocationData
  isLocationItem?: boolean
}
type MapCenter = {
  lat: number
  lng: number
}
const Map = ({
  tripShortUid,
  destinationLat,
  destinationLng,
  tripStartDate,
  tripEndDate,
  showMapInfo,
  mapExpanded,
  setMapExpanded,
  showExpandButton,
  locationData,
  isLocationItem,
}: MapProps) => {
  const dispatch = useAppDispatch()
  // google.maps.places.PlaceResul
  const [selectedPlace, setSelectedPlace] = useState<google.maps.places.PlaceResult>({})
  const [mapCenterCoords, setMapCenterCoords] = useState<MapCenter>({
    lat: destinationLat ?? 0,
    lng: destinationLng ?? 0,
  })
  const [closeMapInfo, setCloseMapInfo] = useState(true)
  const mapRef = useRef(null)
  // return all searched places from state
  const state = useAppSelector(state => state.locationSearchResults)
  const { workspaceTabIndex, userOwnedLocations } = useAppSelector(
    state => state.tripItemLocationsMap,
  )
  const { places, endingIndex, mapInfoPlaceId, mapCenter, mapBounds } = state

  const tabletMobileView = tabletMobileScreenSize()
  const [zoom, setZoom] = useState(locationData ? 16 : 12)
  /**
   * Handle marker being clicked. Display additional information
   * about the clicked location.
   *
   * @param { LocationMarkerType } marker clicked marker
   */
  const onHandleMarkerClick = (marker: LocationMarkerType) => {
    getGooglePlaceInformation({
      place_id: marker.placeId,
      setSelectedPlace,
    })

    if (marker.lat && marker.lng) {
      setMapCenterCoords({
        lat: marker?.lat - parseFloat(latDifference[zoom]),
        lng: marker?.lng,
      })
    }
    setCloseMapInfo(false)
  }

  const LocationPin = (locationPin: LocationPinType) => {
    return (
      <LocationMarker
        placeIsPartOfTrip={locationPin.placeIsPartOfTrip}
        handleClick={() => onHandleMarkerClick(locationPin)}
        planningBoardLocationItemColor={
          locationPin.planningBoardLocationItemColor
        }
        showMarkerMapInfo={locationPin.showMarkerMapInfo}
        placeTitle={locationPin.placeTitle}
        uid={locationPin.uid}
        planningBoardOnly={locationPin.planningBoardOnly}
        locationItemColor={locationPin.color}
        placeId={locationPin.placeId}
        lat={locationPin.lat}
        lng={locationPin.lng}
        selected={!closeMapInfo && selectedPlace.place_id === locationPin.placeId}
      />
    )
  }

  /**
   * Set new search coordinates for places search.
   * @param { Object } event drag object
   */
  const onDragEnd = (event: DragEvent) => {
    const { center } = event
    const curLat = center.lat()
    const curLng = center.lng()

    dispatch(
      updateTripCoords({
        lat: curLat,
        lng: curLng,
      }),
    )
  }

  /**
   * Clicking on map when MapInfo container is open
   * will force close it.
   */
  const onMapClick = () => {
    dispatch(resetMapInfoForSearchGridItem())
  }

  useEffect(() => {
    if (mapInfoPlaceId) {
      onHandleMarkerClick({ placeId: mapInfoPlaceId })
    }

    if (locationData && locationData.place_id) {
      onHandleMarkerClick({ placeId: locationData?.place_id })
    }
    if (mapCenter?.lng && mapCenter?.lat && workspaceTabIndex === 1) {
      setMapCenterCoords(mapCenter)
    }
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(userOwnedLocations),
    mapInfoPlaceId,
    mapCenter,
    workspaceTabIndex,
    places,
    locationData,
  ])

  return (
    <MapContainerDiv
      tabletMobileView={tabletMobileView}
      isLocationPopup={!!locationData}
      mapExpanded={mapExpanded}>
      <GoogleMapReact
        ref={mapRef}
        options={mapOptions}
        bootstrapURLKeys={mapConfig}
        center={
          locationData?.lat && locationData?.lng
            ? {
                lat: locationData.lat,
                lng: locationData.lng,
              }
            : mapCenterCoords
        }
        defaultZoom={12}
        zoom={zoom}
        onDragEnd={e => onDragEnd(e)}
        onClick={onMapClick}
        onChange={e => setZoom(e.zoom)}
        yesIWantToUseGoogleMapApiInternals>
        {workspaceTabIndex === 0 &&
          !locationData &&
          userOwnedLocations &&
          userOwnedLocations.length > 0 &&
          userOwnedLocations.map((loc, idx) => (
            <LocationPin
              key={idx}
              lat={loc.lat}
              lng={loc.lng}
              placeId={loc.place_id}
              planningBoardLocationItemColor={loc.color}
              showMarkerMapInfo={loc.showMarkerMapInfo}
              placeTitle={loc.title}
              uid={loc.uid}
              planningBoardOnly={true}
            />
          ))}
        {workspaceTabIndex !== 0 &&
          !locationData &&
          places &&
          places.length > 0 &&
          places
            .slice(0, endingIndex)
            .map((loc, idx: number) => (
              <LocationPin
                key={idx}
                lat={loc.lat}
                lng={loc.lng}
                placeId={loc.place_id}
                placeIsPartOfTrip={loc.placeIsPartOfTrip}
                showMarkerMapInfo={loc.showMarkerMapInfo}
                placeTitle={loc.name}
                color={loc.color}
              />
            ))}
        {locationData && (
          <LocationPin
            placeId={locationData.place_id!}
            lat={locationData?.lat ?? 0}
            lng={locationData?.lng ?? 0}
          />
        )}
      </GoogleMapReact>
      <MapButtonGroup
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
        }}
        tabletMobileView={tabletMobileView}
        isLocationPopup={!!locationData}
        mapExpanded={mapExpanded}
        closeMapInfo={closeMapInfo}>
        <CustomToolTip title={'Center'} showonClick={false}>
          <MapCenterButton
            onClick={() => {
              setZoom(locationData ? 16 : 12)
              setMapCenterCoords(mapCenterCoords)
            }}>
            <MapCenterIcon height="24" width="24" stroke="#686868" />
          </MapCenterButton>
        </CustomToolTip>
        <ZoomButtonGroup>
          <CustomToolTip title={'Zoom in'} showonClick={false}>
            <MapZoomInButton onClick={() => setZoom(zoom + 1)}>
              <MapZoomInIcon height="24" width="24" stroke="#686868" />
            </MapZoomInButton>
          </CustomToolTip>

          <CustomToolTip title={'Zoom out'} showonClick={false}>
            <MapZoomOutButton onClick={() => setZoom(zoom > 1 ? zoom - 1 : 0)}>
              <MapZoomOutIcon height="24" width="24" stroke="#686868" />
            </MapZoomOutButton>
          </CustomToolTip>
        </ZoomButtonGroup>
      </MapButtonGroup>
      {showExpandButton && (
        <ExpandButtonContainer>
          <CustomToolTip
            title={mapExpanded ? 'Collapse' : 'Expand'}
            showonClick={false}>
            <ExpandButton
              onClick={() => {
                setMapExpanded!(!mapExpanded)
              }}>
              {mapExpanded ? (
                <CollapseIcon height="24" width="24" stroke="#686868" />
              ) : (
                <ExpandIcon height="24" width="24" stroke="#686868" />
              )}
            </ExpandButton>
          </CustomToolTip>
        </ExpandButtonContainer>
      )}

      <MapInfo
        showMapInfo={showMapInfo}
        showAddToBoardButton={true}
        tripShortUid={tripShortUid}
        locationData={(locationData ?? selectedPlace) as LocationData}
        closeMapInfo={closeMapInfo}
        setCloseMapInfo={setCloseMapInfo}
        tripStartDate={tripStartDate}
        tripEndDate={tripEndDate}
        isLocationItem={isLocationItem}
        height="310px"
      />
    </MapContainerDiv>
  )
}

export default Map
