import styled from '@emotion/styled'

export const styles = ({ colors }) => {
  return {
    viewalltripscontainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '2em 0 4em 0',
    },
    allTripsButton: {
      fontSize: '1.7rem',
      color: colors.primaryBlue,
      textTransform: 'capitalize',
    },
  }
}


export const TitleText = styled.p`
  margin: 0;
  font-size: 60px;
  line-height: 70px;
  letter-spacing: -3%;
  font-weight: 900;
`

export const InfoText = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  color: #686868;
`

export const TripsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 25px;
  justify-content: center;
  margin-bottom: 100px;
`

export const TripsInformation = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  margin-bottom: 32px;
`
