import { compact } from 'lodash'

type GooglePlaceInfoObject = {
  place_id: string
  setSelectedPlace?: (place: PlaceDetailsObject) => void
  setPlaceHeroPhoto?: (photo: string) => void
  setPlacePhotos?: (
    photos: string[] | ((oldPhotos: string[]) => string[]),
  ) => void
}
export type PlaceResult = google.maps.places.PlaceResult | null
type PlacesServiceStatus = google.maps.places.PlacesServiceStatus

type OpeningHoursObject = {
  day: string
  start_time: string
  end_time: string
  is_closed: boolean
}

type PlaceDetailsObject = {
  hoursOfOperations: Array<OpeningHoursObject>
} & PlaceResult

/**
 * Google Place fields
 */
export const placeFields = [
  'place_id',
  'name',
  'formatted_address',
  'geometry',
  'formatted_phone_number',
  'website',
  'photos',
  'rating',
  'price_level',
  'user_ratings_total',
  'utc_offset_minutes',
  'opening_hours',
]

/**
 * If Google Place contains hours of operations, parse them into a readable
 * format for the user.
 *
 * @param { PlaceResult } place Google Place PlaceResult Object
 */
export const parseOpeningHours = (place: PlaceResult) => {
  const openTime = []

  if (
    place?.opening_hours?.periods &&
    place?.opening_hours?.periods.length > 0
  ) {
    const periods = place?.opening_hours?.periods
    // Open 24 hours
    if (periods[0].close === undefined) {
      for (let i = 0; i < 7; i++) {
        openTime.push({
          day: `${i}`,
          start_time: '0000',
          end_time: '2359',
          is_closed: false,
        })
      }
    } else {
      for (let i = 0; i < 7; i++) {
        openTime.push({
          day: `${i}`,
          start_time: '',
          end_time: '',
          is_closed: true,
        })
      }
      for (let i = 0; i < periods.length; i++) {
        openTime[periods[i].open.day] = {
          day: `${periods[i].open.day}`,
          start_time: periods[i].open.time,
          end_time: place?.opening_hours?.periods[i]?.close?.time,
          is_closed: false,
        }
      }
    }
  }
  return openTime
}

/**
 *
 * @param { PlaceResult } place google place
 * @param { Array<OpeningHoursObject> } openTime array of hours
 * @returns
 */
const placeDetails = (
  place: PlaceResult,
  openTime: Array<OpeningHoursObject>,
) => ({
  place_id: place?.place_id,
  title: place?.name,
  address: place?.formatted_address,
  lat: place?.geometry?.location?.lat(),
  lng: place?.geometry?.location?.lng(),
  website: place?.website,
  phone: place?.formatted_phone_number,
  photos: compact(place?.photos),
  rating: place?.rating,
  price_level: place?.price_level,
  user_ratings_total: place?.user_ratings_total,
  utc_offset_minutes: place?.utc_offset_minutes,
  opening_hours: place?.opening_hours,
  weekday_text: place?.opening_hours && place?.opening_hours.weekday_text,
  hoursOfOperations: openTime,
})

/**
 * Get Google place information.
 * @param {String} id place_id
 * @param {Function} setSelectedPlace callback to set place to local state
 * @param {Function} setPlaceHeroPhoto callback to set place photo to local state
 */
export const getGooglePlaceInformation = ({
  place_id,
  setSelectedPlace,
  setPlaceHeroPhoto,
  setPlacePhotos,
}: GooglePlaceInfoObject) => {
  const service = new window.google.maps.places.PlacesService(
    document.createElement('div'),
  )

  const request = {
    placeId: place_id,
    fields: placeFields,
  }

  /**
   * Callback from google service with place data.
   * @param {Object} place Google place object
   * @param {Number} status Return status
   */
  const callback = (place: PlaceResult, status: PlacesServiceStatus) => {
    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let openTime: any = []
      let googlePlaceDetailsObject = null

      if (place?.opening_hours && place?.utc_offset_minutes) {
        openTime = parseOpeningHours(place)
      }

      if (place) {
        googlePlaceDetailsObject = placeDetails(place, openTime)
        googlePlaceDetailsObject = {
          ...googlePlaceDetailsObject,
          ...{
            place_id: place?.place_id,
            photos: place?.photos,
            hoursOfOperations: openTime,
          },
        }
      }

      if (
        googlePlaceDetailsObject?.photos &&
        googlePlaceDetailsObject?.photos.length > 0
      ) {
        const randomNumber =
          Math.floor(
            Math.random() * (googlePlaceDetailsObject?.photos.length - 1),
          ) + 0

        const placePhoto = googlePlaceDetailsObject.photos[randomNumber].getUrl(
          {
            maxWidth: 1280,
            maxHeight: 720,
          },
        )
        if (setPlaceHeroPhoto) setPlaceHeroPhoto(placePhoto)

        if (setPlacePhotos) {
          const photosArray = googlePlaceDetailsObject.photos.map(photo => {
            return photo.getUrl({
              maxWidth: 320,
              maxHeight: 426,
            })
          })
          setPlacePhotos((oldPlacePhotos: string[]) => [
            ...oldPlacePhotos,
            ...photosArray,
          ])
        }
      }
      if (setSelectedPlace && googlePlaceDetailsObject)
        setSelectedPlace(googlePlaceDetailsObject)
    }
  }

  service.getDetails(request, callback)
}
