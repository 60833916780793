import { passwordRegex } from 'utils/validators'
import * as Yup from 'yup'

export const validationSchema = Yup.object({
  password: Yup.string()
    .min(8, 'Too Short!')
    .max(20, 'Too Long!')
    .required(
      'Password must be min. 8 characters and contain a mix of uppercase, lowercase and special characters!',
    )
    .matches(
      passwordRegex,
      'Password must be min. 8 characters and contain a mix of uppercase, lowercase and special characters!',
    ),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match',
  ),
})
