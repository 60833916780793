export const style = () => {
  return {
    gutters: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    root: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    list: {
      width: '100%',
      maxWidth: 360,
    },
    listItemIconRoot: {
      minWidth: 0,
    },
  }
}
