import styled from '@emotion/styled'
import AnimateHeight from 'react-animate-height'

export const MapContainer = styled(AnimateHeight)`
  width: 30%;
  position: absolute;
  box-shadow: none;
  border-radius: 12px;
  overflow: hidden;
  right: 32px;
  display: inherit;
  box-shadow: 0px 2px 9px -1px rgb(0 0 0 / 10%);
  top: 2.5vh;
  height: 820px !important;
`

type MapContainerDivProp = {
  tabletMobileView: boolean
  isLocationPopup: boolean
  mapExpanded: boolean
  closeMapInfo?: boolean
}



export const MapContainerDiv = styled.div<MapContainerDivProp>`
  margin-top: ${props =>
    props.tabletMobileView || props.isLocationPopup ? '10px' : '-280px'};
  height: ${props => (props.tabletMobileView ? '350px' :  'calc(290px + 280px)')};
  height: ${props => (props.mapExpanded ? 'calc(100vh - 83px)': '')};
  height: ${props => (props.isLocationPopup ? '35rem': '')};
  position: ${props => (props.mapExpanded ? 'sticky': 'relative')};
  top: ${props => (props.mapExpanded ? '83px': '0')};
  box-shadow: none;
  min-height: ${props => (props.isLocationPopup ? '0': '350px')};
  border-radius: 12px;
  box-shadow: 0px 2px 9px -1px rgb(0 0 0 / 10%);
  width: ${ props => (props.tabletMobileView ? !props.isLocationPopup ? 'calc(100vw - 32px)' : '100%' : '100%')};
  & div:nth-of-type(1) {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-radius: ${ props => (props.tabletMobileView ? '12px' : '')}
  }
  &:focus,
  &:hover {
    outline: none;
    border: none
  }
`
export const ExpandButtonContainer = styled.div`
position: absolute;
top: 12px;
right: 12px;
border: none;
border-radius: 6px;
cursor: pointer;
z-index: 1000;
`

export const ExpandButton = styled.button`
background-color: white;
border: none;
border-radius: 6px;
cursor: pointer;
z-index: 1000;
padding: 4px;
box-shadow: 0px 1px 2px 0px #0000004D;
`

export const MapButtonGroup = styled.div<MapContainerDivProp>`
position: absolute;
width: 32px;
top: ${props => (props.tabletMobileView ? (props.closeMapInfo ? 'calc((350px - 100px) / 2)' : 'calc(50% - 110px)') :  (props.closeMapInfo ?'calc((290px + 280px - 100px) / 2)' : 'calc(50% - 110px)'))};
top: ${props => (props.mapExpanded ? (props.closeMapInfo ? 'calc((100vh - 83px - 100px) / 2 )' : 'calc(50% - 110px)') : '')};
top: ${props => (props.isLocationPopup ? 'calc((35rem - 100px) / 2 )': '')};
right: ${props => (props.tabletMobileView && props.isLocationPopup ? '2px' : '12px')};
border: none;
cursor: pointer;
z-index: 1000;
display: flex;
flex-direction: column;
border-radius: 6px;
`

export const MapCenterButton = styled.div`
display: flex;
align-items: center;
justify-content: center;
background-color: white;
border: none;
cursor: pointer;
z-index: 1000;
padding: 4px;
height: 32px;
width: 32px;
border-radius: 6px !important;
box-shadow: 0px 1px 2px 0px #0000004D;
`
export const MapZoomInButton = styled.div`
display: flex;
align-items: center;
justify-content: center;
background-color: white;
border: none;
cursor: pointer;
z-index: 1000;
padding: 0;
height: 32px;
width: 26px;
`
export const MapZoomOutButton = styled.div`
display: flex;
align-items: center;
justify-content: center;
background-color: white;
padding: 0;
border: none;
cursor: pointer;
z-index: 1000;
height: 32px;
width: 26px;
border-top: 1px solid #E5E5E5;
border-radius: 0 !important;
`
export const ZoomButtonGroup = styled.div`
border: none;
width: 32px;
background-color: white;
align-items: center;
justify-content: center;
border-radius: 6px !important;
border-top-left-radius: 6px !important;
border-bottom-left-radius:6px !important;
cursor: pointer;
z-index: 1000;
display: flex;
flex-direction: column;
margin-top: 4px;
box-shadow: 0px 1px 2px 0px #0000004D;
`
