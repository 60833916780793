import React, { useState, Fragment, MouseEvent } from 'react'

import {
  makeStyles,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core'
import { MTAvatar } from 'components/MTAvatar'
import { CONSTANTS } from 'core/constants'
import { isNil, isNull } from 'lodash'
import mixpanel from 'mixpanel-browser'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { resetUserStore } from 'store'
import { useAppSelector } from 'store/hooks'
import { sendNotification } from 'utils/toast'

import {
  styles,
  Options,
  Container,
  ExitToAppIconStyled,
  AccountBoxIconStyled,
  IconButtonStyled,
} from './navAuthMenu.styles'

const useStyles = makeStyles(() => styles())

const NavAuthMenu = () => {
  const { user } = useAppSelector(state => state.user)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const classes = useStyles()
  const isMenuOpen = Boolean(anchorEl)
  const menuId = 'primary-search-account-menu'
  const dispatch = useDispatch()
  const history = useHistory()

  const handleProfileMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  /**
   * Close auth modal
   */
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  /**
   * Display auth modal or sign user out
   * @param {String} option auth option
   */
  const onSignout = () => {
    mixpanel.reset()
    handleMenuClose()
    resetUserStore(dispatch)
    history.push('/create-trip')
    sendNotification(`${CONSTANTS.SIGN_OUT_SUCCESS}`, 'success')
  }

  /**
   * Display the profile section
   * @param {String} option auth option
   */
  const onProfile = () => {
    history.push('/profile')
  }

  return (
    <Fragment>
      <Container>
        <Options>
          {!isNull(user) && (
            <IconButtonStyled
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit">
              <div>
                <MTAvatar
                  displayName={
                    !isNil(user) && !isNull(user.firstName)
                      ? user?.firstName.substring(0, 1)
                      : ''
                  }
                  verticalAnimation={false}
                />
              </div>
            </IconButtonStyled>
          )}
        </Options>

        <Menu
          anchorEl={anchorEl}
          id={menuId}
          keepMounted
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          disableScrollLock
          disableRestoreFocus
          open={isMenuOpen}
          onClose={handleMenuClose}
          getContentAnchorEl={null}
          classes={{ paper: classes.paper }}>
          <MenuItem onClick={onProfile}>
            <ListItemIcon>
              <AccountBoxIconStyled fontSize="large" />
            </ListItemIcon>
            <ListItemText classes={{ root: classes.menuItemText }}>
              {CONSTANTS.PROFILE}
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={onSignout}>
            <ListItemIcon>
              <ExitToAppIconStyled fontSize="large" />
            </ListItemIcon>
            <ListItemText classes={{ root: classes.menuItemText }}>
              {CONSTANTS.SIGN_OUT}
            </ListItemText>
          </MenuItem>
        </Menu>
      </Container>
    </Fragment>
  )
}

export default NavAuthMenu
