export const style = () => {
  return {
    membersContainer: {
      padding: '1rem',
      display: 'flex',
      flexDirection: 'column',
      width: 300,
    },
    tripItemMembersContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    tripMembersContainer: {},
    tripMembersContainerInner: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    tripMembersContainerText: {
      color: '#686868',
    },
  }
}
