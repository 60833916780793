import React from 'react'

import { MTButton } from 'components'
import { CONSTANTS } from 'core/constants'
import { useQuery } from 'hooks/useQuery'
import { useHistory } from 'react-router-dom'
import { cssVariables } from 'theme'

import { Container, ErrorMessage, ErrorLogo } from './404.style'
import errorLogo from './assets/404.svg'

type Message = { message: string }

const FourOhFour = () => {
  const history = useHistory<Message>()
  const query = useQuery()
  const apiError = query.get('message')
  let errorMessage = ''

  if (history?.location?.state) {
    errorMessage = history?.location?.state?.message as string
  } else if (apiError) {
    errorMessage = CONSTANTS.ERROR_JWT_EXPIRED
  } else {
    errorMessage = CONSTANTS.ERROR_GENERAL
  }

  return (
    <Container>
      <ErrorMessage>{`Uh-oh! ${errorMessage}`}</ErrorMessage>
      <MTButton
        customStyles={{
          color: 'white',
          backgroundColor: cssVariables.colors.primaryBlue,
          height: '40px',
          padding: '8px 12px'

        }}

        onHandleClick={() => history.push('/')}
        title={CONSTANTS.FOUR_OH_FOUR}
      />
      <ErrorLogo src={errorLogo} alt="error" />
    </Container>
  )
}

export default FourOhFour
