import styled from '@emotion/styled'
import { Menu, TextField, withStyles } from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { Field, Form } from 'formik'

export const ParentContainer = styled.div`
  background-color: white;
  margin-bottom: 2rem;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0px 5px 11px -1px rgba(0, 0, 0, 0.1);
  width: 70%;
  max-width: 850px;

  @media (max-width: 960px) {
    width: 90%;
  }

  @media (max-width: 690px) {
    width: 95%;
  }
`

export const Title = styled(Field)`
  font-size: 2.5em;
  border: 1px solid white;
  width: 100%;
  flex: 9;
  &:hover {
    border: 1px solid white;
  }
  &:focus {
    border: 1px solid white;
    outline: none;
  }
  &:disabled {
    background-color: white;
  }
  margin-bottom: 1rem;

  input {
    text-overflow: ellipsis;
  }
  min-width: 210px;
`
export const HeroMenuButton = styled.button`
  float: right;
  margin-top: -3px;
  &:hover {
    cursor: pointer;
  }
  width: 2.5rem;
  border: 0;
  margin-right: 5px;
  background-color: transparent;
`
export const Icon = styled(MoreHorizIcon)`
  font-size: 26px;
  line-height: 1;
`

export const MenuStyled = styled(Menu)`
  font-size: 1.5rem;
  border-radius: 12px;
  margin-top: 15px;
  margin-right: 5px;
`

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
`

export const Span = styled.span`
  font-size: 2em;
  margin: 0 1rem;
`

export const CustomTextField = withStyles({
  root: {
    '& .MuiInput-underline:before': {
      borderBottom: '0',
    },
    '& .MuiInputBase-input': {
      fontSize: '2rem',
      fontWeight: 'bold',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottom: '0',
    },
  },
})(TextField)

interface HeaderProps{
  isCommunityTrip: boolean
}

export const Header = styled.div<HeaderProps>`
  display: flex;
  justify-content: space-between;
  flex-wrap: ${props => (props.isCommunityTrip? 'wrap': 'no-wrap')};
  align-items: ${props => (props.isCommunityTrip? 'center': 'flex-start')}
`

export const Body = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
`

export const MembersContainer = styled.div`
  margin: 0 0 0 auto;
`

export const OwnerNameText = styled.p`
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  line-height: 24px;
  max-width: fit-content;
  margin-left: auto;
  margin: 0;
  color: #3A3A3A
`

export const DuplicateButton = styled.button`
  background-color: transparent;
  border: 0;
  padding: 6px 8px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  background-color: #4184FF;
  color: white;

`
export const MenuItemText = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
`

