import styled from '@emotion/styled'
import { Spinner } from 'reactstrap'

export const CommentsContainer = styled.div`
  display: flex;
  gap: 12px;
`

export const CommentBodyContainer = styled.div`
    padding-left: 1rem;
`

export const CommentLoadingText = styled.div`
  margin: 1 rem;
`

export const FullWidthDiv = styled.div`
  width: 100%;
`

export const CommentInputContainer = styled.div`
  padding-left: 1rem;
  display: flex;
`

export const EntryParentContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`
export const SpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
export const StyledSpinner = styled(Spinner)`
  height: 16px;
  width: 16px;
  color: #686868;
`
export const PlaceHolderInput = styled.input`
  border: 0;
  outline: 0;
  font-size: 14px;
`
export const CommentIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 32px;
  height: 32px;
`
export const CommentButton = styled.button`
  height: 32px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-top: 12px;
  background-color: white;
  border: 1px solid #8F8F8F;
  border-radius: 6px;
  width: 86px;
  color: #3A3A3A;
  transition: box-shadow 0.2s ease-in-out;
  &:not(:disabled):hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    cursor: not-allowed;
  }
`
export const CommentText = styled.p`
  margin: 0;
  paddig: 0;
`
