import { NextIcon, RightIcon, SearchIcon } from 'components/MTIcons'
import CommunityLogo from 'components/MTIcons/CommunityLogo'
import React, { useState, useEffect } from 'react'
import { Tag } from 'pages/Workspace/layouts/PlanningBoard/layouts/ItemPopup/layouts/PopupTags/components/Tag'

import {
  ClearAllButton,
  CommunityContainer,
  CommunityHeaderText,
  CommunityText,
  ImageContainer,
  NavFooter,
  SearchAllButton,
  SearchBarContainer,
  SearchIconContainer,
  SearchInput,
  TagList,
  TextContainer,
} from './Community.style'
import { TravelStyleDropDown } from './components/TravelStyleDropDown'
import { CommunityTrips } from './components/CommunityTrips'
import { Text } from './components/CommunityTripCard/CommunityTripCard.style'
import { Link, useHistory } from 'react-router-dom'

interface CommunityProps{
  preview?: boolean
}

export default function Community({preview}: CommunityProps) {
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [debouncedSearch, setDebouncedSearch] = useState('')
  const [debouncedSelectedTags, setDebouncedSelectedTags] = useState<string[]>([])
  const [customLoading, setCustomLoading] = useState(false)
  const history = useHistory()



  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const searchValue = searchParams.get('search') || '' // Default to empty string if not present
    const tagsValue = searchParams.get('tags') ? JSON.parse(searchParams.get('tags') ?? '') : [] // Default to empty string if not present
    const pageNumber = searchParams.get('page') ?? 1// Default to 1
    setPage(+pageNumber ?? 5)
    setSearch(searchValue)
    setSelectedTags(tagsValue)
  }, [])

  // Effect for debouncing the search input
  useEffect(() => {
    const handler = setTimeout(() => {
      setPage(1)
      setDebouncedSearch(search)
      setCustomLoading(false)
    }, 1500)
    // 500ms delay for debounce
    // Cleanup function to clear timeout if the user types within the delay
    return () => {
      clearTimeout(handler)
    }
  }, [search])

  // Effect for debouncing the search input
  useEffect(() => {
    const Tagshandler = setTimeout(() => {
      setPage(1)
      setDebouncedSelectedTags([...selectedTags])
      setCustomLoading(false)
    }, 1500)
    // 500ms delay for debounce
    // Cleanup function to clear timeout if the user types within the delay
    return () => {
      clearTimeout(Tagshandler)
    }
  }, [selectedTags])

  const handleCheckboxChange = (tag: string) => {
    const searchParams = new URLSearchParams(window.location.search)
    setCustomLoading(true)
    setPage(1)
    searchParams.delete('page')
    const colorExists = selectedTags.find(c => c === tag)
    if (colorExists) {
      setSelectedTags(selectedTags.filter(t => t !== tag))
      searchParams.set('tags', JSON.stringify(selectedTags.filter(t => t !== tag)))
    }
    if (!colorExists) {
      setSelectedTags([...selectedTags, tag])
      searchParams.set('tags', JSON.stringify([...selectedTags, tag]))
    }
    history.push({
      pathname: window.location.pathname,
      search: searchParams.toString(),
    })
    setCustomLoading(true)
  }

  const handleSearchChange = (e: React.SyntheticEvent) => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.delete('page')
    searchParams.set('search', (e.target as HTMLInputElement).value)
    history.push({
      pathname: window.location.pathname,
      search: searchParams.toString(),
    })
    setCustomLoading(true)
    setSearch((e.target as HTMLInputElement).value)
  }

  const clearAll = () => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.delete('search')
    searchParams.delete('tags')
    history.push({
      pathname: window.location.pathname,
      search: searchParams.toString(),
    })
    setCustomLoading(true)
    setSelectedTags([])
    setSearch('')
  }

  return (
    <CommunityContainer>
      {
        preview &&
        <NavFooter>
          <CommunityText style={{ margin: 0, fontSize: '28px', lineHeight: '34px' }}>Featured community trips for inspirations</CommunityText>
          <Link to='/community'>
            <SearchAllButton>
              <Text>Search Community Trips</Text>
              <RightIcon/>
            </SearchAllButton>
          </Link>
        </NavFooter>
      }
      { !preview && <>
      <TextContainer>
        <CommunityHeaderText>Community</CommunityHeaderText>
        <CommunityText style={{ maxWidth: '520px' }}>
          Get inspired for your next trip with Planning Boards created by Experienced Travelers from all over the world.
        </CommunityText>
      </TextContainer>
      <ImageContainer>
        <CommunityLogo />
      </ImageContainer>
      <SearchBarContainer>
        <SearchIconContainer>
            <SearchIcon stroke='#3A3A3A'/>
        </SearchIconContainer>
        <SearchInput type='text' placeholder='Enter a destination or keyword...' value={search} onChange={handleSearchChange}/>
      </SearchBarContainer>
      <div style={{ display: 'flex', alignItems: 'center', columnGap: '16px', flexWrap: 'wrap' }}>
      <TravelStyleDropDown selectedTags={selectedTags} setSelectedTags={setSelectedTags} handleCheckboxChange={handleCheckboxChange}/>
      <ClearAllButton onClick={clearAll}>Clear all filters</ClearAllButton>
      </div>
      <TagList>
        {selectedTags.map((tag, idx) => {
          return (
            <Tag
              key={idx}
              tag={tag}
              removeTags={() => handleCheckboxChange(tag)}
            />
          )
        })}
      </TagList>
      </>
}
        <CommunityTrips search={debouncedSearch} selectedTags={debouncedSelectedTags} page={page} setPage={setPage} preview={preview} customLoading={customLoading}/>
    </CommunityContainer>
  )
}
