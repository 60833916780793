import React, { useState, useEffect } from 'react'

import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import { Input } from '@mui/material'
import classNames from 'classnames'
import { HTTPError } from 'ky'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'store/hooks'
import { updateUser } from 'store/slices/userSlice'
import { sendNotification } from 'utils/toast'

import { update } from 'api/user'

export default function EditProfileForm() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { user } = useAppSelector(state => state.user)
  const [editable, setEditable] = useState(false)
  const [formData, setFormData] = useState({
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    dateOfBirth: user?.date_of_birth || '',
    gender: user?.gender || '',
  })

  const sharedInputStyle = {
    fontSize: '14px',
    padding: '5px 5px 5px 5px',
  }

  const handleEditToggle = () => {
    setEditable(!editable)
  }

  const handleChange = (field: string, value: unknown) => {
    if (field === 'dateOfBirth' && !value) {
      return
    }
    setFormData({
      ...formData,
      [field]: value,
    })
  }

  const handleSubmit = async () => {
    try {
      if (!formData.firstName && !formData.lastName) {
        setFormData({
          ...formData,
          firstName: user?.firstName || '',
          lastName: user?.lastName || '',
        })
        sendNotification('First Name and Last Name cannot be empty', 'error')
        return
      }
      if (!formData.firstName) {
        setFormData({
          ...formData,
          firstName: user?.firstName || '',
          // lastName: formData.lastName ? formData.lastName : user?.lastName,
        })
        sendNotification('First Name cannot be empty', 'error')
        return
      }
      if (!formData.lastName) {
        setFormData({
          ...formData,
          // firstName: formData.firstName ? formData.firstName : user?.firstName,

          lastName: user?.lastName || '',
        })
        sendNotification('Last Name cannot be empty', 'error')
        return
      }

      const data = {
        uid: user?.uid,
        firstName: formData.firstName,
        lastName: formData.lastName,
        date_of_birth: formData.dateOfBirth
          ? new Date(formData.dateOfBirth).toISOString()
          : null,
        gender: formData.gender,
      }
      const res = await update(data)
      dispatch(updateUser(res))
      if (res) {
        sendNotification('Successfully updated the user', 'success')
        setEditable(false) // After successful update, set editable to false
      }
    } catch (error) {
      const e = await (error as HTTPError)?.response?.json()
      const message = e?.message || error
      sendNotification(message, 'error')
      console.log('error occurred: ', error)
    }
  }

  return (
    <div className={classes.root}>
      <form
        onSubmit={e => {
          e.preventDefault()
          handleSubmit()
        }}
        className={classes.form}>
        <Grid container spacing={2} justify="space-between">
          {/* First Name */}
          <Grid container style={{ marginBottom: '2px' }} spacing={2}>
            <Grid
              item
              xl={2}
              lg={2}
              md={2}
              xs={4}
              style={{ display: 'flex', alignItems: 'center', paddingLeft: 0 }}>
              <h4>First Name</h4>
            </Grid>
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              xs={8}
              style={{ display: 'flex', alignItems: 'center', paddingLeft: 0 }}>
              <FormControl fullWidth variant="outlined">
                {/* <InputLabel htmlFor="firstName">First Name</InputLabel> */}
                <Input
                  className={editable ? classes.highlightInput : ''}
                  style={sharedInputStyle}
                  disableUnderline={true}
                  id="firstName"
                  value={formData.firstName}
                  onChange={e => handleChange('firstName', e.target.value)}
                  disabled={!editable}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container style={{ marginBottom: '2px' }} spacing={2}>
            <Grid
              item
              xl={2}
              lg={2}
              md={2}
              xs={4}
              style={{ display: 'flex', alignItems: 'center', paddingLeft: 0 }}>
              <h4>Last Name</h4>
            </Grid>
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              xs={8}
              style={{ display: 'flex', alignItems: 'center', paddingLeft: 0 }}>
              <FormControl fullWidth variant="outlined">
                {/* <InputLabel htmlFor="firstName">First Name</InputLabel> */}
                <Input
                  className={editable ? classes.highlightInput : ''}
                  style={sharedInputStyle}
                  disableUnderline={true}
                  id="lastName"
                  // classes={{ input: classes.customOutlinedInput }}
                  value={formData.lastName}
                  onChange={e => handleChange('lastName', e.target.value)}
                  disabled={!editable}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container style={{ marginBottom: '2px' }} spacing={2}>
            <Grid
              item
              xl={2}
              lg={2}
              md={2}
              xs={4}
              style={{ display: 'flex', alignItems: 'center', paddingLeft: 0 }}>
              <h4>Gender</h4>
            </Grid>
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              xs={8}
              style={{ display: 'flex', alignItems: 'center', paddingLeft: 0 }}>
              <FormControl fullWidth variant="outlined">
                <Select
                  id="gender"
                  input={
                    <Input
                      className={editable ? classes.highlightInput : ''}
                      style={sharedInputStyle}
                      disableUnderline={true}
                      // classes={{ input: classes.customOutlinedInput }}
                    />
                  }
                  value={formData.gender}
                  onChange={e => handleChange('gender', e.target.value)}
                  disabled={!editable}>
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container style={{ marginBottom: '2px' }} spacing={2}>
            <Grid
              item
              xl={2}
              lg={2}
              md={2}
              xs={4}
              style={{ display: 'flex', alignItems: 'center', paddingLeft: 0 }}>
              <h4>Birthday</h4>
            </Grid>
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              xs={8}
              style={{ display: 'flex', alignItems: 'center', paddingLeft: 0 }}>
              <FormControl fullWidth variant="outlined">
                {/* <InputLabel htmlFor="firstName">First Name</InputLabel> */}
                <Input
                  className={editable ? classes.highlightInput : ''}
                  style={sharedInputStyle}
                  disableUnderline={true}
                  id="dateOfBirth"
                  // classes={{ input: classes.customOutlinedInput }}
                  type="date"
                  value={formData.dateOfBirth}
                  onChange={e => handleChange('dateOfBirth', e.target.value)}
                  disabled={!editable}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container style={{ marginBottom: '2px' }} spacing={2}>
            <Grid
              item
              xl={2}
              lg={2}
              md={2}
              xs={4}
              style={{ display: 'flex', alignItems: 'center', paddingLeft: 0 }}>
              <h4>Email</h4>
            </Grid>
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              xs={8}
              style={{ display: 'flex', alignItems: 'center', paddingLeft: 0 }}>
              <FormControl fullWidth variant="outlined">
                <Input
                  className={editable ? classes.highlightInput : ''}
                  style={sharedInputStyle}
                  disableUnderline={true}
                  // classes={{ input: classes.customOutlinedInput }}
                  id="email"
                  type="text"
                  value={user?.email}
                  disabled={true}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container style={{ marginBottom: '2px' }} spacing={2}>
            <Grid
              item
              xl={2}
              lg={2}
              md={2}
              xs={4}
              style={{ display: 'flex', alignItems: 'center', paddingLeft: 0 }}>
              <h4>User ID</h4>
            </Grid>
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              xs={8}
              style={{ display: 'flex', alignItems: 'center', paddingLeft: 0 }}>
              <FormControl fullWidth variant="outlined">
                <Input
                  className={editable ? classes.highlightInput : ''}
                  style={sharedInputStyle}
                  disableUnderline={true}
                  // classes={{ input: classes.customOutlinedInput }}
                  id="userId"
                  type="text"
                  value={user?.username}
                  disabled={true}
                />
              </FormControl>
            </Grid>
          </Grid>

          {/* Edit Button */}
          <Grid container spacing={2}>
            <Grid
              item
              xl={2}
              lg={2}
              md={2}
              xs={4}
              style={{ paddingLeft: 0 }}
              className={classes.submitBtnContainer}>
              <Button
                type={!editable ? 'submit' : 'button'}
                variant="contained"
                style={{
                  background: 'rgb(65, 132, 255)',
                  color: 'white',
                  fontWeight: 800,
                  border: 'none',
                  borderRadius: '6px',
                  boxShadow: 'none',
                  textTransform: 'none',
                  padding: '10px',
                  fontSize: '13px',
                }}
                // color="primary"
                className={classes.submitBtn}
                onClick={handleEditToggle}>
                {editable ? 'Save' : 'Edit'}
              </Button>
              {editable && (
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  style={{
                    background: '#e5e5e5',
                    color: 'black',
                    fontSize: '13px',
                    fontWeight: 800,
                    border: 'none',
                    marginLeft: '6px',
                    boxShadow: 'none',
                    textTransform: 'none',
                    padding: '10px',
                  }}
                  className={classes.submitBtn}
                  onClick={handleEditToggle}>
                  {'Cancel'}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {},
  form: {
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  submitBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  submitBtn: {
    marginTop: theme.spacing(1),
    padding: '10px 20px',
  },
  // customOutlinedInput: {
  //   padding: '10px', // Adjust the padding value as needed
  // },
  highlightInput: {
    fontSize: '14px',
    border: '1px solid #bdbdbd', // Change the border color to red when focused
    borderRadius: '5px',
    '&:hover': {
      border: '1px solid #4184ff', // Change the border color to red when focused
    },
  },
  inputUnderlineDisabled: {
    '& .MuiInput-underline.Mui-disabled': {
      '&:before': {
        borderBottom: 'none', // Remove the bottom border for disabled input
      },
    },
  },
}))
