import styled from '@emotion/styled'
import { CardMedia } from '@material-ui/core'

export const CreateTripContainer = styled.div`
  height: calc(100vh - 72px);
  background-color: red;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #ECF3FF;
`
export const Text = styled.div`
  word-break: break-word;
  font-weight: 900;
  font-size: 60px;
  line-height: 70px;
  letter-spacing: -0.03em;
  max-width: 550px;
`

export const SubText = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
`
export const TopContainer = styled.div`
  background-color: #ECF3FF;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  column-gap: 20px;
  padding: 87px 0;
`
export const FormContainer = styled.div`
  width: 30%;
`

interface ContentContainerProps {
  smallScreen: boolean
}

export const ContentContainer = styled.div<ContentContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: ${props => props.smallScreen ? '100%' : '50%'};
  row-gap: 32px;
  padding: 0 15px;
  `

  export const LogoContainer = styled.div`
    max-width: 568px;
  `
