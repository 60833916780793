import React, { createContext, useState, useContext } from 'react'

type DialogObject = {
  type: string
  show: boolean
}

interface IContext {
  openDialog: DialogObject
  setOpenDialog: (val: DialogObject) => void
}

const defaultState = {
  openDialog: {
    type: '',
    show: false,
  },
  setOpenDialog: () => null,
}

type Props = {
  children: React.ReactNode
}

const DialogContext = createContext<IContext>(defaultState)

const DialogProvider = ({ children }: Props) => {
  const [openDialog, setOpenDialog] = useState({
    type: '',
    show: false,
  })

  return (
    <DialogContext.Provider value={{ openDialog, setOpenDialog }}>
      {children}
    </DialogContext.Provider>
  )
}

export { DialogContext, DialogProvider }

export const useDialog = () => useContext(DialogContext)
