import styled from '@emotion/styled'
import { Button } from '@material-ui/core'
import { cssVariables } from 'theme'

export const SaveButtonStyled = styled(Button)`
  border-radius: 0px 7px 7px 0px;
  background: #4184ff;
  color: ${cssVariables.colors.primaryWhite};
  width: 50px;
  height: 35px;
  font-size: 16px;
  font-family: Lato;
  font-weight: bold;
  text-transform: capitalize;
  margin-top: 9px;
`

export const SaveButtonWithDropdownStyled = styled(Button)`
  border-radius: 7px 7px 7px 7px;
  background: #4184ff;
  color: ${cssVariables.colors.primaryWhite};
  width: 50px;
  height: 35px;
  font-size: 16px;
  font-family: Lato;
  font-weight: bold;
  text-transform: capitalize;
  margin-top: 9px;
`

export const style = () => {
  return {
    contained: {
      boxShadow: 'none',
    },
  }
}
