/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, createRef } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone'
import { MTButton, MTDialog, MTTooltip } from 'components'
import { isEmpty, isNull } from 'lodash'
import { Member } from 'pages/Workspace/layouts/MembersListRow/components/Member'
import CollaboratorsModal from 'pages/Workspace/layouts/MembersListRow/layouts/CollaboratorsModal/CollaboratorsModal'
import { TripLocationItem } from 'pages/Workspace/layouts/PlanningBoard/types'
import { cssVariables } from 'theme'

import { PublishIcon, SendIcon } from 'components/MTIcons'

import { MembersPopover } from './layouts/MembersPopover'
import { styles, CollabModalIcon, MembersList } from './membersListRow.styles'
import { modalTypes } from 'components/MTDialog/types'
import { CONSTANTS } from 'core/constants'
import { handleTripPublication } from 'api/trip'
import { sendNotification } from 'utils/toast'
import PublishTripModal from './layouts/CollaboratorsModal/PublishTripModal'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { isScreenSize } from 'utils/screenSzie'
import CustomToolTip from 'components/CustomTooltip/CustomTooltip'
const useStyles = makeStyles(() => styles())

type BackgroundColor = {
  [key: number]: string
}

const backgroundColors: BackgroundColor = {
  0: '#ECF3FF',
  1: '#FEF0F2',
  2: '#FFFCEE',
  3: '#E2FFEC',
}

type Props = {
  currentTrip?: any
  setCurrentTripCallback?: (trip: any) => void
  tripItem?: TripLocationItem
  tripMembers?: any
  setFieldValue?: (key: string, value: string) => void
  canEdit?: boolean
  showEditButton?: boolean
  showEditIconOnly?: boolean
  openUnPublishModal?: boolean
  setopenUnPublishModal?: (arg_0: boolean) => void
  openPublishModal?: boolean
  setOpenPublishModal?: (arg_0: boolean) => void
}

const MembersListRow = ({
  currentTrip = null,
  tripItem,
  tripMembers = null,
  setFieldValue,
  canEdit,
  showEditButton = true,
  showEditIconOnly = false,
  setCurrentTripCallback,
  openUnPublishModal,
  setopenUnPublishModal,
  openPublishModal,
  setOpenPublishModal,
}: Props) => {
  console.log(
    currentTrip,
    '-----------------------------------------------------------------------',
  )
  const [openCollaboratorsModal, setOpenCollaboratorsModal] = useState(false)
  const { user } = useAppSelector(state => state.user)
  console.log(user)
  const [unpublishLoading, setunpublishLoading] = useState(false)
  const [membersPopoverAnchorEl, setMembersPopoverAnchorEl] =
    useState<HTMLDivElement | null>(null)
  const membersPopoverOpen = Boolean(membersPopoverAnchorEl)
  const coordinates = useAppSelector(state => state.geoLocation)
  const classes = useStyles()
  const membersListRef = createRef<HTMLDivElement>()
  const showShareIcon = !isScreenSize(300)
  let memberModalList
  const getEmailUsername = (email: string) => {
    const atIndex = email.indexOf('@')
    if (atIndex !== -1) {
      return email.slice(0, atIndex)
    } else {
      // Return the entire email if "@" is not found (or handle as needed)
      return email
    }
  }
  if (!isEmpty(currentTrip) && !isEmpty(currentTrip?.trips_members)) {
    memberModalList = currentTrip?.trips_members
      .filter((member: any, idx: number) => idx < 4)
      .map((member: any, idx: number) => {
        // if (!isNull(member?.user_first_name)) {
        let title =
          member?.user_first_name && member?.user_last_name
            ? `${member?.user_first_name} ${member?.user_last_name}`
            : undefined

        if (!title && member?.user_email) {
          title = getEmailUsername(member?.user_email)
        }
        if (!title) {
          //invalid user
          return
        }

        let displayName = member?.user_first_name?.substring(0, 1).toUpperCase()
        if (!displayName) {
          displayName = member?.user_email?.substring(0, 1).toUpperCase()
        }
        return (
          <MTTooltip title={title} key={idx}>
            <div style={{ marginLeft: '-5px' }}>
              <Member
                backgroundColor={
                  backgroundColors[idx % currentTrip.trips_members.length]
                }
                name={member?.user_first_name}
                displayName={displayName}
              />
            </div>
          </MTTooltip>
        )
        // }
      })
  }
  if (
    tripItem &&
    !isEmpty(tripItem?.trips_locations_items_members) &&
    tripItem?.trips_locations_items_members.length > 0
  ) {
    memberModalList = tripItem.trips_locations_items_members
      .filter((member, idx) => idx < 5)
      .map((member, idx) => {
        if (
          member.users &&
          member.users.length > 0 &&
          !isNull(member?.users[0]?.firstName)
        ) {
          return (
            <MTTooltip
              title={`${member?.users[0]?.firstName} ${member?.users[0]?.lastName}`}
              key={idx}>
              <Member
                backgroundColor={
                  backgroundColors[
                    idx % currentTrip?.trips_locations_items_members.length
                  ]
                }
                name={member?.users[0]?.firstName || ''}
                displayName={
                  !isNull(member?.users[0]?.firstName)
                    ? member?.users[0]?.firstName.substring(0, 1).toUpperCase()
                    : ''
                }
              />
            </MTTooltip>
          )
        }
      })
  }

  const onOpenModalBasedOnType = () => {
    if (!!currentTrip) setOpenCollaboratorsModal(true)
    if (!!tripItem && canEdit)
      setMembersPopoverAnchorEl(
        membersPopoverAnchorEl ? null : membersListRef.current,
      )
  }

  const handleClosePopover = () => {
    setMembersPopoverAnchorEl(null)
  }

  const unPublishTrip = async () => {
    setunpublishLoading(true)
    try {
      await handleTripPublication({
        operation: 'unpublish',
        short_uid: currentTrip.short_uid,
        coordinates,
      })
      setCurrentTripCallback!({ ...currentTrip, published: false })
      sendNotification('Trip unpublished successfully', 'success')
    } catch (e) {
      sendNotification(
        'Unable to unpublish the trip, Please try again later',
        'error',
      )
    } finally {
      setunpublishLoading(false)
      setopenUnPublishModal!(false)
    }
  }

  return (
    <MembersList ref={membersListRef}>
      <div style={{ display: 'flex', alignItems: 'center'}}>
        {memberModalList}
        {currentTrip && currentTrip.trips_members.length > 4 && (
          <div style={{ marginLeft: '-5px' }}>
            <Member
              extraMembers={currentTrip.trips_members.length - 4}
              backgroundColor="#E5E5E5"
              color="#757575"
              displayName=""
            />
          </div>
        )}
        {tripItem && tripItem.trips_locations_items_members.length > 4 && (
          <div style={{ marginLeft: '-5px' }}>
            <Member
              extraMembers={tripItem.trips_locations_items_members.length - 4}
              backgroundColor="#E5E5E5"
              color="#757575"
              displayName=""
            />
          </div>
        )}
      </div>
      {showEditButton && (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
            rowGap: '10px',
            columnGap: '10px',
          }}>
          {!currentTrip.community_trip &&
            !user.is_tentative &&
            currentTrip.trips_roles[0]?.role === 'ADMIN' && (
              <CustomToolTip
                title={
                  currentTrip.published
                    ? ' Unpublish trip'
                    : ' Publish trip to community'
                }
                showonClick={false}>
                <MTButton
                  title={currentTrip.published ? 'Unpublish' : 'Publish'}
                  onHandleClick={() =>
                    currentTrip.published
                      ? setopenUnPublishModal!(true)
                      : setOpenPublishModal!(true)
                  }
                  customIcon={<PublishIcon height={'20'} width={'20'} />}
                  customStyles={{
                    backgroundColor: cssVariables.colors.primaryWhite,
                    color: cssVariables.colors.secondaryDarkGrey,
                    border:
                      '1px solid ' + cssVariables.colors.secondaryLightGrey,
                    width: '100px',
                    boxShadow: 'none',
                    minWidth: '120px',
                    marginRight: '5px'
                  }}
                />
              </CustomToolTip>
            )}
          <MTTooltip title={'Share trip with other travel-mates!'}>
            <MTButton
              title={showShareIcon ? 'Share trip' : 'Invite'}
              onHandleClick={onOpenModalBasedOnType}
              customIcon={
                showShareIcon ? (
                  <SendIcon
                    height={20}
                    width={20}
                    stroke={cssVariables.colors.primaryBlue}
                  />
                ) : (
                  <></>
                )
              }
              customStyles={{
                backgroundColor: '#ECF3FF',
                color: cssVariables.colors.primaryBlue,
                width: 'fit-content',
                boxShadow: 'none',
                minWidth: '120px',
                marginRight: '5px'
              }}
            />
          </MTTooltip>
        </div>
      )}
      {showEditIconOnly && (
        <MTTooltip title={'Edit travel-mates for this location'}>
          <CollabModalIcon
            classes={{
              colorDefault: classes.colorDefault,
              root: classes.root,
            }}>
            <EditTwoToneIcon
              fontSize="large"
              onClick={onOpenModalBasedOnType}
              style={{ color: '#3A3A3A' }}
            />
          </CollabModalIcon>
        </MTTooltip>
      )}
      {tripItem && (
        <MembersPopover
          membersPopoverAnchorEl={membersPopoverAnchorEl}
          membersPopoverOpen={membersPopoverOpen}
          handleClosePopover={handleClosePopover}
          tripItemMembers={tripItem.trips_locations_items_members}
          tripMembers={tripMembers}
          setFieldValue={setFieldValue}
        />
      )}
      <CollaboratorsModal
        open={openCollaboratorsModal}
        setOpen={setOpenCollaboratorsModal}
        currentTrip={currentTrip}
        setCurrentTripCallback={setCurrentTripCallback}
      />
      <PublishTripModal
        open={openPublishModal}
        setOpen={setOpenPublishModal}
        currentTrip={currentTrip}
        setCurrentTripCallback={setCurrentTripCallback}
      />
      <MTDialog
        openDialog={openUnPublishModal!}
        onCloseModal={() => setopenUnPublishModal!(false)}
        loading={unpublishLoading}
        onDeleteTripClickHandler={unPublishTrip}
        onCancelClickHandler={() => setopenUnPublishModal!(false)}
        type={modalTypes.UNPUBLISH_A_TRIP}
        itemToBeDeleted={CONSTANTS.UNPUBLISH_CONFIRMATION}
      />
    </MembersList>
  )
}

export default MembersListRow
