/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment'
import type { Moment } from 'moment'

type Props = {
  updatedValue: string
  startDate: Moment
  endDate: Moment
  setFieldValue: (key: string, value: Date) => void
  setStartDateVisual: (value: Date) => void
  setEndDateVisual: (value: Date) => void
  handleSubmit: (data: any) => void
}
/**
 * Update start and end date. Pass updated value back to formik
 * and visually display date to the user.
 *
 * @param { String } updatedValue type
 * @param { Date } startDate start date
 * @param { Date } endDate end date
 * @param { Function } setFieldValue Formik callback
 * @param { Function } setStartDateVisual Local start date state setter
 * @param { Function } setEndDateVisual Local end date state setter
 */
export const updateDates = ({
  updatedValue,
  startDate,
  endDate,
  setFieldValue,
  setStartDateVisual,
  setEndDateVisual,
  handleSubmit,
}: Props) => {
  if (updatedValue === 'startDate') {
    const momentStart = moment(startDate)
    const momentEnd = moment(endDate)

    setFieldValue('startDate', momentStart.toDate())
    setStartDateVisual(momentStart.toDate())

    handleSubmit({ startDate: momentStart, endDate: momentEnd })

    // if new starting date is later than end date,
    // move end date to be the same day as new start date
    if (
      momentStart.isValid() &&
      momentEnd.isValid() &&
      momentStart.isAfter(momentEnd)
    ) {
      const differenceInDays = momentStart.diff(momentEnd, 'days') + 1
      const incrimentEndDateByNDays = momentEnd.add(differenceInDays, 'day')

      setFieldValue('endDate', incrimentEndDateByNDays.toDate())
      setEndDateVisual(incrimentEndDateByNDays.toDate())
      handleSubmit({ startDate: momentStart, endDate: momentEnd })
    }
  }

  if (updatedValue === 'endDate') {
    const momentStart = moment(startDate)
    const momentEnd = moment(endDate)

    setFieldValue('endDate', momentEnd.toDate())
    setEndDateVisual(momentEnd.toDate())
    handleSubmit({ startDate: momentStart, endDate: momentEnd })

    // if new ending date is earlier than start date,
    // move start date to be the same date as new end date
    if (
      momentStart.isValid() &&
      momentEnd.isValid() &&
      momentEnd.isBefore(startDate)
    ) {
      const differenceInDays = momentStart.diff(momentEnd, 'days') + 1
      const decrimentStartDateByNDays = momentStart.subtract(
        differenceInDays,
        'day',
      )
      setFieldValue('startDate', decrimentStartDateByNDays.toDate())
      setStartDateVisual(decrimentStartDateByNDays.toDate())
      handleSubmit({ startDate: momentStart, endDate: momentEnd })
    }
  }
}
