import React from 'react'

import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import PollOutlinedIcon from '@material-ui/icons/PollOutlined'
import { CONSTANTS } from 'core/constants'
import {
  AddGridItemProps,
  CurrentTrip,
} from 'pages/Workspace/layouts/PlanningBoard/types'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { can, TRIP_ITEM_EDIT } from 'utils/permissions'
import { isScreenSize } from 'utils/screenSzie'

import { NoteForm } from './layouts/NoteForm'
import { PollForm } from './layouts/PollForm'
import {
  Container,
  Body,
  TabNameWithIcon,
  TabName,
  Content,
  Overlay,
} from './notesAndPolls.style'
import { dialogContent } from 'components/MTDialog/helper'

type Props = {
  currentTrip: CurrentTrip
  setIsPollTabSelected: (setIsPollTabSelected: boolean) => void
  disabled?: boolean
}

const NotesAndPolls = ({
  currentTrip,
  setIsPollTabSelected,
  disabled,
}: Props) => {
  const canAddOrEditPollOrNote = can(
    currentTrip?.trips_roles[0]?.role,
    TRIP_ITEM_EDIT,
  )

  let createPollTitle = CONSTANTS.NOTE_POLL_CONTAINER_CREATE_POLL
  let createNoteTitle = CONSTANTS.NOTE_POLL_CONTAINER_CREATE_NOTE
  const isScreenSizeEquals350 = isScreenSize(350)

  if (isScreenSizeEquals350) {
    createPollTitle = 'Add Poll'
    createNoteTitle = 'Add Note'
  }

  return (
    <Container style={{ background: 'rgba(128, 128, 128, 0.2)' }}>
      {currentTrip.community_trip && <Overlay />}

      <Content
        style={currentTrip.community_trip ? { pointerEvents: 'none' } : {}}>
        <Tabs>
          <TabList className="react-tabs__tab-list--inner">
            <Tab
              disabled={disabled}
              onClick={() => setIsPollTabSelected(true)}
              className="react-tabs__tab--inner"
              selectedClassName="react-tabs__tab--inner--selected">
              <TabNameWithIcon>
                <PollOutlinedIcon></PollOutlinedIcon>
                <TabName>{createPollTitle}</TabName>
              </TabNameWithIcon>
            </Tab>
            <Tab
              disabled={disabled}
              onClick={() => setIsPollTabSelected(false)}
              className="react-tabs__tab--inner"
              selectedClassName="react-tabs__tab--inner--selected">
              <TabNameWithIcon>
                <DescriptionOutlinedIcon></DescriptionOutlinedIcon>
                <TabName>{createNoteTitle}</TabName>
              </TabNameWithIcon>
            </Tab>
          </TabList>
          <TabPanel>
            <Body>
              <PollForm
                currentTrip={currentTrip}
                canEdit={canAddOrEditPollOrNote}
              />
            </Body>
          </TabPanel>
          <TabPanel>
            <Body>
              <NoteForm
                currentTrip={currentTrip}
                canEdit={canAddOrEditPollOrNote}
              />
            </Body>
          </TabPanel>
        </Tabs>
      </Content>
    </Container>
  )
}

export default NotesAndPolls
