import styled from '@emotion/styled'
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined'
import { Input } from 'antd'
import { cssVariables } from 'theme'

export const SearchBarInput = styled(Input)`
  width: 100%;
  border-radius: 12px;
`
export const SuggestionsContainer = styled.div`
  width: 425px;
  background: #fff;
  position: absolute;
  z-index: 20;
  font-size: 1.3rem;
  border-radius: 5px;
`
export const SuggestionsItem = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.4rem;
  cursor: pointer;
  font-weight: 500;
  padding: 7px;
  &:hover {
    background-color: ${cssVariables.colors.secondaryLightBlue}
    border-radius: 5px;
  }`
export const LoadingSpinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem;
`
export const LocationOnOutlinedIconStyled = styled(LocationOnOutlinedIcon)`
  margin-right: 0.5rem;
`
