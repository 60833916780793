import React, { useState, useEffect } from 'react'

import { Tooltip } from '@material-ui/core'
import { useWindowSize } from 'hooks/useWindowSize'

import {
  GarbageIcon,
} from './removePollOptions.style'

type Option = {
  uid: string
  title: string
}

type Props = {
  options: Option
  onOpenItemDeletePopupModal: (option: Option) => void
  optionHovered: string | null
}

const RemovePollOptions = ({ options, onOpenItemDeletePopupModal, optionHovered }: Props) => {
  const [showRemoveIcon, setShowRemoveIcon] = useState(false)

  const { width } = useWindowSize()

  useEffect(() => {
    if (width <= 1024) {
      setShowRemoveIcon(true)
    } else {
      setShowRemoveIcon(false)
    }
  }, [width, options, optionHovered])

  return (
    <div
      style={{ height: 'unset' }}
      key={options.uid}
      >
      <Tooltip title="Remove option">
        <GarbageIcon
          style={{
            opacity: showRemoveIcon
              ? '1'
              : optionHovered && options.uid === optionHovered
              ? '1'
              : '0',
            transition: 'opacity 0.25s ease',
          }}
          onClick={() => onOpenItemDeletePopupModal(options)}
        />
      </Tooltip>
    </div>
  )
}

export default RemovePollOptions
