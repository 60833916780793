import { CSSProperties } from 'react'
import styled from '@emotion/styled'

interface Styles {
  dropdownContainer: CSSProperties
  dropdownButton: CSSProperties
  dropdownMenu: CSSProperties
  dropdownItem: CSSProperties
  dropdownItemInput: CSSProperties
  colorBox: CSSProperties
  [key: string]: CSSProperties // Index signature for dynamic keys
}

export const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const style = (): Styles => {
  return {
    dropdownContainer: {
      position: 'relative',
      display: 'inline-block',
      margin: '5px 0',
      height: '48px',
    },
    dropdownButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '2px 16px',
      cursor: 'pointer',
      border: '1px solid #AFAFAF',
      borderRadius: '5px',
      backgroundColor: 'white',
      height: '48px',
      width: '168px'
    },
    buttonText: {
      fontSize: '16px',
      margin: '0 8px',
      color: '#3A3A3A',
      lineHeight: '22px',
      fontWeight: 700
    },
    dropdownText: {
      fontSize: '16px',
      padding: 0,
      margin: 0,
      marginLeft: '8px'
    },
    dropdownMenu: {
      position: 'absolute',
      top: '105%',
      width: '90vw',
      maxWidth: '420px',
      left: 0,
      backgroundColor: 'white',
      border: '1px solid #ccc',
      borderRadius: '4px',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
      padding: '16px',
      zIndex: 1000,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      columnGap: '24px',
      rowGap: '12px'

    },
    dropdownItem: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      minWidth: '180px',
      paddingLeft: '8px'
    },
    dropdownItemInput: {
      marginRight: '0px',
      height: '16px',
      width: '16px'

    },
    colorBox: {
      width: '12px',
      height: '12px',
      marginRight: '10px',
      border: '1px solid #ccc',
      borderRadius: '2px',
    },
    rotate: {
      transform: 'rotate(90deg)',
      transformOrigin: 'center' },
  }
}
