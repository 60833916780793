import { CustomDropDown } from 'components/MTIcons'
import React, { useState, useEffect, useRef } from 'react'
import { colorslist } from '../ColorPalette/ColorPalette'
import { CenteredContainer, style } from './ColorDropDown.style'
import { useAppSelector } from 'store/hooks'

interface ColorDropDownProps {
  handleCustomTagSelect: (arg_0: string, arg_2: string) => void
  handleCustomTagDeselect: (arg_0: string, arg2: string) => void
  selectedColors: string[]
  removeAllColors: (e: React.SyntheticEvent) => void
}

const ColorDropDown = ({
  handleCustomTagSelect,
  handleCustomTagDeselect,
  selectedColors,
  removeAllColors,
}: ColorDropDownProps) => {
  const styles = style()
  const [open, setOpen] = useState<boolean>(false)

  const stateColors = useAppSelector(state => state.tripItemColors)
  const uniqueColors = [
    ...new Set(Object.values(stateColors.data).map(item => item.color)),
  ]

  const dropdownRef = useRef<HTMLDivElement>(null) // Reference to the dropdown

  const toggleDropdown = () => setOpen(!open)

  const handleCheckboxChange = (color: string) => {
    const colorExists = selectedColors.find(c => c === color)
    if (colorExists) {
      handleCustomTagDeselect(color, 'color')
    }
    if (!colorExists) {
      handleCustomTagSelect(color, 'color')
    }
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setOpen(false)
    }
  }

  const getColorName = (hex: string) => {
    return colorslist.find(c => c.color === hex)?.label ?? ''
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div ref={dropdownRef} style={styles.dropdownContainer}>
      <button onClick={toggleDropdown} style={styles.dropdownButton}>
        <CenteredContainer>
          <input
            type="checkbox"
            title="Colors"
            checked={selectedColors.length > 0}
            style={styles.dropdownItemInput}
            onClick={removeAllColors}
          />
          <p style={styles.buttonText}>Colors</p>
        </CenteredContainer>
        <CenteredContainer style={open ? styles.rotate : {}}>
          <CustomDropDown height="10px" width="5px" />
        </CenteredContainer>
      </button>
      {open && (
        <div style={styles.dropdownMenu}>
          {uniqueColors.map((color, idx) => (
            <div key={idx} style={styles.dropdownItem} onClick={event => handleCheckboxChange(color)}
            >
              <input
                type="checkbox"
                checked={!!selectedColors.find(c => c === color)}
                style={styles.dropdownItemInput}
              />
              <div style={{ ...styles.colorBox, backgroundColor: color }} />
              <p style={{ ...styles.dropdownText }}>{getColorName(color)}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default ColorDropDown
