import React, { CSSProperties } from 'react'

import styled from '@emotion/styled'
import { Icon } from '@material-ui/core'

type MTIconProps = {
  src: string
  customStyles: Partial<CSSProperties>
}

const MTImageStyled = styled.img`
  vertical-align: unset;
`

const MTIconStyled = styled(Icon)`
  height: 2.5rem;
  width: 2.5rem;
  margin-top: 5px;
`

const MTIcon = ({ src, customStyles }: MTIconProps) => (
  <MTIconStyled style={customStyles}>
    <MTImageStyled src={src} />
  </MTIconStyled>
)

export default MTIcon
