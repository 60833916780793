/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
  createAction,
} from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { removeCookie } from 'utils/cookie'

// reducers
import geoLocation, { clearGeoLocation } from './slices/geoLocation'
import locationSearchResults, {
  resetAllLocationFields,
} from './slices/locationSearchResults'
import popupEditGridItem, {
  clear as clearPopupEditGridItem,
} from './slices/popupEditGridItem'
import tripCoords, { resetTripCoords } from './slices/tripCoords'
import tripGroupSlice from './slices/tripGroupSlice'
import tripItemColors, { clearAllTripItemColors } from './slices/tripItemColor'
import tripItemLocationsMap, {
  clearLocationsAndIndex,
} from './slices/tripItemLocationsMap'
import tripUserRoleSlice from './slices/tripUserRoleSlice'
import userPlanSlice from './slices/userPlanSlice'
import user, { clearUser } from './slices/userSlice'

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}

// import all reducers
const reducer = combineReducers({
  user,
  popupEditGridItem,
  locationSearchResults,
  tripItemColors,
  tripUserRoleSlice,
  tripItemLocationsMap,
  tripCoords,
  tripGroupSlice,
  userPlanSlice,
  geoLocation,
})

// global state reset action called on clearUser
export const resetAction = createAction('reset')

// global state reset reducer
const resettableReducer = (state: any, action: any) => {
  if (resetAction.match(action)) {
    removeCookie('google_access_token')
    removeCookie('mta_u_uid')
    removeCookie('access_token_signature')
    removeCookie('access_token_header_payload')
    removeCookie('refresh_token')
    return reducer(undefined, action)
  }

  return reducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, resettableReducer)

// ignore all the action types from redux-persist as specified in the docs
export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = ReturnType<typeof store.dispatch>

/**
 * Clear user redux store if user leaves trip page
 * as a tentative user.
 */
export const resetUserStore = (dispatch: AppDispatch, dropCookies = true) => {
  if (dropCookies) dispatch(resetAction())
  dispatch(clearLocationsAndIndex())
  dispatch(resetAllLocationFields())
  dispatch(clearAllTripItemColors())
  dispatch(resetTripCoords())
  dispatch(clearPopupEditGridItem())
  dispatch(clearUser())
  // dispatch(clearGeoLocation())
}
