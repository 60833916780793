interface Props {
  height: string
  width: string
}

export const styles = ({ height, width }: Props) => {
  return {
    root: {
      height,
      width,
    },
  }
}
