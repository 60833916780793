/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, memo } from 'react'

import styled from '@emotion/styled'
import 'react-multi-carousel/lib/styles.css'
import { Slide, Fade, Link } from '@material-ui/core'
import ControlPointIcon from '@material-ui/icons/ControlPoint'
import { MTButton, Stars } from 'components'
import { modalTypes } from 'components/MTDialog/types'
import { useDialog } from 'contexts/DialogContext'
import { useGridOrientation } from 'contexts/GridOrientationContext'
import { CONSTANTS } from 'core/constants'
import { isUndefined } from 'lodash'
import moment from 'moment'
import { TripGroup } from 'pages/Workspace/layouts/PlanningBoard/types'
import Carousel from 'react-multi-carousel'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  updateUserOwnedPlacesArray,
  updatePlacesArray,
} from 'store/slices/locationSearchResults'
import { addItemInTripGroup } from 'store/slices/tripGroupSlice'
import { cssVariables } from 'theme'
import { sendNotification } from 'utils/toast'

import { create } from 'api/tripLocationItem'

import CreateGroupModal from '../../../CreateGroupModal/modal'
import { Icon } from '../../../PlanningBoard/layouts/ItemPopup/layouts/PollItemPopup/pollItemPopup.style'
import { HoursOfOperation } from '../../components/HoursOfOperation'
import {
  responsive,
  InfoContainer,
  Header,
  HeaderTitle,
  DetailsTitle,
  DetailsContainerRow,
  PhotosCarouselContainer,
  CarouselItem,
  CarouselItemPhoto,
  Details,
  DetailIconLanguage,
  DetailIconAccessTime,
} from './mapInfo.styles'

type OpeningHours = any

export type LocationData = {
  place_id?: string
  destination?: string
  title?: string
  lat?: number
  lng?: number
  photos?: Array<string>
  address?: string
  rating?: number
  phone?: string
  website?: string
  opening_hours?: OpeningHours
  utc_offset_minutes?: number
  hoursOfOperations?: any
}

type Position = 'unset'

type MapInfoProps = {
  locationData: LocationData
  tripShortUid?: string
  position?: Position | undefined
  height?: string
  placePhotos?: Array<string>
  showAddToBoardButton?: boolean
  tripStartDate?: string
  tripEndDate?: string
  showMapInfo?: boolean
  isLocationItem?: boolean
  closeMapInfo?: boolean
  setCloseMapInfo?: (arg: boolean) => void
}

const GroupDropdown = styled.div`
  border-radius: 7px 0px 0px 7px;
  font-size: 1.4rem;
  width: 140px;
  height: 35px;
  margin-top: 9px;
  //margin-right: 5px;
  border: none;
  &:focus {
    outline-width: 0;
  }
`
const GroupDropDownContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`

const MapInfo = memo(
  ({
    locationData,
    tripShortUid,
    height,
    placePhotos,
    showAddToBoardButton = false,
    tripStartDate,
    tripEndDate,
    isLocationItem,
    closeMapInfo,
    setCloseMapInfo,
  }: MapInfoProps) => {
    const dispatch = useAppDispatch()
    const { gridOrientation } = useGridOrientation()
    const { setOpenDialog } = useDialog()
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const toggle = () => setDropdownOpen(prevState => !prevState)
    const [active, setActive] = useState(false)
    const [userOwnedPlaceGroup, setUserOwnedPlaceGroup] = useState('')
    let scroll = true
    if (isLocationItem) {
      scroll = false
    }

    const { user } = useAppSelector(state => state.user)
    const { userOwnedPlacesArray } = useAppSelector(
      state => state.locationSearchResults,
    )
    const { tripGroups } = useAppSelector(state => state.tripGroupSlice)
    const { data: tripRoles } = useAppSelector(state => state.tripUserRoleSlice)
    const [tripGroup, setTripGroup] = useState<TripGroup | null>()
    const coordinates = useAppSelector(state => state.geoLocation)

    const [photos, setPhotos] = useState<Array<string>>([])
    const [isUserOwnedPlace, setIsUserOwnedPlace] = useState(false)
    const [selectedGroupTitle, setSelectedGroupTitle] = useState('')

    type InlineStyleType = {
      height: string | undefined
      marginTop: string
      bottom: string
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      overflowY: any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      position?: any
      top?: string
      display?: string
      borderRadius?: string
      borderBottomLeftRadius?: string
      borderBottomRightRadius?: string
      width?: string
    }

    let inlineStyle: InlineStyleType

    inlineStyle = {
      position: 'relative',
      top: 'unset',
      height: !isLocationItem ? '50%' : height,
      marginTop: '0',
      bottom: isLocationItem ? '0%' : '50%',
      overflowY: scroll ? 'auto' : 'unset',
      borderBottomLeftRadius: '12px',
    }

    if (closeMapInfo) {
      inlineStyle = { ...inlineStyle, display: 'none' }
    }

    const onAddToBoard = async () => {
      if (!tripGroup) {
        sendNotification(
          `${CONSTANTS.TRIP_LOCATION_ITEM_SELECT_ALERT}`,
          'error',
        )
        return
      }
      if (user.is_tentative && !user?.is_trip_creator) {
        return setOpenDialog({
          show: true,
          type: modalTypes.SIGN_UP_TO_AS_NON_ADMIN_TO_EDIT_TRIP,
        })
      }

      const allGroupItems = tripGroup?.tripItems
      const lastItemsOrder =
        allGroupItems && allGroupItems.length > 0
          ? allGroupItems?.length + 1
          : 1

      let data = {
        google_place_id: locationData.place_id,
        destination: locationData.title,
        created_by: user.uid,
        user_uid: user.uid,
        short_uid: tripShortUid,
        title: locationData.title,
        lat: locationData.lat,
        lng: locationData.lng,
        grid_orientation: gridOrientation,
        is_tentative: !!user?.is_tentative,
        trip_group: tripGroup.uid,
        order: lastItemsOrder + 1,
        coordinates,
      }

      const currentDate = new Date()

      const currentDateBeforeTripStartDate =
        moment(currentDate).isBefore(tripStartDate)
      const currentDateAfterTripStartDate =
        moment(currentDate).isAfter(tripStartDate)

      let dates = {}
      if (currentDateBeforeTripStartDate && tripStartDate && tripEndDate) {
        dates = {
          start_date: new Date(tripStartDate),
          end_date: new Date(tripEndDate),
          start_time: new Date(tripStartDate),
          end_time: new Date(tripEndDate),
        }
      }

      if (currentDateAfterTripStartDate) {
        dates = {
          start_date: new Date(currentDate.setHours(0, 0, 0, 0)),
          end_date: new Date(currentDate.setHours(0, 0, 0, 0)),
          start_time: new Date(currentDate.setHours(0, 0, 0, 0)),
          end_time: new Date(currentDate.setHours(0, 0, 0, 0)),
        }
      }

      data = { ...data, ...dates }

      try {
        const res = await create(data)
        sendNotification(CONSTANTS.TRIP_ITEM_LOCATION, 'success')
        if (locationData?.place_id) {
          dispatch(
            updateUserOwnedPlacesArray([
              {
                place_id: locationData?.place_id,
                color: '#4184FF',
                trip_group_uid: tripGroup.uid,
              },
            ]),
          )
        }
        const payload = {
          groupUid: tripGroup,
          tripItem: res,
        }
        await dispatch(addItemInTripGroup(payload))
        dispatch(updatePlacesArray({ place_id: locationData.place_id }))
      } catch (error) {
        sendNotification(CONSTANTS.ERROR_GENERAL, 'error')
      }
    }

    useEffect(() => {
      if (placePhotos && placePhotos.length > 0) {
        setPhotos(placePhotos)
      } else if (
        locationData &&
        locationData.photos &&
        locationData.photos.length > 0
      ) {
        const tempPhotos = locationData.photos.map((photo: any) =>
          photo.getUrl(),
        )
        setPhotos(tempPhotos)
      }

      const locationPartOfUsersBoard = userOwnedPlacesArray.some(
        i => i.place_id === locationData.place_id,
      )
      setIsUserOwnedPlace(locationPartOfUsersBoard)
      const filteredUserPlace = userOwnedPlacesArray.filter(
        array => array.place_id === locationData.place_id,
      )
      const tripGroup = tripGroups.filter(tripGroup => {
        if (filteredUserPlace[0] && filteredUserPlace[0].trip_group_uid) {
          return tripGroup.uid === filteredUserPlace[0].trip_group_uid
        }
      })
      if (tripGroup[0]) {
        setUserOwnedPlaceGroup(tripGroup[0].title)
      } else {
        setUserOwnedPlaceGroup('')
      }
    }, [placePhotos, locationData, userOwnedPlacesArray, tripGroups])

    const goToMaps = () => {
      const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${locationData.address}&query_place_id=${locationData.place_id}`
      window.open(mapsUrl, '_blank') // Opens the URL in a new tab
    }
    return Object.keys(locationData).length > 0 ? (
      <InfoContainer style={inlineStyle}>
        <Slide direction="up" in={true} timeout={1000}>
          <div>
            <Fade in={true} timeout={1000}>
              <div>
                {locationData && Object.keys(locationData).length > 0 && (
                  <div>
                    {showAddToBoardButton && (
                      <Header
                        style={{
                          display: 'flex',
                          flexFlow: 'column wrap',
                          alignItems: 'flex-start',
                        }}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: isUserOwnedPlace
                              ? 'flex-end'
                              : 'space-between',
                            width: '100%',
                          }}>
                          {!isUserOwnedPlace && (
                            <GroupDropDownContainer>
                              <GroupDropdown>
                                {
                                    <Dropdown
                                      style={{ width: 'initial' }}
                                      size={'lg'}
                                      isOpen={dropdownOpen}
                                      toggle={toggle}
                                      direction={'down'}>
                                      <div
                                        style={{
                                          display: 'flex',
                                          width: '100%',
                                          height: '35px',
                                        }}>
                                        <DropdownToggle
                                          caret
                                          style={{
                                            fontFamily: 'Lato',
                                            fontWeight: 700,
                                            width: '100%',
                                            border: 'none',
                                            color: '#686868',
                                            borderRadius: '7px 0px 0px 7px',
                                            background: '#F9F9F9',
                                          }}
                                          size="lg">
                                          {userOwnedPlaceGroup
                                            ? userOwnedPlaceGroup
                                            : selectedGroupTitle.length > 0
                                            ? selectedGroupTitle
                                            : 'Select Group'}
                                        </DropdownToggle>
                                      </div>

                                      <DropdownMenu
                                        style={{
                                          marginTop: '6px',
                                          fontSize: '1.5rem',
                                          width: '140px',
                                          overflowX: 'hidden',
                                          borderRadius: '10px',
                                          padding: '7px',
                                        }}>
                                        {!isUserOwnedPlace &&
                                          tripGroups.map(tripGroup => {
                                            return (
                                              <DropdownItem
                                                style={{
                                                  marginTop: '6px',
                                                  fontFamily: 'Lato',
                                                  fontWeight: 700,
                                                  whiteSpace: 'unset',
                                                  overflowWrap: 'anywhere',
                                                }}
                                                key={tripGroup.uid}
                                                onClick={() => {
                                                  setTripGroup(tripGroup)
                                                  setSelectedGroupTitle(
                                                    tripGroup.title,
                                                  )
                                                }}>
                                                {tripGroup.title}
                                              </DropdownItem>
                                            )
                                          })}
                                        <DropdownItem
                                          style={{
                                            fontSize: '1.4rem',
                                            color: '#4287f5',
                                            fontWeight: 'bold',
                                            marginTop: '4px',
                                          }}
                                          onClick={() => {
                                            setActive(true)
                                            setTripGroup(null)
                                            setSelectedGroupTitle('')
                                          }}>
                                          <ControlPointIcon /> Create Group
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                }
                              </GroupDropdown>
                              <MTButton
                                disabled={
                                  isUserOwnedPlace ||
                                  tripRoles[0]?.role === 'VIEWER'
                                }
                                onHandleClick={onAddToBoard}
                                customStyles={{
                                  backgroundColor: '#F3FAFF',
                                  borderRadius: '0px 7px 7px 0px',
                                  height: '35px',
                                  color: isUserOwnedPlace
                                    ? undefined
                                    : cssVariables.colors.primaryBlue,
                                  boxShadow: 'none',
                                  width: '50px',
                                  fontSize: '1.4rem',
                                  marginTop: '10px',
                                  zIndex: 100,
                                }}
                                title={
                                  isUserOwnedPlace
                                    ? CONSTANTS.ALREADY_PART_OF_BOARD
                                    : 'Save'
                                }
                                buttonFontSize={14}
                              />
                            </GroupDropDownContainer>
                          )}
                          {!isLocationItem && (
                            <a
                              style={{ padding: '10px 5px' }}
                              onClick={() =>
                                setCloseMapInfo && setCloseMapInfo(true)
                              }>
                              <Icon />
                            </a>
                          )}
                        </div>
                      </Header>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        flexFlow: 'row wrap',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}>
                      <HeaderTitle>{locationData?.title}</HeaderTitle>
                      <MTButton
                        customStyles={{
                          backgroundColor: 'white',
                          border: '1px solid #8F8F8F',
                          borderRadius: '6px',
                          height: '32px',
                          color: cssVariables.colors.primaryBlack,
                          boxShadow: 'none',
                          width: 'fit-content',
                          zIndex: 100,
                          padding: '6px 8px',
                        }}
                        buttonFontSize={16}
                        onHandleClick={goToMaps}
                        title="Open on Google Maps"
                        transformprovidedTitle={false}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        flexWrap: 'wrap',
                      }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          flex: 1,
                        }}>
                        {locationData?.address !== '' ? (
                          <DetailsContainerRow>
                            {locationData?.rating && (
                              <Stars rating={locationData?.rating} />
                            )}
                          </DetailsContainerRow>
                        ) : null}

                        {locationData?.address !== '' ? (
                          <DetailsContainerRow>
                            <Details>
                              <b>Address: </b>
                              {locationData?.address}
                            </Details>
                          </DetailsContainerRow>
                        ) : null}

                        {locationData?.phone !== '' ? (
                          <DetailsContainerRow>
                            <Details>
                              <b>Phone: </b>
                              {locationData?.phone}
                            </Details>
                          </DetailsContainerRow>
                        ) : null}
                      </div>
                    </div>
                    {photos && photos.length > 0 && (
                      <PhotosCarouselContainer
                        style={{
                          display: 'grid',
                          gridTemplateColumns:
                            'repeat(auto-fill, minmax(100px, 1fr))',
                          gridGap: '100%',
                        }}>
                        <Carousel responsive={responsive} showDots={false}>
                          {photos.map((photo, ind) => (
                            <CarouselItem key={ind}>
                              <CarouselItemPhoto src={photo} />
                            </CarouselItem>
                          ))}
                        </Carousel>
                      </PhotosCarouselContainer>
                    )}
                    {!isUndefined(locationData?.website) && (
                      <DetailsContainerRow>
                        <DetailIconLanguage fontSize="large" />
                        <Details>
                          <Link
                            href={locationData?.website}
                            style={{ textDecoration: 'none' }}
                            onClick={event => {
                              event.preventDefault()
                              window.open(locationData?.website, '_blank')
                            }}>
                            Visit the website
                          </Link>
                        </Details>
                      </DetailsContainerRow>
                    )}

                    {locationData?.opening_hours?.weekday_text &&
                    locationData?.opening_hours?.weekday_text.length > 0 ? (
                      <DetailsContainerRow>
                        <DetailIconAccessTime fontSize="large" />
                        <HoursOfOperation locationData={locationData} />
                      </DetailsContainerRow>
                    ) : null}
                  </div>
                )}
              </div>
            </Fade>
          </div>
        </Slide>
        <CreateGroupModal
          active={active}
          setActive={setActive}
          currentTrip={tripShortUid}
          user={user}
        />
      </InfoContainer>
    ) : (
      <></>
    )
  },
)
MapInfo.displayName = 'MapInfo'

export default MapInfo
