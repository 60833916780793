import { useMediaQuery } from '@material-ui/core'

export const isScreenSize = (size: number, isMinQuery = false): boolean => {
  const str = isMinQuery ? `(min-width:${size}px)` : `(max-width:${size}px)`
  return useMediaQuery(str)
}

export const tabletMobileScreenSize = () => {
  return isScreenSize(959)
}
