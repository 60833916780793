import styled from '@emotion/styled'

type DeleteButtonProps = {
  tabletMobileView: boolean
}

export const CommentsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin: 10px 0;
  font-size: 14px;
  color: #3A3A3A;
`
export const CommentsHeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  flex-wrap: wrap;
  width: 100%;
`
export const CommentUserName = styled.p`
  font-weight: bold;
  margin: 0;
`

export const CommentDate = styled.p`
  margin: 0;
  font-size: 12px;
  color: #686868
`
export const DeleteButton = styled.p<DeleteButtonProps>`
  margin: 0;
  color: #C10F45;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  cursor: pointer;
  background-color: transparent;
  outline: 0;
  border: 0;
  padding:0;
  margin-left: ${props => (props.tabletMobileView ? 0 : 'auto')}
`
export const ReplyButton = styled.p`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 8px;
  cursor: pointer;
  outline: 0;
  margin: 0;
  border:0;
  background-color: transparent;
`

export const FullWidthDiv = styled.div`
  width: 100%;
`
export const ReplyText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 12px;
`
export const Mentionedtext = styled.span`
  color: #0545BA
`

export const NormalText = styled.span`
  padding: 0;
  margin: 0;
`

export const ReturnedText = styled.p`
  margin: 0;
  font-size: 14px;
  word-break: break-word;
`
