import * as Yup from 'yup'

/**
 * Formik login user validation
 */
export const validationSchemaLogin = Yup.object().shape({
  usernameOrEmail: Yup.string()
    .trim()
    .required('Please enter your username or email.'),
  password: Yup.string().trim().required('Please enter your password.'),
})
