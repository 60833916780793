import styled from '@emotion/styled'

export const VoteContainer = styled.article`
  list-style: none;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  font-family: inherit;
`

export const VotesList = styled.ul`
  list-style: none;
  margin: 0,
  padding: 0,
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 5px 0;
  white-space: nowrap;
`

export const Voters = styled.div`
  font-size: 12px;
  color: #3a3a3a;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`

export const ListItemContainer = styled.li`
  display: flex;
  flex-direction: row;
  height: 57px;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  // margin: 5px 0;
`

export const HiddenAvatar = styled.div`
  visibility: hidden;
`
