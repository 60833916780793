import { request } from '../utils/request'

export const invite = async data => {
  return await request({
    url: '/api/trip-member-invite/invite',
    method: 'post',
    data,
  })
}

export const find = async data => {
  return await request({
    url: '/api/trip-member-invite/find',
    method: 'get',
    searchParams: data,
  })
}

export const remove = async data => {
  return await request({
    url: '/api/trip-member-invite/remove',
    method: 'delete',
    data: { uid: data.uid, ...data },
  })
}
