import React, { useState, useEffect, useCallback } from 'react'

import Popover from '@material-ui/core/Popover'
import { makeStyles } from '@material-ui/core/styles'

import { MemberPopover } from './layouts/MemberPopover'
import { style } from './membersPopover.style'

const useStyles = makeStyles(theme => style(theme))

const MembersPopover = ({
  membersPopoverAnchorEl,
  membersPopoverOpen,
  handleClosePopover,
  tripItemMembers,
  tripMembers,
  setFieldValue,
}) => {
  const classes = useStyles()
  const [localMembers, setLocalMembers] = useState([])
  const allTripMembersHaveBeenAddedToLocationItem = localMembers.some(
    mem => !mem.is_or_will_be_part_of_trip,
  )
  const borderBottomContainer = {
    borderTopWidth: '1px',
    borderTopColor: '#C4C4C4',
    borderTopStyle: 'solid',
  }
  /**
   * Combine all current trip item location members from this location item
   * and all trip members into one local state array.
   */
  const combineTripMembersAndTripLocationMembers = useCallback(() => {
    let joinedMembersObject = {}

    tripMembers.forEach(member => {
      joinedMembersObject[member.user_email] = {
        user_email: member.user_email,
        user_first_name: member.user_first_name,
        user_last_name: member.user_last_name,
        user_uid: member.user_uid,
        user_is_admin: member.role === 'ADMIN',
        is_or_will_be_part_of_trip: false,
        uid: null,
      }
    })

    tripItemMembers.forEach(itemMember => {
      if (
        itemMember.users &&
        itemMember.users.length > 0 &&
        joinedMembersObject[itemMember.users[0].email]
      ) {
        joinedMembersObject[
          itemMember.users[0].email
        ].is_or_will_be_part_of_trip = true

        joinedMembersObject[itemMember.users[0].email].uid = itemMember.uid
      }
    })

    const joinedMembers = Object.values(joinedMembersObject)

    setLocalMembers(joinedMembers)
  }, [tripMembers, tripItemMembers])

  /**
   * Set trip location item member to be added on save.
   *
   * @param { Object } member member object
   * @param { Boolean } addMember add or remove member
   */
  const onAddMemberToTripLocationItem = member => {
    onUpdateLocalMember(member, true)
  }
  /**
   * Set trip location item member to be removed on save.
   *
   * @param { Object } member member object
   * @param { Boolean } addMember add or remove member
   */
  const onRemoveMemberToTripLocationItem = member => {
    onUpdateLocalMember(member, false)
  }

  /**
   * Set whether trip location member will be removed or added after
   * location item is saved.
   *
   * @param { Object } member member object
   * @param { Boolean } addMember add or remove member
   */
  const onUpdateLocalMember = (member, addMember) => {
    const tempLocalMembers = localMembers
    const index = localMembers.findIndex(m => m.user_uid === member.user_uid)
    tempLocalMembers[index].is_or_will_be_part_of_trip = addMember
    setLocalMembers([...[], ...tempLocalMembers])
    setFieldValue('members', tempLocalMembers)
  }

  useEffect(() => {
    combineTripMembersAndTripLocationMembers()
  }, [combineTripMembersAndTripLocationMembers])

  return (
    <Popover
      open={membersPopoverOpen}
      anchorEl={membersPopoverAnchorEl}
      onClose={handleClosePopover}
      anchorOrigin={{
        vertical: '0',
        horizontal: '0',
      }}
      transformOrigin={{
        left: '0',
        top: 'top',
      }}
      disableScrollLock
      disableRestoreFocus
      elevation={2}>
      <div className={classes.membersContainer}>
        <div
          className={classes.tripItemMembersContainer}
          style={
            allTripMembersHaveBeenAddedToLocationItem
              ? { marginBottom: '10px' }
              : { marginBottom: '0' }
          }>
          {localMembers &&
            localMembers.length > 0 &&
            localMembers.map(
              (member, index) =>
                member.is_or_will_be_part_of_trip && (
                  <MemberPopover
                    key={index}
                    member={member}
                    addingView
                    onRemoveMemberToTripLocationItem={
                      onRemoveMemberToTripLocationItem
                    }
                  />
                ),
            )}
        </div>
        <div
          className={classes.tripMembersContainer}
          style={
            allTripMembersHaveBeenAddedToLocationItem
              ? borderBottomContainer
              : { display: 'none', marginBottom: '0px !important' }
          }>
          <p className={classes.tripMembersContainerText}>
            {allTripMembersHaveBeenAddedToLocationItem &&
              'Select people to add to this location'}
          </p>
          <div className={classes.tripMembersContainerInner}>
            {localMembers &&
              localMembers.length > 0 &&
              localMembers.map(
                (member, index) =>
                  !member.is_or_will_be_part_of_trip && (
                    <MemberPopover
                      key={index}
                      member={member}
                      onAddMemberToTripLocationItem={
                        onAddMemberToTripLocationItem
                      }
                    />
                  ),
              )}
          </div>
        </div>
      </div>
    </Popover>
  )
}

export default MembersPopover
