import React, { useState } from 'react'

import { MTDialog } from 'components'
import { MTAvatar } from 'components/MTAvatar'
import { modalTypes } from 'components/MTDialog/types'
import { CONSTANTS } from 'core/constants'
import { CommentReplyIcon } from 'components/MTIcons'
import { useAppSelector } from 'store/hooks'
import { comment } from 'components/CommentSection/CommentSection'
import { tabletMobileScreenSize } from 'utils/screenSzie'
import {
  CommentDate,
  CommentsContainer,
  CommentsHeaderContainer,
  CommentUserName,
  DeleteButton,
  FullWidthDiv,
  Mentionedtext,
  NormalText,
  ReplyButton,
  ReplyText,
  ReturnedText,
} from './comment.style'
import { RawDraftContentBlock } from 'draft-js'
interface CommentProps {
  handleDeleteComment: (arg_0: string) => void
  addMention: (arg_0: { username: string; id: string }) => void
  commentDeleteLoading: boolean
  comment: comment
  lastCommentUserId: string
}

function formatDate(isoString: string) {
  const date = new Date(isoString)
  const formattedDate = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')
  return `${formattedDate} at ${hours}:${minutes}`
}

//This function converts the draft.js default JSON to the Previewable Display JSX
const convertToPreviewFormat = (jsonString: string) => {
  // Parse the stringified JSON
  const rawJson = JSON.parse(jsonString)
  const { blocks, entityMap } = rawJson
  return blocks.map((block: RawDraftContentBlock) => {
    const { text, entityRanges } = block
    // Initialize array to hold JSX parts
    const jsxParts = []
    let lastIndex = 0
    // Iterate through entityRanges to process mentions
    entityRanges.forEach(entityRange => {
      const { offset, length } = entityRange
      // Extract normal text before the mention
      if (lastIndex < offset) {
        jsxParts.push(
          <NormalText key={lastIndex}>
            {text.slice(lastIndex, offset)}
          </NormalText>,
        )
      }
      jsxParts.push(
        <Mentionedtext key={offset}>
          {text.slice(offset, offset + length)}
        </Mentionedtext>,
      )
      lastIndex = offset + length
    })
    // Add any remaining normal text after the last mention
    if (lastIndex < text.length) {
      jsxParts.push(<span key={lastIndex}>{text.slice(lastIndex)}</span>)
    }
    return <ReturnedText key={block.key}>{jsxParts}</ReturnedText>
  })
}

export default function Comment({
  comment,
  handleDeleteComment,
  commentDeleteLoading,
  addMention,
  lastCommentUserId,
}: CommentProps) {
  const [dialogOpen, setDialogOpen] = useState(false)
  const { user } = useAppSelector(state => state.user)
  const deleteComment = () => {
    handleDeleteComment(comment.uid)
    setDialogOpen(false)
  }
  const tabletMobileView = tabletMobileScreenSize()

  return (
    <CommentsContainer>
      <MTAvatar
        displayName={comment.user.firstName.charAt(0).toUpperCase()}
        height="32px"
        width="32px"
      />
      <FullWidthDiv>
        <CommentsHeaderContainer>
          <CommentUserName>
            {comment.user.firstName + ' ' + comment.user.lastName}
          </CommentUserName>
          <CommentDate>{formatDate(comment.created_at)}</CommentDate>
          {user.uid == comment.user.uid && (
            <DeleteButton
              tabletMobileView={tabletMobileView}
              onClick={() => setDialogOpen(true)}>
              Delete
            </DeleteButton>
          )}
        </CommentsHeaderContainer>
        {convertToPreviewFormat(comment.text)}

        {/* {user.uid != comment.user.uid && lastCommentUserId != comment.user.uid && (
          <ReplyButton
            onClick={() =>
              addMention({
                username: comment.user.firstName + ' ' + comment.user.lastName,
                id: comment.user.id,
              })
            }>
            <CommentReplyIcon height="16px" width="16px" />
            <ReplyText>Reply</ReplyText>
          </ReplyButton>
        )} */}
      </FullWidthDiv>
      <MTDialog
        loading={commentDeleteLoading}
        openDialog={dialogOpen}
        onCloseModal={() => setDialogOpen(false)}
        onDeleteTripClickHandler={deleteComment}
        onCancelClickHandler={() => setDialogOpen(false)}
        itemToBeDeleted={CONSTANTS.COMMENT_TO_BE_DELETED}
        type={modalTypes.DELETE_ITEM}
      />
    </CommentsContainer>
  )
}
