import React from 'react'

import styled from '@emotion/styled'
import { MTButton } from 'components'
import {
  RedButtonStyleProps,
  TransparentButtonStyleProps,
} from 'components/MTDialog/helper'
import { isScreenSize } from 'utils/screenSzie'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
`

type DeleteItemProps = {
  handleCancelSubscription?: () => void
  onCancelClickHandler?: () => void
  redButtonStyle: RedButtonStyleProps
  transparentButtonStyle: TransparentButtonStyleProps
}

const DowngradePlan = ({
  handleCancelSubscription,
  onCancelClickHandler,
  redButtonStyle,
  transparentButtonStyle,
}: DeleteItemProps) => {
  const smallScreen = isScreenSize(500)

  return (
    <>
      <Container>
        <MTButton

          customStyles={{...redButtonStyle, width: '100%'}}
          onHandleClick={() => {
            if (handleCancelSubscription) handleCancelSubscription()
          }}
          title={smallScreen ? 'Downgrade' : 'Cancel your plan'}
        />
      </Container>
    </>
  )
}

export default DowngradePlan
