import React, { useEffect, useState } from 'react'
import { CustomTooltip } from 'components/CustomTooltip'
import { MTAvatar } from 'components/MTAvatar'
import { DuplicateIcon, ViewsIcon } from 'components/MTIcons'
import DurationIcon from 'components/MTIcons/DurationIcon'
import { Trip } from '../CommunityTrips/CommunityTrips'
import { CommunityCardContainer, CommunityCardDescription, CommunityCardFooter, CommunityCardImage, DateSection, Duplicates, DurationSection, DurationText, Text, TripOwnerInformation, TripTitle, Views, ViewsAndDuplicates } from './CommunityTripCard.style'
import { getGooglePlaceInformation } from 'utils/googlePlace'
import { fallBackImage } from 'pages/Dashboard/layouts/Card/helper'
import { PreviewTags } from 'pages/Workspace/layouts/PlanningBoard/layouts/ItemPopup/layouts/PopupTags/components/PreviewTags'
export interface CommunityTripCardProps {
    preview?: boolean
    trip: Trip
}

function formatDate(isoDate: string): string {
    const date = new Date(isoDate)
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const year = date.getFullYear()

    return `${month}/${day}/${year}`
}

function getDateDifference(startDate: string, endDate: string): string {
    const start = new Date(startDate)
    const end = new Date(endDate)
    const diffInMilliseconds = Math.abs(end.getTime() - start.getTime())
    const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24)) + 1

    // Check if the difference is exactly a multiple of 7 (whole weeks)
    if (diffInDays % 7 === 0) {
        const weeks = diffInDays / 7
        return `${weeks} week${weeks > 1 ? 's' : ''}`
    } else {
        return `${diffInDays} day${diffInDays > 1 ? 's' : ''}`
    }
}

export default function CommunityTripCard({trip, preview}: CommunityTripCardProps){
    const TripOwnerName = (trip?.trips_members[0]?.users[0]?.firstName ?? '') + (trip?.trips_members[0]?.users[0]?.firstName ? ' ' : '') + (trip?.trips_members[0]?.users[0]?.lastName ?? '')
    const [placeHeroPhoto, setPlaceHeroPhoto] = useState<string|null>(null)
    useEffect(() => {
        if (trip && trip.google_place_id) {
          getGooglePlaceInformation({
            place_id: trip.google_place_id,
            setPlaceHeroPhoto,
          })
        }
      }, [setPlaceHeroPhoto, trip])

    const tripTags = trip.travel_styles
        ? JSON.parse(trip.travel_styles).map((m: string) => {
            return {title: m}
        })
        : []
    return (
        <CommunityCardContainer preview={!!preview}>
            <CommunityCardImage src={placeHeroPhoto ?? fallBackImage}/>
            <CommunityCardDescription>
                <CustomTooltip title={trip?.title} showonClick={true}>
                    <TripTitle>{trip?.title}</TripTitle>
                </CustomTooltip>
                <DateSection>
                        <Text>{formatDate(trip?.start_date)} - {formatDate(trip?.end_date)}</Text>
                        <DurationSection>
                            <DurationIcon/>
                            <DurationText>{getDateDifference(trip.start_date, trip.end_date)}</DurationText>
                        </DurationSection>
                </DateSection>
                <CommunityCardFooter>
                    <TripOwnerInformation>
                        <MTAvatar displayName={TripOwnerName?.substring(0,1).toLocaleUpperCase() ?? ''} width='32px' height='32px' backgroundColor='#ECF3FF'/>
                        <Text>{TripOwnerName || ' '}</Text>
                    </TripOwnerInformation>

                    <ViewsAndDuplicates>
                        <Views>
                            <ViewsIcon/>
                            <Text>{trip.views ?? '0'}</Text>
                        </Views>

                        <Duplicates>
                            <DuplicateIcon/>
                            <Text>{trip.duplicates ?? '0'}</Text>
                        </Duplicates>
                    </ViewsAndDuplicates>
                </CommunityCardFooter>
                <PreviewTags tags={tripTags} tagsToShow={2}/>
            </CommunityCardDescription>
        </CommunityCardContainer>
    )
}
