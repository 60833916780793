import React from 'react'

type PublishIconProps = {
  stroke?: string
  height?: string
  width?: string
}
const PublishIcon = ({ stroke, height, width }: PublishIconProps) => {
  return (
    <svg
      width={width ?? '16'}
      height={height ?? '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.3684 8.68443C14.3685 7.57847 14.0459 6.49651 13.4402 5.57115C12.8345 4.64579 11.972 3.91723 10.9584 3.47475C9.94477 3.03228 8.82411 2.8951 7.73371 3.08004C6.64332 3.26497 5.63056 3.76399 4.81954 4.51593C4.00852 5.26788 3.43449 6.24008 3.16777 7.3134C2.90105 8.38673 2.95325 9.51455 3.31795 10.5587C3.68266 11.6028 4.34404 12.5178 5.22104 13.1916C6.09803 13.8654 7.15255 14.2688 8.25537 14.3522M3.37895 6.7897H13.9895M3.37895 10.5792H7.73684"
        stroke={stroke ?? '#3F3F3F'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.36832 3C7.30432 4.70502 6.74023 6.67444 6.74023 8.68421C6.74023 10.694 7.30432 12.6634 8.36832 14.3684M8.99989 3C9.96629 4.54766 10.5219 6.31626 10.6142 8.13853M12.4736 15L14.5894 12.9259C14.7191 12.8 14.8223 12.6494 14.8929 12.4829C14.9635 12.3165 15 12.1376 15.0003 11.9568C15.0006 11.776 14.9646 11.597 14.8946 11.4303C14.8246 11.2636 14.7219 11.1126 14.5925 10.9863C14.3286 10.7282 13.9743 10.5834 13.6052 10.5827C13.236 10.582 12.8812 10.7254 12.6163 10.9825L12.4748 11.1215L12.334 10.9825C12.0701 10.7246 11.716 10.5799 11.347 10.5792C10.978 10.5785 10.6233 10.7218 10.3584 10.9787C10.2286 11.1046 10.1254 11.2552 10.0547 11.4216C9.98412 11.588 9.94754 11.7669 9.94719 11.9477C9.94684 12.1285 9.98271 12.3076 10.0527 12.4743C10.1227 12.641 10.2253 12.7919 10.3546 12.9183L12.4736 15Z"
        stroke={stroke ?? '#3F3F3F'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PublishIcon
