import React from 'react'

type IconProp = {
  stroke?: string
  height?: string
  width?: string
}
const CollapseIcon = ({ stroke, height, width }: IconProp) => {
  return (
    <svg
      width={width ?? '20'}
      height={height ?? '20'}
      viewBox="0 0 24 24"
      fill="none"
      stroke={stroke ?? '#4184FF'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        stroke={stroke ?? '#4184FF'}
        d="M18.9999 9H16.4199L19.7099 5.71C19.8982 5.5217 20.004 5.2663 20.004 5C20.004 4.7337 19.8982 4.47831 19.7099 4.29C19.5216 4.1017 19.2662 3.99591 18.9999 3.99591C18.7336 3.99591 18.4782 4.1017 18.2899 4.29L14.9999 7.57V5C14.9999 4.73479 14.8946 4.48043 14.707 4.2929C14.5195 4.10536 14.2652 4 13.9999 4C13.7347 4 13.4804 4.10536 13.2928 4.2929C13.1053 4.48043 12.9999 4.73479 12.9999 5V10C12.9999 10.2652 13.1053 10.5196 13.2928 10.7071C13.4804 10.8946 13.7347 11 13.9999 11H18.9999C19.2652 11 19.5195 10.8946 19.707 10.7071C19.8946 10.5196 19.9999 10.2652 19.9999 10C19.9999 9.73479 19.8946 9.48043 19.707 9.2929C19.5195 9.10536 19.2652 9 18.9999 9ZM9.99994 13H4.99994C4.73472 13 4.48037 13.1054 4.29283 13.2929C4.1053 13.4804 3.99994 13.7348 3.99994 14C3.99994 14.2652 4.1053 14.5196 4.29283 14.7071C4.48037 14.8946 4.73472 15 4.99994 15H7.56994L4.28994 18.29C4.19621 18.383 4.12182 18.4936 4.07105 18.6154C4.02028 18.7373 3.99414 18.868 3.99414 19C3.99414 19.132 4.02028 19.2627 4.07105 19.3846C4.12182 19.5064 4.19621 19.617 4.28994 19.71C4.3829 19.8037 4.4935 19.8781 4.61536 19.9289C4.73722 19.9797 4.86793 20.0058 4.99994 20.0058C5.13195 20.0058 5.26266 19.9797 5.38452 19.9289C5.50638 19.8781 5.61698 19.8037 5.70994 19.71L8.99994 16.42V19C8.99994 19.2652 9.1053 19.5196 9.29283 19.7071C9.48037 19.8946 9.73472 20 9.99994 20C10.2652 20 10.5195 19.8946 10.707 19.7071C10.8946 19.5196 10.9999 19.2652 10.9999 19V14C10.9999 13.7348 10.8946 13.4804 10.707 13.2929C10.5195 13.1054 10.2652 13 9.99994 13Z"
        fill="#3A3A3A"
      />
    </svg>
  )
}

export default CollapseIcon
