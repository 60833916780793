import React, { ReactNode, CSSProperties } from 'react'

import { css } from '@emotion/css'
import styled from '@emotion/styled'
import { Button } from '@material-ui/core'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
type MTButtonProps = {
  customIcon?: ReactNode | null
  customStyles?: CSSProperties
  title?: string | ReactJSXElement
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onHandleClick: (data?: any) => void
  disabled?: boolean
  iconOnly?: boolean
  buttonFontSize?: number
  changeWidth?: false | true | undefined
  marginBottom?: false | true | undefined
  transformprovidedTitle?: boolean
  customTextStyles?: CSSProperties
}

const MTButtonTripGroupStyled = styled(Button)`
  width: 45%;
  border-radius: 0px 7px 7px 0px;

  font-size: 1.75rem !important;
  text-transform: none !important;
  font-weight: 700 !important;
`

const MTButtonStyled = styled(Button)`
  width: 45%;
  border-radius: 6px;
  font-size: 1.75rem !important;
  text-transform: none !important;
  font-weight: 700 !important;
`

const MTButton = ({
  customIcon = null,
  customStyles = {},
  title,
  onHandleClick,
  disabled = false,
  iconOnly = false,
  buttonFontSize,
  changeWidth,
  marginBottom,
  transformprovidedTitle = true,
  customTextStyles = {}
}: MTButtonProps) => {
  /**
   * Capitalize first letter of button title.
   * @returns title with capitalized first letter
   */
  const transformTitle = () => {
    const lowerCaseTitle = title?.toLowerCase()
    return (
      (lowerCaseTitle &&
        lowerCaseTitle?.charAt(0).toUpperCase() + lowerCaseTitle?.slice(1)) ||
      ''
    )
  }
  if (iconOnly) {
    customStyles = {
      ...customStyles,
      ...{
        padding: '3px',
        boxShadow: 'none',
        minWidth: '5px',
        backgroundColor: 'transparent',
      },
    }
  }

  return (
    <MTButtonStyled
      disabled={disabled}
      variant="contained"
      color="inherit"
      style={customStyles}
      onClick={onHandleClick}
      startIcon={customIcon}
      className={css`
        width: ${changeWidth ? '-webkit-fill-available !important' : 'auto'};
        margin-bottom: ${marginBottom ? '5px' : ''};
        & > span {
          font-size: ${buttonFontSize ? `${buttonFontSize}px` : 'inherit'};
        }
      `}>
      <p style={{ margin:0, ...customTextStyles  }}>{transformprovidedTitle ? transformTitle() : title}</p>
    </MTButtonStyled>
  )
}

export default MTButton
