import React from 'react'

import { makeStyles, Typography } from '@material-ui/core'
import { StartTripButton } from 'components/StartTripButton'
import { CONSTANTS } from 'core/constants'
import { isScreenSize } from 'utils/screenSzie'

import peopleLogo from '../../assets/dashboard.png'
import { styles } from './bodyContainerWithoutTrips.styles'

const useStyles = makeStyles(theme => styles(theme))

const BodyContainerWithoutTrips = () => {
  const classes = useStyles()
  const title =
    '<a href="https://www.freepik.com/vectors/people">People vector created by pch.vector - www.freepik.com</a>'

  let noCurrentTripsFont = 'h2'
  let readyToPlanFont = 'h4'

  const isScreenSizeEquals600 = isScreenSize(600)
  const isScreenSizeEquals500 = isScreenSize(500)
  const isScreenSizeEquals400 = isScreenSize(400)
  const isScreenSizeEquals300 = isScreenSize(300)

  if (isScreenSizeEquals600) {
    noCurrentTripsFont = 'h3'
  }
  if (isScreenSizeEquals500) {
    noCurrentTripsFont = 'h4'
    readyToPlanFont = 'h5'
  }
  if (isScreenSizeEquals400) {
    noCurrentTripsFont = 'h5'
    readyToPlanFont = 'h6'
  }
  if (isScreenSizeEquals300) {
    noCurrentTripsFont = 'h6'
  }

  return (
    <div className={classes.wrapper}>
      <Typography variant={noCurrentTripsFont} className={classes.title}>
        {CONSTANTS.DASHBOARD_NO_CURRENT_TRIPS_PLANNED}
      </Typography>
      <Typography variant={readyToPlanFont} className={classes.subTitle}>
        {CONSTANTS.DASHBOARD_READY_T0_PLAN_NEXT_ADV}
      </Typography>
      <StartTripButton
        customIcon
        title={CONSTANTS.START_TRIP}
        textCol="white"
        width="auto"
      />
      <img
        src={peopleLogo}
        alt={title}
        className={classes.dashboardPeopleLogo}
      />
    </div>
  )
}

export default BodyContainerWithoutTrips
