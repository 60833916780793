import styled from '@emotion/styled'

export const styles = ({ breakpoints, colors }) => {
  return {
    dashboardContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      backgroundColor: '#ffffff !important',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
    },
    headerLeft: {
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      backgroundColor: '#F2F7FF',
      padding: '3rem',
      marginBottom: '4rem',
      borderRadius: '1.5rem',
    },
    headerCloseButton: {
      position: 'absolute',
      right: '.5rem',
    },
    headerLeftWelcomeText: {
      fontWeight: 'bold',
    },
    headerLeftInfoText: {
      marginTop: '5px',
      paddingTop: '.5rem',
      fontSize: '1.6rem',
    },
    headerLeftInfoTextSupport: {
      marginTop: '5px',
      fontStyle: 'italic',
    },
    headerLeftInfoTextSupportEmail: {
      fontWeight: 'bold',
    },
    headerRight: {
      flex: '1',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    },
    headerRightButton: {
      background: colors.primaryBlue,
      color: 'white',
      padding: '20px 0',
      width: '50%',
      borderRadius: '20px',
      fontSize: '1.4rem',
      textTransform: 'capitalize',
    },
    body: {
    },
    [breakpoints.down('sm')]: {
      dashboardContainer: {
        display: 'flex',
        flex: 1,
        flexdirection: 'column',
      },
    },
  }
}

export const TabButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: 20px;
  line-height: 26px;
  color: ${props => (props.active ? '#3A3A3A': '#AFAFAF')};
  font-weight: ${props => (props.active ? 700 : '')};
`

export const Tab = styled.div`
  min-width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const TabContainer = styled.div`
  display: flex;
  gap: 24px;
  padding: 56px 0 48px 0;
  align-items: flex-start;
`

export const TabIndicator = styled.div`
  width: 100%;
  height: 4px;
  border-radius: 11px;
  background-color: #4184FF;
  margin-top: 8px;
  display: ${props => (props.active ? 'flex' : 'none')}
`
