import React from 'react'

import styled from '@emotion/styled'

type UnsplashImageCreditProps = {
  unsplashLink: React.ReactElement | undefined
  rightOffset?: number
  topOffset?: number
}

type UnsplashImageCreditStyleProps = {
  rightOffset: number
  topOffset: number
}

const UnsplashImageCreditStyled = styled.div<UnsplashImageCreditStyleProps>`
  position: absolute;
  height: max-content;
  right: ${props => props.rightOffset}px;
  bottom: ${props => (props.topOffset > 0 ? '0' : '10px')};
  color: white;
  top: ${props => (props.topOffset > 0 ? `${props.topOffset}px` : 'none')};
  font-size: 8px;
`

const UnsplashImageCredit = ({
  unsplashLink,
  rightOffset = 10,
  topOffset = 0,
}: UnsplashImageCreditProps) => {
  return (
    <UnsplashImageCreditStyled rightOffset={rightOffset} topOffset={topOffset}>
      {unsplashLink}
    </UnsplashImageCreditStyled>
  )
}

export default UnsplashImageCredit
