import React from 'react'

import { MTAvatar } from 'components/MTAvatar'

type Props = {
  name?: string
  displayName: string
  color?: string
  extraMembers?: number
  verticalAnimation?: boolean
  backgroundColor?: string
  customStyles?: React.CSSProperties
}

const Member = ({
  name,
  displayName,
  color,
  extraMembers,
  verticalAnimation,
  backgroundColor,
}: Props) => {
  return (
    <MTAvatar
      name={name}
      color={color}
      backgroundColor={backgroundColor}
      displayName={displayName}
      extraMembers={extraMembers}
      verticalAnimation={verticalAnimation}
      height='32px'
      width='32px'
    />
  )
}

export default Member
