import React from 'react'

import { Grid, CardContent, Typography } from '@material-ui/core'
import { Card } from '@mui/material'
import { CONSTANTS } from 'core/constants'
import { useAppSelector } from 'store/hooks'
import { PlanObject } from 'store/slices/userPlanSlice'
import { isScreenSize } from 'utils/screenSzie'
import ActiveSubscription from './ActiveSubscription'
import PlanCard from './PlanCard'

interface PlansProps {
  otherPlans: Array<PlanObject> // Specify PlanObject array type
  handleClose?: () => void
  isPopup: boolean
}

const LargerPlan = () => {
  const sm = isScreenSize(960, true)

  return (
    <div style={{ maxWidth: '280px', minWidth: '220px', margin: '5px' }}>
      <Card
        style={{
          fontSize: '13px',
          boxShadow:
            'rgba(0, 0, 0, 0.1) 0px 0px 1px 0px, rgb(0 0 0 / 4%) 0px 2px 10px 0px',
          flexGrow: 1,
        }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid style={{ paddingBottom: 0 }} item xs={12}>
              <Typography variant="h5">
                <strong>Need a larger plan?</strong>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              Contact us at{' '}
              <a
                style={{
                  textDecoration: 'underline',
                  textDecorationColor: 'blue',
                  paddingBottom: 0,
                }}
                target="_blank"
                href="mailto:support@mitravelapp.com"
                rel="noreferrer">
                {' '}
                support@mitravelapp.com
              </a>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}
const Plans: React.FC<PlansProps> = ({
  otherPlans,
  handleClose,
  isPopup = false,
}) => {
  const userPlan = useAppSelector(state => state.userPlanSlice.userPlan)

  return (
    <div>
      <ActiveSubscription
          otherPlans={otherPlans}
          subscribedPlan={userPlan.plan}
      />
      <div style={{ marginTop: '20px' }}>
      <strong style={{ fontSize: '16px'}}>
        Other plans you might be interested in
      </strong>
      <div style={{ display: 'flex', maxWidth: '100%', overflow: 'auto', margin: '10px 0'}}>
        {otherPlans.map((plan, index) => {
            return (
              <div key = {index} >
                <div>
                  <PlanCard
                    handleClose={handleClose}
                    plan={plan}
                    isPopup={false}
                    buttonText={plan?.name !== CONSTANTS.FREE_PLAN ? 'Purchase' : undefined}
                    isSubscribed={false}
                  />
                </div>
                {index === 0 && <LargerPlan />}
              </div>
            )
          return null
        })}
      </div>
      </div>
    </div>
  )
}

export default Plans
