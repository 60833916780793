import styled from '@emotion/styled'
import { Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Field } from 'formik'
import { cssVariables } from 'theme'

export const Container = styled.div`
  padding: 1rem 2rem;
`

export const QuestionBar = styled(Field)`
  font-size: 1.5rem;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  margin-: 0.5rem 0px;
  margin-bottom: 1rem;
  border: none;
  color: ${cssVariables.colors.secondaryDarkGrey};
  &:focus {
    outline-width: 0px;
  }
`

export const ErrorsMsg = styled.div`
  font-size: 1.3rem;
  color: red;
`

export const Answer = styled(Field)`
  font-size: 1.5rem;
  width: 100%;
  padding: 1rem;
  margin-bottom: 0.5rem;
  border: none;
  background-color: #f3faff;
  border-radius: 5px;
  &:focus {
    outline-width: 0px;
  }
`

export const AddAnswerBtn = styled(Button)`
  margin-top: 0.5rem;
  font-size: 1.3rem;
  color: ${cssVariables.colors.primaryBlue};
  padding: 1rem 0;
`

export const OptionCancelButton = styled.div`
  position: absolute;
  right: 0px;
  top: 3px;
  z-index: 1500;
  cursor: pointer;
  display: inline-flex; /* Use flex to center content vertically */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 2rem;
  width: 2rem;
  padding: 20px; /* Increase this padding to enlarge the clickable area */
`

export const Icon = styled(CloseIcon)`
  font-size: 24px;
  line-height: 1;
`

export const SubmitWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Body = styled.div`
  margin-left: 0.3rem;
`
