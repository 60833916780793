type TripItemType = {
  TRIPS_POLLS_ITEMS: 'trips_polls_items'
  TRIPS_NOTES_ITEMS: 'trips_notes_items'
  TRIPS_LOCATIONS_ITEMS: 'trips_locations_items'
}

export const tripItemTypes: TripItemType = {
  TRIPS_POLLS_ITEMS: 'trips_polls_items',
  TRIPS_NOTES_ITEMS: 'trips_notes_items',
  TRIPS_LOCATIONS_ITEMS: 'trips_locations_items',
}

export const tripItemTypeSingular = {
  POLL: 'poll',
  NOTE: 'note',
  LOCATION: 'location',
}
