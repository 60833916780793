import React, {
  useEffect,
  useCallback,
  useRef,
  useState,
  Dispatch,
  SetStateAction,
} from 'react'

import { HTTPError } from 'ky'
import { Map } from 'pages/Workspace/layouts/Map'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setScrolltoSearchedLocationOnMap } from 'store/slices/locationSearchResults'
import { pageVariants } from 'styles/sharedMotion'
import { isScreenSize, tabletMobileScreenSize } from 'utils/screenSzie'
import { sendNotification } from 'utils/toast'

import { update } from 'api/trip'

import { CurrentTrip } from '../PlanningBoard/types'
import { Container } from './explore.style'
import { SearchExplore } from './layouts/SearchExplore'
import { SearchResults } from './layouts/SearchResults'

type Props = {
  currentTrip: CurrentTrip
  mapExpanded: boolean
  setMapExpanded: Dispatch<SetStateAction<boolean>>
}

const Explore = ({
  currentTrip,
  mapExpanded = false,
  setMapExpanded,
}: Props) => {
  const dispatch = useAppDispatch()

  const { user } = useAppSelector(state => state.user)
  const { scrollToSearchedLocation } = useAppSelector(
    state => state.locationSearchResults,
  )
  const myRef = useRef<HTMLDivElement>(null)
  const [searchedTitle, setSearchedTitle] = useState('')

  /**
   * Check if trip bounds exist
   */
  const isTripBoundsEmpty = useCallback(() => {
    return (
      currentTrip?.ne_bounds.every(bound => bound === 0) &&
      currentTrip?.sw_bounds.every(bound => bound === 0)
    )
  }, [currentTrip])

  /**
   * Add default trip location bounds to trip if they are empty.
   * Mainly for cases where trips created before nov 2021.
   * Might need to remove this after it is merged to production.
   */
  const onUpdateTripBoundsToDefaultValue = useCallback(async () => {
    const lngDiff = 0.159558449999997265
    const latDiff = 0.15083600000000104

    const { lat, lng, uid, short_uid } = currentTrip
    const swBounds = [lat - lngDiff, lng - lngDiff]
    const neBounds = [lat + latDiff, lng + latDiff]

    const data = {
      uid,
      short_uid,
      user_uid: user.uid,
      sw_bounds: swBounds,
      ne_bounds: neBounds,
    }
    try {
      await update(data)
    } catch (error) {
      const e = await (error as HTTPError)?.response?.json()
      const message = e?.message || error
      sendNotification(message, 'error')
      console.log('error occured: ', error)
    }
  }, [currentTrip, user])

  useEffect(() => {
    if (isTripBoundsEmpty()) {
      onUpdateTripBoundsToDefaultValue()
    }
  }, [isTripBoundsEmpty, onUpdateTripBoundsToDefaultValue])

  const scrollToMap = async () => {
    myRef.current &&
      (await myRef.current.scrollIntoView({
        behavior: 'smooth',
      }))
  }

  useEffect(() => {
    if (scrollToSearchedLocation) {
      scrollToMap()
      dispatch(setScrolltoSearchedLocationOnMap(false))
    }
  }, [scrollToSearchedLocation, dispatch])

  const smallScreen = isScreenSize(350)
  const tabletMobileView = tabletMobileScreenSize()

  return (
    <Container
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}>
      <div style={{ width: tabletMobileView ? 'calc(100vw - 40px)' : '70vw', margin: '10px', minWidth: tabletMobileView ? 'calc(100vw - 40px)' : ''}}>
        <SearchExplore
          tripName={currentTrip.title}
          tripDestination={currentTrip.destination}
          setSearchedTitle={setSearchedTitle}
        />
        <div>
        <SearchResults
          tripStartDate={currentTrip.start_date}
          searchedTitle={searchedTitle}
          tripName={currentTrip.title}
          tripDestination={currentTrip.destination}
          tripEndDate={currentTrip.end_date}
          tripShortUid={currentTrip.short_uid}
        />
        </div>
        {tabletMobileView && (
          <div>
          <Map
            showExpandButton={false}
            showMapInfo
            tripStartDate={currentTrip.start_date}
            tripEndDate={currentTrip.end_date}
            tripShortUid={currentTrip.short_uid}
            destinationLat={currentTrip.lat}
            destinationLng={currentTrip.lng}
            mapExpanded={false}
            setMapExpanded={setMapExpanded}
          />
          </div>
        )}
      </div>
      <div style={{ width: '30vw' }}>
        {!tabletMobileView && (
          <Map
            showExpandButton={false}
            showMapInfo
            tripStartDate={currentTrip.start_date}
            tripEndDate={currentTrip.end_date}
            tripShortUid={currentTrip.short_uid}
            destinationLat={currentTrip.lat}
            destinationLng={currentTrip.lng}
            mapExpanded={true}
            setMapExpanded={setMapExpanded}
          />
        )}
      </div>
    </Container>
  )
}

export default Explore
