import React from 'react'

import { LinearProgress, makeStyles, Typography } from '@material-ui/core'
import { CONSTANTS } from 'core/constants'
import { Formik, Form } from 'formik'
import { Input, SubmitButton } from 'formik-antd'
import { useQuery } from 'hooks/useQuery'
import { TermsOfService } from 'layouts/Auth/components/TermsOfService'
import { useHistory } from 'react-router-dom'
import { tabletMobileScreenSize } from 'utils/screenSzie'
import { sendNotification } from 'utils/toast'

import { confirmPasswordReset } from 'api/auth'

import { validationSchema } from './helper'
import { style } from './passwordReset.style.js'

const useStyles = makeStyles(theme => style(theme))

const PasswordReset = () => {
  const history = useHistory()
  const classes = useStyles()
  const userUid = useQuery().get('user_uid')

  let tabletMobileViewGeneral = tabletMobileScreenSize()

  /**
   * Reset user password
   * @param { password, passwordConfirm } param new passwords
   */
  const onResetPassword = async ({ password, passwordConfirm, resetForm }) => {
    if (password !== passwordConfirm) {
      resetForm()
      return sendNotification(
        CONSTANTS.PASSWORD_RESET_DONT_MATCH_MESSAGE,
        'error',
      )
    }
    try {
      const res = await confirmPasswordReset({ password, uid: userUid })
      if (res.success) {
        sendNotification(CONSTANTS.PASSWORD_RESET_SUCCESS_MESSAGE, 'success')
        history.push('/signin')
      } else {
        sendNotification(res.message, 'error')
      }
    } catch (error) {
      const res = await error?.response?.json()
      if (
        !_.isNil(res?.message) &&
        res?.message !== CONSTANTS.EXPIRED_REFRESH_TOKEN_ERROR_API
      ) {
        await history.push('/404', {
          message: 'Error occured',
        })
      }
    }
  }


  return (
    <div className={classes.signInForm}>
    <div style={{ width: '100%', maxWidth: '1020px' }}>
      <div className={classes.signInInput}>
        <div className={classes.signInHeader}>
          <Typography component="h1" variant="h4" className={classes.title}>
            {CONSTANTS.RESET_PASSWORD}
          </Typography>
        </div>
        <Formik
          initialValues={{
            password: '',
            passwordConfirm: '',
          }}
          validateOnBlur={false}
          validationSchema={validationSchema}
          onSubmit={async (
            { password, passwordConfirm },
            { setSubmitting, resetForm },
          ) => {
            await onResetPassword({
              password,
              passwordConfirm,
              setSubmitting,
              resetForm,
            })
          }}>
          {({ isSubmitting, errors }) => (
            <Form className={classes.form}>
              <p className={classes.label}>Password</p>
              <Input
                className={`${classes.input}`}
                name="password"
                type="password"
                label={CONSTANTS.LABEL_PASSWORD}
                autoFocus={!tabletMobileViewGeneral}
              />
              <p className={classes.errorField}>{errors.password}</p>
              <p className={classes.label}>Confirm Password</p>
              <Input
                className={`${classes.input}`}
                name="passwordConfirm"
                type="password"
                label={CONSTANTS.LABEL_PASSWORD}
                autoFocus={!tabletMobileViewGeneral}
              />
              <p className={classes.errorField}>{errors.passwordConfirm}</p>

              {isSubmitting && <LinearProgress />}
              <SubmitButton
                variant="contained"
                fullWidth
                color="primary"
                className={classes.signInButton}>
                {CONSTANTS.RESET_PASSWORD}
              </SubmitButton>
            </Form>
          )}
        </Formik>
      </div>
      <TermsOfService />
      </div>
    </div>
  )
}

export default PasswordReset
