import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'tripCoords',
  initialState: {
    coords: {},
    lat: 0,
    lng: 0,
  },
  reducers: {
    _updateTripCoords: (state, { payload }) => {
      state.coords = { ...{}, ...payload }
      state.lat = payload.lat
      state.lng = payload.lng
    },
    // eslint-disable-next-line no-unused-vars
    _resetTripCoords: state => {
      state.coords = {}
    },
  },
})

export default slice.reducer

// Actions

const { _updateTripCoords, _resetTripCoords } = slice.actions

export const updateTripCoords = data => dispatch => {
  try {
    dispatch(_updateTripCoords(data))
  } catch (e) {
    throw new Error(e.message)
  }
}

export const resetTripCoords = data => dispatch => {
  try {
    dispatch(_resetTripCoords(data))
  } catch (e) {
    throw new Error(e.message)
  }
}
