/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState, useCallback } from 'react'

import { MTTooltip } from 'components'
import { modalTypes } from 'components/MTDialog/types'
import { MTModal } from 'components/MTModal'
import { useDialog } from 'contexts/DialogContext'
import { ModalContext } from 'contexts/ModalContext'
import { CONSTANTS } from 'core/constants'
import type { ArrayHelpers } from 'formik'
import { Formik, Form, ErrorMessage, FieldArray } from 'formik'
import { HTTPError } from 'ky'
import { isEmpty, isNil } from 'lodash'
import moment from 'moment'
import { SaveButton } from 'pages/Workspace/layouts/PlanningBoard/components/SaveButton'
import { TitleInput } from 'pages/Workspace/layouts/PlanningBoard/components/TitleInput'
import {
  TripItemType,
  TripLocationItem,
  TripNoteItem,
  UpdateGridItemProps,
  DeleteGridItemProps,
  LocationTripGroupItem,
  NoteTripGroupItem,
  PollTripGroupItem,
} from 'pages/Workspace/layouts/PlanningBoard/types'
import { Map } from 'pages/Workspace/layouts/Map'

import {
  useAppDispatch,
  useAppSelector,
  useGeolocation,
  CoordsObject,
} from 'store/hooks'
import {
  updateLocationItemColor,
  updateUserOwnedPlacesArray,
} from 'store/slices/locationSearchResults'
import { clear } from 'store/slices/popupEditGridItem'
import { updateIdOfTripGroupItem } from 'store/slices/tripGroupSlice'
import { updateTripItemColor } from 'store/slices/tripItemColor'
import { getGooglePlaceInformation } from 'utils/googlePlace'
import { tripItemTypes } from 'utils/itemTypes'
import { can, TRIP_ITEM_EDIT } from 'utils/permissions'
import { tabletMobileScreenSize, isScreenSize } from 'utils/screenSzie'
import { sendNotification } from 'utils/toast'

import {
  getSignedURL as getSignedURLTripLocationItem,
  update as tripLocationItemUpdate,
} from 'api/tripLocationItem'
import {
  update as tripNoteItemUpdate,
  getSignedURL as getSignedURLTripNoteItem,
} from 'api/tripNoteItem'
import { updatePollItem } from 'api/tripPollItem'
import { TagIcon } from 'components/MTIcons'

import { TripGroup } from '../../types'
import {
  Container,
  Header,
  Footer,
  Body,
  BodyRow,
  BodyRowIcon,
  BodyRowContainer,
  ContainerWrapper,
  LocationMapContainer,
} from './itemPopup.style'
import { LocationItemPopup } from './layouts/LocationItemPopup'
import { NoteItemPopup } from './layouts/NoteItemPopup'
import { PollItemPopup } from './layouts/PollItemPopup'
import { PopupTags } from './layouts/PopupTags'
import { NoEncryption } from '@material-ui/icons'
import { Spinner } from 'reactstrap'

type LocationItemColorState = {
  trip_location_item_metadata: {
    color: string
  }
  google_place_id: string
}

type APIDataCommonProps = {
  title: string
  color: string
  tags: Array<string>
  trip_group: TripGroup
}

type APILocationData = {
  startTime: Date
  endTime: Date
  startDate: Date
  endDate: Date
  note: string
  members: Array<any>
  files: Array<File>
} & APIDataCommonProps

type APINoteData = {
  description: string
  files: Array<File>
} & APIDataCommonProps

type APIPollData = APIDataCommonProps

type APIAllDataProps = APILocationData & APINoteData & APIPollData

type AllTypesDataProps = {
  setFieldValue: (key: any, val: any) => void
  values: any
  handleChange: (val: string) => void
  tripShortUid: string
}

export type ItemPopupFormikValues = {
  title: string
  startDate: Date | null
  endDate: Date | null
  startTime: Date | null
  endTime: Date | null
  tags: Array<string>
  type: TripItemType
  members: Array<any>
  note: string
  description: string
  color: string
  files: Array<File>
  trip_group: TripGroup
}

type ItemPopupProps = {
  open: boolean
  tripShortUid: string
  tripItem: NoteTripGroupItem | PollTripGroupItem | LocationTripGroupItem
  tripMembers: any
  bucket: string | undefined
}

const ItemPopup = ({
  open,
  tripShortUid,
  tripItem,
  tripMembers,
  bucket,
}: ItemPopupProps) => {
  const dispatch = useAppDispatch()
  const { handleLayout } = useContext(ModalContext)
  const [selectedPlace, setSelectedPlace] = useState({})
  const [placePhotos, setPlacePhotos] = useState<Array<string>>([])
  const [docURL, setDocURL] = useState(null)
  const [myFiles, setMyFiles] = useState<Array<File>>([])

  const { user } = useAppSelector(state => state.user)
  const { tripGroups } = useAppSelector(state => state.tripGroupSlice)
  const { data: tripRoles } = useAppSelector(state => state.tripUserRoleSlice)
  const canEditTripItem = can(tripRoles[0]?.role, TRIP_ITEM_EDIT)
  const { setOpenDialog } = useDialog()
  const coordinates = useAppSelector(state => state.geoLocation)

  const [loading, setLoading] = useState(false)

  // booleans to toggle the map item container
  const fullGridColumnLength = tripItem.type === 'location' ? 2 : 3
  const gridWithTwoColumns = tripItem.type === 'location' ? '50% 49%' : '1fr'
  const gridDisplay = tripItem.type === 'location' ? 'grid' : 'inherit'
  const showLocationItemMapContainer =
    tripItem.type === 'location' ? true : false

  let width = '60%'
  if (tripItem.type === 'location') {
    width = '60%'
  }
  let padding = '2rem'
  const tabletMobileView = tabletMobileScreenSize()

  const isScreenSizeEquals500 = isScreenSize(500)
  const isScreenSizeEquals400 = isScreenSize(400)
  const isScreenSizeEquals350 = isScreenSize(350)

  if (isScreenSizeEquals500) {
    width = '80%'
    padding = '1rem'
  }
  if (isScreenSizeEquals400) {
    width = '85%'
  }
  if (isScreenSizeEquals350) {
    width = '90%'
  }

  let formInlineStyle: any = {}
  let containerWrapper: { [key: string]: string | undefined } = {
    gridTemplateColumns: gridWithTwoColumns,
    display: gridDisplay,
  }

  if (tripItem.type === 'poll' || tripItem.type === 'note') {
    containerWrapper = {
      ...containerWrapper,
      display: 'flex',
      flexFlow: 'column',
      marginTop: 'auto',
      maxHeight: '70rem',
    }
  }

  if (tabletMobileView) {
    formInlineStyle = {
      maxHeight: tripItem.type === 'location' ? '85dvh' : 'unset',
    }
    containerWrapper = {
      display: 'flex',
      flexFlow: 'column',
      marginTop: 'auto',
      maxHeight: '85dvh',
    }
    // if (tripItem.type === 'poll' || tripItem.type === 'note') {
    //   containerWrapper = {
    //     ...containerWrapper,
    //     maxHeight: '85vh',
    //   }
    // }
  }
  /**
   * Open and close create modal.
   */
  const handleClose = () => {
    handleLayout('')
    dispatch(clear())
  }

  /**
   * Download any attached files to trip item.
   */
  const onDownloadDocument = useCallback(async () => {
    const tripBucket = `${bucket}/${tripItem.uid}`
    const key = (tripItem as unknown as TripLocationItem).doc

    const args = {
      bucket: tripBucket,
      key,
      user_uid: user.uid,
    }
    let res = null
    if (key) {
      if (tripItem.type === 'location') {
        res = await getSignedURLTripLocationItem(args)
      } else {
        res = await getSignedURLTripNoteItem(args)
      }

      setDocURL(res.signedUrl)
    }
  }, [tripItem, user.uid, bucket])

  /**
   * Render specific trip item component based on type.
   * @returns Component
   */
  const onRenderItemBodyByType = ({
    setFieldValue,
    values,
    handleChange,
    tripShortUid,
  }: AllTypesDataProps) => {
    let renderComponent

    switch (tripItem.type) {
      case 'location':
        renderComponent = (
          <LocationItemPopup
            tripItem={tripItem as unknown as TripLocationItem}
            selectedPlace={selectedPlace}
            setFieldValue={setFieldValue}
            values={values}
            handleChange={handleChange}
            tripMembers={tripMembers}
            docURL={docURL}
            myFiles={myFiles}
            setMyFiles={setMyFiles}
            canEdit={canEditTripItem}
          />
        )
        break
      case 'note':
        renderComponent = (
          <NoteItemPopup
            tripItem={tripItem as unknown as TripNoteItem}
            docURL={docURL}
            values={values}
            myFiles={myFiles}
            setMyFiles={setMyFiles}
            setFieldValue={setFieldValue}
            handleChange={handleChange}
            canEdit={canEditTripItem}
            loading={loading}
          />
        )
        break
      case 'poll':
        renderComponent = (
          <PollItemPopup
            tripItem={tripItem as unknown as PollTripGroupItem}
            canEdit={canEditTripItem}
            tripMembers={tripMembers}
            tripShortUid={tripShortUid}
            loading={loading}
          />
        )
        break
      default:
        break
    }
    return (
      <MTTooltip
        scroll
        title={
          !canEditTripItem
            ? 'You do not have permission to edit this item.'
            : ''
        }>
        {renderComponent}
      </MTTooltip>
    )
  }

  /**
   * Update trip item based on type.
   * @param { APIAllDataProps } data trip item data of any of the 3 types
   */
  const onHandleUpdateByType = async (data: APIAllDataProps) => {
    if (user?.is_tentative && !user?.is_trip_creator) {
      return setOpenDialog({
        show: true,
        type: modalTypes.SIGN_UP_TO_AS_NON_ADMIN_TO_EDIT_TRIP,
      })
    }

    switch (tripItem.type) {
      case 'location':
        onHandleUpdateForLocationItem(data)
        break
      case 'note':
        onHandleUpdateForNoteItem(data)
        break
      case 'poll':
        onHandleUpdateForPollItem(data)
        break
      default:
        break
    }
  }

  /**
   * Update trip note item and add it to this current trip.
   *
   * @param { APINoteData } data trip item data
   */
  const onHandleUpdateForNoteItem = async (data: APINoteData) => {
    setLoading(true)
    console.timeLog()
    const formData = new FormData()
    let tripGroupOfItem: TripGroup = tripGroups[0] //hack. just initialized it with any group, we are reseting this value below anyways.
    await tripGroups.forEach(tripGroup => {
      tripGroup.tripItems.forEach(tripGroupItem => {
        if (tripGroupItem.uid === tripItem.uid) {
          tripGroupOfItem = tripGroup
        }
      })
    })

    formData.append('user_uid', user.uid as string)
    formData.append('short_uid', tripShortUid)
    formData.append('uid', tripItem.uid)
    formData.append('order', tripItem?.order?.toString())

    if (!isEmpty(data.title)) {
      formData.append('title', data.title)
    }

    if (!isEmpty(data.description)) {
      formData.append('description', data.description)
    }

    if (!isEmpty(data.color)) {
      formData.append('color', data.color)
    }

    if (!isEmpty(data.tags)) {
      formData.append('trips_notes_items_tags', JSON.stringify(data.tags))
    }

    if (data.files && data.files.length > 0) {
      formData.append('fileName', data.files[0].name)
      formData.append('file', data.files[0], data.files[0].name)
    } else if (myFiles.length > 0) {
      formData.append('removeFile', myFiles[0])
    }
    if (!isEmpty(data.trip_group) && !(typeof data.trip_group === 'object')) {
      formData.append('trip_group', JSON.stringify(+data.trip_group)) //+ will safly typecast string to number
    } else {
      formData.append('trip_group', JSON.stringify(tripGroupOfItem.id)) //+ will safly typecast string to number
    }

    if (coordinates.latitude && coordinates.longitude) {
      formData.append('latitude', coordinates?.latitude?.toString())
      formData.append('longitude', coordinates?.longitude?.toString())
    }

    try {
      const res = await tripNoteItemUpdate(tripItem.uid, formData)
      if (res) {
        handleClose()
        sendNotification(
          `${CONSTANTS.TRIP_NOTE_ITEM_UPDATE_SUCCESS}`,
          'success',
        )

        const payload = {
          groupUid: res.trip_group.uid,
          tripItem: res,
        }
        dispatch(updateIdOfTripGroupItem(payload))
        dispatch(
          updateTripItemColor({
            uid: res.uid,
            color: res.trip_note_item_metadata.color,
          }),
        )
      }
    } catch (error) {
      const e = await (error as HTTPError)?.response?.json()
      const message = e?.message || error
      sendNotification(message, 'error')
      console.log('error occured: ', error)
    } finally {
      setLoading(false)
    }

  }

  /**
   * Update trip poll item and add it to this current trip.
   *
   * @param { APIPollData } data trip item data
   */
  const onHandleUpdateForPollItem = async (data: APIPollData) => {
    setLoading(true)
    try {
      if (!canEditTripItem)
        return sendNotification(
          `${CONSTANTS.UPDATE_POLL_TITLE_PERMISSION_ERROR}`,
          'error',
        )
      if (data.title.length === 0)
        return sendNotification(`${CONSTANTS.EMPTY_POLL_TITLE_ERROR}`, 'error')

      const params = {
        user_uid: user.uid,
        uid: tripItem.uid,
        order: tripItem.order,
        short_uid: tripShortUid,
        title: data.title,
        trips_polls_items_tags: JSON.stringify(data.tags),
        color: data.color,
        trip_group: +data.trip_group.id,
        coordinates,
      }

      const res = await updatePollItem(tripItem.uid, params)
      if (res) {
        let tripGroupOfThatItem: any = {}

        for (const tripGroup of tripGroups) {
          for (const tripGroupItem of tripGroup.tripItems) {
            if (tripGroupItem.uid === tripItem.uid) {
              tripGroupOfThatItem = tripGroup
              break
            }
          }
        }

        const payload = {
          groupUid: tripGroupOfThatItem.uid,
          tripItem: res,
        }
        dispatch(updateIdOfTripGroupItem(payload))
        handleClose()
        sendNotification(
          `${CONSTANTS.TRIP_POLL_ITEM_UPDATE_SUCCESS}`,
          'success',
        )
        dispatch(
          updateTripItemColor({
            uid: res.uid,
            color: res.trip_poll_item_metadata.color,
          }),
        )
      }
    } catch (error) {
      const e = await (error as HTTPError)?.response?.json()
      const message = e?.message || error
      sendNotification(message, 'error')
      console.log('error occured: ', error)
    } finally {
      setLoading(false)
    }
  }

  /**
   * Update trip location item and add it to this current trip.
   *
   * @param { APILocationData } data trip item data
   */
  const onHandleUpdateForLocationItem = async (data: APILocationData) => {
    setLoading(true)
    const formData = new FormData()

    let tripGroupOfItem: TripGroup = tripGroups[0] //hack. just initialized it with any group, we are reseting this value below anyways.
    await tripGroups.forEach(tripGroup => {
      tripGroup.tripItems.forEach(tripGroupItem => {
        if (tripGroupItem.uid === tripItem.uid) {
          tripGroupOfItem = tripGroup
        }
      })
    })

    formData.append('created_by', user.uid as string)
    formData.append('user_uid', user.uid as string)
    formData.append('short_uid', tripShortUid)
    formData.append('uid', tripItem.uid)
    formData.append('order', tripItem?.order?.toString())

    if (!isNil(data.startTime)) {
      const startTime = moment(data.startTime).format()
      formData.append('start_time', startTime)
    }

    if (!isNil(data.endTime)) {
      const endTime = moment(data.endTime).format()
      formData.append('end_time', endTime)
    }

    if (!isNil(data.startDate)) {
      const startDate = moment(data.startDate).format()
      formData.append('start_date', startDate)
    }

    if (!isNil(data.endDate)) {
      const endDate = moment(data.endDate).format()
      formData.append('end_date', endDate)
    }

    if (!isEmpty(data.members)) {
      formData.append('members', JSON.stringify(data.members))
    }

    if (!isEmpty(data.color)) {
      formData.append('color', data.color)
    }

    if (!isEmpty(data.title)) {
      formData.append('title', data.title)
    }

    if (!isEmpty(data.note)) {
      formData.append('note', data.note)
    }

    if (!isEmpty(data.tags)) {
      formData.append('trips_locations_items_tags', JSON.stringify(data.tags))
    }

    if (data.files && data.files.length > 0) {
      formData.append('file_name', data.files[0].name)
      formData.append('file', data.files[0], data.files[0].name)
    } else if (myFiles.length > 0) {
      formData.append('removeFile', myFiles[0])
    }

    if (!isEmpty(data.trip_group) && !(typeof data.trip_group === 'object')) {
      formData.append('trip_group', JSON.stringify(+data.trip_group)) //+ will safly typecast string to number
    } else {
      formData.append('trip_group', JSON.stringify(tripGroupOfItem.id)) //+ will safly typecast string to number
    }

    if (coordinates.latitude && coordinates.longitude) {
      formData.append('latitude', coordinates?.latitude?.toString())
      formData.append('longitude', coordinates?.longitude?.toString())
    }

    try {
      const res = await tripLocationItemUpdate(tripItem.uid, formData)
      if (res) {
        let tripGroupOfThatItem: any = {}

        for (const tripGroup of tripGroups) {
          for (const tripGroupItem of tripGroup.tripItems) {
            if (tripGroupItem.uid === tripItem.uid) {
              tripGroupOfThatItem = tripGroup
              break
            }
          }
        }

        const payload = {
          groupUid: tripGroupOfThatItem.uid,
          tripItem: res,
        }
        dispatch(updateIdOfTripGroupItem(payload))
        handleClose()
        sendNotification(
          `${CONSTANTS.TRIP_LOCATION_ITEM_UPDATE_SUCCESS}`,
          'success',
        )

        dispatch(
          updateTripItemColor({
            uid: res.uid,
            color: res.trip_location_item_metadata.color,
          }),
          updateLocationItemColor({
            color: res.trip_location_item_metadata.color,
            place_id: (tripItem as unknown as TripLocationItem).google_place_id,
          }),
        )
      }
    } catch (error) {
      const e = await (error as HTTPError)?.response?.json()
      const message = e?.message || error
      sendNotification(message, 'error')
      console.log('error occured: ', error)
    } finally {
      setLoading(false)
    }
  }

  /**
   * Once trip item color is updated, update the userOwnedLocations
   * color object to reflect it in the explorer map marker.
   * @param { LocationItemColorState } data trip item location object
   */
  const updateLocationItemColorInState = (data: LocationItemColorState) => {
    const { trip_location_item_metadata, google_place_id } = data
    dispatch(
      updateUserOwnedPlacesArray([
        {
          place_id: google_place_id,
          color: trip_location_item_metadata.color,
        },
      ]),
    )
  }

  /**
   * Helper method used by Formik
   * @returns [item's tagsArray based on itemType] or [empty array]
   */
  const getTagsArrayBasedOnItemType = () => {
    if (tripItem.type === 'note') {
      return (tripItem as NoteTripGroupItem)['trips_notes_items_tags'].map(
        (e: any) => e.title,
      )
    }
    if (tripItem.type === 'poll') {
      return (tripItem as PollTripGroupItem)['trips_polls_items_tags'].map(
        (e: any) => e.title,
      )
    }
    if (tripItem.type === 'location') {
      return (tripItem as LocationTripGroupItem)[
        'trips_locations_items_tags'
      ].map((e: any) => e.title)
    }
    return []
  }

  /**
   * Function listens for the enter key to be hit.
   * If user is editing a trip location item note and hits enter,
   * prevent this function from executing.
   * @param {Event} keyEvent
   */
  const onKeyDown = (keyEvent: any) => {
    const mdeTarget = keyEvent.target.className.includes('ql-editor')
    if (keyEvent.keyCode === 13 && !mdeTarget) {
      keyEvent.preventDefault()
    }
  }

  useEffect(() => {
    if (
      !isNil(tripItem) &&
      (tripItem as unknown as TripLocationItem)?.google_place_id
    )
      getGooglePlaceInformation({
        place_id: (tripItem as unknown as TripLocationItem).google_place_id,
        setSelectedPlace,
        setPlacePhotos,
      })
    if (
      tripItem &&
      (tripItem.type === 'location' || tripItem.type === 'note')
    ) {
      onDownloadDocument()
    }
  }, [tripItem, onDownloadDocument])

  const modalContent = (
    <Container
      style={{
        padding,
        maxHeight: '85dvh',
      }}>
      <Formik
        initialValues={{
          title: tripItem?.title,
          startDate:
            moment(
              (tripItem as unknown as TripLocationItem)?.start_date,
            ).toDate() || null,
          endDate:
            moment(
              (tripItem as unknown as TripLocationItem)?.end_date,
            ).toDate() || null,
          startTime:
            moment(
              (tripItem as unknown as TripLocationItem)?.start_time,
            ).toDate() || null,
          endTime:
            moment(
              (tripItem as unknown as TripLocationItem)?.end_time,
            ).toDate() || null,
          tags: getTagsArrayBasedOnItemType(),
          type: tripItem?.type,
          members: [],
          note: (tripItem as unknown as TripLocationItem)?.note || '',
          description: (tripItem as unknown as TripNoteItem)?.description || '',
          color: '',
          files: [],
          trip_group: (tripItem as unknown as TripNoteItem)?.trip_group || 0,
        }}
        onSubmit={async data => await onHandleUpdateByType(data)}
        enableReinitialize={false}>
        {({ handleChange, setFieldValue, errors, touched, values, dirty }) => (
          <Form style={formInlineStyle} noValidate onKeyDown={onKeyDown}>
            <ContainerWrapper style={containerWrapper}>
              <Header
                style={{
                  gridColumnEnd: fullGridColumnLength,
                }}>
                <TitleInput
                  title={canEditTripItem ? values.title : tripItem.title}
                  canEdit={canEditTripItem}
                  handleChange={handleChange}
                  formikKey="title"
                  autoFocus={!tabletMobileView}
                  setFieldValue={setFieldValue}
                  onCloseModal={handleClose}
                  values={values}
                  defaultColor={
                    (tripItem.type === 'note' &&
                      (tripItem as unknown as NoteTripGroupItem)
                        .trip_note_item_metadata.color) ||
                    (tripItem.type === 'poll' &&
                      (tripItem as unknown as PollTripGroupItem)
                        .trip_poll_item_metadata.color) ||
                    (tripItem.type === 'location' &&
                      (tripItem as unknown as LocationTripGroupItem)
                        .trip_location_item_metadata.color)
                  }
                  tripItem={tripItem}
                  tripShortUid={tripShortUid}
                />
                {errors.title && touched.title ? (
                  <div>{errors.title}</div>
                ) : null}
                <ErrorMessage name="title" />
              </Header>

              <Body
                style={{
                  gridColumnEnd: fullGridColumnLength,
                }}>
                <BodyRow>
                  <BodyRowIcon>
                    <TagIcon height="16" width="16" />
                    <BodyRowContainer>
                      <FieldArray
                        name="tags"
                        render={(arrayHelpers: ArrayHelpers) => (
                          <PopupTags
                            setFieldValue={setFieldValue}
                            values={values}
                            arrayHelpers={arrayHelpers}
                          />
                        )}
                      />
                    </BodyRowContainer>
                  </BodyRowIcon>
                </BodyRow>
              </Body>
              <div style={{ overflow: 'auto' }}>
                {onRenderItemBodyByType({
                  setFieldValue,
                  values,
                  handleChange,
                  tripShortUid,
                })}
              </div>

              {/* The reason map is here is because the map in location
              item popup requires the full height of the modal and thus
              it needs to be a sibling of the entire left side. */}
              {!tabletMobileView && showLocationItemMapContainer && (
                <LocationMapContainer
                  style={{ overflowY: 'auto' }}
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}>
                  <div style={{ width: '100%' }}>
                    <Map
                      mapExpanded={false}
                      isLocationItem={true}
                      locationData={selectedPlace}
                      showMapInfo={true}
                      showExpandButton={false}
                    />
                  </div>
                </LocationMapContainer>
              )}

              <Footer
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  marginBottom: '4px',
                  marginLeft: '0.7rem',
                }}>
                <SaveButton showTripGroupDropdown={true} disabled={!dirty || loading} children={loading ? <Spinner/> : ''}/>
              </Footer>
            </ContainerWrapper>
          </Form>
        )}
      </Formik>
    </Container>
  )

  const modalProps = {
    open,
    modalContent,
    width,
  }

  return (
    <MTModal
      {...modalProps}
      onClose={handleClose}
      width={width}
      customStyles={{
        maxWidth: tripItem.type !== 'location' ? '678px' : width,
      }}
    />
  )
}

export default ItemPopup
