import React from 'react'

const Purchase = () => {
  return (
    <>
      <svg
        width="136"
        height="119"
        viewBox="0 0 136 119"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="76.5" cy="59.3022" rx="59.5" ry="59.3023" fill="#FF1053" />
        <circle cx="56.1255" cy="27.887" r="13.9742" fill="white" />
        <circle cx="60.2527" cy="27.8871" r="9.47241" fill="#263238" />
        <circle cx="62.7848" cy="20.1029" r="1.68815" fill="white" />
        <path
          d="M45.8613 11.6005C46.6116 10.6001 48.6562 8.71184 50.832 9.16202"
          stroke="#263238"
          strokeWidth="1.12543"
          strokeLinecap="round"
        />
        <circle cx="86.6997" cy="27.8874" r="13.9742" fill="white" />
        <circle cx="82.573" cy="27.8871" r="9.47241" fill="#263238" />
        <circle cx="86.0436" cy="20.1029" r="1.68815" fill="white" />
        <path
          d="M89.5194 9.09166C90.7627 8.95805 93.5381 9.1654 94.6929 11.0637"
          stroke="#263238"
          strokeWidth="1.12544"
          strokeLinecap="round"
        />
        <path
          d="M0.859799 55.6928C4.28933 58.1591 12.916 61.771 19.9863 56.4879"
          stroke="#263238"
          strokeWidth="1.38775"
          strokeLinecap="round"
        />
        <path
          d="M71.0613 43.2361C69.2014 43.0145 65.6736 43.4549 66.4414 46.9895C67.2092 50.5241 70.4752 50.5031 72.0122 50.0508"
          stroke="#263238"
          strokeWidth="1.25328"
          strokeLinecap="round"
        />
        <path
          d="M71.0994 50.184C69.6806 50.0149 66.9893 50.3509 67.575 53.0474C68.1608 55.7438 70.6523 55.7278 71.8248 55.3827"
          stroke="#263238"
          strokeWidth="0.956097"
          strokeLinecap="round"
        />
        <path
          d="M121.189 89.0096L111.167 68.5795L104.715 49.6778L94.8809 54.8702L85.7193 55.1312L78.6629 60.2817L66.9349 60.325L79.1839 79.037L87.1279 99.0115L96.0372 99.1602L103.675 93.5694L112.913 94.4009L121.189 89.0096Z"
          fill="#EBEBEB"
        />
        <path
          d="M112.913 94.4009L94.8814 54.8701L85.7198 55.1311L103.676 93.5693L112.913 94.4009Z"
          fill="#DBDBDB"
        />
        <path
          d="M96.0373 99.1602L78.663 60.2817L66.9349 60.3249L79.184 79.037L87.1279 99.0115L96.0373 99.1602Z"
          fill="#DBDBDB"
        />
        <path
          d="M126.921 50.2289C124.758 55.149 117.628 64.662 106.416 63.3536"
          stroke="#263238"
          strokeWidth="1.38775"
          strokeLinecap="round"
        />
        <path
          d="M83.1747 46.5834C85.155 46.4086 87.0467 45.6819 88.6346 44.4857C90.2225 43.2895 91.443 41.6718 92.1573 39.8165C92.8717 37.9612 93.0513 35.9427 92.6756 33.9905C92.3 32.0382 91.3843 30.2305 90.0324 28.7728C88.6806 27.3151 86.947 26.2658 85.0286 25.7442C83.1102 25.2227 81.0839 25.2497 79.1801 25.8224C77.2763 26.395 75.5713 27.4902 74.2589 28.9836C72.9465 30.4769 72.0794 32.3084 71.7561 34.27C71.3121 37.0523 71.9865 39.8973 73.6323 42.1842C75.278 44.471 77.7615 46.0142 80.5408 46.4768C81.4108 46.6227 82.2957 46.6585 83.1747 46.5834ZM81.4428 26.8182C82.1764 26.7908 82.9107 26.845 83.6323 26.9799C85.939 27.4309 87.9865 28.7458 89.3563 30.6558C90.726 32.5657 91.3148 34.9267 91.0021 37.2562C90.6895 39.5857 89.4991 41.708 87.6742 43.1892C85.8492 44.6704 83.5276 45.3989 81.1836 45.2257C78.8395 45.0525 76.6501 43.9908 75.0627 42.2575C73.4753 40.5241 72.6097 38.25 72.6428 35.8998C72.6759 33.5497 73.6052 31.3009 75.2408 29.6129C76.8764 27.9249 79.0948 26.9253 81.4428 26.8182Z"
          fill="#FFC727"
        />
        <path
          d="M58.8022 45.7074C62.8575 45.3466 66.1596 40.8793 66.3328 35.2563C66.4675 32.6049 65.7599 29.9789 64.3114 27.7541C63.7563 26.7571 62.9517 25.9214 61.9765 25.3289C61.0013 24.7364 59.8889 24.4074 58.7483 24.3742C57.6077 24.341 56.4781 24.6047 55.47 25.1394C54.462 25.6741 53.6102 26.4616 52.9981 27.4246C51.4306 29.5572 50.564 32.124 50.518 34.7704C50.3489 40.6359 53.7533 45.5105 58.125 45.6353L58.8022 45.7074ZM58.0685 25.8398L58.6159 25.8803C59.5218 25.9422 60.3993 26.2227 61.1731 26.6978C61.947 27.1728 62.5943 27.8283 63.0595 28.6082C64.3111 30.6209 64.9066 32.9727 64.7637 35.3385C64.6106 40.3906 61.5818 44.4378 58.0015 44.3274C54.4212 44.2169 51.716 40.0104 51.7734 35.0613C51.8087 32.7396 52.5677 30.4869 53.9446 28.6172C54.3939 27.8691 55.0057 27.2316 55.7347 26.7519C56.4637 26.2723 57.2912 25.9628 58.1561 25.8463L58.0685 25.8398Z"
          fill="#FFC727"
        />
        <path
          d="M72.0819 35.5269L73.0199 34.4514C71.4497 33.0585 67.7847 31.1146 65.1157 33.8674L66.1206 34.8663C68.5043 32.4006 71.9383 35.3842 72.0819 35.5269Z"
          fill="#FFC727"
        />
      </svg>

      <h2 className="m-5">
        {' '}
        <strong>Woot! You have successfully changed your plan!</strong>
      </h2>
    </>
  )
}

export default Purchase
