import React, { useCallback, useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/'
import { motion } from 'framer-motion'
import { useQuery } from 'hooks/useQuery'
import { useAppDispatch } from 'store/hooks'
import { setGeoLocation } from 'store/slices/geoLocation'
import { pageVariants } from 'styles/sharedMotion'
import { isScreenSize } from 'utils/screenSzie'
import { fetchUnsplashImageData } from 'utils/unsplash'
import { useHistory } from 'react-router'
import CreateTripForm from './layouts/CreateTripCard/CreateTripForm'
import CreateTripLogo from 'components/MTIcons/CreateTripLogo'
import {
  ContentContainer,
  CreateTripContainer,
  FormContainer,
  LogoContainer,
  SubText,
  Text,
  TopContainer,
} from './createTrip.style'
import { Community } from 'pages/Community'
import CommunityPage from 'pages/Community/CommunityPage'
import { CommunityTrips } from 'pages/Community/components/CommunityTrips'

const CreateTrip = () => {
  const query = useQuery()
  const displayLoginOnAccountActivation = query.get('display_login')
  const dispatch = useAppDispatch()
  const history = useHistory()

  React.useEffect(() => {
    // dispatch(clearGeoLocation())
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        const coordinates = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        }
        dispatch(setGeoLocation(coordinates))
      })
    } else {
      console.log('Geolocation is not available in your browser.')
    }
  }, [dispatch])

  const smallScreen = isScreenSize(650)

  return (
    <CreateTripContainer>
      <TopContainer>
        <div style={{ padding: '0 10px', width: '98%', maxWidth: '1280px',display: 'flex', columnGap: '20px', justifyContent: 'space-between', alignItems: 'center' }}>
        <ContentContainer smallScreen={smallScreen}>
          <div>
            <Text>Create a trip with your travel-mates</Text>
          </div>

          <SubText>
            Collaborate with people to plan your first free trip with us!
          </SubText>
          {!smallScreen ? (
            <LogoContainer>
              <CreateTripLogo width='100%'/>
            </LogoContainer>
          ) : (
            <CreateTripForm fullWidth={true} />
          )}
        </ContentContainer>
        {!smallScreen && <CreateTripForm />}
        </div>
      </TopContainer>
      <div style={{ minWidth: '100vw', backgroundColor: '#ffffff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ width: '98%', maxWidth: '1260px' }}>
          <Community preview={true}/>
        </div>
      </div>
    </CreateTripContainer>
  )
}

export default CreateTrip
