/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import MyTrips from './MyTrips'
import './Dashboard.css'
import { Tab, TabButton, TabContainer, TabIndicator } from './dashboard.styles'
import {Community} from '../Community'

const Dashboard = () => {
  const [tripsSelected, setTripsSelected] = useState(true)
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    if (queryParams.has('community')) {
      setTripsSelected(false)
      // Add any actions you want to take when "community" is present
    }
  }, [])

  const handleOpenCommunity = () => {
    setTripsSelected(false)
    const url = new URL(window.location)
    url.searchParams.set('community', '1') // Adds "community" without a value
    window.history.pushState({}, '', url)
  }

  const handleOpenTrips = () => {
    setTripsSelected(true)
    const url = new URL(window.location)
    url.searchParams.delete('community') // Removes "community" parameter
    window.history.pushState({}, '', url) // Updates the URL without reloading
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#FFFFFF' }}>
    <div style={{ width: '90%', maxWidth: '1258px' }}>
      <TabContainer>
        <Tab>
        <TabButton onClick={handleOpenTrips} active={tripsSelected}>
          My Trips
        </TabButton>
        <TabIndicator active={tripsSelected}/>
        </Tab>
        <Tab>
        <TabButton onClick={handleOpenCommunity} active={!tripsSelected}>
          Community
        </TabButton>
        <TabIndicator active={!tripsSelected}/>
        </Tab>
      </TabContainer>
      <div>
        {
          tripsSelected
          ? <MyTrips/>
          : <Community/>
        }
      </div>
    </div>
   </div>
  )
}
export default Dashboard
