import React from 'react'

import styled from '@emotion/styled'
import { Button } from '@material-ui/core'
import { MTButton } from 'components/MTButton'
import {
  BlueButtonStyleProps,
  TransparentButtonStyleProps,
} from 'components/MTDialog/helper'
import { CONSTANTS } from 'core/constants'
import { isScreenSize } from 'utils/screenSzie'

const ContainerTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
`

const ContainerBottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
`

type SignUpToInviteUserProps = {
  onSkipAndContinueClickHandler?: () => void
  onDeleteTripClickHandler?: () => void
  onSignUpClickHandler?: () => void
  onSignInClickHandler?: () => void
  blueButtonStyle: BlueButtonStyleProps
  transparentButtonStyle: TransparentButtonStyleProps
  isTripCreator: boolean
}

const SignUpToSaveYourTrip = ({
  onSkipAndContinueClickHandler,
  onDeleteTripClickHandler,
  onSignUpClickHandler,
  onSignInClickHandler,
  blueButtonStyle,
  transparentButtonStyle,
  isTripCreator,
}: SignUpToInviteUserProps) => {
  console.log({onSignInClickHandler, onSignUpClickHandler})
  const smallScreen = isScreenSize(450)
  return (
    <>
      <ContainerTop style={smallScreen ? { flexDirection: 'column' } : {}}>
        <MTButton
          customStyles={transparentButtonStyle}
          changeWidth={smallScreen}
          marginBottom={smallScreen}
          onHandleClick={() => {
            if (onSkipAndContinueClickHandler) onSkipAndContinueClickHandler()
          }}
          title="Skip and continue"
        />
        <MTButton
          customStyles={blueButtonStyle}
          changeWidth={smallScreen}
          onHandleClick={() => {
            if (onSignUpClickHandler) onSignUpClickHandler()
          }}
          title="Yes, sign up!"
        />
      </ContainerTop>
      <ContainerBottom style={smallScreen ? { flexDirection: 'column' } : {}}>
        {isTripCreator && (
          <Button
            style={{
              fontSize: '1.3rem',
              textTransform: 'none',
              color: '#C10F2A',
            }}
            onClick={onDeleteTripClickHandler}>
            Delete trip
          </Button>
        )}
        <Button
          style={{
            fontSize: '1.3rem',
            textTransform: 'none',
          }}
          onClick={onSignInClickHandler}>
          {CONSTANTS.ALREADY_HAVE_ACCOUNT_SIGN_IN}
        </Button>
      </ContainerBottom>
    </>
  )
}

export default SignUpToSaveYourTrip
