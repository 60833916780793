import styled from '@emotion/styled'
import { Modal } from '@material-ui/core'

export const ModalStyled = styled(Modal)`
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
`

export const ModalWrapperStyled = styled.div`
  display: flex;
  align-items: stretch;
  border: 0;
  background-color: white;
  outline: none;
  min-width: 100%;
  padding: 0rem;
`

export const ModalContentStyled = styled.div`
  display: flex;
  align-items: stretch;
  min-width: 100%;
  padding: 0rem;
  height: 100%;
  max-height: 98dvh;
  overflow: hidden
`
