import React from 'react'

import { cssVariables } from 'theme'

type IconProps = {
  stroke?: string
  fill?: string
}
const UserIcon = ({ stroke, fill }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill={fill || 'none'}
      stroke={stroke || cssVariables.colors.alternativeDarkGrey2}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-user">
      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
      <circle cx="12" cy="7" r="4"></circle>
    </svg>
  )
}

export default UserIcon
