import React from 'react'

import { DatePicker as DatePickerAntD } from 'formik-antd'
import { isNil } from 'lodash'
import moment from 'moment'
import { updateDates } from './helper'
import {StyledDatePicker} from './DatePicker.style'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

type DatePickerProps = {
  fullWidth?: boolean
  formikKey: string
  startDateVisual: Date
  endDateVisual: Date
  displayDate: Date
  setFieldValue: (key: string, val: Date) => void
  setStartDateVisual: (date: Date) => void
  setEndDateVisual: (date: Date) => void
  height?: number
  iconSize?: number
  customStyles?: CSSProperties
}

const DatePicker = ({
  fullWidth = false,
  formikKey,
  startDateVisual,
  endDateVisual,
  displayDate,
  setFieldValue,
  setStartDateVisual,
  setEndDateVisual,
  height,
  iconSize,
  customStyles
}: DatePickerProps) => {
  const dateFormat = 'MMM DD, YYYY'

  const style = {
    ...(fullWidth && { width: '100%' }),
    ...(height && { height }), // Only adds height if it's provided
    ...(customStyles && {customStyles})
  }
  return (
    <StyledDatePicker
      name={formikKey}
      style={style}
      value={moment(displayDate)}
      format={dateFormat}
      size='large'
      iconSize={iconSize}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(date: any) => {
        if (isNil(date) && formikKey === 'startDate') date = new Date()
        if (isNil(date) && formikKey === 'endDate')
          date = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
        return updateDates({
          date,
          formikKey,
          startDate: startDateVisual,
          endDate: endDateVisual,
          setFieldValue,
          setStartDateVisual,
          setEndDateVisual,
        })
      }}
    />
  )
}

export default DatePicker
