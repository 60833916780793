import React from 'react'

type IconProp = {
  stroke?: string
  height?: string
  width?: string
}
const CreateTripLogo = ({ stroke, height, width }: IconProp) => {
  return (
    <svg
      width={width ?? '567'}
      height={height ?? '349'}
      viewBox="0 0 567 349"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.2"
        d="M398.284 339.873C474.926 339.873 537.057 277.742 537.057 201.1C537.057 124.459 474.926 62.3281 398.284 62.3281C321.642 62.3281 259.512 124.459 259.512 201.1C259.512 277.742 321.642 339.873 398.284 339.873Z"
        fill="#01D0FF"
      />
      <path
        d="M397.461 305.121C456.05 305.121 503.546 257.625 503.546 199.036C503.546 140.446 456.05 92.9502 397.461 92.9502C338.871 92.9502 291.375 140.446 291.375 199.036C291.375 257.625 338.871 305.121 397.461 305.121Z"
        fill="#E8F9FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M514.856 222.116C518.822 199.155 515.829 175.512 506.258 154.178C496.686 132.844 480.965 114.777 461.082 102.261C441.2 89.7452 418.049 83.343 394.557 83.864C371.065 84.3849 348.288 91.8057 329.105 105.188C309.922 118.57 295.196 137.312 286.788 159.045C282.803 169.346 280.322 180.099 279.368 190.985L279.324 190.998C277.51 205.714 269.343 230.235 263.563 244.158L253.447 265.802C250.232 272.681 254.468 280.762 262.006 282.127L286.251 286.517C301.148 289.802 325.892 297.102 339.046 303.974L339.073 303.953C350.987 310.716 364.045 315.426 377.692 317.804C408.744 323.147 440.61 316.078 466.316 298.145C492.023 280.212 509.476 252.875 514.856 222.116ZM388.484 255.73C358.428 250.493 338.234 222.092 343.38 192.295C348.526 162.497 377.063 142.588 407.118 147.825C437.174 153.062 457.368 181.463 452.222 211.26C447.076 241.057 418.54 260.967 388.484 255.73Z"
        fill="#196AFF"
      />
      <mask
        id="mask0_132_31884"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="274"
        y="102"
        width="225"
        height="200">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M496.698 218.981C500.043 199.611 497.519 179.665 489.444 161.668C481.369 143.67 468.107 128.428 451.334 117.87C434.56 107.311 415.03 101.91 395.212 102.35C375.394 102.789 356.179 109.049 339.996 120.339C323.813 131.628 311.389 147.44 304.296 165.774C300.934 174.464 298.841 183.536 298.037 192.72L298 192.731C296.47 205.142 289.584 225.819 284.708 237.567L274.756 258.86C272.756 263.14 275.387 268.157 280.075 269.006L303.789 273.3C316.35 276.066 337.27 282.233 348.383 288.039L348.407 288.02C358.457 293.726 369.473 297.699 380.984 299.705C407.18 304.212 434.063 298.249 455.749 283.12C477.435 267.991 492.159 244.929 496.698 218.981ZM390.088 247.337C364.733 242.919 347.697 218.959 352.038 193.822C356.379 168.685 380.453 151.889 405.809 156.307C431.164 160.725 448.2 184.684 443.859 209.822C439.518 234.959 415.444 251.755 390.088 247.337Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_132_31884)">
        <rect
          width="87.5734"
          height="227.171"
          transform="matrix(-0.894111 0.448104 0.466569 0.884473 435.651 57.3233)"
          fill="#FF526D"
        />
        <rect
          width="87.5734"
          height="227.171"
          transform="matrix(-0.894111 0.448104 0.466569 0.884473 357.351 96.5643)"
          fill="#FFDD29"
        />
        <rect
          width="87.5733"
          height="227.171"
          transform="matrix(-0.895396 0.445587 0.464051 0.885769 278.865 133.802)"
          fill="#40DBFF"
        />
      </g>
      <path
        d="M384.251 254.107C355.288 246.541 337.777 217.091 345.319 188.224C352.86 159.358 382.538 142.237 411.5 149.803C440.463 157.37 457.974 186.819 450.433 215.686C442.891 244.552 413.213 261.673 384.251 254.107Z"
        stroke="#196AFF"
        strokeWidth="15.4915"
      />
      <ellipse
        cx="28.5585"
        cy="29.6323"
        rx="28.5585"
        ry="29.6323"
        transform="matrix(-0.967527 -0.252767 -0.252767 0.967527 497.893 97.3945)"
        fill="white"
      />
      <ellipse
        cx="28.5585"
        cy="29.6323"
        rx="28.5585"
        ry="29.6323"
        transform="matrix(-0.967527 -0.252767 -0.252767 0.967527 437.439 81.6016)"
        fill="white"
      />
      <ellipse
        cx="19.3585"
        cy="20.0863"
        rx="19.3585"
        ry="20.0863"
        transform="matrix(-0.967527 -0.252767 -0.252767 0.967527 478.419 102.174)"
        fill="#3A3A3A"
      />
      <ellipse
        cx="19.3585"
        cy="20.0863"
        rx="19.3585"
        ry="20.0863"
        transform="matrix(-0.967527 -0.252767 -0.252767 0.967527 434.284 90.6435)"
        fill="#3A3A3A"
      />
      <ellipse
        cx="6.42003"
        cy="6.54454"
        rx="6.42003"
        ry="6.54454"
        transform="matrix(-0.967527 -0.252767 -0.252767 0.967527 474.64 102.316)"
        fill="white"
      />
      <ellipse
        cx="4.08476"
        cy="4.59153"
        rx="4.08476"
        ry="4.59153"
        transform="matrix(-0.967527 -0.252767 -0.252767 0.967527 468.321 130.132)"
        fill="white"
      />
      <ellipse
        cx="4.08476"
        cy="4.59153"
        rx="4.08476"
        ry="4.59153"
        transform="matrix(-0.967527 -0.252767 -0.252767 0.967527 421.883 118)"
        fill="white"
      />
      <ellipse
        cx="6.21124"
        cy="6.80957"
        rx="6.21124"
        ry="6.80957"
        transform="matrix(-0.967527 -0.252767 -0.252767 0.967527 415.719 85.7961)"
        fill="white"
      />
      <path
        d="M435.422 140.284C434.26 144.73 424.862 158.943 419.59 157.566C414.319 156.188 413.898 139.411 415.06 134.964C416.221 130.517 418.272 131.391 425.483 133.275C433.119 135.269 436.583 135.837 435.422 140.284Z"
        fill="white"
      />
      <path
        d="M473.908 88.0398C471.603 86.8402 466.109 85.1619 462.566 88.0462"
        stroke="#3A3A3A"
        strokeWidth="2.32373"
        strokeLinecap="round"
      />
      <path
        d="M420.379 75.057C419.53 72.6197 416.685 67.6641 412.102 67.3399"
        stroke="#3A3A3A"
        strokeWidth="2.32372"
        strokeLinecap="round"
      />
      <path
        d="M508.162 177.947C524.023 175.395 546.702 166.25 541.635 135.357"
        stroke="#3A3A3A"
        strokeWidth="2.32373"
        strokeLinecap="round"
      />
      <path
        d="M340.553 105.674C327.449 96.4193 311.147 78.2546 329.382 52.7538"
        stroke="#3A3A3A"
        strokeWidth="2.32373"
        strokeLinecap="round"
      />
      <path
        d="M422.815 309.027C438.227 308.405 470.327 305.827 475.426 300.496C480.525 295.164 477.546 288.307 475.419 285.546"
        stroke="#3A3A3A"
        strokeWidth="2.32373"
        strokeLinecap="round"
      />
      <path
        d="M329.771 292.607C316.972 284.062 290.88 265.271 288.906 258.461C286.933 251.65 292.484 247.878 295.506 246.843"
        stroke="#3A3A3A"
        strokeWidth="2.32373"
        strokeLinecap="round"
      />
      <g clipPath="url(#clip0_132_31884)">
        <ellipse
          cx="34.6633"
          cy="34.5481"
          rx="34.6633"
          ry="34.5481"
          transform="matrix(-0.942083 0.335379 0.335379 0.942083 225.311 134)"
          fill="#02C45D"
        />
        <ellipse
          cx="7.67883"
          cy="7.65667"
          rx="7.67883"
          ry="7.65667"
          transform="matrix(-0.942083 0.335379 0.335379 0.942083 230.75 161.677)"
          fill="white"
        />
        <ellipse
          cx="5.20511"
          cy="5.19009"
          rx="5.20511"
          ry="5.19009"
          transform="matrix(-0.942083 0.335379 0.335379 0.942083 227.11 165.592)"
          fill="#3A3A3A"
        />
        <ellipse
          cx="0.927644"
          cy="0.924967"
          rx="0.927644"
          ry="0.924967"
          transform="matrix(-0.942083 0.335379 0.335379 0.942083 221.77 167.492)"
          fill="white"
        />
        <path
          d="M229.271 162.666C228.699 162.288 227.293 161.69 226.25 162.323"
          stroke="#3A3A3A"
          strokeWidth="0.655651"
          strokeLinecap="round"
        />
        <circle
          cx="8.14101"
          cy="8.14101"
          r="8.14101"
          transform="matrix(-0.942083 0.335379 0.335379 0.942083 214.628 166.746)"
          fill="white"
        />
        <circle
          cx="5.5184"
          cy="5.5184"
          r="5.5184"
          transform="matrix(-0.942083 0.335379 0.335379 0.942083 215.302 169.289)"
          fill="#3A3A3A"
        />
        <circle
          cx="0.983477"
          cy="0.983477"
          r="0.983477"
          transform="matrix(-0.942083 0.335379 0.335379 0.942083 209.125 171.488)"
          fill="white"
        />
        <path
          d="M204.847 168.834C204.138 169.003 202.656 169.659 202.393 170.927"
          stroke="#3A3A3A"
          strokeWidth="0.655651"
          strokeLinecap="round"
        />
        <path
          d="M222.738 180.532C222.92 181.044 222.217 182.415 221.082 182.819C219.946 183.224 218.535 182.605 218.353 182.093C218.171 181.581 219.286 181.369 220.422 180.965C221.557 180.561 222.556 180.02 222.738 180.532Z"
          fill="#FFC7E7"
        />
      </g>
      <g clipPath="url(#clip1_132_31884)">
        <ellipse
          cx="66.516"
          cy="160.383"
          rx="54.9293"
          ry="54.7468"
          transform="rotate(-13.9625 66.516 160.383)"
          fill="#FF5B75"
        />
        <circle
          cx="46.128"
          cy="137.223"
          r="12.9007"
          transform="rotate(-13.9625 46.128 137.223)"
          fill="white"
        />
        <circle
          cx="49.825"
          cy="136.304"
          r="8.74475"
          transform="rotate(-13.9625 49.825 136.304)"
          fill="#3A3A3A"
        />
        <circle
          cx="50.3597"
          cy="128.766"
          r="1.55847"
          transform="rotate(-13.9625 50.3597 128.766)"
          fill="white"
        />
        <path
          d="M33.304 124.919C33.7533 123.855 35.1644 121.708 37.214 121.627"
          stroke="#3A3A3A"
          strokeWidth="1.03898"
          strokeLinecap="round"
        />
        <circle
          cx="73.5195"
          cy="130.414"
          r="12.9007"
          transform="rotate(-13.9625 73.5195 130.414)"
          fill="white"
        />
        <circle
          cx="69.8224"
          cy="131.332"
          r="8.74475"
          transform="rotate(-13.9625 69.8224 131.332)"
          fill="#3A3A3A"
        />
        <circle
          cx="71.1973"
          cy="123.585"
          r="1.55847"
          transform="rotate(-13.9625 71.1973 123.585)"
          fill="white"
        />
        <path
          d="M71.859 112.946C72.9431 112.549 75.4758 112.117 76.9332 113.56"
          stroke="#3A3A3A"
          strokeWidth="1.03898"
          strokeLinecap="round"
        />
        <path
          d="M61.0303 148.943C61.4636 150.686 63.0072 153.897 64.8567 153.437C66.7061 152.977 66.3222 148.98 65.8889 147.237C65.4555 145.494 63.9278 142.347 62.0783 142.807C60.2289 143.267 60.5969 147.2 61.0303 148.943Z"
          fill="#FFC7E7"
        />
      </g>
      <g clipPath="url(#clip2_132_31884)">
        <ellipse
          cx="46.7916"
          cy="46.6361"
          rx="46.7916"
          ry="46.6361"
          transform="matrix(-0.962369 0.271744 0.271744 0.962369 178.062 3.00003)"
          fill="#FFD600"
        />
        <circle
          cx="10.9895"
          cy="10.9895"
          r="10.9895"
          transform="matrix(-0.962369 0.271744 0.271744 0.962369 170.058 61.8975)"
          fill="white"
        />
        <circle
          cx="7.44923"
          cy="7.44923"
          r="7.44923"
          transform="matrix(-0.962369 0.271744 0.271744 0.962369 164.49 67.1483)"
          fill="#3A3A3A"
        />
        <circle
          cx="1.32759"
          cy="1.32759"
          r="1.32759"
          transform="matrix(-0.962369 0.271744 0.271744 0.962369 156.682 69.3526)"
          fill="white"
        />
        <path
          d="M166.351 64.8415C165.57 64.2447 163.619 63.2526 162.068 64.0583"
          stroke="#3A3A3A"
          strokeWidth="0.885057"
          strokeLinecap="round"
        />
        <circle
          cx="10.9895"
          cy="10.9895"
          r="10.9895"
          transform="matrix(-0.962369 0.271744 0.271744 0.962369 146.919 68.4317)"
          fill="white"
        />
        <circle
          cx="7.44923"
          cy="7.44923"
          r="7.44923"
          transform="matrix(-0.962369 0.271744 0.271744 0.962369 147.597 71.92)"
          fill="#3A3A3A"
        />
        <circle
          cx="1.32759"
          cy="1.32759"
          r="1.32759"
          transform="matrix(-0.962369 0.271744 0.271744 0.962369 139.08 74.3243)"
          fill="white"
        />
        <path
          d="M133.385 71.7433C132.477 72.1218 130.643 73.3153 130.565 75.0609"
          stroke="#3A3A3A"
          strokeWidth="0.885057"
          strokeLinecap="round"
        />
        <path
          d="M157.535 89.8679C157.61 90.1321 156.948 90.732 154.716 91.3622C152.484 91.9924 151.606 91.8275 151.531 91.5633C151.456 91.299 151.967 89.8692 154.199 89.239C156.431 88.6088 157.461 89.6036 157.535 89.8679Z"
          fill="#FF8CCC"
        />
      </g>
      <g clipPath="url(#clip3_132_31884)">
        <ellipse
          cx="112.964"
          cy="285.774"
          rx="56.9637"
          ry="56.7744"
          fill="#37CFFF"
        />
        <circle cx="106.215" cy="260.886" r="13.3785" fill="white" />
        <circle cx="110.165" cy="260.886" r="9.06863" fill="#3A3A3A" />
        <circle cx="112.59" cy="253.434" r="1.61619" fill="white" />
        <path
          d="M97.0137 246.971C97.732 246.013 99.6894 244.205 101.772 244.636"
          stroke="#3A3A3A"
          strokeWidth="1.07746"
          strokeLinecap="round"
        />
        <circle cx="134.435" cy="259.694" r="12.3273" fill="white" />
        <circle cx="130.794" cy="259.693" r="8.35608" fill="#3A3A3A" />
        <circle cx="133.855" cy="252.826" r="1.4892" fill="white" />
        <path
          d="M138.027 243.397C139.123 243.529 141.467 244.258 142.083 246.119"
          stroke="#3A3A3A"
          strokeWidth="1.07746"
          strokeLinecap="round"
        />
        <path
          d="M125.875 277.619C125.875 276.091 124.735 275.969 121.065 275.969C117.395 275.969 116.002 276.091 116.002 277.619C116.002 279.147 117.395 286.032 121.065 286.032C124.735 286.032 125.875 279.147 125.875 277.619Z"
          fill="#FF8CCC"
        />
      </g>
      <defs>
        <clipPath id="clip0_132_31884">
          <rect
            width="69.3265"
            height="69.3265"
            fill="white"
            transform="matrix(-0.942083 0.335379 0.335379 0.942083 225.311 134)"
          />
        </clipPath>
        <clipPath id="clip1_132_31884">
          <rect
            width="109.859"
            height="109.859"
            fill="white"
            transform="translate(0 120.508) rotate(-13.9625)"
          />
        </clipPath>
        <clipPath id="clip2_132_31884">
          <rect
            width="93.5832"
            height="93.5832"
            fill="white"
            transform="matrix(-0.962369 0.271744 0.271744 0.962369 178.062 3)"
          />
        </clipPath>
        <clipPath id="clip3_132_31884">
          <rect
            width="113.927"
            height="113.927"
            fill="white"
            transform="translate(56 229)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CreateTripLogo
