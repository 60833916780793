import React from 'react'

import styled from '@emotion/styled'
import { MTButton } from 'components'
import {
  RedButtonStyleProps,
  TransparentButtonStyleProps,
} from 'components/MTDialog/helper'
import { isScreenSize } from 'utils/screenSzie'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
`

type DeleteItemProps = {
  setOpenDialog?: (value: boolean) => void
  setOpenDowngradeWarningDialog?: (value: boolean) => void
  onCancelClickHandler?: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  redButtonStyle: any
  transparentButtonStyle: TransparentButtonStyleProps
}

const DowngradePlanWarning = ({
  setOpenDialog,
  setOpenDowngradeWarningDialog,
  redButtonStyle,
  transparentButtonStyle,
}: DeleteItemProps) => {
  return (
    <>
      <Container>
        <MTButton
          customStyles={redButtonStyle}
          onHandleClick={() => {
            if (setOpenDowngradeWarningDialog)
              setOpenDowngradeWarningDialog(false)
            if (setOpenDialog) setOpenDialog(true)
          }}
          title={'Cancel your plan anyway'}
        />
        <MTButton
          customStyles={transparentButtonStyle}
          title="Cancel"
          onHandleClick={() => {
            if (setOpenDowngradeWarningDialog)
              setOpenDowngradeWarningDialog(false)
          }}
        />
      </Container>
    </>
  )
}

export default DowngradePlanWarning
