import styled from '@emotion/styled'
import { SearchIcon } from 'components/MTIcons'


export const CommunityContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    background-color: #ffffff;
`

export const TextContainer = styled.p`
    width: 100%;
    max-width: 600px;
`

export const ImageContainer = styled.p`
    width: 100%;
    max-width: 450px;
`
export const SearchBarContainer = styled.p`
    width: 100%;
    border-radius: 10px;
    border: 1px solid #848484;
    height: 64px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`


export const CommunityHeaderText = styled.p`
    font-weight: 900;
    font-size: 60px;
    line-height: 65px;
    letter-spacing: -3%;
    color: #3A3A3A;
`
export const CommunityText = styled.p`
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: #3A3A3A;
`

export const SearchIconContainer = styled.div`
    padding: 20px 18px;
`

export const SearchInput = styled.input`
    width: 95%;
    font-size: 20px;
    border: 0;
    outline: 0;
    background-color: transparent;
`

export const TagList = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

export const ClearAllButton = styled.button`
  border: 0;
  outline: 0;
  text-decoration: underline;
  color: #0545BA;
  background: transparent;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  padding:0;
  min-width: 110px;
`

export const SearchAllButton = styled.button`
  border: 0;
  background-color:transparent;
  height: 64px;
  color: #3A3A3A;
  font-size: 22px;
  line-height: 28px;
  font-weight: 700;
  border: 1px solid #8F8F8F;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
  min-width: fit-content;
  padding: 20px 13px;
`

export const NavFooter = styled.div`
 display: flex;
 align-items: center;
 width: 100%;
 justify-content: space-between;
 flex-wrap: wrap;
 margin-top: 48px;
 gap: 16px;
 padding: 0 10px;
`
