/* eslint-disable no-console */
import React, { Suspense } from 'react'

import { ErrorBoundary } from 'react-error-boundary'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { Error as ErrorFallback } from 'router/components/Error'
import { resetAllLocationFields } from 'store/slices/locationSearchResults'
import { clearAllTripItemColors } from 'store/slices/tripItemColor'
import { clearLocationsAndIndex } from 'store/slices/tripItemLocationsMap'
import { setDocumentTitle } from 'utils/documentTitle'

import { resetTripCoords } from 'store/slices/tripCoords'
/**
 * Error handler.
 * TODO: implment logger here at some point
 *
 * @param {string} error error
 * @param {Object} info stack trace
 */
const myErrorHandler = (error, info) => {
  console.log('error', error)
  console.log('info', info)
  //TODO: add sentry in here at some point
}

const RouteWithSubRoutes = route => {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  if (!route.path.includes('workspace')) {
    setDocumentTitle()
  }

  if (
    route.location &&
    route.location.state &&
    route.location.state.from &&
    route.location.state.from.includes('workspace')
  ) {
    dispatch(clearLocationsAndIndex())
    dispatch(resetAllLocationFields())
    dispatch(clearAllTripItemColors())
    dispatch(resetTripCoords())
  }

  // TODO: need to switch this for token check instead
  const authenticated = user && user.user ? user.user.authenticated : undefined
  return (
    <Suspense fallback={route.fallback}>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={myErrorHandler}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
        }}>
        <Route
          path={route.path}
          render={props => {
            return route.redirect ? (
              <Redirect to={route.redirect} />
            ) : route.private ? (
              authenticated ? (
                route.component && (
                  <route.component {...props} routes={route.routes} />
                )
              ) : (
                <Redirect to="/" />
              )
            ) : (
              route.component && (
                <route.component {...props} routes={route.routes} />
              )
            )
          }}
        />
      </ErrorBoundary>
    </Suspense>
  )
}

export default RouteWithSubRoutes
