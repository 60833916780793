import Cookies from 'js-cookie'

/**
 * Extract cookie from browser storage
 *
 * @param {string} cookie key
 */
export const getCookie = (cookie: string) => Cookies.get(cookie)

/**
 * Set cookie in browser storage
 * @param {string} cookie key
 * @param {string} token value
 */
export const setCookie = (cookie: string, token: string) =>
  Cookies.set(cookie, token)

/**
 * Remove cookie from browser storage
 * @param {string} cookie key
 */
export const removeCookie = (cookie: string) => Cookies.remove(cookie)
