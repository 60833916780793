import React, { Fragment } from 'react'

import { makeStyles } from '@material-ui/core'
import { CONSTANTS } from 'core/constants'
import PropTypes from 'prop-types'
import { isScreenSize } from 'utils/screenSzie'

import { DashboardCard as Card } from '../Card'
import { InfoText, styles, TitleText, TripsContainer, TripsInformation } from './bodyContainerWithTrips.styles'

const useStyles = makeStyles(theme => styles(theme))

const BodyContainerWithTrips = ({ trips, getUserTrips , totalTrips, totalPublished}) => {
  const classes = useStyles()

  let smallScreen = isScreenSize(930)
  return (
    <Fragment>
      {trips && trips.length > 0 && (
        <TripsInformation>
          <TitleText>
            {CONSTANTS.DASHBOARD_TITLE}
          </TitleText>
          {
            totalTrips > 0 &&
            <InfoText>{`(${totalTrips} ${totalTrips > 1 ? 'trips' : 'trip'} in total, ${totalPublished} published)`}</InfoText>
          }
        </TripsInformation>
      )}
      <TripsContainer style={{ justifyContent: 'flex-start' }}>
      {trips &&
        trips.length > 0 &&
          trips.map((trip, k) => {
            return (
              <Card
                width={(k === 0 || smallScreen) ? '100%' : '405px'}
                key={trip.uid}
                trip={trip}
                firstTrip={true}
                getUserTrips={getUserTrips}
              />
            )
          })
        }
        </TripsContainer>
    </Fragment>
  )
}

BodyContainerWithTrips.propTypes = {
  trips: PropTypes.array,
}

export default BodyContainerWithTrips
