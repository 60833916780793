// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { AppDispatch } from '../index'

export type CoordsObject = {
  latitude: number | null
  longitude: number | null
}

const initialState: CoordsObject = {
  latitude: null,
  longitude: null,
}

const geoLocation = createSlice({
  name: 'geoLocation',
  initialState,
  reducers: {
    _setGeoLocation: (state, { payload }: PayloadAction<CoordsObject>) => {
      state.latitude = payload.latitude
      state.longitude = payload.longitude
    },

    _clearGeoLocation: state => {
      state.latitude = null
      state.longitude = null
    },
  },
})

const { _setGeoLocation, _clearGeoLocation } = geoLocation.actions

export const setGeoLocation =
  (data: CoordsObject) => (dispatch: AppDispatch) => {
    try {
      dispatch(_setGeoLocation(data))
    } catch (e) {
      throw new Error((e as Error).message)
    }
  }

export const clearGeoLocation = () => (dispatch: AppDispatch) => {
  try {
    dispatch(_clearGeoLocation())
  } catch (e) {
    throw new Error((e as Error).message)
  }
}

export default geoLocation.reducer
